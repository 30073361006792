<template>
  <div class="print-sect" id="print-multi-receipts" style="direction: rtl;display: none">
    <div class="receipt-container" style="direction: rtl; width: 255px;"
    v-for="receipt in receipts" :key='receipt.id'>
      <table class="table table-striped">
        <tbody style="font-weight:bold !important">
          <tr>
            <td colspan="2">{{ receipt.code }} <span style="float:left">{{ receipt.created_at }}</span></td>
          </tr>
          <tr style="font-weight:bold !important">
            <td style="font-weight:bold !important">المستخدم: {{ receipt.user_name }}</td>
            <td>{{ receipt.branch }}</td>
          </tr>
          <tr v-if="!receipt.lecture && !receipt.revesion">
            <td>نوع الإيصال: {{ receipt.receipt_type_name }}
            </td>
            <td v-if="!receipt.lecture && !receipt.revesion">
            نوع الحركة النقدية: {{ receipt.transaction_type }}
            </td>
          </tr>
          <tr v-if="receipt.lecture && receipt.transaction_type === 'استرداد'">
            <td>نوع الإيصال: {{ receipt.receipt_type_name }}
            </td>
            <td>
             الحركة النقدية: {{ 'استرداد حضور' }}
            </td>
          </tr>
           <tr v-if="receipt.lecture && receipt.transaction_type != 'استرداد'">
            <td colspan="2" style="text-align: center">
              <span style="font-size:35px !important; font-weight: 900  !important;">{{ receipt.number }}</span>
            </td>
          </tr>
          <tr v-if="receipt.lecture">
            <td> المحاضرة : {{ receipt.lecture }}</td>
            <td>رقم الدورة : {{ receipt.course_number }}</td>
          </tr>
          <tr>
            <td colspan="2">الطالب : {{ receipt.student_number + ' - ' + receipt.student}}</td>
          </tr>
          <tr v-if="receipt.course && !receipt.lecture">
            <td v-if="!receipt.revesion && receipt.course" colspan="2">الدورة: {{ receipt.course_number + '-' + receipt.course }}</td>
            <td v-else>المحاضرة : {{ receipt.selectedLectures}} -  {{receipt.course }}</td>
          </tr>
          <tr v-if="receipt.offer && !receipt.lecture">
            <td v-if="!receipt.revesion" colspan="2">العرض: {{ receipt.offer }}</td>
          </tr>
          <tr v-if="receipt.course && receipt.lecture">
            <td  class="info" colspan="2" style="font-weight:bold !important">اسم الدورة: {{ receipt.course }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import setMaterialInput from '@/assets/js/material-input.js'
export default {
  name: 'PrintMultipleReceipt',
  props: {
    receipts: []
  },
  mounted () {
    setMaterialInput()
  }
}
</script>
<style>
#print-receipt .table > :not(caption) > * > * {
  padding: 0px !important;
}
@media print {
  html, body {
      margin-bottom: 0;
      padding: 0;
      margin: 0;
  }

  .receipt-container {
      direction: rtl;
      page-break-after: always !important;
      page-break-inside: avoid !important;
  }
  table {page-break-after: auto}
  tr,td {
      page-break-inside: avoid;
      page-break-after: auto;
  }

}
</style>
