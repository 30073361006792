<template>
  <div class="container-fluid py-4 add-student offline-course">
    <div class="row">
      <div class="col-12">
        <div class="card my-4">
          <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
            <div
              class="bg-gradient-success shadow-success border-radius-lg pt-4 pb-3"
            >
              <h6 class="text-white text-capitalize ps-3">
              {{$t('message.showCourse')}}
              </h6>
            </div>
          </div>
          <div class="card-body px-0 pb-2">
            <form role="form" class="text-start mt-3">
              <div class="row">
                <div class="mb-3 col-lg-6">
                  <label>  {{$t('message.name')}}</label>
                  <vmd-input id="" type="text" :label=" course.number + '-' + course.name" disabled />
                </div>
                <div class="mb-3 col-lg-6">
                  <label> {{$t('message.subject')}}</label>
                  <vmd-input
                    id=""
                    type="text"
                    :label="course.subject"
                    disabled
                  />
                </div>
              </div>
              <div class="row">
                <div class="mb-3 col-lg-6">
                  <label> {{$t('message.teacher')}}</label>
                  <vmd-input
                    id=""
                    type="text"
                    :label="course.instructor"
                    disabled
                  />
                </div>
                <div class="mb-3 col-lg-6">
                  <label> {{$t('message.branch')}}</label>
                  <vmd-input
                    id=""
                    type="text"
                    :label="course.branch"
                    disabled
                  />
                </div>
              </div>
              <div class="row">
                <div class="mb-3 col-lg-6">
                  <label>  {{$t('message.lecturesNum')}}</label>
                  <vmd-input
                    id=""
                    type="text"
                    :label="course.lectures ? course.lectures.length : 0"
                    disabled
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <!-- lectures -->
      <div class="row">
        <div class="col-12">
          <div class="card my-4">
            <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
              <div
                class="bg-gradient-success shadow-success border-radius-lg pt-4 pb-3"
              >
                <h6 class="text-white text-capitalize ps-3">{{$t('message.lectures')}}</h6>
              </div>
            </div>
            <div class="card-body px-0 pb-2">
              <div class="table-responsive p-0">
                <table class="table align-items-center mb-0">
                  <thead>
                    <tr>
                      <th class="text-uppercase x font-weight-bolder o"> {{$t('message.id')}}</th>
                      <th class="text-uppercase x font-weight-bolder o">
                         {{$t('message.totalAttendance')}}
                      </th>
                      <th class="text-uppercase x font-weight-bolder o">
                           {{$t('message.totalAbsence')}}
                      </th>
                      <th class="text-uppercase x font-weight-bolder o">
                          {{$t('message.room')}}
                      </th>
                      <th class="text-uppercase x font-weight-bolder o">
                            {{$t('message.startDate')}}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="lecture in lectures" :key="lecture.id">
                      <td>
                        <p class="text-s mb-0">{{ lecture.lecture_number }}</p>
                      </td>
                      <td>
                        <p class="text-s mb-0">
                          {{ lecture.total_attend_students }}
                        </p>
                      </td>
                      <td>
                        <p class="text-s mb-0">{{ lecture.total_absence }}</p>
                      </td>
                      <td>
                        <p class="text-s mb-0">{{ lecture.room.name }}</p>
                      </td>
                      <td>
                        <p class="text-s mb-0">{{ lecture.start }}</p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- students -->
      <div class="row">
        <div class="col-12">
          <div class="card my-4">
            <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
              <div
                class="bg-gradient-success shadow-success border-radius-lg pt-4 pb-3"
              >
                <h6 class="text-white text-capitalize ps-3">
                       {{$t('message.registeredStudents')}}
                </h6>
              </div>
            </div>
            <div class="card-body px-0 pb-2">
              <div class="table-responsive p-0">
                <table class="table align-items-center mb-0">
                  <thead>
                    <tr>
                      <th class="text-uppercase x font-weight-bolder o">  {{$t('message.id')}}</th>
                      <th class="text-uppercase x font-weight-bolder o">
                          {{$t('message.name')}}
                      </th>
                      <th class="text-uppercase x font-weight-bolder o">
                           {{$t('message.mobile')}}
                      </th>
                       <th class="text-uppercase x font-weight-bolder o">
                           {{$t('message.totalHours')}}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="student in students" :key="student.id">
                      <td>
                        <p class="text-s mb-0">{{ student.number }}</p>
                      </td>
                      <td>
                        <p class="text-s mb-0">{{ student.full_name }}</p>
                      </td>
                      <td>
                        <p class="text-s mb-0">{{ student.mobile }}</p>
                      </td>
                       <td>
                        <p class="text-s mb-0">{{ calcTotalHours(student?.attendance) }}</p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import VmdInput from '@/components/VmdInput.vue'
// import $ from 'jquery'
export default {
  name: 'ShowCourse',
  components: {
    VmdInput
  },
  data () {
    return {
      course: {},
      groupArray: '',
      lectures: [],
      students: []
    }
  },
  created () {
    this.$http
      .get(`${this.$hostUrl}courses/${this.$route.params.id}`)
      .then((response) => {
        this.course = response.data.data
        const sliceEnd = this.course.groups.length - 1
        this.groupArray = this.course.groups
          .split(' ')
          .join('-')
          .slice(0, sliceEnd)
        this.lectures = this.course.lectures
        this.students = this.course.students
      })
  },
  methods: {
    calcTotalHours (attendance) {
      let totalHours = 0

      attendance.forEach(element => {
        const parts = element.attended_duration.split(':')
        const hours = parseInt(parts[0])
        const minutes = parseInt(parts[1])
        const seconds = parseInt(parts[2])

        totalHours += hours
        totalHours += minutes / 60
        totalHours += seconds / 3600
      })

      const hours = Math.floor(totalHours)
      const minutes = Math.floor((totalHours - hours) * 60)
      const seconds = Math.floor(((totalHours - hours) * 60 - minutes) * 60)

      return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`
    }
  }
}
</script>
<style scoped>
.message {
  padding: 10px;
}
</style>
<style>
.offline-course .form-label {
  color: #00000b !important;
  font-size: 14px !important;
  font-weight: 500;
}
</style>
