<template>
     <div class="container-fluid py-4 add-student">
    <div class="row">
      <div class="col-12">
        <div class="card my-4">
          <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
            <div
              class="bg-gradient-success shadow-success border-radius-lg pt-4 pb-3"
            >
              <h6 class="text-white text-capitalize ps-3">  {{$t('message.createUser')}}</h6>
            </div>
          </div>
          <div class="card-body px-0 pb-2">
                   <form @submit="createUser" role="form" class="text-start mt-3">

                             <div class='row'>
                     <div class="mb-3 col-lg-6">
                    <label>  {{$t('message.name')}} <span class="red">*</span>  <span color='danger' class="error">{{errors.name?errors.name[0]:''}}</span></label>
                  <vmd-input
                    id=""
                    type="text"
                   :label=" $t('message.name') "
                   v-model='user.name'
                   required
                  />
                </div>
                  <div class="mb-3 col-lg-6">
                    <label class="">   {{$t('message.email')}} <span class="red">*</span>   <span color='danger' class="error">{{errors.email?errors.email[0]:''}}</span></label>
                  <vmd-input
                    id=""
                    type="email"
                   :label=" $t('message.email')"
                   v-model='user.email'
                   required
                  />
                </div>
                </div>
                <div class="row">
                <div class="mb-3 col-lg-6">
                      <label>  {{$t('message.password')}}<span class="red">*</span>  <span color='danger' class="error">{{errors.password?errors.password[0]:''}}</span></label>
                  <vmd-input
                    id="password"
                    type="password"
                    :label="$t('message.password')  "
                    v-model='user.password'
                    required
                  />
                </div>
                 <div class="mb-3 col-lg-6">
                      <label>   {{$t('message.confirmPassword')}}<span class="red">*</span>  <span color='danger' class="error">{{errors.password_confirmation?errors.password_confirmation[0]:''}}</span></label>
                  <vmd-input
                    id="password"
                    type="password"
                    :label="$t('message.confirmPassword')"
                    v-model='user.passwordConfirmation'
                   required
                  />
                </div>
                       </div>
                       <div class="row">
                         <div class="mb-3 col-lg-6">
                    <label> {{$t('message.job')}} <span class="red">*</span>  <span color='danger' class="error">{{errors.role_id?errors.role_id[0]:''}}</span></label>
                    <multiselect
                        class='required form-control'
                        id='group'
                        v-model="selectedRole"
                        :options='roles'
                        :searchable='true'
                        :close-on-select='true'
                        :placeholder=" $t('message.select') "
                        label='name'
                        :custom-label="customLabel"
                        track-by='name'
                        required='required'
                        :internal-search="true"
                        >
                      >
                        <template v-slot:noOptions>
                          <span> فارغة </span>
                        </template>
                        <template v-slot:noResult>
                          <span>لا يوجد نتيجة </span>
                        </template>
                      </multiselect>
                </div>
                          <div class="mb-3 col-lg-6">
                  <label>   {{$t('message.userType')}} <span class="red">*</span> <span color='danger' class="error">{{errors.user_type_id?errors.user_type_id[0]:''}}</span></label>
                  <div class="input-group input-group-outline null">
                    <select class="form-control" v-model="selectedType">
                      <option selected value="">  {{$t('message.select')}}</option>
                      <option v-for ="type in userTypes" :key='type.id' :value='type.id'>{{type.name}}</option>
                    </select>
                    <i class="fas fa-caret-down" aria-hidden="true"></i>
                  </div>
                </div>
                </div>
                <div class="row">
                       <div  v-if="selectedType === 2" class="mb-3 col-lg-6">
                    <label> {{$t('message.branch')}} <span class="red">*</span>  <span color='danger' class="error">{{errors.role_id?errors.role_id[0]:''}}</span></label>
                    <multiselect
                        class='required form-control'
                        id='group'
                        v-model="selectedBranch"
                        :options='branches'
                        :searchable='true'
                        :close-on-select='true'
                        :placeholder="  $t('message.select') "
                        label='name'
                        :custom-label="customLabel"
                        track-by='name'
                        required='required'
                        :internal-search="true"
                        >
                      >
                        <template v-slot:noOptions>
                          <span> فارغة </span>
                        </template>
                        <template v-slot:noResult>
                          <span>لا يوجد نتيجة </span>
                        </template>
                      </multiselect>
                </div>
                    <div class="mb-3 col-lg-6" v-if="selectedRole.id === 141">
                    <label> المدرس<span class="red">*</span>  <span color='danger' class="error">{{errors.instructor_id?errors.instructor_id[0]:''}}</span></label>
                    <multiselect
                        class='required form-control'
                        id='group'
                        v-model="selectedInstructor"
                        :options='instructors'
                        :searchable='true'
                        :close-on-select='true'
                        :placeholder="  $t('message.select') "
                        label='name'
                        :custom-label="customLabel"
                        track-by='name'
                        required='required'
                        :internal-search="true"
                        >
                      >
                        <template v-slot:noOptions>
                          <span> فارغة </span>
                        </template>
                        <template v-slot:noResult>
                          <span>لا يوجد نتيجة </span>
                        </template>
                      </multiselect>
                </div>
                  </div>
                       <div class="row">
                <div class="text-center col-lg-6">
                  <vmd-button
                    class="my-4 mb-2"
                    variant="gradient"
                    color="info"
                    > {{$t('message.save')}}</vmd-button
                  >
                </div>
                 <div class="text-center col-lg-6">
                  <vmd-button
                  type='reset'
                    class="my-4 mb-2"
                    variant="gradient"
                    color="danger"
                    > {{$t('message.delete')}}</vmd-button
                  >
                </div>
                       </div>
              </form>
          </div>
        </div>
      </div>
    </div>
     </div>
</template>
<script>
import VmdInput from '@/components/VmdInput.vue'
import VmdButton from '@/components/VmdButton.vue'
import $ from 'jquery'
export default {
  name: 'AddUser',
  components: {
    VmdInput,
    VmdButton
  },
  data () {
    return {
      user: {},
      roles: [],
      selectedRole: '',
      userTypes: [],
      selectedType: '',
      branches: [],
      selectedBranch: '',
      centerType: '',
      instructors: [],
      selectedInstructor: '',
      errors: []
    }
  },
  created () {
    this.centerType = localStorage.centerType
    if (this.centerType === 'semester') {
      this.$http.get(`${this.$hostUrl}instructors`)
        .then((response) => {
          this.instructors = response.data.data
        })
    }
    const roles = []
    this.$http.get(`${this.$hostUrl}roles`)
      .then((response) => {
        $.each(response.data.data, function (key, value) {
          roles.push({
            name: value.name,
            id: value.id
          })
        })
        this.roles = roles
      })
    this.userTypes = [
      { id: 1, name: 'كل الفروع' },
      { id: 2, name: 'فرع واحد' }
    ]

    if (this.centerType === 'semester') {
      const branches = []
      this.$http.get(`${this.$hostUrl}branches`).then((response) => {
        $.each(response.data.data, function (key, value) {
          branches.push({
            name: value.name,
            id: value.id
          })
        })
        this.branches = branches
      })
    }
  },
  methods: {
    async createUser (e) {
      e.preventDefault()
      const formData = new FormData()
      formData.append('name', this.user.name)
      formData.append('email', this.user.email)
      formData.append('password', this.user.password)
      formData.append('password_confirmation', this.user.passwordConfirmation)
      if (this.selectedBranch) {
        formData.append('branch_id', this.selectedBranch.id)
      }
      if (this.selectedRole.id === 141) {
        formData.append('instructor_id', this.selectedInstructor.id)
      }
      formData.append('role_id', this.selectedRole.id)
      formData.append('user_type_id', this.selectedType)

      this.errors = await this.postRequest(formData, this.$hostUrl + 'users', '/users')
    }
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>
