<template>
  <div class="container-fluid py-4 add-student grade-detail">
    <div class="row">
      <div class="col-12">
        <div class="card my-4">
          <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
            <div
              class="bg-gradient-success shadow-success border-radius-lg pt-4 pb-3"
            >
              <div class="row">
                <div class="col-lg-6">
                  <h6 class="text-white text-capitalize ps-3">   تقارير الطلاب طبقا للدورة التدريبية   </h6>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body px-0 pb-2">
            <!-- exceptional attendance row -->
         <div class="row align-center ex-row">
          <div class="mb-3 col-lg-4">
                    <label>   اسم الدورة<span class="red">*</span></label>
                    <multiselect
                        class='required form-control'
                        id='grade'
                        v-model="selectedCourse"
                        :options='courses'
                        :searchable='true'
                        :close-on-select='true'
                        placeholder=" اختر الدورة"
                        label='name'
                        :custom-label="customLabel"
                        track-by='id'
                        required='required'
                        :internal-search="true"
                        >
                      >
                        <template v-slot:noOptions>
                          <span> فارغة </span>
                        </template>
                        <template v-slot:noResult>
                          <span>لا يوجد نتيجة </span>
                        </template>
                      </multiselect>
                </div>

                  <div class="text-center col-lg-2">
                  <vmd-button
                    class="my-4 mb-2 width pointer"
                    variant="gradient"
                    color="primary"
                    @click="filterData()"
                    >  فلتر</vmd-button
                  >
                </div>
                  <div class="text-center col-lg-2">
                  <vmd-button
                    class="my-4 mb-2 width pointer"
                    variant="gradient"
                    color="danger"
                    @click="clearData()"
                    >  مسح</vmd-button
                  >
                </div>
                 <div class="text-center col-lg-2">
                  <vmd-button
                    class="my-4 mb-2 width pointer"
                    variant="gradient"
                    color="info"
                    @click="printReport()"
                    >  طباعة</vmd-button
                  >
                </div>
                  <div class="text-center col-lg-2">
                  <vmd-button
                    class="my-4 mb-2 width pointer"
                    variant="gradient"
                    color="warning"
                     @click="exportTableToExcel('myTable')"
                    >  اكسل</vmd-button
                  >
                </div>
              </div>
                   <div class='row times course-info '>
                <div class="col-md-4" >
                  <h5 class="info-h">الطلاب المسجلين في الدورة : <span class="green info-data">{{courseInfo.allStudents}}</span></h5>
                </div>
                <div class="col-md-2"  v-for="item in courseInfo.groupsStudents" :key="item.id" >
                   <h5 class="info-h"> مج {{item.group_name ?? '' }} : <span class="green info-data">{{item.students_count ?? 'لا يوجد'}}</span></h5>
                </div>
              </div>
              <div id='print-report' style=" margin-top: 40px;direction:rtl">
                <div class='row times' style=" margin-top: 40px;direction:rtl">
                <div class="col-md-8" >
                  <h5>الدورة التدريبية : <span class="green">{{courseName}}</span></h5>
                </div>
                <div class="col-md-4">
                  <h5>   الفرقة : <span class="green">{{grade}}</span></h5>
                </div>
              </div>
            <div class="row align-center" style=" margin-top: 40px;direction:rtl" >
              <div class="text-center col-lg-12 overflow-x" style="direction:rtl">
                <table id="myTable" class=" b-block  table " style="direction:rtl">
                  <thead>
                    <tr>
                      <th >رقم</th>
                        <th>اسم الطالب</th>
                        <th>الهاتف</th>
                        <th>الفرقة</th>
                         <th>المجموعة</th>
                        </tr>

                  </thead>
                  <tbody>
                    <tr v-for="student in students" :key="student.id">
                      <td>{{student.number}}</td>
                      <td>{{student.full_name}}</td>
                       <td>{{student.mobile}}</td>
                       <td>{{student.grade?student.grade.name:'-'}}</td>
                       <td>{{student.group?student.group.name:'-'}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            </div>
          </div>
   <!-- seconed tab -->
          </div>
          </div>

      </div>
    </div>
</template>
<script>
import VmdButton from '@/components/VmdButton.vue'
import swal from 'sweetalert'
import $ from 'jquery'
import * as XLSX from 'xlsx'
// import exportFromJSON from 'export-from-json'
const today = new Date()
const date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate()
const time = today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds()
const CurrentDateTime = date + ' ' + time
export default {
  name: 'AreaReports',
  components: {
    VmdButton
  },
  data () {
    return {
      courses: [],
      selectedCourse: '',
      students: [],
      courseName: '',
      grade: '',
      fileName: '',
      courseInfo: {},
      errors: []
    }
  },
  created () {
    this.$http.get(`${this.$hostUrl}courses`).then(response => {
      response.data.data.forEach((course) => {
        this.courses.push({
          id: course.id,
          name: course.number + ' - ' + course.name
        })
      })
    })
  },
  methods: {
    filterData () {
      if (!this.selectedCourse) {
        swal('يجب ادخال الدورة')
      } else {
        this.$http.get(`${this.$hostUrl}courses/${this.selectedCourse.id}`).then(response => {
          this.students = response.data.data.students
          this.courseName = response.data.data.number + ' - ' + response.data.data.name
          this.grade = response.data.data.grade
          this.courseInfo = {
            allStudents: response.data.data.total_students,
            absenceCount: response.data.data.total_absence,
            attendanceCount: response.data.data.total_attended,
            groupsStudents: response.data.data.groups_students
          }
        })
        console.log(this.courseInfo.groupsAttendance)
      }
    },
    clearData (data) {
      this.selectedCourse = ''
      this.courseName = ''
      this.grade = ''
      this.students = []
      this.courseInfo = {}
    },
    printReport () {
    // $('.card-footer').hide()
      const options = {
        name: ' Seven Eleven System ',
        specs: ['fullscreen=yes', 'titlebar=yes', 'scrollbars=yes'],
        styles: [
          'https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.rtl.min.css',
          `${window.location.origin}/print-report.css`
        ],
        timeout: 1000, // default timeout before the print window appears
        autoClose: true, // if false, the window will not close after printing
        windowTitle: 'Seven Eleven System' // override the window title
      }

      setTimeout(this.$htmlToPaper('print-report', options), 10000)
      $('.card-footer').show()
    },
    exportTableToExcel (tableID) {
      console.log('export excel all')
      var wb = XLSX.utils.table_to_book(document.getElementById(tableID))
      var wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
      var blob = new Blob([wbout], { type: 'application/octet-stream' })
      var url = URL.createObjectURL(blob)
      var a = document.createElement('a')
      document.body.appendChild(a)
      a.href = url
      a.download = `تقرير الطلاب  لدورة ${this.courseName}  ${CurrentDateTime}` + '.xlsx'
      a.click()
      setTimeout(function () {
        URL.revokeObjectURL(url)
        document.body.removeChild(a)
      }, 0)
    }
  }
}

</script>
<style>
#collection-date{
  font-weight: 800 !important;
  font-size: 18px !important;
}
.table > :not(:first-child) {
    border-bottom: 1px solid #f0f2f5 !important;
}
.width{
  width:100%;
}
.online{
  border:1px solid #ddd
}
.times{
  margin-top: 10px !important;
}
.times h5{
  font-size:16px !important
}
.times .red{
  position: relative !important;
}
.filter-text{
  font-weight: 700;
    color: #dfad07;
    font-size: 15px !important;
}
.tab2{
  margin-top: 27px !important;
    margin-bottom: 41px !important;
    border-bottom: 1px solid #ddd;
    padding-bottom: 26px !important;
}
.course-info{
      background: #f8f9fa;
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 38px;
    font-size: 14px;
}
.info-h{
      font-size: 14px !important;
    font-weight: 500;
}
.groups-ul{
  background: #fff;
    padding-top: 10px;
    padding-bottom: 10px;
    width: 206px;
    border-top: 2px solid #5cb360;
    box-shadow: 0 4px 6px -1px rgb(0 0 0 / 10%), 0 2px 4px -1px rgb(0 0 0 / 6%);
    display: none;
    position:absolute;
    border-radius: 10px;
}
/* .groups-con{
  color:blue;
  cursor: pointer;
} */
.groups-con:hover .groups-ul{
  display: block;
}

.info-data{
      font-family: "Roboto", Helvetica, Arial, sans-serif !important;
      font-weight:bold !important;
      font-size:17px !important
}

</style>
