<template>
     <div class="container-fluid py-4 add-student grade-detail reservation ">
    <div class="row">
      <div class="col-12">
        <div class="card my-4">
          <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
            <div
              class="bg-gradient-success shadow-success border-radius-lg pt-4 pb-3"
            >
             <div class="row">
              <div class="col-lg-6">
              <h6 class="text-white text-capitalize ps-3"> الحجز في مادة : {{subject.name}}</h6>
              </div>
              </div>
            </div>
          </div>
          <div class="card-body px-0 pb-2">
            <form @submit="reservation">
            <div class="row align-center">
              <div class="mb-3 col-lg-5">
                    <label> أدخل رقم   الطالب أو الهاتف  <span class="red">*</span></label>
                  <vmd-input
                    id=""
                    type="text"
                   label="  رقم الطالب أو الهاتف"
                   v-model="student.mobile"
                   @input='search'
                   required
                  />
              </div>
                </div>
                <div class="row align-center">
                  <h5 id='data'>بيانات الطالب</h5>
                 <div class="mb-3 col-lg-5">
                    <label> اسم الطالب </label>
                  <vmd-input
                    id=""
                    type="text"
                   v-model="student.full_name "
                   disabled
                  />
              </div>
            </div>
            <div class="text-center col-lg-2">
              <vmd-button
              style=" width: 100%;"
                class="my-4 mb-2"
                variant="gradient"
                color="info"
                > حجز
              </vmd-button>
            </div>
          </form>َ
      </div>
    </div>
                <!-- current regesteriation -->
     <div class="row reseve-row">
      <div class="col-12">
        <div class="card my-4">
          <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
            <div
              class="bg-gradient-success shadow-success border-radius-lg pt-4 pb-3"
            >
            <div class="row">
               <div class="col-lg-6">
              <h6 class="text-white text-capitalize ps-3">   حجوزات الطلاب في مادة : {{subject.name}} </h6>
              </div>
             <div class="col-lg-6">
               <span @click=" printReservations()" class="badge badge-sm  new-student pointer"><i class="fas fa-print "></i> طباعة </span>
               </div>
            </div>
          </div>
          </div>
          <div class="card-body px-0 pb-2" id="printMe" style="direction:rtl">
            <div class="table-responsive p-0" style="direction:rtl">
              <table class="table align-items-center mb-0 " style="direction:rtl">
                <thead>
                  <tr>
                      <th
                      class="text-uppercase text-secondary  font-weight-bolder opacity-7"
                    >
                      رقم الطالب
                    </th>
                    <th
                      class="text-uppercase text-secondary  font-weight-bolder opacity-7"
                    >
                      اسم الطالب
                    </th>
                       <th
                      class="text-uppercase text-secondary  font-weight-bolder opacity-7"
                    >
                        رقم الهاتف
                    </th>
                  </tr>
                </thead>
                 <tbody>
                  <tr v-for='item in reservedStudents' :key="item.id" >
                     <td>
                     <p class=" text-s  mb-0">{{item.number}} </p>
                    </td>
                    <td>
                     <p class=" text-s  mb-0">{{item.name}} </p>
                    </td>
                     <td>
                     <p class=" text-s  mb-0">  {{item.mobile}}  </p>
                    </td>
                  </tr>
                 </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
      </div>
    </div>
     </div>
</template>
<script>
import VmdInput from '@/components/VmdInput.vue'
import VmdButton from '@/components/VmdButton.vue'
import swal from 'sweetalert'
import $ from 'jquery'
let timeout = null
export default {
  name: 'Reservation',
  components: {
    VmdButton,
    VmdInput
  },
  data () {
    return {
      student: {},
      subject: {},
      reservedStudents: []

    }
  },
  created () {
    const reservedStudents = []
    this.$http.get(`${this.$hostUrl}subjects/${this.$route.params.id}`).then(response => {
      this.subject = response.data.data
      $.each(this.subject.students, function (key, val) {
        reservedStudents.push({
          id: val.id,
          number: val.number,
          name: val.full_name,
          mobile: val.mobile
        })
      })
      this.reservedStudents = reservedStudents
    })
  },
  methods: {
    search () {
      this.student.id = null
      this.student.full_name = null
      const current = this
      clearTimeout(timeout)
      timeout = setTimeout(function () {
        current.$http
          .get(`${current.$hostUrl}students-list?search=${current.student.mobile}`)
          .then((response) => {
            if (response.data.data.length === 1) {
              current.student.full_name = response.data.data[0].full_name
              current.student.id = response.data.data[0].id
            } else {
              current.student.id = null
              current.student.full_name = null
              swal('الطالب غير موجود')
            }
            if (response.data.error) {
              swal(response.data.message)
            }
          })
      }, 800)
    },
    async reservation (e) {
      e.preventDefault()
      const formData = new FormData()
      formData.append('subject_id', this.$route.params.id)
      formData.append('student_id', this.student.id)

      this.errors = await this.postRequest(formData, this.$hostUrl + 'reservations', '')
      const reservedStudents = []
      this.$http.get(`${this.$hostUrl}subjects/${this.$route.params.id}`).then(response => {
        this.subject = response.data.data
        $.each(this.subject.students, function (key, val) {
          reservedStudents.push({
            id: val.id,
            number: val.number,
            name: val.full_name,
            mobile: val.mobile
          })
        })
        this.reservedStudents = reservedStudents
      })
    },
    printReservations () {
      $('.card-footer').hide()
      const options = {
        name: ' Seven Eleven System ',
        specs: ['fullscreen=yes', 'titlebar=yes', 'scrollbars=yes'],
        styles: [
          'https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.rtl.min.css'
          // `${basePath}/print.css`
        ],
        timeout: 1000, // default timeout before the print window appears
        autoClose: true, // if false, the window will not close after printing
        windowTitle: 'Seven Eleven System' // override the window title
      }
      setTimeout(this.$htmlToPaper('printMe', options), 10000)
      $('.card-footer').show()
    }

  }
}

</script>
<style >
#data{
    background: #55ae59;
    color: #fff;
    padding: 13px;
    border-radius: 8px;
    margin: 30px 0px;
    }
    .reseve-row{
      margin-top: 70px !important;
    }
</style>
