<template>
  <div class="container-fluid py-4 instr-report">
    <div class="row">
      <div class="col-12">
        <div class="card mx-4">
          <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
            <div
              class="bg-gradient-success shadow-success border-radius-lg pt-4 pb-3"
            >
              <h6 class="text-white text-capitalize ps-3">
                اجمالي تحصيلات المدرس
              </h6>
            </div>
          </div>
          <div class="card-body px-4 pb-2" style="direction: rtl">
            <div class="row">
              <div class="mb-3 col-6">
                <label>
                  المدرسين <span color="danger" class="red">*</span>
                </label>
                <multiselect
                  class="required form-control"
                  id="grade"
                  v-model="selectedInstructor"
                  :options="instructors"
                  @select="updateData()"
                  :searchable="true"
                  :close-on-select="true"
                  placeholder=" اختر مدرس"
                  label="name"
                  track-by="id"
                  required="required"
                  :internal-search="true"
                >
                  <template v-slot:noOptions>
                    <span> فارغة </span>
                  </template>
                  <template v-slot:noResult>
                    <span>لا يوجد نتيجة </span>
                  </template>
                </multiselect>
              </div>
              <div class="col-2">
                <button
                  @click="filterData"
                  type="button"
                  class="my-4 mb-2 btn bg-gradient-primary width"
                >
                  <span class="d-none d-sm-block">فلتر</span>
                  <i class="fas fa-print d-block d-sm-none" title="فلتر"></i>
                </button>
              </div>
              <div class="col-2">
                <button
                  @click="printReport"
                  type="button"
                  class="my-4 mb-2 btn bg-gradient-info width"
                >
                  <span class="d-none d-sm-block">طباعة</span>
                  <i class="fas fa-print d-block d-sm-none" title="طباعة"></i>
                </button>
              </div>
            </div>
            <div class="col-12">
            <h5 class="p-2">المدرس: {{ selectedInstructor.name }} - اجمالي الدورات : <span class="green">{{ (totalOfflineCourses ?? 0) + (totalOnlineCourses ?? 0)}}</span></h5>
            </div>
            <hr />
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-12">
        <div
          class="card mx-4 print-sect"
          id="print-report"
          style="direction: rtl"
        >
          <div class="card-body px-4 pb-2" style="direction: rtl">
            <div style="direction: rtl" class="overflow-x">
              <div class="row">
                <div class="card-header p-0 position-relative mx-3 z-index-2">
                  <div
                    class="bg-gradient-success shadow-success border-radius-lg pt-4 pb-3"
                  >
                    <h6 class="text-white text-capitalize ps-3">الأوفلاين - عدد الدورات {{totalOfflineCourses}}</h6>
                  </div>
                </div>
                <div class="col-12">
                  <div class="row mt-4">
                    <div
                      class="col-12"
                      v-for="(value, key) in instrCollections.offline_courses"
                      :key="key"
                    >
                      <h6
                        class="border p-2 br-h6"
                        v-if="key !== 'total_payment'"
                      >
                        الفرع: {{ key }}
                      </h6>
                      <table class="table" v-if="key !== 'total_payment'">
                        <thead>
                          <th>رقم الدورة</th>
                          <th>اسم الدورة</th>
                          <th>اجمالي التحصيل</th>
                        </thead>
                        <tbody
                          v-for="(subValue, subKey) in value"
                          :key="subKey"
                        >
                          <tr v-if="subKey !== 'total_payment'">
                            <td>{{ subValue.course_number }}</td>
                            <td ><router-link class="pointer course-link" :to="{path: '/courses/'+subValue.id+'/show'}">{{ subValue.course }}</router-link></td>
                            <td>
                              {{
                                $filters.number(subValue.instructor_total_paid)
                              }}
                            </td>
                          </tr>
                        </tbody>
                            <tfoot  v-for="(subValue, subKey) in value"
                          :key="subKey" style="background:#dad6d669;font-weight:bold">
                              <tr  v-if="subKey === 'total_payment'">
                      <td >اجمالي الفرع</td>
                      <td>عدد الدورات : {{Object.keys(value).length-1}}</td>
                       <td>{{$filters.number(subValue)}}</td>
                              </tr>
                    </tfoot>
                      </table>
                    </div>
                  </div>
                  <!-- total -->
                </div>
                <div class="col-12">
                  <h6 class="border p-2 total-bra">
                    إجمالي الأوفلاين:
                    {{
                      $filters.number(
                        instrCollections.offline_courses
                          ? instrCollections.offline_courses.total_payment
                          : 0
                      )
                    }}
                  </h6>
                </div>
              </div>
              <div class="row mt-4">
                <div
                  class="card-header p-0 position-relative  mx-3 z-index-2"
                >
                  <div
                    class="bg-gradient-success shadow-success border-radius-lg pt-4 pb-3"
                  >
                    <h6 class="text-white text-capitalize ps-3">الأونلاين - عدد الدورات : {{instrCollections.online_courses?Object.keys(instrCollections.online_courses).length-1: null}}</h6>
                  </div>
                </div>
                <div class="">
                  <div class="col-12 mt-4" v-if="instrCollections.online_courses">
                      <table class="table">
                        <thead>
                          <th>رقم الدورة</th>
                          <th>اسم الدورة</th>
                          <th>اجمالي التحصيل</th>
                        </thead>
                        <tbody>
                          <!-- eslint-disable-next-line vue/no-v-for-template-key -->
                          <template v-for="(value, key) in instrCollections.online_courses" :key="key">
                          <tr v-if="key !== 'total_payment'">
                            <td>{{ value.course_number }}</td>
                            <td ><router-link class="pointer course-link" :to="{path: '/courses/'+value.id+'/show'}">{{ value.course }}</router-link></td>
                            <td>
                              {{ $filters.number(value.instructor_total_paid) }}
                            </td>
                          </tr>
                          </template>
                        </tbody>
                      </table>
                  </div>
                  <!-- branch -->
                  <!-- total -->
                </div>
                <div class="col-12">
                  <h6 class="border p-2 total-bra">
                    إجمالي الأونلاين:
                    {{
                      $filters.number(
                        instrCollections.online_courses
                          ? instrCollections.online_courses.total_payment
                          : 0
                      )
                    }}
                  </h6>
                </div>
              </div>
              <div class="col-12 mt-4">
                  <h6 class="border p-2 total-bra">
                     الإجمالي الكلي:
                    {{
                      $filters.number(
                        instrCollections
                          ? instrCollections.total_payment
                          : 0
                      )
                    }}
                  </h6>
                </div>
            </div>
          </div>
          <!-- attendance report -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import * as XLSX from 'xlsx'
// import { utils, writeFile } from 'xlsx'
import $ from 'jquery'
export default {
  name: 'InstructorCollections',
  components: {},
  data () {
    return {
      instructors: [],
      selectedInstructor: '',
      instrCollections: {}
    }
  },
  created () {
    this.$http.get(`${this.$hostUrl}instructors`).then((response) => {
      this.instructors = response.data.data
    })
  },
  computed: {
    totalOfflineCourses () {
      if (this.instrCollections?.offline_courses) {
        let count = 0
        for (const key in this.instrCollections.offline_courses) {
          if (key !== 'total_payment') {
            const subKeys = Object.keys(this.instrCollections.offline_courses[key])
            const filteredKeys = subKeys.filter(subKey => subKey !== 'total_payment')
            count += filteredKeys.length
          }
        }
        return count
      }
      return 0
    },
    totalOnlineCourses () {
      if (this.instrCollections?.online_courses) {
        return Object.keys(this.instrCollections.online_courses)
          .filter(key => key !== 'total_payment')
          .length
      }
      return 0
    }

  },
  methods: {
    filterData () {
      this.instrCollections = {}
      this.instrCollections.offline_courses = {}
      this.$http
        .get(
          `${this.$hostUrl}report/instructor/grouped/courses?instructor_id=${this.selectedInstructor.id}`
        )
        .then((response) => {
          this.instrCollections = response.data.data
        })
    },
    updateData () {
      this.instrCollections = {}
    },
    printReport () {
      $('.card-footer').hide()
      const options = {
        name: ' Seven Eleven System ',
        specs: ['fullscreen=yes', 'titlebar=yes', 'scrollbars=yes'],
        styles: [
          'https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.rtl.min.css',
          `${window.location.origin}/print-report.css`
        ],
        timeout: 1000, // default timeout before the print window appears
        autoClose: true, // if false, the window will not close after printing
        windowTitle: 'Seven Eleven System' // override the window title
      }
      setTimeout(this.$htmlToPaper('print-report', options), 10000)
      $('.card-footer').show()
    },
    // prepareExportData (data) {
    //   const exportData = []

    //   // Prepare the header row
    //   const header = [
    //     'الفرع',
    //     'المادة',
    //     'المجموعة',
    //     'عدد الطلاب',
    //     'قيمة السداد  ',
    //     ' الإجمالي'
    //   ]
    //   exportData.push(header)

    //   // Iterate through the data and fill exportData
    //   for (const branch in data) {
    //     for (const subject in data[branch]) {
    //       for (const group in data[branch][subject]) {
    //         for (const item in data[branch][subject][group]) {
    //           const row = [
    //             branch,
    //             subject,
    //             group,
    //             data[branch][subject][group][item].course_student_count,
    //             data[branch][subject][group][item].course_payed,
    //             data[branch][subject][group][item].total
    //           ]
    //           exportData.push(row)
    //         }
    //       }
    //     }
    //   }

    //   return exportData
    // },
    exportToExcel (tableId) {
      var wb = XLSX.utils.table_to_book(document.getElementById(tableId))
      var wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
      var blob = new Blob([wbout], { type: 'application/octet-stream' })
      var url = URL.createObjectURL(blob)
      var a = document.createElement('a')
      document.body.appendChild(a)
      a.href = url
      a.download = `تقرير  الدورات للمدرس ${this.instructor.name}.xlsx`
      a.click()
      setTimeout(function () {
        URL.revokeObjectURL(url)
        document.body.removeChild(a)
      }, 0)
    }

  }
}
</script>
<style>
.link {
  color: blue !important;
}
.students-payments .modal-content {
  max-height: 500px;
  overflow-y: auto;
}
</style>
<style scoped>
th h4 {
  color: #d1fdd3;
}
.date {
  padding-left: 130px;
}
span {
  margin-right: 2px;
  margin-left: 2px;
}
.line-height {
  line-height: 47px;
  color: #222 !important;
  width: 28% !important;
}
.br-h6 {
  background: rgb(224, 218, 221) !important;
}
.total-bra {
  background: rgb(240, 242, 245) !important;
}
.course-link{
  color: blue !important
}
</style>
