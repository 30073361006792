<template>
  <div class="container-fluid py-4 add-student add-receipt">
    <div class="row">
      <div class="col-12">
        <div class="card my-4">
          <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
            <div
              class="bg-gradient-success shadow-success border-radius-lg pt-4 pb-3"
            >
              <h6 class="text-white text-capitalize ps-3">إضافة إيصال مراجعة</h6>
            </div>
          </div>
          <div class="card-body px-0 pb-2">
            <form @submit="createReceipt" role="form" class="text-start mt-3">
              <div class="row align-center">
                <div class="mb-3 col-lg-3">
                  <label>  ادخل رقم الطالب أو الهاتف<span class="red">*</span></label>
                  <vmd-input
                    id="student-number"
                    type="text"
                    placeholder="  رقم الطالب أو الهاتف "
                    v-model="student.number"
                    @keyup.enter="search"
                    required
                  />
                </div>
                <div class="mb-3 col-lg-3">
                  <label> اسم الطالب </label>
                  <vmd-input
                    id="student-name"
                    type="text"
                    v-model="student.full_name"
                    readonly
                  />
                </div>
                <div class="mb-3 col-lg-3">
                  <label> رقم هاتف الطالب </label>
                  <vmd-input
                    id="student-mobile"
                    type="text"
                    v-model="student.mobile"
                    readonly
                  />
                </div>
                <div class="mb-3 col-lg-3">
                  <label>الفرقة </label>
                  <vmd-input
                    id="student-grade"
                    type="text"
                    v-model="student.grade_name"
                    readonly
                  />
                </div>
              </div>
              <div class="row">
                <div class="mb-3 col-lg-6">
                  <label>
                    الدورة <span color="danger" class="red">*</span
                    ><span class="error">{{
                      emptyCourse ? emptyCourse  : ''
                    }}</span></label
                  >
                  <multiselect
                    class="required form-control"
                    id="grade"
                    v-model="selectedCourse"
                    :options="courses"
                    :searchable="true"
                    :close-on-select="true"
                    placeholder=" اختر الدورة"
                    label="name"
                    track-by="id"
                    required="required"
                    :internal-search="true"
                    @select="getActiveLectures"
                  >
                    <template v-slot:noOptions>
                      <span> فارغة </span>
                    </template>
                    <template v-slot:noResult>
                      <span>لا يوجد نتيجة </span>
                    </template>
                  </multiselect>
                </div>
                <div class="mb-3 col-lg-6">
                  <label>
                    المحاضرة <span color="danger" class="red">*</span
                    ><span class="error">{{
                      errors.lecture_id ? errors.lecture_id[0] : ''
                    }}</span></label
                  >
                  <multiselect
                    class="required form-control"
                    id="grade"
                    v-model="selectedLecture"
                    :options="activeLectures"
                    :searchable="true"
                    :multiple="true"
                    :close-on-select="true"
                    placeholder=" اختر محاضرة"
                    label="name"
                    track-by="id"
                    required="required"
                    @select="getLectureCost"
                    @remove="removeLecture"
                    :internal-search="true"
                  >
                    <template v-slot:noOptions>
                      <span> فارغة </span>
                    </template>
                    <template v-slot:noResult>
                      <span>لا يوجد محاضرات اليوم </span>
                    </template>
                  </multiselect>
                </div>
              </div>
              <div class="row">
                <div class="mb-3 col-lg-6">
                  <label>
                    القيمة الإجمالية <span color="danger" class="red">*</span
                    ><span class="error">{{
                      errors.amount ? errors.amount[0] : ''
                    }}</span></label
                  >
                  <vmd-input
                    id=""
                    type="number"
                    placeholder="القيمة"
                    v-model="receipt.amount"
                  />
                </div>
                <div class="mb-3 col-lg-6">
                  <label>
                    البيان <span color="danger" class="red">*</span
                    ><span class="error">{{
                      errors.description ? errors.description[0] : ''
                    }}</span></label
                  >
                  <vmd-input id="" type="text" v-model="receipt.description" />
                </div>
              </div>
              <br/>
              <div class="row">
                <div class="text-center col-lg-6">
                  <vmd-button class="my-4 mb-2" variant="gradient" color="info">
                    حفظ</vmd-button
                  >
                </div>
                <div class="text-center col-lg-6">
                  <vmd-button
                    type="reset"
                    class="my-4 mb-2"
                    variant="gradient"
                    color="danger"
                  >
                    مسح</vmd-button
                  >
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div>
      <PrintReceipt :receipt="receipt"></PrintReceipt>
    </div>
  </div>
</template>
<script>
import VmdInput from '@/components/VmdInput.vue'
import VmdButton from '@/components/VmdButton.vue'
import PrintReceipt from '@/components/PrintReceipt.vue'
import swal from 'sweetalert'
import $ from 'jquery'

// const date = new Date()
// const today =
//   date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate()
let timeout = null

export default {
  name: 'AddRevesionReceipt',
  components: {
    PrintReceipt,
    VmdInput,
    VmdButton
  },
  data () {
    return {
      receipt: {
        number: null,
        full_name: null,
        revesion: true,
        selectedLectures: [],
        emptyCourse: '',
        amount: '',
        title: '',
        description: 'سداد',
        user_name: localStorage.userName,
        branch: localStorage.branchName,
        trans: '2a187fd8-574f-4260-8e18-cd75ccea891f'
      },
      students: [],
      courses: [],
      student: {
        id: null,
        full_name: null,
        mobile: null,
        number: null
      },
      selectedCourse: '',
      coursePayed: 0,
      selectedLecture: '',
      activeLectures: [],
      receiptTypes: [],
      transactionTypes: [],
      selectedType: '',
      enrolled: '',
      errors: []
    }
  },
  created () {
    this.receiptTypes = [
      { id: 'a7e640c6-dbe0-4224-b9a2-e53c7d406a8c', name: 'ملىْ المحفظه' },
      { id: 'cec350eb-de0c-4fba-8e0d-110d236a95e8', name: 'محفظه' },
      { id: 'd2d3aa71-8c7e-4f94-bbc9-ec516f1ba759', name: 'نقدي' }
    ]

    this.transactionTypes = this.$store.state.transactionTypes

    if (this.$route.params.id) {
      this.$http.get(`${this.$hostUrl}students/${this.$route.params.id}`).then((response) => {
        this.student = response.data.data
        // this.getStudentCourse(this.$route.params.id)
      })
    }
  },
  methods: {
    getActiveLectures (event) {
      const current = this
      console.log('event', event)
      if (this.student.payments) {
        this.student.payments.forEach(function (receipt) {
          if (receipt.payable.id === event.id) {
            if (
              receipt.transaction_type_id ===
            '83ccdbfa-a2af-4c14-b325-4568a882cf37'
            ) {
              current.coursePayed -= receipt.amount
            } else {
              current.coursePayed += receipt.amount
            }
          }
        })
      }
      const activeLectures = []
      this.$http
        .get(
          `${this.$hostUrl}course/${event.id}/lectures`
        )
        .then((response) => {
          $.each(response.data.data, function (key, val) {
            console.log(val)
            activeLectures.push({
              name: val.lecture_number,
              lecture_number: val.lecture_number,
              courseName: val.course_name,
              course: {
                id: val.course.id,
                name: val.course_name
              },
              grade: val.grade,
              id: val.id
            })
          })
          this.activeLectures = activeLectures
        })
    },
    search () {
      const current = this
      this.student.id = null
      this.student.full_name = null
      this.student.grade_name = null
      this.student.mobile = null
      this.student.payments = []
      this.student.totalPayment = 0
      this.selectedLecture = ''
      this.selectedCourse = ''
      if (!this.student.number.length) {
        return false
      }
      clearTimeout(timeout)
      timeout = setTimeout(() => {
        this.$http
          .get(`${this.$hostUrl}students-list?search=${this.student.number}`)
          .then((response) => {
            if (response.data.data.length === 1) {
              this.student.full_name = response.data.data[0].full_name
              this.receipt.student = response.data.data[0].full_name
              this.receipt.student_number = response.data.data[0].number
              this.student.mobile = response.data.data[0].mobile
              this.student.id = response.data.data[0].id
              this.student.grade_name = response.data.data[0].grade_name
              current.courses = response.data.data[0].courses.filter((item) => {
                return item.type === 0 && item.revision === true
              })
              if (!current.courses.length) {
                current.emptyCourse = 'الطالب غير مسجل في دورات المراجعة '
              } else {
                current.emptyCourse = ''
                current.activeLectures = ''
              }
            } else {
              swal('الطالب غير موجود ')
              this.student.id = null
              this.student.full_name = null
              this.student.mobile = null
              this.student.grade_name = null
              this.student.payments = []
              this.student.totalPayment = 0
            }
          })
      }, 800)
    },
    getLectureCost () {
      this.$http.get(`${this.$hostUrl}settings`).then((response) => {
        this.receipt.amount = response.data.data.revision_fees * this.selectedLecture.length
        this.receipt.payed = this.receipt.amount
      })
    },
    async createReceipt (e) {
      const current = this
      e.preventDefault()
      const formData = new FormData()
      if (this.receipt.attend) {
        formData.append('payable_id', this.selectedLecture.id)
      } else {
        formData.append('payable_id', this.selectedCourse.id)
      }
      if (!this.selectedLecture) {
        swal('يجب اختيار محاضرة ')
      } else {
        this.receipt.course = this.selectedCourse.name
        const receiptLectures = []
        const sendReceipt = []
        $.each(this.selectedLecture, (key, val) => {
          receiptLectures.push(val.name)
          sendReceipt.push(val.id)
        })
        formData.append('lectures', JSON.stringify(sendReceipt))
        this.receipt.selectedLectures = receiptLectures
        formData.append('student_id', this.student.id)
        formData.append('attend', 1)
        this.receipt.attend = 1
        await this.$http
          .post(this.$hostUrl + 'revision/receipt', formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          })
          .then((response) => {
            if (response.data.error) {
              swal({ title: response.data.message, buttons: 'تم' })
            } else {
              swal({ title: response.data.data, buttons: 'تم' })
              // current.receipt = response.data.data
              // current.selectedCourse = ''
            }
          })
          .catch(function (error) {
            if (Object.prototype.hasOwnProperty.call(error, 'response')) {
              console.log('error.response.data.data', error.response.data.data)
              if (error.response.data.data) {
                current.errors = error.response.data.data
              } else {
                swal({ title: error.response.data.message, buttons: 'تم' })
              }
              window.scrollTo(0, 0)
            }
          })
        setTimeout(this.$htmlToPaper('print-receipt'), 10000)
      }
    }
  }
}
</script>
<style>
.radio-row {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f8f9fa;
  margin-top: 28px !important;
  border-radius: 20px;
  gap: 30px;
  position: relative;
}
.custom-control-label {
  float: left !important;
}
.attend {
  padding: 5px 25px;
  width: 45%;
  font-weight: 700;
  border-radius: 7px;
  font-weight: bold;
  background: #f8f9fa;
  margin: 25px 0 !important;
}
.check:not(.form-switch) .form-check-input[type='radio']:checked {
  border: 1px solid #222 !important;
}
.green-type {
  background: #78f178 !important;
}
.radio-label {
  position: absolute;
  top: -30px;
}
</style>
