<template>
     <div class="container-fluid py-4 add-student">
    <div class="row">
      <div class="col-12">
        <div class="card my-4">
          <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
            <div
              class="bg-gradient-success shadow-success border-radius-lg pt-4 pb-3"
            >
              <h6 class="text-white text-capitalize ps-3">الإعدادات</h6>
            </div>
          </div>
          <div class="card-body px-0 pb-2">
                   <form @submit="editSettings" role="form" class="text-start mt-3">

                             <div class='row'>
                                <div class="mb-3 col-lg-6">
                    <label>  الترم<span class="red">*</span> </label>
                  <vmd-input
                    id=""
                    type="text"
                   v-model=" settings.semester"
                   placeholder=" الترم"
                  />
                </div>
                  <div class="mb-3 col-lg-6">
                    <label>  حد المشاهدات <span class="red">*</span> </label>
                  <vmd-input
                    id=""
                    type="text"
                   v-model=" settings.watch_limit"
                   placeholder=" حد المشاهدات "
                  />
                </div>
                </div>
                           <div class='row'>
                              <div class="mb-3 col-lg-6">
                    <label>   قيمة المحاضرة <span class="red">*</span></label>
                  <vmd-input
                    id=""
                    type="text"
                   v-model="settings.lecture_fees"
                    placeholder="  قيمة المحاضرة "
                  />
                </div>
                     <div class="mb-3 col-lg-6">
                    <label>   قيمة المراجعة النهائية <span class="red">*</span> </label>
                  <vmd-input
                    id=""
                    type="text"
                   v-model=" settings.revision_fees"
                   placeholder=" المراجعة النهائية  "
                  />
                </div>
                  <div class="mb-3 col-lg-6">
                    <label>    اخر اصدار اندوريد <span class="red">*</span></label>
                  <vmd-input
                    id=""
                    type="text"
                   v-model=" settings.last_android_version"
                    placeholder="   اخر اصدار "
                  />
                </div>
                </div>
                       <div class="row">
                <div class="text-center col-lg-6">
                  <vmd-button
                    class="my-4 mb-2"
                    variant="gradient"
                    color="info"
                    > حفظ</vmd-button
                  >
                </div>
                 <div class="text-center col-lg-6">
                  <vmd-button
                   @click='goBack($event)'
                    class="my-4 mb-2"
                    variant="gradient"
                    color="warning"
                    > رجوع</vmd-button
                  >
                </div>
                       </div>
              </form>
          </div>
        </div>
      </div>
    </div>
     </div>
</template>
<script>
import VmdInput from '@/components/VmdInput.vue'
import VmdButton from '@/components/VmdButton.vue'
export default {
  name: 'Setting',
  components: {
    VmdInput,
    VmdButton
  },
  data () {
    return {
      settings: {},
      errors: []
    }
  },
  created () {
    this.$http.get(`${this.$hostUrl}settings`).then((response) => {
      this.settings = response.data.data
    })
  },
  methods: {
    goBack (e) {
      e.preventDefault()
      this.$router.push({ path: '/dashboard' })
    },
    async editSettings (e) {
      e.preventDefault()
      const formData = new FormData()
      formData.append('semester', this.settings.semester)
      formData.append('watch_limit', this.settings.watch_limit)
      if (this.settings.revision_fees) {
        formData.append('revision_fees', this.settings.revision_fees)
      }
      if (this.settings.lecture_fees) {
        formData.append('lecture_fees', this.settings.lecture_fees)
      }
      if (this.settings.last_android_version) {
        formData.append('last_android_version', this.settings.last_android_version)
      }
      this.errors = await this.postRequest(formData, this.$hostUrl + 'setting', '')
    }
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>
