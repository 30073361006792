<template>
     <div class="container-fluid py-4 add-student">
    <div class="row">
      <div class="col-12">
        <div class="card my-4">
          <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
            <div
              class="bg-gradient-success shadow-success border-radius-lg pt-4 pb-3"
            >
              <h6 class="text-white text-capitalize ps-3">تعديل بيانات الطالب</h6>
            </div>
          </div>
          <div class="card-body px-0 pb-2">
                        <form @submit="UpdateStudent" role="form" class="text-start mt-3">
                       <div class="row">
                <div class="mb-3 col-lg-6">
                    <label>اسم الطالب<span class="red">*</span>
                    <span color='danger' class="error">{{errors.full_name?errors.full_name[0]:''}}</span></label>
                  <vmd-input
                    id=""
                    type="text"
                    name="البريد الإلكتروني"
                   v-model='student.full_name'
                   placeholder='اسم الطالب'
                   :invalidFeedback="errors.full_name"
                  />
                </div>
                <div class="mb-3 col-lg-6">
                    <label> رقم الهاتف<span class="red">*</span>
                    <span color='danger' class="error">{{errors.mobile?errors.mobile[0]:''}}</span></label>
                  <vmd-input
                    id=""
                    type="text"
                   v-model='student.mobile'
                    placeholder=' رقم الهاتف'
                    :invalidFeedback="errors.mobile"
                  />
                </div>
                </div>
                <div class='row'>
                <div class="mb-3 col-lg-6">
                      <label class='unrequired'>  <span class="green">(اختياري)</span>البريد الإلكتروني</label>
                  <vmd-input
                    id="email"
                    type="email"
                    v-model='student.email'
                    placeholder=' البريد الإلكتروني'
                    :invalidFeedback="errors.email"
                  />
                </div>
                   <div class="mb-3 col-lg-6">
                    <label class='unrequired'>رابط الفيسبوك<span class="green">(اختياري)</span>
                      <span color='danger' class="error">{{errors.facebook_url?errors.facebook_url[0]:''}}</span></label>
                  <vmd-input
                    id=""
                    type="text"
                    v-model='student.facebook_url'
                    placeholder=' رابط الفيسبوك'
                  />
                </div>
                       </div>
                           <div class='row'>
                     <div class="mb-3 col-lg-6">
                    <label> الفرقة <span class="red">*</span>
                    <span color='danger' class="error">{{errors.grade_id?errors.grade_id[0]:''}}</span></label>
                    <multiselect
                        class='required form-control'
                        id='grade'
                        v-model=" selectedGrade"
                        :options='grades'
                        :searchable='true'
                        :close-on-select='true'
                        placeholder="اختر الفرقة"
                        label='name'
                        :custom-label="customLabel"
                        track-by='id'
                        required='required'
                        :internal-search="true"
                        >
                      >
                        <template v-slot:noOptions>
                          <span> فارغة </span>
                        </template>
                        <template v-slot:noResult>
                          <span>لا يوجد نتيجة </span>
                        </template>
                      </multiselect>
                </div>
                       </div>
                       <div class="row">
                <div class="text-center col-lg-6">
                  <vmd-button
                    class="my-4 mb-2"
                    variant="gradient"
                    color="info"
                    > حفظ</vmd-button
                  >
                </div>
                 <div class="text-center col-lg-6">
                  <router-link to="/students">
                  <vmd-button
                    class="my-4 mb-2"
                    type='reset'
                    variant="gradient"
                    color="warning"
                    > رجوع</vmd-button
                  >
                  </router-link>
                </div>
                       </div>
              </form>
          </div>
        </div>
      </div>
    </div>
     </div>
</template>
<script>
import VmdInput from '@/components/VmdInput.vue'
import VmdButton from '@/components/VmdButton.vue'
import $ from 'jquery'
import swal from 'sweetalert'
export default {
  name: 'EditStudent',
  components: {
    VmdInput,
    VmdButton
  },
  data () {
    return {
      student: {},
      groups: [],
      grades: [],
      areas: [],
      selectedGroup: '',
      selectedGrade: '',
      selectedArea: '',
      errors: []
    }
  },
  created () {
    this.$http.get(`${this.$hostUrl}students/${this.$route.params.id}`).then(response => {
      this.student = response.data.data
      this.selectedGrade = {
        id: this.student.grade.id,
        name: this.student.grade.name

      }
    })
    const grades = []
    this.$http.get(`${this.$hostUrl}grades`)
      .then((response) => {
        $.each(response.data.data, function (key, value) {
          grades.push({
            name: value.name,
            id: value.id
          })
        })
        this.grades = grades
      })
  },
  methods: {
    async UpdateStudent (e) {
      e.preventDefault()
      const formData = new FormData()
      if (/^\d+$/.test(this.student.full_name)) {
        swal('اسم الطالب يجب ان يحتوي على حروف')
        return 0
      }
      formData.append('full_name', this.student.full_name)
      if (this.student.email) {
        formData.append('email', this.student.email)
      }
      if (this.student.facebook_url) {
        formData.append('facebook_url', this.student.facebook_url)
      }
      formData.append('mobile', this.student.mobile)
      formData.append('grade_id', this.selectedGrade.id)
      formData.append('_method', 'PUT')
      this.errors = await this.postRequest(formData, this.$hostUrl + 'students/' + this.$route.params.id, '/students')
    }
  }
}
</script>
