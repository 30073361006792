
import { createI18n } from 'vue-i18n'
import arMessages from './locales/ar.json'
import enMessages from './locales/en.json'

const messages = {
  en: {
    message: enMessages
  },
  ar: {
    message: arMessages
  }
}
if (!localStorage.Lang) {
  localStorage.Lang = 'ar'
  localStorage.dir = 'rtl'
}
const i18n = createI18n({
  locale: localStorage.Lang,
  fallbackLocale: 'ar',
  globalInjection: true,
  direction: 'rtl',
  messages
})
export default i18n
