<template>
  <div class="container-fluid py-4 add-student teachers roles">
    <div class="row">
      <div class="col-12">
        <div class="card my-4">
          <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
            <div
              class="bg-gradient-success shadow-success border-radius-lg pt-4 pb-3"
            >
              <h6 class="text-white text-capitalize ps-3">إضافة وظيفة</h6>
            </div>
          </div>
          <div class="card-body px-0 pb-2 overflow">
            <form @submit="createRole" role="form" class="text-start mt-3">
              <div class="row">
                <div class="mb-3 col-lg-6">
                  <label>
                    الاسم<span class="red">*</span>
                    <span color="danger" class="error">{{
                      errors.name ? errors.name[0] : ''
                    }}</span></label
                  >
                  <vmd-input
                    id=""
                    class="height"
                    type="text"
                    label="اسم الوظيفة  "
                    v-model="role.name"
                    required
                  />
                </div>
              </div>
              <div class="row align-center">
                <h5 id="data" class="today-lec">
                  <span>اختر الأذونات</span>
                </h5>
                <div class="text-center col-lg-12 overflow-x">
                  <table class="table b-block">
                    <thead>
                      <tr>
                        <th>
                          <vmd-checkbox v-model="checkAll"></vmd-checkbox>
                          <input
                            type="checkbox"
                            v-model="checkAll"
                            class="form-control"
                          />
                        </th>
                        <th>الاسم</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(permission, index) in permissions"
                        :key="index"
                      >
                        <td>
                          <vmd-checkbox
                            v-model="permission.checked"
                          ></vmd-checkbox>
                          <input
                            type="checkbox"
                            v-model="permission.checked"
                            class="form-control"
                          />
                        </td>
                        <td>
                          {{ permission.name }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="row">
                <div class="text-center col-lg-6">
                  <vmd-button class="my-4 mb-2" variant="gradient" color="info">
                    حفظ</vmd-button
                  >
                </div>
                <div class="text-center col-lg-6">
                  <vmd-button
                    type="reset"
                    class="my-4 mb-2"
                    variant="gradient"
                    color="danger"
                  >
                    مسح</vmd-button
                  >
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import VmdInput from '@/components/VmdInput.vue'
import VmdButton from '@/components/VmdButton.vue'
import VmdCheckbox from '@/components/VmdCheckbox.vue'
import swal from 'sweetalert'
import $ from 'jquery'
export default {
  name: 'CreateRole',
  components: {
    VmdInput,
    VmdButton,
    VmdCheckbox
  },
  data () {
    return {
      role: {},
      permissions: [],
      selectedPermissions: [],
      errors: []
    }
  },
  created () {
    const permissions = []
    this.$http.get(`${this.$hostUrl}permissions`).then((response) => {
      $.each(response.data.data, function (key, value) {
        permissions.push({
          name: value.name_ar,
          id: value.id
        })
      })
      this.permissions = permissions
    })
  },
  computed: {
    checkAll: {
      get () {
        return this.permissions.every((permission) => permission.checked)
      },
      set (value) {
        this.permissions.forEach((permission) => {
          permission.checked = value
        })
      }
    }
  },
  methods: {
    async createRole (e) {
      e.preventDefault()
      const formData = new FormData()
      if (/^\d+$/.test(this.role.name)) {
        swal('اسم المدرس يجب ان يحتوي على حروف')
        return 0
      }
      formData.append('name', this.role.name)
      const allSelectedPermissions = []

      this.permissions.forEach((permission) => {
        if (permission.checked === true) {
          allSelectedPermissions.push({
            id: permission.id
          })
        }
      })
      if (this.checkAll === true) {
        this.permissions.forEach((permission) => {
          permission.checked = true
          allSelectedPermissions.push({
            id: permission.id
          })
        })
      }
      formData.append('permissions', JSON.stringify(allSelectedPermissions))
      formData.append('_method', 'POST')
      this.errors = await this.postRequest(
        formData,
        this.$hostUrl + 'roles',
        '/roles'
      )
    }
  }
}
</script>
<style>
.height {
  height: 46px !important;
}
</style>
