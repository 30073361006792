<template>
     <div class="container-fluid py-4 add-student">
    <div class="row">
      <div class="col-12">
        <div class="card my-4">
          <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
            <div
              class="bg-gradient-success shadow-success border-radius-lg pt-4 pb-3"
            >
              <h6 class="text-white text-capitalize ps-3">  إضافة فرع</h6>
            </div>
          </div>
          <div class="card-body px-0 pb-2">
                   <form role="form" class="text-start mt-3">

                             <div class='row'>
                     <div class="mb-3 col-lg-6">
                    <label>اسم الفرع</label>
                  <vmd-input
                    id=""
                    type="text"
                   label="الإسم  "
                  />
                </div>
                   <div class="mb-3 col-lg-6">
                    <label>رقم الهاتف </label>
                  <vmd-input
                    id=""
                    type="number"
                   label="  رقم الهاتف"
                  />
                </div>
                       </div>

                       <div class="row">
                <div class="text-center col-lg-6">
                  <vmd-button
                    class="my-4 mb-2"
                    variant="gradient"
                    color="info"
                    > حفظ</vmd-button
                  >
                </div>
                 <div class="text-center col-lg-6">
                  <vmd-button
                    class="my-4 mb-2"
                    variant="gradient"
                    color="danger"
                    > مسح</vmd-button
                  >
                </div>
                       </div>
              </form>
          </div>
        </div>
      </div>
    </div>
     </div>
</template>
<script>
import VmdInput from '@/components/VmdInput.vue'
import VmdButton from '@/components/VmdButton.vue'

export default {
  name: 'CreateBranch',
  components: {
    VmdInput,
    VmdButton

  }
}
</script>
