<template>
  <div class="container-fluid py-4 add-student add-receipt">
    <div class="row">
      <div class="col-12">
        <div class="card my-4">
          <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
            <div
              class="bg-gradient-success shadow-success border-radius-lg pt-4 pb-3"
            >
              <h6 class="text-white text-capitalize ps-3">إضافة إيصال عرض</h6>
            </div>
          </div>
          <div class="card-body px-0 pb-2">
            <form @submit="createReceipt" role="form" class="text-end mt-3">
              <div class="row">
                <div class="mb-0 col-12 col">
                  <label>
                    نوع الحركة <span color="danger" class="red">*</span
                    ><span class="error">{{
                      errors.receipt_type_id ? errors.receipt_type_id[0] : ''
                    }}</span></label
                  >
                  <div class="  tab-div tab2 br-w-0 mb-0">
              <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li style="" class="nav-item" role="presentation">
                  <button
                  @click="handleCheckboxChange"
                    class="w-96 nav-link active btn mb-0 bg-gradient-info btn-md null null btn-lg"
                    id="home-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#home"
                    type="button"
                    role="tab"
                    aria-controls="home"
                    aria-selected="true"
                  >
                     حركة نقدية
                  </button>
                </li>
                <li style="" class="nav-item" role="presentation">
                  <button
                  @click="handleWalletReceipt"
                   class="w-96 nav-link btn mb-0 bg-gradient-info btn-md null null btn-lg"
                    id="profile-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#profile"
                    type="button"
                    role="tab"
                    aria-controls="profile"
                    aria-selected="false"
                  >
                     حركة داخلية
                  </button>
                </li>
              </ul>
            </div>
                </div>
                  <div class="tab-content border-bottom mb-5" id="myTabContent">
              <div
                class="tab-pane fade active show"
                id="home"
                role="tabpanel"
                aria-labelledby="home-tab"
              >
              <div class ="row d-flex cache-r">
                <label>
            <span class="error">{{
                      errors.receipt_type_id ? errors.receipt_type_id[0] : ''
                    }}</span></label>
                <div class="col-6">
                  <div class="row checked-row ">
                  <div class="col-4" v-for="type in receiptTypes" :key="type.id">
                    <vmd-checkbox id="free-video" type="text" :value="type.id"
                    v-model="type.selectedType" @change="handleCheckboxChange(type)">
                      {{ type.name }}
                    </vmd-checkbox>
                        </div>
                    </div>
                </div>
                 <div class="mb-4 col-6  radio-row paid-back checked-row">
                  <label class="radio-label">
                    <span color="danger" class="error">{{
                      errors.transaction_type_id
                        ? errors.transaction_type_id[0]
                        : ''
                    }}</span></label
                  >
                  <div
                    class="form-check
                    "
                    v-for="item in transactionTypes"
                    :key="item.id"
                  >
                    <input
                      class="form-check-input"
                      :name="item.name"
                      type="radio"
                      :value="item.id"
                      v-model="receipt.trans"
                      @change="changColor(item.name, item.id)"
                    />
                    <label class="form-check-label" :for="item.name">
                      {{ item.name }}
                    </label>
                  </div>
                </div>
              </div>
              </div>
               <div
                class="tab-pane fade show"
                id="profile"
                role="tabpanel"
                aria-labelledby="profile-tab"
              >
              <div :class="{'paid-back':receipt.wallet === 'wallet-receive',
              'refund-back':receipt.wallet === 'wallet-refund'}"
              class="mb-4 col-8  cache-r radio-row paid-back checked-row">
                  <label class="radio-label">
                    <span color="danger" class="error">{{
                      errors.transaction_type_id
                        ? errors.transaction_type_id[0]
                        : ''
                    }}</span></label
                  >
                  <div
                    class="form-check
                    "
                  >
                    <input
                      class="form-check-input"
                      type="radio"
                      value="wallet-receive"
                      v-model="receipt.wallet"
                      @change='handleWalletReceipt'
                    />
                    <label class="form-check-label">
                      من محفظة لعرض
                    </label>
                  </div>
                   <div
                    class="form-check
                    "
                  >
                    <input
                      class="form-check-input"
                      type="radio"
                      value="wallet-refund"
                      v-model="receipt.wallet"
                      @change='handleWalletReceipt'
                    />
                    <label class="form-check-label" >
                      من  عرض لمحفظة
                    </label>
                  </div>
                </div>
              </div>
              </div>
              </div>
               <div class="row align-center ex-row">
                <div class="mb-3 col-lg-3">
                  <label>  ادخل رقم الطالب أو الهاتف<span class="red">*</span></label>
                  <vmd-input
                    id="student-number"
                    type="text"
                   placeholder="  رقم الطالب او الهاتف "
                    v-model="student.number"
                    @keyup.enter="search"
                    required
                  />
                </div>
                <div class="mb-3 col-lg-3">
                  <label> اسم الطالب </label>
                  <vmd-input
                    id="student-name"
                    type="text"
                    v-model="student.full_name"
                    readonly
                  />
                </div>
                <div class="mb-3 col-lg-3">
                  <label> رقم الهاتف الطالب </label>
                  <vmd-input
                    id="student-name"
                    type="text"
                    v-model="student.mobile"
                     readonly
                  />
                </div>
                <div class="mb-3 col-lg-2">
                  <label>الفرقة </label>
                  <vmd-input
                    id="student-grade"
                    type="text"
                    v-model="student.grade_name"
                     readonly
                  />
                </div>
                <div class="mb-3 col-lg-1">
                  <label>المجموعة </label>
                  <vmd-input
                    id="student-grade"
                    type="text"
                    v-model="student.group_name"
                     readonly
                  />
                </div>

              </div>
              <div class="row">
                <div class="mb-3 col-lg-7" v-if="showOffer">
                  <label>
                    العرض <span color="danger" class="red">*</span
                    ><span class="error">{{
                      errors.payabel_id ? errors.payabel_id[0] : ''
                    }}</span></label
                  >
                  <multiselect
                    class="required form-control"
                    id="grade"
                    v-model="selectedOffer"
                    :options="offers"
                    :searchable="true"
                    :close-on-select="true"
                    placeholder=" اختر العرض"
                    label="name"
                    track-by="id"
                    required="required"
                    :internal-search="true"
                    @select="getSubjects"
                  >
                    <template v-slot:noOptions>
                      <span> فارغة </span>
                    </template>
                    <template v-slot:noResult>
                      <span>لا يوجد نتيجة </span>
                    </template>
                  </multiselect>
                </div>
              </div>
              <div class="row mt-4" v-if="showOffer">
                <div class="card col-12 my-4">
                  <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
                    <div
                      class="bg-gradient-success shadow-success border-radius-lg pt-4 pb-3"
                    >
                      <h6 class="text-white text-capitalize ps-3">
                        المواد
                      </h6>
                    </div>
                  </div>
                  <div class="card-body px-0 pb-2">
                    <div class="table-responsive p-0">
                      <table class="table b-block table-responsive">
                        <thead>
                            <tr>
                              <th>اسم المادة</th>
                              <th> التكلفة</th>
                              <th> رسوم التطبيق</th>
                              <th>الإجمالي</th>
                              <th> المسدد</th>
                              <th>القيمة</th>
                            </tr>
                        </thead>
                        <tbody >
                            <tr v-for="subject in selectedOffer.subjects" :key="subject.id">
                              <td>
                                  <span class="course-name">{{ subject.name }}</span>
                              </td>
                              <td>
                                  {{ $filters.number(subject.pivot.subject_cost) }}
                              </td>
                              <td>
                                  {{ $filters.number(subject.pivot.app_fees) }}
                              </td>
                              <td>
                                  {{ $filters.number(subject.total) }}
                              </td>
                              <td :class="{'paid-back' :subject.payed> 0}">
                                  {{ $filters.number(subject.payed) }}
                              </td>
                               <td>
                                  <vmd-input
                                    id=""
                                    type="number"
                                    placeholder="القيمة"
                                    @input="calcOfferTotal"
                                    v-model="subject.amount"
                                  />
                              </td>
                            </tr>
                            <tr style="background: #f8f9fa; color:#222">
                              <th>الإجماليات</th>
                              <th style="font-size:18px !important">{{$filters.number(selectedOffer.totalSubjectCost)}}</th>
                              <th style="font-size:18px !important">{{$filters.number(selectedOffer.totalAppFees)}}</th>
                              <th style="font-size:18px !important">{{$filters.number(totalCost)}}</th>
                              <th style="font-size:18px !important">{{$filters.number(offerPayments) }}</th>
                              <th></th>
                            </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="mb-3 col-lg-6">
                  <label>
                    القيمة <span color="danger" class="red">*</span
                    ><span class="error">{{
                      errors.amount ? errors.amount[0] : ''
                    }}</span></label
                  >
                  <vmd-input
                    id=""
                    type="number"
                    :disabled="showOffer === true"
                    placeholder="القيمة"
                    v-model="receipt.amount"
                  />
                </div>
                <div class="mb-3 col-lg-6">
                  <label>
                    البيان <span color="danger" class="red">*</span
                    ><span class="error">{{
                      errors.description ? errors.description[0] : ''
                    }}</span></label
                  >
                  <vmd-input id="" type="text" v-model="receipt.description" />
                </div>
              </div>
              <br/>
              <div class="row border-top pt-4">
                <div class="col-6 text-end">
                  <h6>الرصيد المسدد : {{totalPayment}}</h6>
                </div>
                <div class="col-6 text-end">
                  <h6>الرصيد المتبقي : {{ totalRemain }}</h6>
                </div>
              </div>
              <div class="row">
                <div class="text-center col-lg-6">
                  <vmd-button class="my-4 mb-2 pay-btn" variant="gradient" color="info" type="button" @click="createReceipt">
                    حفظ</vmd-button
                  >
                </div>
                <div class="text-center col-lg-6">
                  <vmd-button
                    type="reset"
                    class="my-4 mb-2"
                    variant="gradient"
                    color="danger"
                  >
                    مسح</vmd-button
                  >
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
     <div class="row mt-4">
      <div class="card col-12 my-4">
        <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
          <div
            class="bg-gradient-success shadow-success border-radius-lg pt-4 pb-3"
          >
            <h6 class="text-white text-capitalize ps-3">
              الإيصالات المالية السابقة
            </h6>
          </div>
        </div>
        <div class="card-body px-0 pb-2">
          <div class="table-responsive p-0">
            <table class="table b-block table-responsive">
              <thead>
                  <tr>
                    <th>العرض</th>
                    <th>نوع الإيصال</th>
                     <th> مستمر</th>
                    <th>التكلفة</th>
                    <th>المسدد</th>
                    <th>الباقي</th>
                  </tr>
                  <tr></tr>
              </thead>
              <tbody >
                  <tr v-for="payment in student.payments" :key="payment.id">
                    <td>
                        <span class="course-name">{{ payment.offer_name }}</span>
                    </td>
                    <td>
                        {{ payment.transaction_type_name }}
                    </td>
                     <td v-if="payment.enrolled" class="text-center">
                              <span class="badge badge-sm bg-gradient-success"
                                ><i class="fas fa-check"></i
                              ></span>
                            </td>
                            <td v-else class="text-center">
                              <span class="badge badge-sm bg-gradient-danger">X</span>
                            </td>
                    <td>
                        {{ $filters.number(payment.amount) }}
                    </td>
                    <td>
                        {{ $filters.number(payment.payed) }}
                    </td>
                    <td>
                        {{ $filters.number(payment.remain) }}
                    </td>
                  </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div>
      <PrintReceipt :receipt="receipt"></PrintReceipt>
    </div>
  </div>
</template>
<script>
import VmdInput from '@/components/VmdInput.vue'
import VmdButton from '@/components/VmdButton.vue'
import PrintReceipt from '@/components/PrintReceipt.vue'
import VmdCheckbox from '@/components/VmdCheckbox.vue'
import swal from 'sweetalert'
import $ from 'jquery'

let timeout = null

export default {
  name: 'AddReceipt',
  components: {
    PrintReceipt,
    VmdInput,
    VmdButton,
    VmdCheckbox
  },
  data () {
    return {
      receipt: {
        number: null,
        full_name: null,
        amount: '',
        title: '',
        description: 'سداد',
        user_name: localStorage.userName,
        branch: localStorage.branchName,
        trans: '1413a440-d013-416c-a882-dc23fb8d52c4',
        wallet: 'wallet-receive'
      },
      students: [],
      offers: [],
      showOffer: true,
      student: {
        id: null,
        full_name: null,
        mobile: null,
        number: null
      },
      selectedOffer: '',
      offerPayed: 0,
      offerRemain: 0,
      totalCost: '',
      offerPayments: '',
      receiptTypes: [],
      transactionTypes: [],
      selectedType: 'd2d3aa71-8c7e-4f94-bbc9-ec516f1ba759',
      enrolled: '',
      errors: []
    }
  },
  created () {
    this.receiptTypes = [{
      id: 'd2d3aa71-8c7e-4f94-bbc9-ec516f1ba759',
      name: 'عرض'
    },
    {
      id: 'a7e640c6-dbe0-4224-b9a2-e53c7d406a8c',
      name: 'محفظة'
    }]
    this.receiptTypes.find((type) => {
      if (type.id === 'd2d3aa71-8c7e-4f94-bbc9-ec516f1ba759') {
        type.selectedType = true
      }
    })
    this.transactionTypes = this.$store.state.transactionTypes
    if (this.$route.params.id) {
      this.$http.get(`${this.$hostUrl}students/${this.$route.params.id}`).then((response) => {
        this.student = response.data.data
        this.getStudentOffer(this.$route.params.id)
      })
    }
  },
  methods: {
    handleCheckboxChange (selectedType) {
      this.selectedType = selectedType.id
      this.receiptTypes.forEach(type => {
        if (type !== selectedType) {
          type.selectedType = false // Uncheck other types
        }
        if (selectedType.id === 'd2d3aa71-8c7e-4f94-bbc9-ec516f1ba759') {
          this.showOffer = true
        } else {
          this.showOffer = false
        }
      })
    },
    handleWalletReceipt () {
      this.showOffer = true
      if (!this.receipt.wallet) {
        this.receipt.wallet = 'wallet-receive'
      }
      this.selectedType = 'cec350eb-de0c-4fba-8e0d-110d236a95e8'
      if (this.receipt.wallet === 'wallet-receive') {
        this.receipt.trans = '1413a440-d013-416c-a882-dc23fb8d52c4'
        this.receipt.description = 'سداد'
      } else {
        this.receipt.trans = '78d9a393-9b84-4372-bb38-5d8b42eccd29'
        this.receipt.description = 'استرداد'
      }
    },
    getStudentOffer (studentId) {
      const current = this
      current.student.payments = []
      current.totalPayment = 0
      current.totalRemain = 0
      current.$http.get(`${current.$hostUrl}student/offers?student_id=${studentId}&type=all`).then((response) => {
        current.offers.splice(0)
        response.data.data.forEach(function (offer) {
          current.offers.push({
            id: offer.id,
            name: offer.pivot.enrolled ? offer.name + ' - ' + 'مستمر' : offer.name + ' - ' + 'ملغي'
          })
          offer.subject_name = ''
          console.log('offer.receipts', offer.receipts)
          current.student.payments = offer.receipts
          current.totalPayment += offer.totalPayed
          current.totalRemain += offer.totalRemain
        })
      })
    },
    search () {
      this.student.id = null
      this.student.full_name = null
      this.student.grade_name = null
      this.student.mobile = null
      this.student.payments = []
      this.student.totalPayment = 0
      this.selectedLecture = ''
      if (!this.student.number.length) {
        return false
      }
      const current = this
      this.$store.commit('setSearchVal', current.student.number)
      clearTimeout(timeout)
      timeout = setTimeout(function () {
        current.$http
          .get(`${current.$hostUrl}students-list?search=${current.student.number}`)
          .then((response) => {
            if (response.data.data.length === 1) {
              current.student = response.data.data[0]
              current.student.payments = []
              current.totalPayment = 0
              current.totalRemain = 0
              current.$http.get(`${current.$hostUrl}student/offers?student_id=${response.data.data[0].id}&type=all`).then((response) => {
                current.offers = []
                console.log('response.data.data', response.data.data)
                response.data.data.forEach(function (offer) {
                  current.offers.push({
                    id: offer.id,
                    name: offer.pivot.enrolled ? offer.name + ' - ' + 'مستمر' : offer.name + ' - ' + 'ملغي'
                  })
                  offer.subject_name = ''
                  console.log('offer.receipts', offer.receipts)
                  current.student.payments = offer.receipts
                  current.totalPayment += offer.totalPayed
                  current.totalRemain += offer.totalRemain
                })
              })
            } else {
              this.student.id = null
              this.student.full_name = null
              this.student.mobile = null
              this.student.grade_name = null
              this.student.payments = []
              this.totalPayment = 0
              this.totalRemain = 0
            }
            if (response.data.error) {
              swal(response.data.message)
            }
          })
      }, 800)
    },
    getSubjects (event) {
      const offerId = event.id
      const current = this
      current.$http.get(`${current.$hostUrl}student/offers?student_id=${this.student.id}&offer_id=${offerId}&type=all`).then((response) => {
        console.log('response.data.data', response.data.data)
        if (response.data.data.length) {
          current.selectedOffer = response.data.data[0]
          current.selectedOffer.totalSubjectCost = 0
          current.selectedOffer.totalAppFees = 0
          current.selectedOffer.subjects.forEach(function (subject) {
            if (subject.pivot.app_fees === null) {
              subject.pivot.app_fees = 0
            }
            subject.amount = 0
            subject.total = 0
            subject.total = subject.pivot.app_fees + subject.pivot.subject_cost
            current.selectedOffer.totalSubjectCost += subject.pivot.subject_cost
            current.selectedOffer.totalAppFees += subject.pivot.app_fees
            current.selectedOffer.totalCost += subject.pivot.app_fees + subject.pivot.subject_cost
          })

          current.student.payments = response.data.data[0].receipts
          current.totalPayment = response.data.data[0].totalPayed
          current.offerPayments = response.data.data[0].totalPayed
          current.totalRemain = response.data.data[0].totalRemain
          current.totalCost = response.data.data[0].cost
        }
      })
    },
    calcOfferTotal () {
      let total = 0
      this.selectedOffer.subjects.forEach(function (subject) {
        console.log(subject.amount, 'amount')
        total += subject.amount
      })
      this.receipt.amount = total
    },
    changColor (name, id) {
      this.receipt.trans = id
      if (name === 'استلام') {
        this.receipt.description = 'سداد'
        $('.radio-row').addClass('paid-back')
        $('.radio-row').removeClass('refund-back')
      } else {
        this.receipt.description = 'استرداد'
        $('.radio-row').addClass('refund-back')
        $('.radio-row').removeClass('paid-back')
      }
    },
    async createReceipt (e) {
      const current = this
      e.preventDefault()
      this.receipt.created_at = ''
      const formData = new FormData()
      if (!this.selectedOffer && this.selectedType !== 'a7e640c6-dbe0-4224-b9a2-e53c7d406a8c') {
        this.errors.payabel_id = []
        this.errors.payabel_id.push('الرجاء اختيار العرض أولا')
        return 0
      }
      if (!this.selectedOffer && this.showOffer === true) {
        this.errors.payabel_id = []
        this.errors.payabel_id.push('الرجاء اختيار عرض أولا')
        return 0
      }
      if (!this.receipt.trans) {
        this.errors.transaction_type_id = []
        this.errors.transaction_type_id.push('الرجاء اختيار نوع الإيصال أولا')
        return 0
      }
      if (!this.receipt.trans) {
        this.errors.transaction_type_id = []
        this.errors.transaction_type_id.push('الرجاء اختيار نوع الإيصال أولا')
        return 0
      }
      formData.append('payable_id', this.selectedOffer.id)
      this.receipt.course = this.selectedOffer.name
      formData.append('student_id', this.student.id)
      this.receipt.full_name = this.student.full_name
      formData.append('receipt_type_id', this.selectedType)
      formData.append('transaction_type_id', this.receipt.trans)
      formData.append('amount', this.receipt.amount)
      formData.append('exceptional', this.enrolled ? 0 : 1)
      if (this.receipt.description) {
        formData.append('description', this.receipt.description)
      }
      const sendSubject = []
      let subjectCost = 0
      for (let i = 0; i < this.selectedOffer?.subjects?.length; i++) {
        subjectCost = this.selectedOffer.subjects[i].pivot.subject_cost + this.selectedOffer.subjects[i].pivot.app_fees
        if (this.selectedOffer.subjects[i].amount > subjectCost) {
          this.errors.payabel_id = []
          this.errors.payabel_id.push('تكلفة المادة اقل من القيمة المدخلة')
          return 0
        }
        sendSubject.push({
          subject_id: this.selectedOffer.subjects[i].id,
          amount: this.selectedOffer.subjects[i].amount
        })
      }
      $('.pay-btn').attr('disabled', true)
      formData.append('amount', this.receipt.amount)
      formData.append('subjects', JSON.stringify(sendSubject))
      formData.append('attend', 0)
      this.receipt.attend = 0
      await this.$http
        .post(this.$hostUrl + 'offer/receipt', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then((response) => {
          $('.pay-btn').attr('disabled', false)
          if (response.data.error) {
            swal({ title: response.data.message, buttons: 'تم' })
          } else {
            swal({ title: response.data.message, buttons: 'تم' })
            current.receipt = response.data.data
            current.receipt.transaction_type = response.data.data.transaction_type_name
            current.selectedOffer = ''
            current.receipt.subject_name = ''
            if (response.data.data.course) {
              current.receipt.course = response.data.data.course
            } else if (response.data.data.offer) {
              current.receipt.offer = response.data.data.offer_name
              current.receipt.course_number = ''
            }
            if (current.receipt.number === null) {
              current.receipt.subject_name = current.receipt.payable.subject ? current.receipt.payable.subject.name : ''
            }
          }
        })
        .catch(function (error) {
          $('.pay-btn').attr('disabled', false)
          if (Object.prototype.hasOwnProperty.call(error, 'response')) {
            if (error.response.data.data) {
              current.errors = error.response.data.data
            } else {
              swal({ title: error.response.data.message, buttons: 'تم' })
            }
            window.scrollTo(0, 0)
          }
        })
      if (this.receipt.created_at) {
        setTimeout(this.$htmlToPaper('print-receipt'), 90000)
        this.student = {}
        this.receipt.created_at = ''
        this.receipt.wallet = 'wallet-receive'
        this.receipt.trans = '1413a440-d013-416c-a882-dc23fb8d52c4'
        $('.radio-row').addClass('paid-back')
        $('.radio-row').removeClass('refund-back')
      }
    }
  }
}
</script>
<style>
.radio-row {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f8f9fa;
  margin-top: 28px !important;
  border-radius: 20px;
  gap: 30px;
  position: relative;
}
.custom-control-label {
  float: left !important;
}
.attend {
  padding: 5px 25px;
  width: 45%;
  font-weight: 700;
  border-radius: 7px;
  font-weight: bold;
  background: #f8f9fa;
  margin: 25px 0 !important;
}
.check:not(.form-switch) .form-check-input[type='radio']:checked {
  border: 1px solid #222 !important;
}
.green-type {
  background: #78f178 !important;
}
.radio-label {
  position: absolute;
  top: -30px;
}
</style>
