<template>
  <div class="container-fluid py-4 add-student show-receipt">
    <div class="row">
      <div class="col-12">
        <div class="card my-4">
          <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
            <div
              class="bg-gradient-success shadow-success border-radius-lg pt-4 pb-3"
            >
              <h6 class="text-white text-capitalize ps-3">بيانات الإيصال</h6>
            </div>
          </div>
          <div class="card-body px-0 pb-2" style="direction: rtl">
          <PrintReceipt :receipt="receipt"></PrintReceipt>
            <div class="row print-btn">
              <div class="text-center col-lg-6">
                <vmd-button
                  @click="printReceipt"
                  class="my-4 mb-2"
                  variant="gradient"
                  color="info"
                >
                  طباعة</vmd-button
                >
              </div>
            </div>
          </div>
          <!-- attendance report -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import VmdButton from '@/components/VmdButton.vue'
import PrintReceipt from '@/components/PrintReceipt.vue'
export default {
  name: 'ShowReceipts',
  components: {
    VmdButton,
    PrintReceipt
  },
  data () {
    return {
      receipt: {}
    }
  },
  created () {
    this.$http
      .get(`${this.$hostUrl}receipts/${this.$route.params.id}`)
      .then((response) => {
        this.receipt = response.data.data
        this.receipt.student = response.data.data.student.full_name
        this.receipt.transaction_type = response.data.data.transaction_type_name
        console.log((typeof response.data.data.branch === 'object'))
        if ((typeof response.data.data.branch === 'object')) {
          this.receipt.branch = (response.data.data.branch ? response.data.data.branch.name : '')
        }
      })
  },
  methods: {
    printReceipt () {
      setTimeout(this.$htmlToPaper('print-receipt'), 10000)
    }
  }
}
</script>
<style scoped>
.date {
  padding-left: 130px;
}
span {
  margin-right: 2px;
  margin-left: 2px;
}
#print-receipt {
  display: block !important;
}
</style>
