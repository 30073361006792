<template>
     <div class="container-fluid py-4 add-student">
    <div class="row">
      <div class="col-12">
        <div class="card my-4">
          <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
            <div
              class="bg-gradient-success shadow-success border-radius-lg pt-4 pb-3"
            >
              <h6 class="text-white text-capitalize ps-3">  إضافة شرائح</h6>
            </div>
          </div>
          <div class="card-body px-0 pb-2">
                   <form @submit='createSlider' role="form" class="text-start mt-3">

                             <div class='row'>
                     <div class="mb-3 col-lg-6">
                    <label>الصورة  <span class="red">*</span></label>
                  <vmd-input
                    id="image"
                    type="file"
                    required
                    @change='onFileChange'
                  />
                </div>
                   <div class="mb-3 col-lg-6">
                    <label>الفيديو  <span class="green">(اختياري)</span></label>
                  <vmd-input
                    id="video"
                    type="file"
                    @change='onFileChange2'
                  />
                </div>
                       </div>
                        <div class='row '>
                  <h6  style="text-align:right; margin:12px" class="text-warning text-right "> الوصف بالإنجليزية مطلوب  عند إدخال الوصف بالعربية</h6>
                </div>
                       <div class="row">
                    <div class="mb-3 col-lg-6">
                         <label>
                     الوصف بالعربية  <span class="green">(اختياري)</span></label>
                  <div class="input-group">
                 <textarea class="form-control"
                 rows="4"
                 cols="100"
                 maxlength='100'
                 placeholder='الرسالة'
                 v-model='slider.description'
                  >
                 </textarea>
                 </div>
                   </div>
                     <div class="mb-3 col-lg-6">
                         <label>
                    الوصف بالإنجليزية  <span class="green">(اختياري)</span></label>
                  <div class="input-group">
                 <textarea class="form-control"
                 rows="4"
                 cols="100"
                 maxlength='100'
                 placeholder='الرسالة'
                  v-model='slider.description_en'
                  >
                 </textarea>
                 </div>
                        </div>
                        </div>

                       <div class="row">
                <div class="text-center col-lg-6">
                  <vmd-button
                    class="my-4 mb-2"
                    variant="gradient"
                    color="info"
                    > حفظ</vmd-button
                  >
                </div>
                 <div class="text-center col-lg-6">
                  <vmd-button
                    class="my-4 mb-2"
                    variant="gradient"
                    color="danger"
                    > مسح</vmd-button
                  >
                </div>
                       </div>
              </form>
          </div>
        </div>
      </div>
    </div>
     </div>
</template>
<script>
import VmdInput from '@/components/VmdInput.vue'
import VmdButton from '@/components/VmdButton.vue'

export default {
  name: 'CreateSlider',
  data () {
    return {
      slider: {},
      image: '',
      video: ''
    }
  },
  components: {
    VmdInput,
    VmdButton

  },
  methods: {
    onFileChange (e) {
      e.preventDefault()
      const _URL = window._URL || window.webkitURL
      this.image = e.target.files[0]
      let file = ''
      if ((file = e.target.files[0])) {
        const img = new Image()
        img.src = _URL.createObjectURL(file)
      }
    },
    onFileChange2 (e) {
      e.preventDefault()
      const _URL = window._URL || window.webkitURL
      this.video = e.target.files[0]
      let file = ''
      if ((file = e.target.files[0])) {
        const img = new Image()
        img.src = _URL.createObjectURL(file)
      }
    },
    async createSlider (e) {
      e.preventDefault()
      const formData = new FormData()
      formData.append('slide', this.image)
      if (this.video) {
        formData.append('video', this.video)
      }
      if (this.slider.description) {
        formData.append('description', this.slider.description)
      }
      if (this.slider.description_en) {
        formData.append('description_en', this.slider.description_en)
      }
      this.errors = await this.postRequest(formData, this.$hostUrl + 'slides', '/slider')
    }
  }
}
</script>
<style scoped>
textarea{
   border: 1px solid #d2d6da !important;
   padding-right: 10px;
}
</style>
