<template>
     <div>
    <vue-final-modal
      :clickToClose="true"
       v-model="isModalVisible"
      class='cancel-offer call-modal course-payment'
      name="example"
      classes="modal-container"
      content-class="modal-content"
    >
      <div class="card my-4">
        <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
          <div
            class="bg-gradient-success shadow-success border-radius-lg pt-4 pb-3"
          >
            <h6 class="text-white text-capitalize ps-3">الإيصالات المالية للدورة : {{courseName}} </h6>
          </div>
        </div>
         <div class="card-body px-0 pb-2">
              <div class="table-responsive p-0">
                <table class="table align-items-center mb-0">
                  <thead style="background: #252527 !important">
                    <tr>
                      <th
                      class="text-uppercase  font-weight-bolder "
                      >
                        #
                      </th>
                      <th
                        class="text-uppercase  font-weight-bolder "
                      >
                        المستخدم
                      </th>
                      <th
                        class="text-uppercase  font-weight-bolder "
                      >
                        نوع الإيصال
                      </th>
                      <th
                        class="text-uppercase  font-weight-bolder "
                      >
                        القيمة
                      </th>
                       <th
                        class="text-uppercase  font-weight-bolder "
                      >
                        التاريخ
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="receipt in coursePayments" :key="receipt.id">
                      <td>
                        <p class="text-s mb-0">{{ receipt.code }}</p>
                      </td>
                      <td>
                        <p class="text-s mb-0">
                          {{receipt.user_name}}
                        </p>
                      </td>
                      <td>
                        <p class="text-s mb-0">
                          {{ receipt.transaction_type_name }}
                        </p>
                      </td>
                      <td  :class="{'paid-back' :receipt.transaction_type.name_en === 'pay',
                        'refund-back' :receipt.transaction_type.name_en === 'refund'}">
                        <p class="text-s mb-0">
                          {{ $filters.number(receipt.amount) }}
                        </p>
                      </td>
                      <td>
                        <p class="text-s mb-0">
                          {{receipt.created_at}}
                        </p>
                      </td>
                    </tr>
                  </tbody>
                  <tfoot class="back-color">
                    <tr>
                      <th colspan="3">المبلغ المسدد</th>
                      <td style="font-size:17px !important" class="bold bolder">{{ $filters.number(totalCoursePaments) }}</td>
                      <td></td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
        <div class="card-footer text-center">
          <button
            id="unroll"
            @click="$emit('hide-modal')"
            class="btn bg-gradient-info mx-1 col-4"
            >اغلاق </button>
        </div>
      </div>

    </vue-final-modal>
    </div>
</template>
<script>
import { VueFinalModal } from 'vue-final-modal'
export default {
  name: 'CoursePayments',
  components: {
    VueFinalModal
  },
  props: {
    courseName: String,
    coursePayments: Array,
    totalCoursePaments: Number,
    showPaymentsModal: Boolean
  },
  data () {
    return {
      isModalVisible: false
    }
  },
  watch: {
    showPaymentsModal (value) {
      // Update the local data property when the prop changes
      this.isModalVisible = value
    }
  }
}
</script>
