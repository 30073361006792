<template>
     <div class="container-fluid py-4 add-student">
    <div class="row">
      <div class="col-12">
        <div class="card my-4">
          <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
            <div
              class="bg-gradient-success shadow-success border-radius-lg pt-4 pb-3"
            >
              <h6 class="text-white text-capitalize ps-3">  تعديل مادة</h6>
            </div>
          </div>
          <div class="card-body px-0 pb-2">
                   <form @submit="updateSubject" role="form" class="text-start mt-3">
                       <div class="row">
                <div class="mb-3 col-lg-6">
                    <label>اسم المادة<span class="red">*</span></label>
                  <vmd-input
                    id=""
                    type="text"
                   v-model='subject.name'
                  placeholder="اسم المادة"
                  required

                  />
                </div>
                <div class="mb-3 col-lg-6">
                    <label class='unrequired'> عدد الفيديوهات<span class="green">(اختياري)</span></label>
                  <vmd-input
                    id=""
                    type="number"
                   v-model='subject.number_of_videos'
                    placeholder=" عدد الفيديوهات"
                  />
                </div>
                </div>

                         <div class='row'>
                <div class="mb-3 col-lg-6">
                  <label> الجامعة <span class="red">*</span>
                  <span color='danger' class="error">{{errors.university_id?errors.university_id[0]:''}}</span></label>
                  <multiselect
                    class='required form-control'
                    id='grade'
                    v-model="selectedUniversity"
                    :options='universities'
                    placeholder="اختر الجامعة"
                    label='name'
                    @select="getFaculties"
                    track-by='id'
                    required='required'
                  >
                    <template v-slot:noOptions>
                      <span> فارغة </span>
                    </template>
                    <template v-slot:noResult>
                      <span>لا يوجد نتيجة </span>
                    </template>
                  </multiselect>
                </div>
                <div class="mb-3 col-lg-6">
                  <label> الكلية <span class="red">*</span>
                   <span color='danger' class="error">{{errors.faculty_id?errors.faculty_id[0]:''}}</span></label>
                      <multiselect
                        class='required form-control'
                        id='grade'
                        v-model=" selectedFaculty"
                        :options='faculties'
                        :searchable='true'
                        :close-on-select='true'
                        placeholder="اختر الكلية"
                        label='name'
                        track-by='id'
                        required='required'
                        :internal-search="true"
                        >
                        <template v-slot:noOptions>
                          <span> فارغة </span>
                        </template>
                        <template v-slot:noResult>
                          <span>لا يوجد نتيجة </span>
                        </template>
                      </multiselect>
                </div>
              </div>
                       <div class="row">
                         <div class="mb-3 col-lg-6">
                    <label class='unrequired'> قيمة المادة<span class="green">(اختياري)</span></label>
                  <vmd-input
                    id=""
                    type="number"
                    v-model='subject.cost'
                      placeholder=" قيمة المادة"
                  />
                </div>
                       <div class="mb-3 col-lg-6">
                    <label class='unrequired'> قيمة الفيديو داخل المادة<span class="green">(اختياري)</span></label>
                  <vmd-input
                    id=""
                    type="number"
                   v-model='subject.video_cost'
                     placeholder=" قيمة الفيديو داخل المادة"
                  />
                </div>
                       </div>
                       <div class="row">
                <div class="text-center col-lg-6">
                  <vmd-button
                    class="my-4 mb-2"
                    variant="gradient"
                    color="info"
                    > حفظ</vmd-button
                  >
                </div>
                 <div class="text-center col-lg-6">
                  <vmd-button
                  type='reset'
                    class="my-4 mb-2"
                    variant="gradient"
                    color="danger"
                    > مسح</vmd-button
                  >
                </div>
                       </div>
              </form>
          </div>
        </div>
      </div>
    </div>
     </div>
</template>
<script>
import VmdInput from '@/components/VmdInput.vue'
import VmdButton from '@/components/VmdButton.vue'
import $ from 'jquery'
import swal from 'sweetalert'

export default {
  name: 'EditSubject',
  components: {
    VmdInput,
    VmdButton
  },
  data () {
    return {
      universities: [],
      selectedUniversity: '',
      faculties: '',
      selectedFaculty: '',
      subject: {},
      errors: []
    }
  },
  created () {
    console.log(this.$route.params.id)
    this.$http.get(`${this.$hostUrl}subjects/${this.$route.params.id}`).then(response => {
      this.subject = response.data.data
      this.selectedUniversity = {
        id: this.subject.faculty[0].university.id,
        name: this.subject.faculty[0].university.name
      }
      this.selectedFaculty = {
        id: this.subject.faculty[0].id,
        name: this.subject.faculty[0].name
      }
    })
    const universities = []
    this.$http.get(`${this.$hostUrl}universities`)
      .then((response) => {
        $.each(response.data.data, function (key, value) {
          universities.push({
            name: value.name,
            id: value.id
          })
        })
        this.universities = universities
      })
  },
  methods: {
    getFaculties (e) {
      const faculties = []
      this.$http.get(`${this.$hostUrl}faculties?university_id=${this.selectedUniversity.id}`)
        .then((response) => {
          $.each(response.data.data, function (key, value) {
            faculties.push({
              name: value.name,
              id: value.id
            })
          })
          this.faculties = faculties
        })
    },
    async updateSubject (e) {
      e.preventDefault()
      const formData = new FormData()
      if (/^\d+$/.test(this.subject.name)) {
        swal('اسم المادة يجب ان يحتوي على حروف')
        return 0
      }
      formData.append('name', this.subject.name)
      formData.append('name_en', this.subject.name)
      if (this.subject.number_of_videos) {
        formData.append('number_of_videos', this.subject.number_of_videos)
      }
      formData.append('university_id', this.selectedUniversity.id)
      formData.append('faculty_id', this.selectedFaculty.id)
      if (this.subject.cost) {
        formData.append('cost', this.subject.cost)
      }
      if (this.subject.video_cost) {
        formData.append('video_cost', this.subject.video_cost)
      }
      formData.append('_method', 'PUT')

      this.errors = await this.postRequest(formData, this.$hostUrl + 'subjects/' + this.$route.params.id, '/subjects')
    }
  }
}
</script>
