<template>
  <div class="container-fluid py-4 add-student grade-detail">
    <div class="row">
      <div class="col-12">
        <div class="card my-4">
          <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
            <div
              class="bg-gradient-success shadow-success border-radius-lg pt-4 pb-3"
            >
              <div class="row">
                <div class="col-lg-6">
                  <h6 class="text-white text-capitalize ps-3">   تقرير دخل السنتر     </h6>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body px-0 pb-2">
            <!-- exceptional attendance row -->
         <div class="row align-center ex-row">
            <div class="mb-3 col-lg-3">
                <label>   من : </label>
                  <vmd-input
                            id=""
                            type="date"
                            v-model="fromDate"
                          />
                        </div>
                          <div class="mb-3 col-lg-3">
                <label>   الي: </label>
                  <vmd-input
                            id=""
                            type="date"
                            v-model="toDate"
                          />
                        </div>
                        <div class="mb-3 col-lg-3">
                        <label>الفروع</label>
                    <multiselect
                        class='required form-control'
                        id='student'
                        v-model="selectedBranch"
                        :options='branches'
                        :searchable='true'
                        :close-on-select='true'
                        placeholder="  كل الفروع"
                        label='name'
                        track-by='id'
                        required='required'
                        :internal-search="true"
                        >
                      >
                        <template v-slot:noOptions>
                          <span> فارغة </span>
                        </template>
                        <template v-slot:noResult>
                          <span>لا يوجد نتيجة </span>
                        </template>
                      </multiselect>
                </div>
                 <div class="text-center col-lg-1">
                  <vmd-button
                    class="my-4 mb-2 width action-btn"
                    variant="gradient"
                    color="primary"
                    @click="filterData()"
                    >  فلتر</vmd-button
                  >
                </div>
                  <div class="text-center col-lg-1">
                  <vmd-button
                    class="my-4 mb-2 width action-btn"
                    variant="gradient"
                    color="info"
                    @click="printReport()"
                    >  طباعة</vmd-button
                  >
                </div>
              </div>

              <div id='print-report' style=" margin-top: 40px;direction:rtl">
            <div class="row align-center" style=" margin-top: 40px;direction:rtl" >
              <div class="text-center col-lg-12 overflow-x" style="direction:rtl">
                <table class=" b-block  table " style="direction:rtl">
                  <thead>
                    <tr>
                      <th rowspan ="2">التاريخ</th>
                      <th colspan="2">الدورات</th>
                        <th colspan="2">المحاضرات </th>
                        <th colspan="2">العروض</th>
                        <th colspan="2">المحفظة</th>
                        <th rowspan="2"> صافي اليوم </th>
                        </tr>
                        <tr>
                        <th>اجمالي الدخل</th>
                        <th>اجمالي الاسترداد</th>
                        <th>اجمالي الدخل</th>
                        <th>اجمالي الاسترداد</th>
                         <th>اجمالي الدخل</th>
                        <th>اجمالي الاسترداد</th>
                         <th>اجمالي الدخل</th>
                        <th>اجمالي الاسترداد</th>
                        </tr>

                  </thead>
                  <tbody>
                    <tr v-for="(value,key) in payments" :key="key">
                      <td>{{key}}</td>
                      <td>{{$filters.number(value.Course.total_paid)}}</td>
                      <td>{{$filters.number(value.Course.total_refund)}}</td>
                      <td>{{$filters.number(value.Lecture.total_paid)}}</td>
                      <td>{{$filters.number(value.Lecture.total_refund)}}</td>
                       <td>{{$filters.number(value.Offer.total_paid)}}</td>
                      <td>{{$filters.number(value.Offer.total_refund)}}</td>
                      <td>{{$filters.number(value.Wallet.total_paid)}}</td>
                      <td>{{$filters.number(value.Wallet.total_refund)}}</td>
                      <td>{{$filters.number(value.total)}}</td>
                    </tr>
                      <tr style="background:#dad6d669;font-weight:bold">
                      <td colspan="1">الإجماليات</td>
                      <td>{{$filters.number(totalsInfo.coursePayments)}} </td>
                       <td>{{$filters.number(totalsInfo.courseRefunds)}}</td>
                        <td>{{$filters.number(totalsInfo.lecturePayments)}}</td>
                        <td>{{$filters.number(totalsInfo.lectureRefunds)}}</td>
                        <td>{{$filters.number(totalsInfo.offerPayments)}}</td>
                        <td>{{$filters.number(totalsInfo.offerRefunds)}}</td>
                        <td>{{$filters.number(totalsInfo.walletPayments)}}</td>
                        <td>{{$filters.number(totalsInfo.walletRefunds)}}</td>
                        <td>{{$filters.number(totalsInfo.total)}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            </div>
          </div>
   <!-- seconed tab -->
          </div>
          </div>

      </div>
    </div>
</template>
<script>
import VmdButton from '@/components/VmdButton.vue'
import $ from 'jquery'
import VmdInput from '@/components/VmdInput.vue'
import exportFromJSON from 'export-from-json'
const today = new Date()
const date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate()
const time = today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds()
const CurrentDateTime = date + ' ' + time
export default {
  name: 'OverallIncome',
  components: {
    VmdButton,
    VmdInput
  },
  data () {
    return {
      payments: [],
      branches: [],
      selectedBranch: '',
      fromDate: '',
      toDate: '',
      totalsInfo: {
        coursePayments: 0,
        courseRefunds: 0,
        lecturePayments: 0,
        lectureRefunds: 0,
        offerPayments: 0,
        offerRefunds: 0,
        walletPayments: 0,
        walletRefunds: 0,
        appFeesPayments: 0,
        appFeesRefunds: 0,
        total: 0
      },
      excelFile: []
    }
  },
  created () {
    this.$http.get(`${this.$hostUrl}report/center/payments?branch_id=''&from=${this.getStartOfMonth()}&to=${this.getToday()}`).then(response => {
      this.payments = response.data.data
      $.each(this.payments, (key, val) => {
        this.totalsInfo = {
          coursePayments: this.totalsInfo.coursePayments + val.Course.total_paid,
          courseRefunds: this.totalsInfo.courseRefunds + val.Course.total_refund,
          lecturePayments: this.totalsInfo.lecturePayments + val.Lecture.total_paid,
          lectureRefunds: this.totalsInfo.lectureRefunds + val.Lecture.total_refund,
          offerPayments: this.totalsInfo.offerPayments + val.Offer.total_paid,
          offerRefunds: this.totalsInfo.offerRefunds + val.Offer.total_refund,
          walletPayments: this.totalsInfo.walletPayments + val.Wallet.total_paid,
          walletRefunds: this.totalsInfo.walletRefunds + val.Wallet.total_refund,
          appFeesPayments: this.totalsInfo.appFeesPayments + val.App_Fees.total_paid,
          appFeesRefunds: this.totalsInfo.appFeesRefunds + val.App_Fees.total_refund,
          total: this.totalsInfo.total + val.total
        }
      })
    })
    this.$http.get(`${this.$hostUrl}branches`).then(response => {
      this.branches = response.data.data
    })
    this.fromDate = this.getStartOfMonth()
    this.toDate = this.getToday()
  },
  methods: {
    clearTotalsData () {
      this.totalsInfo = {
        coursePayments: 0,
        courseRefunds: 0,
        lecturePayments: 0,
        lectureRefunds: 0,
        offerPayments: 0,
        offerRefunds: 0,
        walletPayments: 0,
        walletRefunds: 0,
        appFeesPayments: 0,
        appFeesRefunds: 0,
        total: 0
      }
    },
    getStartOfMonth () {
      const date = new Date()
      date.setDate(1)
      date.setHours(0, 0, 0, 0)
      date.setMonth(date.getMonth()) // Set the month to the current month
      return date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2)
    },
    getToday () {
      const date = new Date()
      return date.toISOString().slice(0, 10)
    },
    filterData () {
      this.clearTotalsData()
      this.$http.get(`${this.$hostUrl}report/center/payments?branch_id=${this.selectedBranch.id ?? ''}&from=${this.fromDate}&to=${this.toDate}`).then(response => {
        this.payments = response.data.data
        $.each(this.payments, (key, val) => {
          this.totalsInfo = {
            coursePayments: this.totalsInfo.coursePayments + val.Course.total_paid,
            courseRefunds: this.totalsInfo.courseRefunds + val.Course.total_refund,
            lecturePayments: this.totalsInfo.lecturePayments + val.Lecture.total_paid,
            lectureRefunds: this.totalsInfo.lectureRefunds + val.Lecture.total_refund,
            offerPayments: this.totalsInfo.offerPayments + val.Offer.total_paid,
            offerRefunds: this.totalsInfo.offerRefunds + val.Offer.total_refund,
            walletPayments: this.totalsInfo.walletPayments + val.Wallet.total_paid,
            walletRefunds: this.totalsInfo.walletRefunds + val.Wallet.total_refund,
            appFeesPayments: this.totalsInfo.appFeesPayments + val.App_Fees.total_paid,
            appFeesRefunds: this.totalsInfo.appFeesRefunds + val.App_Fees.total_refund,
            total: this.totalsInfo.total + val.total
          }
        })
      })
    },
    printReport () {
    // $('.card-footer').hide()
      const options = {
        name: ' Seven Eleven System ',
        specs: ['fullscreen=yes', 'titlebar=yes', 'scrollbars=yes'],
        styles: [
          'https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.rtl.min.css',
          `${window.location.origin}/print-report.css`
        ],
        timeout: 1000, // default timeout before the print window appears
        autoClose: true, // if false, the window will not close after printing
        windowTitle: 'Seven Eleven System' // override the window title
      }

      setTimeout(this.$htmlToPaper('print-report', options), 10000)
      $('.card-footer').show()
    },
    downloadFile () {
      $.each(this.courses, (key, val) => {
        this.excelFile.push({
          رقم: val.course_number,
          الدورة: val.course,
          رسوم_التطبيق: val.app_fees,
          المدفوع_من_الرسوم: val.fees_paid
        })
      })
      const data = this.excelFile
      const fileName = `تقرير   دخل السنتر ${CurrentDateTime}`
      const exportType = exportFromJSON.types.xls
      if (data) exportFromJSON({ data, fileName, exportType })
    }
  },
  mounted () {
    this.fromDate = this.getStartOfMonth()
    this.toDate = this.getToday()
  }
}

</script>
<style>
#collection-date{
  font-weight: 800 !important;
  font-size: 18px !important;
}
.table > :not(:first-child) {
    border-bottom: 1px solid #f0f2f5 !important;
}
.width{
  width:100%;
}
.online{
  border:1px solid #ddd
}
.times{
  margin-top: 10px !important;
}
.times h5{
  font-size:16px !important
}
.times .red{
  position: relative !important;
}
.filter-text{
  font-weight: 700;
    color: #dfad07;
    font-size: 15px !important;
}
.tab2{
  margin-top: 27px !important;
    margin-bottom: 41px !important;
    border-bottom: 1px solid #ddd;
    padding-bottom: 26px !important;
}
.course-info{
      background: #f8f9fa;
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 38px;
    font-size: 14px;
}
.info-h{
      font-size: 14px !important;
    font-weight: 500;
}
.groups-ul{
  background: #fff;
    padding-top: 10px;
    padding-bottom: 10px;
    width: 206px;
    border-top: 2px solid #5cb360;
    box-shadow: 0 4px 6px -1px rgb(0 0 0 / 10%), 0 2px 4px -1px rgb(0 0 0 / 6%);
    display: none;
    position:absolute;
    border-radius: 10px;
}
/* .groups-con{
  color:blue;
  cursor: pointer;
} */
.groups-con:hover .groups-ul{
  display: block;
}

.info-data{
      font-family: "Roboto", Helvetica, Arial, sans-serif !important;
      font-weight:bold !important;
      font-size:17px !important
}
.pagin-row{
  margin-top:15px !important
}
.pagi-item{
  color: #7b809a !important;
    padding: 0 !important;
    margin: 0 3px !important;
    border-radius: 50% !important;
    width: 36px !important;
    height: 36px !important;
    font-size: 0.875rem;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}

.pagi-active span{
  color: #fff !important;
   background-color: #4ea852 !important;
    border-color: #4ea852 !important;
}

</style>
