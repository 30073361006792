<template>
  <div class="container-fluid py-4 add-student grade-detail">
    <div class="row">
      <div class="col-12">
        <div class="card my-4">
          <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
            <div
              class="bg-gradient-success shadow-success border-radius-lg pt-4 pb-3"
            >
              <div class="row">
                <div class="col-lg-6">
                  <h6 class="text-white text-capitalize ps-3">   تقارير  الدورات الأوفلاين   </h6>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body px-0 pb-2">
            <!-- exceptional attendance row -->

              <div id="print-report2" style=" margin-top: 40px;direction:rtl !important">
            <div class="row align-center" style=" margin-top: 40px;direction:rtl !important" >
              <div class="text-center col-lg-12 overflow-x" style="direction:rtl">
                <table class=" b-block  table " style="direction:rtl">
                  <thead>
                    <tr>
                      <th >رقم  </th>
                        <th >اسم الدورة</th>
                         <th > الحالة</th>
                        <th >المدرس</th>
                        <th >الفرقة</th>
                        <th >الطلاب المسجلين</th>
                         <th > المدفوع</th>
                        </tr>
                  </thead>
                  <tbody>
                    <tr v-for="course in offlineCourses" :key="course.id">
                      <td>{{course.number}}</td>
                      <td><router-link class="to-link pointer" :to="{path:'/courses/' +course.id+'/show'}">{{course.name}}</router-link></td>
                      <td>{{course.status=== 1?'مفتوحة' : 'مغلقة'}}</td>
                       <td>{{course.instructor.name}}</td>
                       <td>{{course.grade.name}}</td>
                        <td>{{course.enrolled_students_count}}</td>
                         <td>{{$filters.number(course.paid)}}</td>
                    </tr>
                      <tr style="background:#dad6d669;font-weight:bold">
                      <td colspan="6">الإجمالي</td>
                      <td>{{$filters.number(offlinePaid)}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <!-- end  -->
                 </div>

          </div>
          </div>
          </div>

      </div>

    </div>
</template>
<script>
export default {
  name: 'OfflineCourses',
  components: {
  },
  data () {
    return {
      offlineCourses: [],
      offlinePaid: 0
    }
  },
  created () {
    this.$http.get(`${this.$hostUrl}report/courses/paid/remain`).then(response => {
      this.offlineCourses = response.data.data.courses
      this.offlinePaid = response.data.data.total_paid
    })
  }
}

</script>
<style>
#collection-date{
  font-weight: 800 !important;
  font-size: 18px !important;
}
.table > :not(:first-child) {
    border-bottom: 1px solid #f0f2f5 !important;
}
.width{
  width:100%;
}
.online{
  border:1px solid #ddd
}
.times{
  margin-top: 10px !important;
}
.times h5{
  font-size:16px !important
}
.times .red{
  position: relative !important;
}
.filter-text{
  font-weight: 700;
    color: #dfad07;
    font-size: 15px !important;
}
.tab2{
  margin-top: 27px !important;
    margin-bottom: 41px !important;
    border-bottom: 1px solid #ddd;
    padding-bottom: 26px !important;
}
.course-info{
      background: #f8f9fa;
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 38px;
    font-size: 14px;
}
.info-h{
      font-size: 14px !important;
    font-weight: 500;
}
.groups-ul{
  background: #fff;
    padding-top: 10px;
    padding-bottom: 10px;
    width: 206px;
    border-top: 2px solid #5cb360;
    box-shadow: 0 4px 6px -1px rgb(0 0 0 / 10%), 0 2px 4px -1px rgb(0 0 0 / 6%);
    display: none;
    position:absolute;
    border-radius: 10px;
}
/* .groups-con{
  color:blue;
  cursor: pointer;
} */
.groups-con:hover .groups-ul{
  display: block;
}

.info-data{
      font-family: "Roboto", Helvetica, Arial, sans-serif !important;
      font-weight:bold !important;
      font-size:17px !important
}
.paid-fees{
  color: blue !important
}

</style>
