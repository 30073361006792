<template>
  <div class="container-fluid py-4 all-students">
    <div class="row">
      <div class="col-12">
        <div class="card my-4">
          <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
            <div
              class="bg-gradient-success shadow-success border-radius-lg pt-4 pb-3"
            >
              <div class="row">
                <div class="col-lg-6">
                  <h6 class="text-white text-capitalize ps-3">
                    {{ val }}{{ $t("message.rooms") }}
                  </h6>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body px-0 pb-2">
            <div class="row">
              <vdtnet-table
                class="table-striped table table-borderless"
                hide-select
                single-select
                :jquery="jq"
                ref="table"
                :fields="fields"
                :opts="options"
                @edit="doAlertEdit"
                @show="doAlertShow"
                @delete="doAlertDelete"
                @detail="doAlertdetail"
                @reloaded="doAfterReload"
                @table-creating="doCreating"
                @table-created="doCreated"
              >
              </vdtnet-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VdtnetTable from 'vue-datatables-net'
import $ from 'jquery'
export default {
  name: 'Rooms',
  components: {
    VdtnetTable
  },
  data () {
    return {
      pagination: {
        rowsPerPage: 30
      },
      jq: $,
      val: '',
      options: {
        ajax: {
          beforeSend: function (xhr) {
            xhr.setRequestHeader(
              'Authorization',
              'Bearer ' + localStorage.token
            )
          },
          url: `${this.$hostUrl}rooms`,
          dataSrc: (json) => {
            return json.data
          }
        },
        buttons: [
          {
            extend: 'print',
            text: '',
            titleAttr: 'طباعة',
            className: 'fas fa-print btn-primary',
            exportOptions: {
              columns: 'th:not(:last-child)'
            },
            customize: function (win) {
              $(win.document.body).css('direction', 'rtl')
            }
          },
          {
            extend: 'copy',
            text: '',
            className: 'fas fa-copy btn-warning',
            titleAttr: 'نسخ',
            exportOptions: {
              columns: 'th:not(:last-child)'
            }
          },
          {
            extend: 'excel',
            text: '',
            titleAttr: 'إكسل',
            className: 'fas fa-table btn-success',
            exportOptions: {
              columns: 'th:not(:last-child)'
            }
          }
        ],
        dom:
          "<'row'<'col-sm-12 col-md-6'f><'col-sm-12 col-md-6 added'l>>" +
          "<'row'<'col-sm-12'Btr>>" +
          "<'row'<'col-sm-12 col-md-6'p><'col-sm-12 col-md-6'i>>",
        paging: true,
        language: {
          url: this.$t('message.url'),
          processing:
            '<i class="fa fa-spinner fa-spin" style="font-size:40px;color:#7aeb7f"></i>'
        },
        responsive: false,
        processing: true,
        searching: true,
        searchDelay: 1500,
        destroy: true,
        ordering: true,
        lengthChange: true,
        serverSide: false,
        fixedHeader: true,
        saveState: true,
        select: false
      },
      fields: {
        name: {
          label: this.$t('message.name'),
          sortable: true,
          searchable: true
        },
        seats: {
          label: this.$t('message.seatsNum'),
          sortable: true,
          searchable: true
        },
        details: {
          label: this.$t('message.details'),
          sortable: true,
          searchable: true,
          defaultContent:
            '&ensp;<a href="javascript:void(0);" data-action="detail"><i class="mdi mdi-square-edit-outline"></i> <span class="badge badge-sm bg-gradient-info">' +
            this.$t('message.details') +
            '</span></a>'
        }
      }
    }
  },
  methods: {
    doAlertEdit (data) {
      this.$router.push({
        path: `/${data.id}/edit `,
        params: { id: data.id }
      })
    },
    doAlertdetail (data) {
      this.$router.push({
        path: `/rooms/${data.id}/detail`,
        params: { id: data.id }
      })
    }
  }
}
</script>
<style>
.ps-3 {
  padding-right: 1rem !important;
}
.me-3 {
  margin-left: 1rem !important;
}
</style>
