<template>
     <div class="container-fluid py-4 add-student">
    <div class="row">
      <div class="col-12">
        <div class="card my-4">
          <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
            <div
              class="bg-gradient-success shadow-success border-radius-lg pt-4 pb-3"
            >
              <h6 class="text-white text-capitalize ps-3">  تعديل محاضرة</h6>
            </div>
          </div>
          <div class="card-body px-0 pb-2">
                   <form @submit='updateLecture' role="form" class="text-start mt-3">
                       <div class='row'>
                      <div class="mb-3 col-lg-6">
                  <label>  رقم المحاضرة<span class="red">*</span> <span color="danger" class="error">{{
                      errors.lecture_number? errors.lecture_number[0] : ''
                    }}</span></label>
                  <vmd-input
                    id=""
                    min-length='10'
                    type="number"
                    placeholder="رقم المحاضرة"
                   v-model='lectureNumber'
                   required
                  />
                </div>
                   <div class="mb-3 col-lg-6">
                  <label>   القاعات<span class="red">*</span></label>
                    <div class="input-group input-group-outline null" >
                   <select class="form-control" v-model="selectedRoom.id">
                      <option selected value="">اختر  القاعة</option>
                      <option v-for="room in rooms" :key="room.id" :value="room.id">{{room.name}}</option>
                    </select>
                    <i class="fas fa-caret-down" aria-hidden="true"></i>
                  </div>
                </div>
                       </div>
                       <div class="row">
                        <div class="mb-3 col-lg-6 ">
                    <label>   تاريخ بدء  المحاضرة<span class="red">*</span></label>
                    <div class='date'>
                  <vmd-input
                    id="date-input"
                    type="datetime-local"
                   label="    تاريخ  بدء المحاضرة "
                   v-model='lecture.start'
                   required
                  />
                  </div>
                </div>
                       </div>
                       <div class="row">
                <div class="text-center col-lg-6">
                  <vmd-button
                    class="my-4 mb-2"
                    variant="gradient"
                    color="info"
                    > حفظ</vmd-button
                  >
                </div>
                 <div class="text-center col-lg-6">
                  <vmd-button
                    class="my-4 mb-2"
                    variant="gradient"
                    color="danger"
                    > مسح</vmd-button
                  >
                </div>
                       </div>
              </form>
          </div>
        </div>
      </div>
    </div>
     </div>
</template>
<script>
import VmdButton from '@/components/VmdButton.vue'
import VmdInput from '@/components/VmdInput.vue'
import $ from 'jquery'
// import swal from 'sweetalert'

export default {
  name: 'EditLecture',
  components: {
    VmdButton,
    VmdInput
  },
  data () {
    return {
      rooms: [],
      lecture: {},
      selectedRoom: '',
      lectureNumber: '',
      errors: []
    }
  },
  created () {
    console.log(this.$route.params.id)
    this.$http.get(`${this.$hostUrl}lectures/${this.$route.params.id}`).then(response => {
      this.lecture = response.data.data
      this.lectureNumber = this.lecture.lecture_number
      this.selectedRoom = {
        id: this.lecture.room.id,
        name: this.lecture.room.name
      }
    })
    const rooms = []
    this.$http.get(`${this.$hostUrl}rooms`)
      .then((response) => {
        $.each(response.data.data, function (key, value) {
          rooms.push({
            name: value.name,
            id: value.id
          })
        })
        this.rooms = rooms
      })
  },
  methods: {
    async updateLecture (e) {
      e.preventDefault()
      const formData = new FormData()
      formData.append('lecture_number', this.lectureNumber)
      formData.append('room_id', this.selectedRoom.id)
      formData.append('course_id', this.lecture.course.id)
      const formatDate = this.lecture.start.replace('T', ' ')
      formData.append('start', formatDate)
      formData.append('_method', 'PUT')

      this.errors = await this.postRequest(formData, this.$hostUrl + 'lectures/' + this.$route.params.id, '/lectures')
    }
  }
}
</script>
