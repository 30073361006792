<template>
  <div class="container-fluid py-4 add-student grade-detail">
    <div class="row">
      <div class="col-12">
        <div class="card my-4">
          <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
            <div
              class="bg-gradient-success shadow-success border-radius-lg pt-4 pb-3"
            >
              <div class="row">
                <div class="col-lg-6">
                  <h6 class="text-white text-capitalize ps-3">   تقارير المناطق   </h6>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body px-0 pb-2">
             <div class="mb-3 col-lg-12 tab-div tab2">
          <ul class="nav nav-tabs" id="myTab" role="tablist">
      <li class="nav-item" role="presentation">
        <button class="  nav-link btn mb-0 bg-gradient-info btn-md null null btn-lg" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true"> تقرير الطالب</button>
      </li>
      <li class="nav-item" role="presentation">
        <button class=" nav-link btn mb-0 bg-gradient-info  btn-md null null btn-lg" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false"> تقرير الفرقة</button>
      </li>
    </ul>
    </div>
            <!-- exceptional attendance row -->
          <div class="tab-content" id="myTabContent">
  <div class="tab-pane fade show " id="home" role="tabpanel" aria-labelledby="home-tab">
         <div class="row align-center ex-row">
          <div class="mb-3 col-lg-3">
                    <label>   المنطقة </label>
                    <multiselect
                        class='required form-control'
                        id='grade'
                        v-model="selectedArea"
                        :options='areas'
                        :searchable='true'
                        :close-on-select='true'
                        placeholder="  الكل"
                        label='name'
                        :custom-label="customLabel"
                        track-by='id'
                        required='required'
                        :internal-search="true"
                        >
                      >
                        <template v-slot:noOptions>
                          <span> فارغة </span>
                        </template>
                        <template v-slot:noResult>
                          <span>لا يوجد نتيجة </span>
                        </template>
                      </multiselect>
                </div>
                   <div class="mb-3 col-lg-3">
                    <label>   الفرقة </label>
                    <multiselect
                        class='required form-control'
                        id='grade'
                        v-model="selectedGrade"
                        :options='grades'
                        :searchable='true'
                        :close-on-select='true'
                        placeholder="  الكل"
                        label='name'
                        :custom-label="customLabel"
                        track-by='id'
                        required='required'
                        :internal-search="true"
                        >
                      >
                        <template v-slot:noOptions>
                          <span> فارغة </span>
                        </template>
                        <template v-slot:noResult>
                          <span>لا يوجد نتيجة </span>
                        </template>
                      </multiselect>
                </div>
                   <div class="mb-3 col-lg-2">
                    <label>المجموعة</label>
                    <multiselect
                        class='required form-control'
                        id='student'
                        v-model="selectedGroup"
                        :options='groups'
                        :searchable='true'
                        :multiple='true'
                        :close-on-select='true'
                        placeholder=" الكل "
                        label='name'
                        :custom-label="customLabel"
                        track-by='id'
                        required='required'
                        :internal-search="true"
                        >
                      >
                        <template v-slot:noOptions>
                          <span> فارغة </span>
                        </template>
                        <template v-slot:noResult>
                          <span>لا يوجد نتيجة </span>
                        </template>
                      </multiselect>
                </div>
                  <div class="text-center col-lg-1 ">
                  <vmd-button
                    class="my-4 mb-2 width action-btn"
                    variant="gradient"
                    color="primary"
                    @click="filterData('student')"
                    >  فلتر</vmd-button
                  >
                </div>
                  <div class="text-center col-lg-1">
                  <vmd-button
                    class="my-4 mb-2 width action-btn"
                    variant="gradient"
                    color="danger"
                    @click="clearData('student')"
                    >  مسح</vmd-button
                  >
                </div>
                  <div class="text-center col-lg-1">
                  <vmd-button
                    class="my-4 mb-2 width action-btn"
                    variant="gradient"
                    color="info"
                    @click="printReport('student')"
                    >  طباعة</vmd-button
                  >
                </div>
                <div class="text-center col-lg-1">
                  <vmd-button
                    class="my-4 mb-2 width action-btn"
                    variant="gradient"
                    color="warning"
                    @click="downloadFile('student')"
                    >  اكسل</vmd-button
                  >
                </div>
              </div>
              <div id='print-report' style=" margin-top: 40px;direction:rtl">
                 <div class='row times' style=" margin-top: 40px;direction:rtl">
                <div class="col-md-4" >
                  <h5>المنطقة : <span class="green">{{selectedArea.name??'جميع المناطق'}}</span></h5>
                </div>
                <div class="col-md-4" >
                  <h5> الفرقة : <span class="green">{{selectedGrade.name??'جميع الفرق'}}</span></h5>
                </div>
                 <div class="col-md-4" >
                  <h5> إجمالي الطلاب : <span class="green">{{studentsCount??' '}}</span></h5>
                </div>
              </div>
            <div class="row align-center" style=" margin-top: 40px;direction:rtl" >
              <div class="text-center col-lg-12" style="direction:rtl">
                <table class=" b-block  table " style="direction:rtl">
                  <thead>
                    <tr>
                      <th >رقم</th>
                        <th>اسم الطالب</th>
                        <th>الهاتف</th>
                        <th>الفرقة</th>
                        <th>المجموعة</th>
                        <th>المنطقة</th>
                        </tr>

                  </thead>
                  <tbody>
                    <tr v-for="student in students" :key="student.id">
                      <td>{{student.number}}</td>
                      <td>{{student.full_name}}</td>
                       <td>{{student.mobile}}</td>
                      <td>{{student.grade?student.grade.name:'-'}}</td>
                      <td>{{student.group?student.group.name:'-'}}</td>
                      <td>{{student.area?student.area.name:'-'}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            </div>
          </div>
   <!-- seconed tab -->
    <div class="tab-pane fade show " id="profile" role="tabpanel" aria-labelledby="profile-tab">
         <div class="row align-center ex-row">
                   <div class="mb-3 col-lg-4">
                    <label>   المنطقة <span class="red">*</span></label>
                    <multiselect
                        class='required form-control'
                        id='grade'
                        v-model="selectedArea"
                        :options='areas'
                        :searchable='true'
                        :close-on-select='true'
                        placeholder="  الكل"
                        label='name'
                        :custom-label="customLabel"
                        track-by='id'
                        required='required'
                        :internal-search="true"
                        >
                      >
                        <template v-slot:noOptions>
                          <span> فارغة </span>
                        </template>
                        <template v-slot:noResult>
                          <span>لا يوجد نتيجة </span>
                        </template>
                      </multiselect>
                </div>
                  <div class="text-center col-lg-2 ">
                  <vmd-button
                    class="my-4 mb-2 width"
                    variant="gradient"
                    color="primary"
                    @click="filterData('area')"
                    >  فلتر</vmd-button
                  >
                </div>
                <div class="text-center col-lg-2">
                  <vmd-button
                    class="my-4 mb-2 width"
                    variant="gradient"
                    color="danger"
                    @click="clearData('area')"
                    >  مسح</vmd-button
                  >
                </div>
                  <div class="text-center col-lg-2">
                  <vmd-button
                    class="my-4 mb-2 width"
                    variant="gradient"
                    color="info"
                    @click="printReport('area')"
                    >  طباعة</vmd-button
                  >
                </div>
                   <div class="text-center col-lg-2">
                  <vmd-button
                    class="my-4 mb-2 width"
                    variant="gradient"
                    color="warning"
                    @click="downloadFile('area')"
                    >  اكسل</vmd-button
                  >
                </div>
              </div>
              <div id='print-report2' style=" margin-top: 40px;direction:rtl">
                 <div class='row times' style=" margin-top: 40px;direction:rtl">
                <div class="col-md-4" >
                  <h5>المنطقة : <span class="green">{{selectedArea.name}}</span></h5>
                </div>
              </div>
            <div class="row align-center" style=" margin-top: 40px;direction:rtl" >
              <div class="text-center col-lg-12" style="direction:rtl">
                <table class=" b-block  table " style="direction:rtl">
                  <thead>
                    <tr>
                        <th> الفرقة</th>
                        <th>عدد الطلاب</th>
                        </tr>

                  </thead>
                  <tbody>
                    <tr v-for="item in areaGrades" :key="item.id">
                      <td>{{item.grade_name}}</td>
                      <td>{{item.count}}</td>
                    </tr>
                      <tr style="background:#dad6d669;font-weight:bold">
                      <td>الإجمالي</td>
                        <td>{{total}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            </div>
          </div>

    </div>
          </div>
          </div>
  </div>
      </div>
    </div>
</template>
<script>
import VmdButton from '@/components/VmdButton.vue'
import swal from 'sweetalert'
import $ from 'jquery'
import exportFromJSON from 'export-from-json'
const date = new Date()
const today =
  date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate()
export default {
  name: 'AreaReports',
  components: {
    VmdButton
  },
  data () {
    return {
      tableDate: today,
      fromDate: today,
      toDate: today,
      students: [],
      groups: [],
      selectedGroup: '',
      subjects: [],
      selectedSubject: '',
      grades: [],
      selectedGrade: '',
      areaGrades: [],
      grade: '',
      areas: [],
      selectedArea: '',
      total: '',
      studentsCount: '',
      excelFile: [],
      errors: []
    }
  },
  created () {
    this.$http.get(`${this.$hostUrl}area-student`).then(response => {
      this.students = response.data.data.students
      this.studentsCount = response.data.data.students_count
    })
    this.$http.get(`${this.$hostUrl}areas`).then(response => {
      this.areas = response.data.data
    })
    this.$http.get(`${this.$hostUrl}groups`).then(response => {
      this.groups = response.data.data
    })
    this.$http.get(`${this.$hostUrl}grades`).then(response => {
      this.grades = response.data.data
    })
  },
  methods: {
    filterData (data) {
      switch (data) {
        case 'student':
          if (!this.selectedArea) {
            swal('يجب ادخال المنطقة')
          } else {
            let allSelectedGroups = []
            $.each(this.selectedGroup, function (key, value) {
              allSelectedGroups.push(value.id)
            })
            allSelectedGroups = JSON.stringify(allSelectedGroups)
            this.$http.get(`${this.$hostUrl}area-student?area_id=${this.selectedArea.id}&grade_id=${this.selectedGrade ? this.selectedGrade.id : ''}&group_id=${this.selectedGroup ? allSelectedGroups : ''}`).then(response => {
              this.students = response.data.data.students
              this.studentsCount = response.data.data.students_count
            })
          }
          break
        case 'area':
          if (!this.selectedArea.id) {
            swal('برجاء ادخال المنطقة')
          } else {
            this.$http.get(`${this.$hostUrl}area-grades?area_id=${this.selectedArea.id}`).then(response => {
              this.areaGrades = response.data.data.students
              this.total = response.data.data.total
            })
          }
          break
      }
    },
    clearData (data) {
      switch (data) {
        case 'student':
          this.selectedArea = ''
          this.selectedGroup = ''
          this.selectedGrade = ''
          this.$http.get(`${this.$hostUrl}area-student`).then(response => {
            this.students = response.data.data.students
          })
          break
        case 'area':
          this.selectedArea = ''
          this.areaGrades = []
          break
      }
    },
    printReport (report) {
    // $('.card-footer').hide()
      const options = {
        name: ' Seven Eleven System ',
        specs: ['fullscreen=yes', 'titlebar=yes', 'scrollbars=yes'],
        styles: [
          'https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.rtl.min.css',
          `${window.location.origin}/print-report.css`
        ],
        timeout: 1000, // default timeout before the print window appears
        autoClose: true, // if false, the window will not close after printing
        windowTitle: 'Seven Eleven System' // override the window title
      }
      if (report === 'student') {
        setTimeout(this.$htmlToPaper('print-report', options), 10000)
        $('.card-footer').show()
      } else {
        setTimeout(this.$htmlToPaper('print-report2', options), 10000)
        $('.card-footer').show()
      }
    },
    downloadFile (report) {
      if (report === 'student') {
        $.each(this.students, (key, val) => {
          this.excelFile.push({
            رقم: val.number,
            الطالب: val.full_name,
            الفرقة: val.grade ? val.grade.name : '-',
            المجموعة: val.group ? val.group.name : '-',
            المنطقة: val.area ? val.area.name : '-'
          })
        })
        const data = this.excelFile
        const fileName = ` تقرير المناطق للطلاب - ${today}`
        const exportType = exportFromJSON.types.xls
        if (data) exportFromJSON({ data, fileName, exportType })
      }
      if (report === 'area') {
        $.each(this.areaGrades, (key, val) => {
          this.excelFile.push({
            المنطقة: this.selectedArea.name ?? '-',
            الفرقة: val.grade_name ?? '-',
            عدد_الطلاب: val.count ?? '-'
          })
        })
        const data = this.excelFile
        const fileName = ` تقرير المناطق للفرق - ${today}`
        const exportType = exportFromJSON.types.xls
        if (data) exportFromJSON({ data, fileName, exportType })
      }
    }
  }
}

</script>
<style>
#collection-date{
  font-weight: 800 !important;
  font-size: 18px !important;
}
.table > :not(:first-child) {
    border-bottom: 1px solid #f0f2f5 !important;
}
.width{
  width:100%;
}
.online{
  border:1px solid #ddd
}
.times{
  margin-top: 10px !important;
}
.times h5{
  font-size:16px !important
}
.times .red{
  position: relative !important;
}
.filter-text{
  font-weight: 700;
    color: #dfad07;
    font-size: 15px !important;
}
.tab2{
  margin-top: 27px !important;
    margin-bottom: 41px !important;
    border-bottom: 1px solid #ddd;
    padding-bottom: 26px !important;
}
thead, tbody, tfoot, tr, td, th{

    border: 1px solid #e0dadd !important;
    /* border-width:unset !important */
}

</style>
