<template>
  <div class="container-fluid py-4 add-student grade-detail collec-report">
    <div class="row">
      <div class="col-12">
        <div class="card my-4">
          <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
            <div class="bg-gradient-success shadow-success border-radius-lg pt-4 pb-3">
              <div class="row">
                <div class="col-lg-6">
                  <h6 class="text-white text-capitalize ps-3"> تقارير التحصيلات </h6>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body px-0 pb-2">
            <div class="mb-3 col-lg-12 tab-div tab2">
              <ul class="nav nav-tabs justify-content-center" id="myTab" role="tablist">
                <!-- <li class="nav-item list2" role="presentation">
                  <button style="width:100% !important" class="  nav-link btn mb-0 bg-gradient-info btn-md null null btn-lg" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true"> اجمالي التحصيلات</button>
                </li> -->
                <li class="nav-item list2" role="presentation">
                  <button style="width:100% !important" class=" nav-link btn mb-0 bg-gradient-info  btn-md null null btn-lg" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false"> تفصيلي التحصيلات</button>
                </li>
                <!-- <li class="nav-item list2" role="presentation">
                  <button style="width:100% !important" class="nav-link btn mb-0 bg-gradient-info  btn-md null null btn-lg" id="date-tab" data-bs-toggle="tab" data-bs-target="#date" type="button" role="tab" aria-controls="date" aria-selected="false"> تفصيلي الاستردادات</button>
                </li> -->
                <li class="nav-item list2" role="presentation">
                  <button style="width:100% !important" class="nav-link btn mb-0 bg-gradient-info  btn-md null null btn-lg" id="lecture-tab" data-bs-toggle="tab" data-bs-target="#lecture" type="button" role="tab" aria-controls="lecture" aria-selected="false"> إجمالي المستخدمين</button>
                </li>
                <li class="nav-item list2" role="presentation">
                  <button style="width:100% !important" class="nav-link btn mb-0 bg-gradient-info  btn-md null null btn-lg" id="users-tab" data-bs-toggle="tab" data-bs-target="#users" type="button" role="tab" aria-controls="users" aria-selected="false"> تفصيلي المستخدمين</button>
                </li>
              </ul>
            </div>
            <!-- exceptional attendance row -->
            <div class="tab-content" id="myTabContent">
              <div class="tab-pane fade show " id="home" role="tabpanel" aria-labelledby="home-tab">
                <div class="row align-center ex-row"></div>
                <div class="row">
                  <div class="mb-3 col-lg-3">
                    <label>الدورة</label>
                    <multiselect class='required form-control' id='student' v-model="selectedCourse" :options='coursesList' :searchable='true' :close-on-select='true' placeholder=" اختر الدورة" label='name'  track-by='id' required='required' :internal-search="true"> > <template v-slot:noOptions>
                        <span> فارغة </span>
                      </template>
                      <template v-slot:noResult>
                        <span>لا يوجد نتيجة </span>
                      </template>
                    </multiselect>
                  </div>
                  <div class="mb-3 col-lg-3">
                    <label> نوع الإيصال </label>
                    <div class="input-group input-group-outline null">
                      <select class="form-control" v-model="receiptType">
                        <option selected value=""> الكل</option>
                        <option value="attend"> ايصالات الحضور</option>
                        <option value="pay"> ايصالات الدفع</option>
                      </select>
                      <i class="fas fa-caret-down" aria-hidden="true"></i>
                    </div>
                  </div>
                  <div class="text-center col-lg-2 ">
                    <vmd-button class="my-4 mb-2 width" variant="gradient" color="primary" @click="filterData('total')"> فلتر</vmd-button>
                  </div>
                  <div class="text-center col-lg-2">
                    <vmd-button class="my-4 mb-2 width action-btn" variant="gradient" color="info" @click="printReport('total')"> طباعة</vmd-button>
                  </div>
                  <div class="text-center col-lg-2">
                    <vmd-button class="my-4 mb-2 width" variant="gradient" color="warning" @click="downloadFile('total')"> اكسل</vmd-button>
                  </div>
                </div>
                <div id="print-total" style="direction:rtl">
                  <div class='row times' style=" margin-top: 40px;direction:rtl">
                    <div class="col-md-4">
                      <h5>الدورة : <span class="green">{{selectedCourse.name ??'جميع الدورات'}}</span>
                      </h5>
                    </div>
                    <div class="col-md-4">
                      <h5>نوع الإيصال : <span class="green">{{receiptType}}</span>
                      </h5>
                    </div>
                  </div>
                  <div class="row align-center" style=" margin-top: 40px;direction:rtl">
                    <div class="text-center col-lg-12 overflow-x" style="direction:rtl">
                      <table class=" b-block  table " style="direction:rtl">
                        <thead>
                          <tr>
                            <th>رقم الدورة </th>
                            <th>اسم الدورة </th>
                            <th> المستلم</th>
                            <th>المسترد </th>
                            <th> تحصيل المراجعة</th>
                            <th> قيمة الدورة</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="item in totalCollections" :key="item.id">
                            <td>{{item.number}}</td>
                            <td>{{item.name}}</td>
                            <td>{{ $filters.number(item.payments)}}</td>
                            <td>{{$filters.number(item.refunds)}}</td>
                            <td>{{$filters.number(item.revision)}}</td>
                            <td>{{$filters.number(item.total_cost)}}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div class="row">
                    <nav aria-label="..." class="center">
                      <ul class="pagination pagination-lg">
                        <li @click="paginate($event)"  class=" pointer " :class="{'pagi-active':currentPage === page} " aria-current="page" v-for="page in allPages" :key="page">
                          <span class="page-link pagi-item">{{page}}</span>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
              <!-- seconed tab -->
              <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                <!-- payment refunds -->
                <!-- exceptional attendance row -->
                <div class="row align-center ex-row">
                  <div class="mb-3 col-lg-3">
                    <label> من : </label>
                    <vmd-input id="" type="date" v-model="fromDate" />
                  </div>
                  <div class="mb-3 col-lg-3">
                    <label> الي: </label>
                    <vmd-input id="" type="date" v-model="toDate" />
                  </div>
                  <div class="mb-3 col-lg-3">
                    <label>الدورة</label>
                    <multiselect class='required form-control' id='student' v-model="selectedCourse" :options='courses' :searchable='true' :close-on-select='true' placeholder="  الكل" label='name'  track-by='id' required='required' :internal-search="true"> > <template v-slot:noOptions>
                        <span> فارغة </span>
                      </template>
                      <template v-slot:noResult>
                        <span>لا يوجد نتيجة </span>
                      </template>
                    </multiselect>
                  </div>
                  <div class="mb-3 col-lg-3">
                    <label>المستخدمين</label>
                    <multiselect class='required form-control' id='student' v-model="selectedUser" :options='users' :searchable='true' :close-on-select='true' placeholder="  الكل" label='name'  track-by='id' required='required' :internal-search="true"> > <template v-slot:noOptions>
                        <span> فارغة </span>
                      </template>
                      <template v-slot:noResult>
                        <span>لا يوجد نتيجة </span>
                      </template>
                    </multiselect>
                  </div>
                  <div class="mb-3 col-lg-3">
                    <label> نوع الإيصال </label>
                    <div class="input-group input-group-outline null">
                      <select class="form-control" v-model="receiptType">
                        <option selected value=""> الكل</option>
                        <option value="attend"> ايصالات الحضور</option>
                        <option value="pay"> ايصالات الدفع</option>
                      </select>
                      <i class="fas fa-caret-down" aria-hidden="true"></i>
                    </div>
                  </div>
                  <div class="text-center col-lg-2 ">
                    <vmd-button class="my-4 mb-2 width" variant="gradient" color="primary" @click="filterData('payment-refunds')"> فلتر</vmd-button>
                  </div>
                  <div class="text-center col-lg-2">
                    <vmd-button class="my-4 mb-2 width action-btn" variant="gradient" color="info" @click="printReport('payment-refunds')"> طباعة</vmd-button>
                  </div>
                  <div class="text-center col-lg-2">
                    <vmd-button class="my-4 mb-2 width " variant="gradient" color="warning" @click="downloadFile('payment-refunds')"> اكسل</vmd-button>
                  </div>
                </div>
                <div id="print-payments" style=" margin-top: 40px;direction:rtl">
                  <div class='row times' style=" margin-top: 40px;direction:rtl">
                    <div class="col-md-4">
                      <h5>الدورة : <span class="green">{{selectedCourse.name ??'جميع الدورات'}}</span>
                      </h5>
                    </div>
                    <div class="col-md-4">
                      <h5> المستخدم : <span class="green">{{selectedUser.name ??'جميع المستخدمين'}}</span>
                      </h5>
                    </div>
                  </div>
                  <div class="row align-center" style=" margin-top: 40px;direction:rtl">
                    <div class="text-center col-lg-12 overflow-x" style="direction:rtl">
                      <table class=" b-block  table " style="direction:rtl">
                        <thead>
                          <tr>
                            <th> رقم الإيصال</th>
                            <th> التاريخ</th>
                            <th> نوع الحركة</th>
                            <th> اسم الطالب</th>
                            <th> استلام نقدية</th>
                            <th> استرداد نقدية</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="item in paymentRefunds.all" :key="item.id">
                            <td>{{item.code}}</td>
                            <td>{{item.created_at}}</td>
                            <td>{{item.transaction_type.name }}</td>
                            <td>{{item.student.full_name}}</td>
                            <td>{{item.transaction_type.name === 'استلام' || item.description ==='حضور استثنائي'?$filters.number(item.amount):'-'}}</td>
                            <td>{{item.transaction_type.name === 'استرداد'?item.amount:'-'}}</td>
                          </tr>
                          <tr style="background:#dad6d669;font-weight:bold">
                            <td colspan="4">الإجمالي</td>
                            <td>{{$filters.number(detailsPayments)}}</td>
                            <td>{{$filters.number(detailsRefunds)}}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <!--  tab -->
              <div class="tab-pane fade" id="date" role="tabpanel" aria-labelledby="date-tab">
                <!-- exceptional attendance row -->
                <div class="row align-center ex-row">
                  <div class="mb-3 col-lg-3">
                    <label> من : </label>
                    <vmd-input id="" type="date" v-model="fromDate" />
                  </div>
                  <div class="mb-3 col-lg-3">
                    <label> الي: </label>
                    <vmd-input id="" type="date" v-model="toDate" />
                  </div>
                  <div class="mb-3 col-lg-3">
                    <label>الدورة</label>
                    <multiselect class='required form-control' id='student' v-model="selectedCourse" :options='courses' :searchable='true' :close-on-select='true' placeholder=" اختر الدورة" label='name'  track-by='id' required='required' :internal-search="true"> > <template v-slot:noOptions>
                        <span> فارغة </span>
                      </template>
                      <template v-slot:noResult>
                        <span>لا يوجد نتيجة </span>
                      </template>
                    </multiselect>
                  </div>
                  <div class="text-center col-lg-1 ">
                    <vmd-button class="my-4 mb-2 width action-btn" variant="gradient" color="primary" @click="filterData('details')"> فلتر</vmd-button>
                  </div>
                  <div class="text-center col-lg-1">
                    <vmd-button class="my-4 mb-2 width action-btn" variant="gradient" color="info" @click="printReport('details')"> طباعة</vmd-button>
                  </div>
                  <div class="text-center col-lg-1">
                    <vmd-button class="my-4 mb-2 width action-btn" variant="gradient" color="warning" @click="downloadFile('details')"> اكسل</vmd-button>
                  </div>
                </div>
                <div id="print-details" style=" margin-top: 40px;direction:rtl">
                  <div class='row times' style=" margin-top: 40px;direction:rtl">
                    <div class="col-md-4">
                      <h5>تقرير تفصيلي الاستردادات</h5>
                    </div>
                    <div class="col-md-4">
                      <h5> الدورة : <span class="green">{{selectedCourse.name ??'جميع الدورات'}}</span>
                      </h5>
                    </div>
                  </div>
                  <div class="row align-center" style=" margin-top: 40px;direction:rtl">
                    <div class="text-center col-lg-12 overflow-x" style="direction:rtl">
                      <table class=" b-block  table " style="direction:rtl">
                        <thead>
                          <tr>
                            <th> رقم الإيصال</th>
                            <th>التاريخ</th>
                            <th> رقم الطالب </th>
                            <th> اسم الطالب </th>
                            <th> الفرقة</th>
                            <th> المجموعة</th>
                            <th> السبب</th>
                            <th> القيمة</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="item in Refunds" :key="item.id">
                            <td>{{item.code}}</td>
                            <td>{{item.created_at}}</td>
                            <td>{{item.student.number}}</td>
                            <td>{{item.student.full_name}}</td>
                            <td>{{item.student.grade}}</td>
                            <td>{{item.student.group}}</td>
                            <td>{{item.description}}</td>
                            <td>{{$filters.number(item.amount)}}</td>
                          </tr>
                          <tr style="background:#dad6d669;font-weight:bold">
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>الإجمالي</td>
                            <td>{{$filters.number(totaldetailRefunds)}}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <!-- users and teachers  -->
              <div class="tab-pane fade" id="lecture" role="tabpanel" aria-labelledby="lecture-tab">
                <div class=" px-0 pb-2">
                  <!-- exceptional attendance row -->
                  <div class="row align-center ex-row">
                    <div class="mb-3 col-lg-3">
                      <label> من : </label>
                      <vmd-input id="" type="date" v-model="fromDate" />
                    </div>
                    <div class="mb-3 col-lg-3">
                      <label> الي: </label>
                      <vmd-input id="" type="date" v-model="toDate" />
                    </div>
                    <div class="text-center col-lg-2 ">
                      <vmd-button class="my-4 mb-2 width" variant="gradient" color="primary" @click="filterData('total-users')"> فلتر</vmd-button>
                    </div>
                    <div class="text-center col-lg-2">
                      <vmd-button class="my-4 mb-2 width " variant="gradient" color="info" @click="printReport('total-users')"> طباعة</vmd-button>
                    </div>
                    <div class="text-center col-lg-2">
                      <vmd-button class="my-4 mb-2 width " variant="gradient" color="warning" @click="downloadFile('total-users')"> اكسل</vmd-button>
                    </div>
                  </div>
                  <div id="print-users_teachers" style=" margin-top: 40px;direction:rtl">
                    <div class='row times' style=" margin-top: 40px;direction:rtl">
                      <div class="col-md-6">
                        <h5> التحصيلات على المستوى اليومي للمستخدمين</h5>
                      </div>
                      <div class="col-md-6">
                        <h5> التاريخ : <span class="green">{{date}}</span>
                        </h5>
                      </div>
                    </div>
                    <div class='row times'>
                      <div class="col-md-12">
                        <div> من التاريخ : <span class="green mr-2">{{fromDate}}</span>
                        إلى التاريخ : <span class="green">{{toDate}}</span></div>
                      </div>
                    </div>
                    <div class="row align-center" style=" margin-top: 40px;direction:rtl">
                      <div class="text-center col-lg-12 overflow-x" style="direction:rtl">
                        <table class=" b-block  table " style="direction:rtl">
                          <thead>
                            <tr>
                              <th rowspan="2">المستخدم</th>
                              <th colspan="5"> استلام نقدية </th>
                              <th colspan="5"> استرداد نقدية</th>
                              <th rowspan="2">الصافي الكلي</th>
                              <th rowspan="2">المدرس</th>
                            </tr>
                            <tr>
                              <th>الدورات</th>
                              <th>العروض</th>
                              <th>الدخول</th>
                              <th>المحفظة</th>
                              <th>الصافي</th>
                              <th>الدورات</th>
                              <th>العروض</th>
                              <th>الدخول</th>
                              <th>المحفظة</th>
                              <th>الصافي </th>
                            </tr>
                            <tr></tr>
                            <tr></tr>
                          </thead>
                          <tbody>
                            <tr v-for="user in totalUsers" :key="user.id">
                              <td>{{user.name}}</td>
                              <td>{{$filters.number(user.course_payment)??'-'}}</td>
                              <td>{{$filters.number(user.offer_payment)??'-'}}</td>
                               <td>{{$filters.number(user.attendance_payment)??'-'}}</td>
                               <td>{{$filters.number(user.wallet_payments)??'-'}}</td>
                              <td style="background:#d1fdd3">{{$filters.number(user.total_payments)??'-'}}</td>
                              <td>{{$filters.number(user.course_refunds)??'-'}}</td>
                              <td>{{$filters.number(user.offer_refunds)??'-'}}</td>
                              <td>{{$filters.number(user.attendance_refunds)??'-'}}</td>
                               <td>{{$filters.number(user.wallet_refunds)??'-'}}</td>
                              <td class="refund-back">{{$filters.number(user.total_refunds)??'-'}}</td>
                              <td style="background:#d1fdd3">{{$filters.number(user.total)??'-'}}</td>
                              <td>{{$filters.number(user.instructor)??'-'}}</td>
                            </tr>
                            <tr class="back-color">
                              <td>
                                <span class="bolder bold"> الإجماليات: </span>
                              </td>
                              <td>
                                <span class="bolder bold">{{$filters.number(coursePayments)}}</span>
                              </td>
                              <td>
                                <span class="bolder bold">{{$filters.number(offerPayments)}}</span>
                              </td>
                              <td>
                                <span class="bolder bold">{{$filters.number(attendancePayment)}}</span>
                              </td>
                               <td>
                                <span class="bolder bold">{{$filters.number(walletPayment)}}</span>
                              </td>
                              <td style="">
                                <span class="bolder bold">{{$filters.number(totalReceived)}}</span>
                              </td>
                              <td style="">
                                <span class="bolder bold">{{$filters.number(totalCourseRefunds)}}</span>
                              </td>
                              <td style="">
                                <span class="bolder bold">{{$filters.number(totalOfferRefunds)}}</span>
                              </td>
                                <td style="">
                                <span class="bolder bold">{{$filters.number(totalAttendanceRefunds)}}</span>
                              </td>
                                <td style="">
                                <span class="bolder bold">{{$filters.number(totalWalletRefunds)}}</span>
                              </td>
                              <td style="">
                                <span class="bolder bold">{{$filters.number(totalRefunds)}}</span>
                              </td>
                              <td>
                                <span class="bolder bold">{{$filters.number(total)}}</span>
                              </td>
                              <td>
                                <span class="bolder bold">{{$filters.number(instructorPayment)}}</span>
                              </td>

                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- users   -->
              <div class="tab-pane fade" id="users" role="tabpanel" aria-labelledby="users-tab">
                <div class=" px-0 pb-2">
                  <!-- exceptional attendance row -->
                  <div class="row align-center ex-row">
                    <div class="mb-3 col-lg-3">
                      <label> من : </label>
                      <vmd-input id="" type="date" v-model="fromDate" />
                    </div>
                    <div class="mb-3 col-lg-3">
                      <label> الي: </label>
                      <vmd-input id="" type="date" v-model="toDate" />
                    </div>
                    <div class="mb-3 col-lg-3">
                      <label>المستخدمين</label>
                      <multiselect class='required form-control' id='student' v-model="selectedUser" :options='users' :searchable='true' :close-on-select='true' placeholder=" اختر المستخدم" label='name'  track-by='id' required='required' :internal-search="true"> > <template v-slot:noOptions>
                          <span> فارغة </span>
                        </template>
                        <template v-slot:noResult>
                          <span>لا يوجد نتيجة </span>
                        </template>
                      </multiselect>
                    </div>
                    <div class="text-center col-lg-3 ">
                      <vmd-button class="my-4 mb-2 width" variant="gradient" color="primary" @click="filterData('users')"> فلتر</vmd-button>
                    </div>
                    <!-- <div class="text-center col-lg-2"><vmd-button
                    class="my-4 mb-2 width"
                    variant="gradient"
                    color="warning"
                    @click="downloadFile('users')"
                    >  اكسل</vmd-button
                  ></div> -->
                    <!-- <div class="text-center col-lg-2"><vmd-button
                    class="my-4 mb-2 width"
                    variant="gradient"
                    color="info"
                    @click="printReport('users')"
                    >  طباعة</vmd-button
                  ></div> -->
                  </div>
                  <div class="row align-center" style=" margin-top: 40px;direction:rtl" id="print-report">
                    <div class="text-center col-lg-12 overflow-x" style="direction:rtl">
                      <table class=" b-block  table " style="direction:rtl">
                        <thead>
                          <tr>
                            <th></th>
                            <th>عدد</th>
                            <th>الإجمالي</th>
                            <th>عدد اللاغي</th>
                            <th>إجمالي اللاغي</th>
                          </tr>
                          <tr>
                            <th> الحضور </th>
                            <td class="hr-cell">{{userReceipts.attendancePaymentCount}}</td>
                            <td class="hr-cell">{{$filters.number(userReceipts.attendancePayment)}}</td>
                            <td class="hr-cell">{{userReceipts.attendancerefundsCount}}</td>
                            <td class="hr-cell">{{$filters.number(userReceipts.attendancerefund)}}</td>
                          </tr>
                          <tr>
                            <th> الحضور الإستثنائي </th>
                            <td class="hr-cell">{{userReceipts.exceptionalPaymentsCount}}</td>
                            <td class="hr-cell">{{$filters.number(userReceipts.exceptionalPayments)}}</td>
                            <td class="hr-cell">{{userReceipts.exceptionalRefundsCount}}</td>
                            <td class="hr-cell">{{$filters.number(userReceipts.exceptionalRefunds)}}</td>
                          </tr>
                          <tr>
                            <th> تحصيل الدورات </th>
                            <td class="hr-cell">{{userReceipts.coursesePaymentCount}}</td>
                            <td class="hr-cell">{{$filters.number(userReceipts.coursesePayment)}}</td>
                            <td class="hr-cell">{{userReceipts.courseserefundCount}}</td>
                            <td class="hr-cell">{{$filters.number(userReceipts.courseserefund)}}</td>
                          </tr>
                          <tr>
                            <th> المحفظة </th>
                            <td class="hr-cell">{{$filters.number(userReceipts.walletPayment)}}</td>
                            <td class="hr-cell">{{$filters.number(userReceipts.walletPayment)}}</td>
                            <td class="hr-cell">-</td>
                            <td class="hr-cell">-</td>
                          </tr>
                          <tr class="back-color">
                            <th style="color:#222; font-size:16px !important"> الإجمالي </th>
                            <td class="hr-cell">{{userReceipts.countPayments}}</td>
                            <td class="hr-cell">{{$filters.number(userReceipts.totalPayments)}}</td>
                            <td class="hr-cell">{{userReceipts.countrefunds}}</td>
                            <td class="hr-cell">{{$filters.number(userReceipts.totalRefunds)}}</td>
                          </tr>
                        </thead>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import VmdButton from '@/components/VmdButton.vue'
import VmdInput from '@/components/VmdInput.vue'
import swal from 'sweetalert'
import $ from 'jquery'
import exportFromJSON from 'export-from-json'
const today =
 new Date().toISOString().slice(0, 10)
export default {
  name: 'CollectionReport',
  components: {
    VmdInput,
    VmdButton
  },
  data () {
    return {
      date: today,
      tableDate: today,
      fromDate: today,
      toDate: today,
      totalCollections: [],
      totalFile: [],
      paymentRefunds: [],
      Refunds: [],
      courses: [],
      coursesList: [],
      selectedCourse: '',
      users: [],
      selectedUser: '',
      userReceipts: {},
      totalUsers: [],
      offerPayments: 0,
      coursePayments: 0,
      totalCourseRefunds: 0,
      totalOfferRefunds: 0,
      attendancePayment: 0,
      totalAttendanceRefunds: 0,
      walletPayment: 0,
      totalWalletRefunds: 0,
      totalReceived: 0,
      totalRefunds: 0,
      detailsPayments: '',
      detailsRefunds: '',
      totaldetailRefunds: '',
      total: 0,
      instructorPayment: 0,
      selectedStatus: '',
      grade: '',
      receiptType: 'جميع الإيصالات',
      allPages: '',
      page: 1,
      currentPage: '',
      errors: []
    }
  },
  created () {
    this.$http.get(`${this.$hostUrl}courses`).then(response => {
      this.coursesList = response.data.data
    })
    this.$http.get(`${this.$hostUrl}courses`).then(response => {
      this.courses = response.data.data
    })
    this.$http.get(`${this.$hostUrl}all-users`).then(response => {
      this.users = response.data.data
    })
    this.$http.get(`${this.$hostUrl}report/course-receipts`).then(response => {
      console.log(response.data.data)
      this.currentPage = response.data.data.current_page
      console.log(response.data.data.per_page)
      this.allPages = response.data.data.last_page
      this.totalCollections = response.data.data.data
    })
    // users and teachers receipt
    this.getUserReceipts()
  },
  methods: {
    async getUserReceipts () {
      try {
        const response = await this.$http.get(`${this.$hostUrl}receipt/report`)
        const data = response.data.data
        this.totalUsers = data[0]
        const paymentData = data[1]
        this.coursePayments = paymentData.total_course_payment
        this.offerPayments = paymentData.total_offer_payment
        this.attendancePayment = paymentData.total_attendance_payment
        this.walletPayment = paymentData.total_wallet_payment
        this.totalReceived = paymentData.users_total_payments
        this.totalAttendanceRefunds = paymentData.total_attendance_refunds
        this.totalWalletRefunds = paymentData.total_wallet_refunds
        this.totalCourseRefunds = paymentData.total_course_refunds
        this.totalOfferRefunds = paymentData.total_offer_refunds
        this.totalRefunds = paymentData.users_total_refunds
        this.total = paymentData.users_total
        this.instructorPayment = paymentData.instructor_total
      } catch (error) {
        console.error(error)
      }
    },
    paginate ($event) {
      this.page = $event.target.innerHTML
      this.$http.get(`${this.$hostUrl}report/course-receipts?page=${this.page}`).then(response => {
        this.currentPage = response.data.data.current_page
        this.totalCollections = response.data.data.data
      })
    },
    filterData (data) {
      switch (data) {
        case 'total':

          this.$http.get(`${this.$hostUrl}report/course-receipts?course_id=${this.selectedCourse.id ?? ''}&type=${this.receiptType ?? ''}`).then(response => {
            this.currentPage = response.data.data.current_page
            this.allPages = response.data.data.last_page
            this.totalCollections = response.data.data.data
          })

          break
        case 'payment-refunds':
          this.$http.get(`${this.$hostUrl}receipt/details?start=${this.fromDate}&end=${this.toDate}&user_id=${this.selectedUser.id ?? ''}&course_id=${this.selectedCourse.id ?? ''}`).then(response => {
            this.paymentRefunds = response.data.data
            this.detailsPayments = response.data.data.totalPayments
            this.detailsRefunds = response.data.data.totalRefunds
          })

          break
        case 'details':
          this.$http.get(`${this.$hostUrl}receipt/details?start=${this.fromDate}&end=${this.toDate}&course_id=${this.selectedCourse.id ?? ''}`).then(response => {
            this.Refunds = response.data.data.refunds
            this.totaldetailRefunds = response.data.data.totalRefunds
          })
          break
        case 'total-users':
          this.$http.get(`${this.$hostUrl}receipt/report?start=${this.fromDate}&end=${this.toDate}`).then(response => {
            const data = response.data.data
            this.totalUsers = data[0]
            const paymentData = data[1]
            this.coursePayments = paymentData.total_course_payment
            this.offerPayments = paymentData.total_offer_payment
            this.attendancePayment = paymentData.total_attendance_payment
            this.walletPayment = paymentData.total_wallet_payment
            this.totalReceived = paymentData.users_total_payments
            this.total = paymentData.users_total
            this.totalAttendanceRefunds = paymentData.total_attendance_refunds
            this.totalWalletRefunds = paymentData.total_wallet_refunds
            this.totalCourseRefunds = paymentData.total_course_refunds
            this.totalOfferRefunds = paymentData.total_offer_refunds
            this.totalRefunds = paymentData.users_total_refunds
            this.instructorPayment = paymentData.instructor_total
          })
          break
        case 'users':
          if (!this.selectedUser) {
            swal('برجاء ادخال المستخدم')
          } else {
            this.$http.get(`${this.$hostUrl}report/user-receipts?start=${this.fromDate}&end=${this.toDate}&user_id=${this.selectedUser.id}`).then(response => {
              this.userReceipts = response.data.data
            })
          }
      }
    },
    printReport (report) {
    // $('.card-footer').hide()
      const options = {
        name: ' Seven Eleven System ',
        specs: ['fullscreen=yes', 'titlebar=yes', 'scrollbars=yes'],
        styles: [
          'https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.rtl.min.css',
          `${window.location.origin}/print-report.css`
        ],
        timeout: 1000, // default timeout before the print window appears
        autoClose: true, // if false, the window will not close after printing
        windowTitle: 'Seven Eleven System' // override the window title
      }
      console.log(report)
      if (report === 'total') {
        setTimeout(this.$htmlToPaper('print-total', options), 10000)
        $('.card-footer').show()
      } if (report === 'payment-refunds') {
        setTimeout(this.$htmlToPaper('print-payments', options), 10000)
        $('.card-footer').show()
      }
      if (report === 'details') {
        setTimeout(this.$htmlToPaper('print-details', options), 10000)
        $('.card-footer').show()
      }
      if (report === 'total-users') {
        setTimeout(this.$htmlToPaper('print-users_teachers', options), 10000)
        $('.card-footer').show()
      }
    },
    downloadFile (report) {
      if (report === 'total') {
        $.each(this.totalCollections, (key, val) => {
          this.totalFile.push({
            رقم_الدورة: val.number,
            اسم_الدورة: val.name,
            قيمة_الدورة: val.total_cost,
            استلام_نقدية: val.payments,
            استرداد_نقدية: val.refunds
          })
        })
        const data = this.totalFile
        const fileName = ' تقرير  اجمالي التحصيلات'
        const exportType = exportFromJSON.types.xls
        if (data) exportFromJSON({ data, fileName, exportType })
      }
      if (report === 'payment-refunds') {
        $.each(this.paymentRefunds.all, (key, val) => {
          this.totalFile.push({
            رقم_الإيصال: val.code,
            التاريخ: val.created_at,
            نوع_الحركة: val.transaction_type.name,
            اسم_الطالب: val.student.full_name,
            قيمة_الإيصال: val.amount
          })
        })
        const data = this.totalFile
        const fileName = ' تقرير  تفصيلي التحصيلات'
        const exportType = exportFromJSON.types.xls
        if (data) exportFromJSON({ data, fileName, exportType })
      }
      if (report === 'details') {
        $.each(this.Refunds, (key, val) => {
          this.totalFile.push({
            رقم_الإيصال: val.code,
            التاريخ: val.created_at,
            رقم_الطالب: val.student.number,
            اسم_الطالب: val.student.full_name,
            الفرقة: val.student.grade,
            المجموعة: val.student.group,
            السبب: val.description,
            القيمة: val.amount
          })
        })
        const data = this.totalFile
        const fileName = ' تقرير  تفصيلي الإستردادات'
        const exportType = exportFromJSON.types.xls
        if (data) exportFromJSON({ data, fileName, exportType })
      }
      if (report === 'total-users') {
        $.each(this.totalUsers, (key, val) => {
          this.totalFile.push({
            المستخدم: val.name,
            مدفوعات_الدورة: val.course_payment === null ? '-' : val.course_payment,
            مدفوعات_الحضور: val.attendance_payment === null ? '-' : val.attendance_payment,
            صافي_المستلم: val.total_payments === null ? '-' : val.total_payments,
            استرداد_نقدية: val.total_refunds === null ? '-' : val.total_refunds,
            الصافي: val.total === null ? '-' : val.total,
            مدفوعات_المدرس: val.instructor === null ? '-' : val.instructor
          })
        })
        const data = this.totalFile
        const fileName = ' التحصيلات على المستوى اليومي للمستخدمين'
        const exportType = exportFromJSON.types.xls
        if (data) exportFromJSON({ data, fileName, exportType })
      }
    }
  }
}

</script>
<style>
#collection-date{
  font-weight: 800 !important;
  font-size: 18px !important;
}
.table > :not(:first-child) {
    border-bottom: 1px solid #f0f2f5 !important;
}
.width{
  width:100%;
}
.online{
  border:1px solid #ddd
}
.times{
  margin-top: 10px !important;
}
.times h5{
  font-size:16px !important
}
.times .red{
  position: relative !important;
}
.filter-text{
  font-weight: 700;
    color: #dfad07;
    font-size: 15px !important;
}
.tab2{
  margin-top: 27px !important;
    margin-bottom: 41px !important;
    border-bottom: 1px solid #ddd;
    padding-bottom: 26px !important;
}
.list2{
      width: 31% !important;
    margin-right: 10px !important;
    margin-bottom: 20px !important
}
.hr-cell{
  background: #fff !important;
    border: 1px solid #ccc !important;
    color:#222 !important
}

</style>
