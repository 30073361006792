<template>
  <div class="container-fluid py-4 add-student grade-detail">
    <div class="row">
      <div class="col-12">
        <div class="card my-4">
          <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
            <div
              class="bg-gradient-success shadow-success border-radius-lg pt-4 pb-3"
            >
              <div class="row">
                <div class="col-lg-6">
                  <h6 class="text-white text-capitalize ps-3">   تقارير    مدفوعات المواد   </h6>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body px-0 pb-2">
            <!-- exceptional attendance row -->
         <div class="row align-center ex-row">
                 <div class="text-center col-lg-2">
                  <vmd-button
                    class="my-4 mb-2 width pointer"
                    variant="gradient"
                    color="info"
                    @click="printReport()"
                    >  طباعة</vmd-button
                  >
                </div>
                  <div class="text-center col-lg-2">
                  <vmd-button
                    class="my-4 mb-2 width pointer"
                    variant="gradient"
                    color="warning"
                    @click="downloadFile()"
                    >  اكسل</vmd-button
                  >
                </div>
              </div>
              <div id='print-report' style=" margin-top: 40px;direction:rtl">
            <div class="row align-center" style=" margin-top: 40px;direction:rtl" >
              <div class="text-center col-lg-12 overflow-x" style="direction:rtl">
                <table class=" b-block  table " style="direction:rtl">
                  <thead>
                    <tr>
                      <!-- <th>#</th> -->
                      <th >المادة</th>
                        <th>مدفوعات الدورة</th>
                        <th>مدفوعات العرض</th>
                         <th> اجمالي مدفوعات المادة </th>
                        </tr>

                  </thead>
                  <tbody>
                    <tr v-for="subject in subjectsPayments" :key="subject.id">
                      <!-- <td>{{index}}</td> -->
                      <td><router-link class="pointer to-link" :to ="{path:'/subjects/'+subject.id +'/show'}">{{subject.name}}</router-link></td>
                       <td>{{$filters.number(subject.course_payments)}}</td>
                       <td>{{$filters.number(subject.payments)}}</td>
                        <td>{{$filters.number(subject.subject_payments)}}</td>
                    </tr>
                       <tr style="background:#dad6d669;font-weight:bold">
                      <td colspan="1">الإجماليات</td>
                       <td>{{$filters.number(totalCoursesPayments)}}</td>
                        <td>{{$filters.number(totalOffersPayments)}}</td>
                        <td>{{$filters.number(totalSubjectPayments)}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            </div>
          </div>
   <!-- seconed tab -->
          </div>
          </div>

      </div>
    </div>
</template>
<script>
import VmdButton from '@/components/VmdButton.vue'
import $ from 'jquery'
import exportFromJSON from 'export-from-json'
const today = new Date()
const date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate()
const time = today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds()
const CurrentDateTime = date + ' ' + time
export default {
  name: 'SubjectsPayments',
  components: {
    VmdButton
  },
  data () {
    return {
      count: 0,
      subjectsPayments: [],
      totalOffersPayments: 0,
      totalCoursesPayments: 0,
      totalSubjectPayments: 0,
      excelFile: [],
      errors: []
    }
  },
  created () {
    this.$http.get(`${this.$hostUrl}report/paid/subjects`).then(response => {
      this.subjectsPayments = response.data.data.subjects
      this.totalOffersPayments = response.data.data.total_payment
      $.each(this.subjectsPayments, (key, val) => {
        this.totalCoursesPayments += val.course_payments
        this.totalSubjectPayments += val.subject_payments
      })
    })
  },
  computed: {
  },
  methods: {
    printReport () {
    // $('.card-footer').hide()
      const options = {
        name: ' Seven Eleven System ',
        specs: ['fullscreen=yes', 'titlebar=yes', 'scrollbars=yes'],
        styles: [
          'https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.rtl.min.css',
          `${window.location.origin}/print-report.css`
        ],
        timeout: 1000, // default timeout before the print window appears
        autoClose: true, // if false, the window will not close after printing
        windowTitle: 'Seven Eleven System' // override the window title
      }

      setTimeout(this.$htmlToPaper('print-report', options), 10000)
      $('.card-footer').show()
    },
    downloadFile () {
      $.each(this.subjectsPayments, (key, val) => {
        this.excelFile.push({
          المادة: val.name,
          مدفوعات_الدورة: val.course_payments,
          مدفوعات_العرض: val.payments,
          اجمالي_مدفوعات_المادة: val.subject_payments
        })
      })
      const data = this.excelFile
      const fileName = `تقرير  مدفوعات المواد ${CurrentDateTime}`
      const exportType = exportFromJSON.types.xls
      if (data) exportFromJSON({ data, fileName, exportType })
    }
  }
}

</script>
<style>
#collection-date{
  font-weight: 800 !important;
  font-size: 18px !important;
}
.table > :not(:first-child) {
    border-bottom: 1px solid #f0f2f5 !important;
}
.width{
  width:100%;
}
.online{
  border:1px solid #ddd
}
.times{
  margin-top: 10px !important;
}
.times h5{
  font-size:16px !important
}
.times .red{
  position: relative !important;
}
.filter-text{
  font-weight: 700;
    color: #dfad07;
    font-size: 15px !important;
}
.tab2{
  margin-top: 27px !important;
    margin-bottom: 41px !important;
    border-bottom: 1px solid #ddd;
    padding-bottom: 26px !important;
}
.course-info{
      background: #f8f9fa;
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 38px;
    font-size: 14px;
}
.info-h{
      font-size: 14px !important;
    font-weight: 500;
}
.groups-ul{
  background: #fff;
    padding-top: 10px;
    padding-bottom: 10px;
    width: 206px;
    border-top: 2px solid #5cb360;
    box-shadow: 0 4px 6px -1px rgb(0 0 0 / 10%), 0 2px 4px -1px rgb(0 0 0 / 6%);
    display: none;
    position:absolute;
    border-radius: 10px;
}
/* .groups-con{
  color:blue;
  cursor: pointer;
} */
.groups-con:hover .groups-ul{
  display: block;
}

.info-data{
      font-family: "Roboto", Helvetica, Arial, sans-serif !important;
      font-weight:bold !important;
      font-size:17px !important
}
.to-link{
  color:blue !important
}
</style>
