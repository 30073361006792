<template>
 <div class="container-fluid py-4 add-student grade-detail">
    <div class="row">
      <div class="col-12">
        <div class="card my-4">
          <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
            <div
              class="bg-gradient-success shadow-success border-radius-lg pt-4 pb-3"
            >
              <div class="row">
                <div class="col-lg-6">
                  <h6 class="text-white text-capitalize ps-3">  حضور لأكثر من محاضرة </h6>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body px-0 pb-2">
            <div class="row align-center">
              <div class="mb-3 col-lg-3">
                <label> ادخل رقم الطالب <span class="red">*</span></label>
                <vmd-input
                  id="student-number"
                  type="text"
                  label="  رقم الطالب"
                  v-model="student.searchVal"
                  @keyup.enter="search"
                  required
                />
              </div>
              <div class="mb-3 col-lg-3">
                <label> اسم الطالب </label>
                <vmd-input
                  id="student-name"
                  type="text"
                  v-model="student.full_name"
                  readonly
                />
              </div>
              <div class="mb-3 col-lg-3">
                <label>الفرقة </label>
                <vmd-input
                  id="student-grade"
                  type="text"
                  v-model="student.grade_name"
                  readonly
                />
              </div>
               <div class="mb-3 col-lg-3">
                <label>المجموعة </label>
                <vmd-input
                  id="student-grade"
                  type="text"
                  v-model="student.group_name"
                  readonly
                />
              </div>
            </div>
            <div class="row align-center">
              <h5 id="data" class="today-lec">
                <span>محاضرات اليوم</span> -
                <span>  {{student.grade_name?? null}}</span>
              </h5>
              <div class="text-center col-lg-12 overflow-x">
                <table class="table b-block">
                  <thead>
                    <tr>
                        <th></th>
                      <th>رقم المحاضرة</th>
                      <th>الدورة</th>
                      <th>المادة</th>
                      <th>عدد حضور الطالب</th>
                       <th>نوع الإيصال</th>
                        <th>المسدد</th>
                        <th>المتبقي</th>
                        <th>دفع</th>
                        <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(lecture, index) in activeLectures"
                      :key="index"
                      :class="{'refund-back' : !lecture.attended, 'paid-back': lecture.attended}"
                    >
                     <td>
                 <vmd-checkbox v-model="lecture.checked" disabled></vmd-checkbox>
                  <input  type="checkbox" v-model="lecture.checked" class="form-control"/>
                     </td>
                      <td>
                        {{ lecture.lecture_number }}
                      </td>
                      <td>
                        {{ lecture.course_name }}
                      </td>
                      <td>
                        {{ lecture.subject_name}}
                      </td>
                      <td>
                        {{ lecture.attendance_count}}
                      </td>
                      <td>
                        {{ lecture.enrolled?'عادي' : 'استثنائي' }}
                      </td>
                       <td>
                        {{ lecture.payed}}
                      </td>
                       <td>
                        {{ lecture.remain}}
                      </td>
                      <td> <button
                          class="btn btn-warning width"
                          @click.prevent="paymentReceipt(student.id,0,lecture.course_id,lecture.course_name)"
                        >
                            دفع
                        </button></td>
                         <td  v-if="lecture.attended">
                        <button id='cancel'
                          class="btn btn-danger width"
                          @click='cancelAttendance(student.id, lecture.lecture_id)'
                        >
                           الغاء
                        </button>
                      </td>
                      </tr>
                  </tbody>
                </table>
                  <div v-if="activeLectures.length">
                        <button style="width:20%"
                          class="btn btn-lg btn-block btn-success multi-attend-btn"
                          @click="createReceipt()"
                        >
                           ايصال حضور
                        </button>
                      </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
   <div>
     <!-- start payment receipt modal -->
    <vue-final-modal
      :clickToClose="false" :close-on-esc="false"
    v-if="attend === 0"
      v-model="showModal"
      name="example"
      classes="modal-container course-modal"
      content-class="modal-content"
    >
      <div class="row">
        <div class="col-12">
          <div class="card my-4">
            <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
              <div
                class="bg-gradient-success shadow-success border-radius-lg pt-4 pb-3"
              >
                <h6 class="text-white text-capitalize ps-3">إضافة إيصال</h6>
              </div>
            </div>
            <div class="card-body px-0 pb-2">
              <form @submit.prevent="createPaymentReceipt" role="form" class="text-start mt-3">
                <div class="row">
                  <div class="mb-3 col-lg-6">
                    <label>
                      نوع الحركة
                      <span color="danger" class="red">*</span></label
                    >
                    <div class="input-group input-group-outline null">
                      <select class="form-control" v-model="selectedType">
                        <option value="d2d3aa71-8c7e-4f94-bbc9-ec516f1ba759">
                          نقدا
                        </option>
                        <option value="cec350eb-de0c-4fba-8e0d-110d236a95e8">
                          محفظة
                        </option>
                      </select>
                      <i class="fas fa-caret-down" aria-hidden="true"></i>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="mb-3 col-lg-6">
                    <label>
                      اسم الطالب
                      <span color="danger" class="red">*</span></label
                    >
                    <div class="input-group input-group-outline null">
                      <vmd-input
                        id="receipt-student"
                        type="text"
                        readonly
                        v-model="student.full_name"
                      />
                    </div>
                  </div>
                  <div class="mb-3 col-lg-6">
                    <label>
                      اسم الدورة
                      <span color="danger" class="red">*</span>
                    </label>
                    <multiselect
                        class='required form-control'
                        id='grade'
                        v-model="selectedCourse"
                        :options='allCousres'
                        :searchable='true'
                        :close-on-select='true'
                        placeholder=" اختر الدورة"
                        label='name'
                        track-by='name'
                        required='required'
                        :internal-search="true"
                        >
                      >
                        <template v-slot:noOptions>
                          <span> فارغة </span>
                        </template>
                        <template v-slot:noResult>
                          <span>لا يوجد نتيجة </span>
                        </template>
                      </multiselect>
                  </div>
                </div>
                <div class="row">
                  <div class="mb-3 col-lg-6">
                    <label>
                      القيمة <span color="danger" class="red">*</span></label
                    >
                    <vmd-input
                      id="receipt-amount"
                      type="number"
                      placehlder='القيمة'
                      v-model="receipt.amount"
                    />
                  </div>
                  <div class="mb-3 col-lg-6">
                    <label>
                      البيان <span color="danger" class="red">*</span>
                    </label>
                    <vmd-input
                      id="receipt-description"
                      type="text"
                      placeholder='سداد'
                      v-model="receipt.description"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="text-center col-lg-6">
                    <vmd-button
                      class="my-4 mb-2 pay-btn"
                      variant="gradient"
                      color="info"
                      @click="showModal = false"
                    >
                      حفظ</vmd-button
                    >
                  </div>
                  <div class="text-center col-lg-6">
                    <vmd-button
                      type="reset"
                      class="my-4 mb-2"
                      variant="gradient"
                      color="danger"
                      @click="showModal = false"
                    >
                      إلغاء
                    </vmd-button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </vue-final-modal>
     <!-- start new student modal -->
       <vue-final-modal
       :clickToClose="false" :close-on-esc="false"
      v-model="showModal2"
      name="example"
      classes="modal-container"
      content-class="modal-content"
    >
      <div class="row">
        <div class="col-12">
          <div class="card my-4">
            <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
              <div
                class="bg-gradient-success shadow-success border-radius-lg pt-4 pb-3"
              >
                <h6 class="text-white text-capitalize ps-3">إضافة طالب جديد</h6>
              </div>
            </div>
           <NewStudent @hide-modal='showModal2= false' @new-attendance ="getStudentLectures($store.state.newStudent)"
           @update-table='getExpectedStudent'/>
          </div>
        </div>
      </div>
    </vue-final-modal>
    <!-- for payment receipt -->
     <PrintReceipt :receipt="receipt"></PrintReceipt>
    <!-- for mululti attendance receipts -->
      <PrintMultipleReceipt :receipts="receipts"></PrintMultipleReceipt>
    </div>
  </div>
</template>
<script>
import VmdInput from '@/components/VmdInput.vue'
import VmdButton from '@/components/VmdButton.vue'
import VmdCheckbox from '@/components/VmdCheckbox.vue'
import swal from 'sweetalert'
import $ from 'jquery'
import PrintReceipt from '@/components/PrintReceipt.vue'
import PrintMultipleReceipt from '@/components/PrintMultipleReceipt.vue'
import { VueFinalModal } from 'vue-final-modal'
import NewStudent from '@/components/NewStudent.vue'
export default {
  name: 'NewAttendance',
  components: {
    VmdInput,
    VmdButton,
    VmdCheckbox,
    PrintReceipt,
    PrintMultipleReceipt,
    VueFinalModal,
    NewStudent

  },
  data () {
    return {
      student: {},
      activeLectures: [],
      allCousres: [],
      selectedCourse: '',
      searchLecture: '',
      selectedLecture: '',
      attend: 0,
      course: {},
      receiptTypes: [],
      receipts: [],
      receipt: {
        number: null,
        amount: '',
        title: 'حضور',
        description: 'سداد',
        userName: localStorage.userName,
        branchName: localStorage.branchName
      },
      selectedType: 'd2d3aa71-8c7e-4f94-bbc9-ec516f1ba759',
      receiptTitle: '',
      showModal: false,
      showModal2: false
    }
  },
  created () {
  },
  methods: {
    search () {
      this.student.id = null
      this.student.full_name = null
      this.student.grade_name = null
      this.student.group_name = null
      const current = this
      current.$http
        .get(`${current.$hostUrl}students-list?search=${current.student.searchVal}`)
        .then((response) => {
          if (response.data.data.length === 1) {
            current.student.full_name = response.data.data[0].full_name
            current.student.id = response.data.data[0].id
            current.student.number = response.data.data[0].number
            current.student.grade_name = response.data.data[0].grade_name
            current.student.group_name = response.data.data[0].group_name
            current.$http
              .get(`${current.$hostUrl}attend/student/${current.student.id}/lectures`)
              .then((response) => {
                this.activeLectures = response.data.data
              })
              // get student courses
            const allCourses = []
            this.$http.get(`${this.$hostUrl}courses?grade_id=${response.data.data[0].grade_id}&&group_id =${response.data.data[0].group_id}`).then((response) => {
              $.each(response.data.data, function (key, val) {
                allCourses.push({
                  name: val.name,
                  id: val.id
                })
              })
              this.allCousres = allCourses
            })
          } else {
            current.student.id = null
            current.student.full_name = null
            current.student.grade_name = null
            current.student.group_name = null
            swal({
              title: ' الطالب غير موجود ',
              icon: 'warning',
              buttons: ['لا', 'إضافة '],
              dangerMode: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33'
            }).then((willDelete) => {
              if (willDelete) {
                current.showModal2 = true
              } else {
                swal({ title: ' تم الإلغاء  !', buttons: 'تم' })
              }
            })
          }
          if (response.data.error) {
            swal(response.data.message)
          }
        })
    },
    updateTableData () {
      this.$http
        .get(`${this.$hostUrl}attend/student/${this.student.id}/lectures`)
        .then((response) => {
          this.activeLectures = response.data.data
        })
    },
    async paymentReceipt (id, attend, courseId, courseName) {
      this.attend = attend
      this.exception = 0
      this.selectedCourse = {
        id: courseId,
        name: courseName
      }
      this.receipt.description = 'سداد'
      this.receipt.amount = ''
      const current = this
      current.showModal = true
    },
    async createReceipt (e) {
      const current = this
      if (current.receipts && current.receipts.length > 0) {
        current.receipts[0].created_at = ''
      }
      // e.preventDefault()
      const formData = new FormData()
      const lectures = []
      this.activeLectures.forEach((lecture) => {
        if (lecture.checked === true) {
          lectures.push({
            lecture_id: lecture.lecture_id,
            attend: 1,
            exceptional: !lecture.enrolled ? 1 : 0,
            enroll: 0
          })
        }
      })
      formData.append('payable_id', JSON.stringify(lectures))
      this.receipt.full_name = this.student.full_name
      this.receipt.student_number = this.student.number
      formData.append('student_id', this.student.id)
      formData.append('receipt_type_id', this.selectedType)

      formData.append(
        'transaction_type_id',
        '1413a440-d013-416c-a882-dc23fb8d52c4'
      )
      formData.append('amount', this.receipt.amount)
      formData.append('description', this.receipt.description)
      await this.$http.post(this.$hostUrl + 'multiple/receipt', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
        .then((response) => {
          if (response.data.error) {
            swal({ title: response.data.message, buttons: 'تم' })
          } else {
            current.receipts = response.data.data
            current.receipt.transaction_type = response.data.data.transaction_type_name
            current.receipt.student_number = this.student.number
            // refresh data
            this.updateTableData()
          }
        })
        .catch(function (error) {
          if (Object.prototype.hasOwnProperty.call(error, 'response')) {
            if (error.response.data.data) {
              swal(error.response.data.data)
              current.errors = error.response.data.data
              swal(current.errors.student_id || current.errors.course_id)
            } else {
              swal({ title: error.response.data.message, buttons: 'تم' })
            }
            window.scrollTo(0, 0)
          }
        })
      if (current.receipts[0].created_at) {
        const options = {
          name: ' Seven Eleven System ',
          specs: ['fullscreen=yes', 'titlebar=yes', 'scrollbars=yes'],
          styles: [
          `${window.location.origin}/print-multiple.css`
          ],
          timeout: 1000, // default timeout before the print window appears
          autoClose: true, // if false, the window will not close after printing
          windowTitle: 'Seven Eleven System' // override the window title
        }
        setTimeout(this.$htmlToPaper('print-multi-receipts', options), 10000)
        this.showModal2 = false
      }
    },
    async createPaymentReceipt (e) {
      this.receipt.created_at = ''
      const current = this
      current.receipt.attend = null
      current.receipt.enroll = null
      const formData = new FormData()
      this.receipt.course = this.selectedCourse.name
      this.receipt.full_name = this.student.full_name
      this.receipt.student_number = this.student.number
      formData.append('student_id', this.student.id)
      formData.append('receipt_type_id', this.selectedType)

      formData.append(
        'transaction_type_id',
        '1413a440-d013-416c-a882-dc23fb8d52c4'
      )
      formData.append('amount', this.receipt.amount)
      if (this.receipt.description) {
        formData.append('description', this.receipt.description)
      }
      formData.append('attend', 0)
      formData.append('payable_id', this.selectedCourse.id)

      $('.pay-btn').attr('disabled', true)
      await this.$http.post(this.$hostUrl + 'receipts', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
        .then((response) => {
          $('.pay-btn').attr('disabled', false)
          if (response.data.error) {
            swal({ title: response.data.message, buttons: 'تم' })
          } else {
            current.receipt = response.data.data
            current.receipt.transaction_type = response.data.data.transaction_type_name
            current.receipt.student_number = this.student.number
          }
        })
        .catch(function (error) {
          $('.pay-btn').attr('disabled', false)
          if (Object.prototype.hasOwnProperty.call(error, 'response')) {
            if (error.response.data.data) {
              current.errors = error.response.data.data
              swal(current.errors.student_id || current.errors.course_id)
            } else {
              swal({ title: error.response.data.message, buttons: 'تم' })
            }
            window.scrollTo(0, 0)
          }
        })
      if (this.receipt.created_at) {
        setTimeout(
          this.$htmlToPaper(
            'print-receipt'
          ),
          10000
        )
        this.showModal2 = false
      }
      // refresh data
      this.updateTableData()
    },
    async cancelAttendance (studentId, lectureId) {
      swal({
        title: 'هل انت متأكد ؟',
        text: 'بمجرد الموافقة ، سيتم الغاء حضور الطالب  !',
        icon: 'warning',
        buttons: ['لا', 'نعم'],
        dangerMode: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33'
      }).then((willDelete) => {
        if (willDelete) {
          this.$http
            .delete(
              `${this.$hostUrl}attendance/remove/${lectureId}/${studentId}`,
              { _method: 'delete' }
            )
            .then((response) => {
              swal({ title: response.data.message, buttons: 'تم' })
            })
          // refresh data
          this.updateTableData()
        } else {
          swal({ title: ' تم الإلغاء  !', buttons: 'تم' })
        }
      })
    },
    getStudentLectures (studentInfo) {
      this.student.full_name = studentInfo.full_name
      this.student.id = studentInfo.id
      this.student.searchVal = studentInfo.number
      this.student.grade_name = studentInfo.grade.name
      this.student.group_name = studentInfo.group.name
      this.$http
        .get(`${this.$hostUrl}attend/student/${this.student.id}/lectures`)
        .then((response) => {
          this.activeLectures = response.data.data
        })
    }
  }
}
</script>
<style >
.today-lec{
    display: flex;
    gap: 15px;
    font-size: 17px;
}
.excep-row{
  background: #ffa72636;
}
</style>
