<template>
  <nav
    class="navbar navbar-main navbar-expand-lg"
    v-bind="$attrs"
    id="navbarBlur"
    data-scroll="true"
    :class="isAbsolute ? 'mt-4' : 'mt-0'"
  >
    <li class="nav-item d-xl-block d-none ps-3 d-flex align-items-center">
      <router-link
        to="#"
        class="p-0 nav-link text-body lh-1"
        id="iconNavbarSidenav"
        @click.prevent="toggleSidebar"
      >
        <div class="sidenav-toggler-inner">
          <i class="sidenav-toggler-line"></i>
          <i class="sidenav-toggler-line"></i>
          <i class="sidenav-toggler-line"></i>
        </div>
      </router-link>
    </li>
    <div class="px-3 py-1 container-fluid">
      <breadcrumbs :currentPage="currentRouteName" :color="color" />
      <div
        class="mt-2 collapse navbar-collapse mt-sm-0 me-md-0 me-sm-4 justify-content-end"
        :class="isRTL ? 'px-0' : 'me-sm-4'"
        id="navbar"
      >
        <ul class="navbar-nav head-ul">
          <li class="nav-item d-flex align-items-center user-info">
            <div class="input-group input-group-outline null">
              <input
                v-if="userInfo.branch"
                type="text"
                class="form-control inp form-control-default"
                disabled
                :placeholder="
                  userInfo.name + ' ' + '||' + ' ' + $t('message.branch')+':' + userInfo.branch
                "
              />
              <input
                v-else
                type="text"
                class="form-control inp form-control-default"
                disabled
                :placeholder="userInfo.name"
              />
            </div>
            <i
              id="user-icon"
              class="material-icons"
              :class="isRTL ? 'ms-sm-2' : 'me-sm-1'"
              title="تسجيل الخروج"
            >
              account_circle
            </i>
          </li>
          <li class="nav-item d-xl-none ps-3 d-flex align-items-center">
            <router-link
              to="#"
              class="p-0 nav-link text-body lh-1"
              id="iconNavbarSidenav"
              @click.prevent="toggleSidebar"
            >
              <div class="sidenav-toggler-inner">
                <i class="sidenav-toggler-line"></i>
                <i class="sidenav-toggler-line"></i>
                <i class="sidenav-toggler-line"></i>
              </div>
            </router-link>
          </li>
          <template v-if="centerType === 'english -school'">
            <li class="pointer" v-if="isRTL">
              <span class="lang-li"  @click="changeLang">English<img width="70px" class="lang-img" src="https://cdn.britannica.com/33/4833-004-828A9A84/Flag-United-States-of-America.jpg" /></span>
            </li>
            <li class="pointer" v-if="!isRTL">
              <span class= "lang-li" @click="changeLang1">
                العربية
                <img width="70px" class="lang-img" src="https://upload.wikimedia.org/wikipedia/commons/thumb/f/fe/Flag_of_Egypt.svg/220px-Flag_of_Egypt.svg.png">

              </span>
            </li>
          </template>

          <li>
            <div class="text-center d-none d-sm-block">
              <vmd-button
                @click="logout"
                class="mb-2 logout"
                variant="gradient"
                color="primary"
                fullWidth
                >{{ $t("message.logOut") }}
              </vmd-button>
            </div>
            <div class="text-center d-block d-sm-none">
              <vmd-button
                @click="logout"
                class="mb-2 logout"
                variant="gradient"
                color="primary"
                title="تسجيل خروج"
                fullWidth
              >
                <i class="fas fa-sign-out-alt"></i
              ></vmd-button>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>
<script>
import VmdButton from '@/components/VmdButton.vue'
import Breadcrumbs from '../Breadcrumbs.vue'
import { mapMutations, mapState } from 'vuex'
import swal from 'sweetalert'

export default {
  name: 'navbar',
  data () {
    return {
      showMenu: false,
      centerType: '',
      userInfo: {},
      icon: '<i class="far fa-map-marker-alt"></i>',
      langs: [
        { name: 'ar', icon: 'ar', class: 'flag-icon flag-icon-sa' },
        { name: 'en', icon: 'en', class: 'flag-icon flag-icon-us' }
      ]
    }
  },
  props: ['minNav', 'color'],
  created () {
    this.centerType = localStorage.centerType
    this.minNav1 = this.minNav
    this.userInfo = {
      name: localStorage.userName,
      branch: localStorage.branchName
    }
  },
  methods: {
    ...mapMutations(['navbarMinimize', 'toggleConfigurator']),

    toggleSidebar () {
      this.navbarMinimize()
    },
    changeLang () {
      location.reload()
      this.$store.commit('setRTL', false)
      localStorage.Lang = 'en'
      localStorage.dir = 'ltr'
      document.querySelector('html').removeAttribute('lang')
      document.querySelector('html').removeAttribute('dir')
      document.querySelector('#app').classList.remove('rtl')

      localStorage.Lang = 'en'
    },
    changeLang1 () {
      this.$store.commit('setRTL', true)
      localStorage.Lang = 'ar'
      localStorage.dir = 'rtl'
      document.querySelector('html').setAttribute('lang', 'ar')
      document.querySelector('html').setAttribute('dir', 'rtl')
      document.querySelector('#app').classList.add('rtl')
      location.reload()
    },
    changeLocal (local) {
      localStorage.Lang = local
      this.$root.$i18n.locale = localStorage.Lang
      const dir = local === 'ar' ? 'rtl' : 'ltr'
      localStorage.dir = dir
      console.log(this.$root.$i18n.locale)
      console.log(localStorage.dir, 'dir')
      location.reload()
    },
    logout () {
      if (localStorage.token) {
        const formData = new FormData()
        formData.append('token', localStorage.token)
        this.$http
          .post(this.$hostUrl + 'logout', formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
              'Access-Control-Allow-Origin': '*'
            }
          })
          .then((response) => {
            if (!response.data.error) {
              localStorage.removeItem('token')
              this.$router.push({ name: 'SignIn' })
            } else {
              swal({ title: response.data.message, buttons: 'تم' })
              localStorage.removeItem('token')
              this.$router.push({ name: 'SignIn' })
            }
          })
      } else {
        localStorage.removeItem('token')
        this.$router.push({ name: 'SignIn' })
      }
    }
  },
  components: {
    Breadcrumbs,
    VmdButton
  },
  computed: {
    ...mapState(['isRTL', 'isAbsolute']),

    currentRouteName () {
      return this.$route.name
    }
  }
}
</script>
<style>
.user-info {
  margin-left: 106px;
}
.user-info input::placeholder {
  padding-right: 30px !important;
  color: #344767;
}
.navbar .sidenav-toggler-inner .sidenav-toggler-line {
  background: #222 !important;
}
.lang-li{
    display: flex;
    align-items: center;
    gap: 4px;
}
.lang-img{
  width:20px !important;
  height:20px !important;
  border-radius: 0!important
}
</style>
