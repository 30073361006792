<template>
     <div class="container-fluid py-4 add-student lecture-details">
    <div class="row">
      <div class="col-12">
        <div class="card my-4">
          <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
            <div
              class="bg-gradient-success shadow-success border-radius-lg pt-4 pb-3"
            >
              <h6 class="text-white text-capitalize ps-3">  عرض بيانات المحاضرة</h6>
            </div>
          </div>
          <div class="card-body px-0 pb-2">
                   <form role="form" class="text-start mt-3">
                       <div class="row">
                           <div class="mb-3 col-lg-6">
                      <label>  اسم الدورة </label>
                  <vmd-input
                    id=""
                    type="text"
                    :label=" lectureDetails.course_name"
                    disabled
                  />
                </div>
                <div class="mb-3 col-lg-6">
                    <label>  اسم المادة</label>
                  <vmd-input
                    id=""
                    type="text"
                   :label=" lectureDetails.subject"
                   disabled
                  />
                </div>
                </div>
                  <div class='row'>
                     <div class="mb-3 col-lg-6">
                    <label>   الفرقة</label>
                  <vmd-input
                    id=""
                    type="text"
                   :label="  lectureDetails.grade "
                   disabled
                  />
                </div>
                <div class="mb-3 col-lg-6">
                      <label>  القاعة   </label>
                  <vmd-input
                    id=""
                    type="text"
                    :label="lectureDetails.room_name"
                    disabled
                  />
                </div>
                       </div>
                <div class='row'>

                <div class="mb-3 col-lg-6">
                      <label>  السنة الدراسية  </label>
                  <vmd-input
                    id=""
                    type="text"
                    :label="lectureDetails.academic_year"
                    disabled
                  />
                </div>
                 <div class="mb-3 col-lg-6">
                      <label>   تاريخ بداية المحاضرة  </label>
                  <vmd-input
                    id=""
                    type="text"
                    :label="lectureDetails.start"
                    disabled
                  />
                </div>
                </div>
                <div class='row'>
                 <div class="mb-3 col-lg-6">
                    <label>   اسم المدرس</label>
                  <vmd-input
                    id=""
                    type="text"
                   :label="lectureDetails.instructor "
                   disabled
                  />
                </div>
                       </div>

              </form>
          </div>
        </div>
        <div class="row reseve-row">
      <div class="col-12">
        <div class="card my-4">
          <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
            <div
              class="bg-gradient-success shadow-success border-radius-lg pt-4 pb-3"
            >
            <div class="row">
               <div class="col-lg-6">
              <h6 class="text-white text-capitalize ps-3">   حضور الطلاب في محاضرة : {{lectureDetails.lecture_number}} - مادة: {{subjectName}} </h6>
              </div>
            </div>
          </div>
          </div>
          <div class="card-body px-0 pb-2" id="printMe" style="direction:rtl">
            <div class="table-responsive p-0" style="direction:rtl">
              <table class="table align-items-center mb-0 " style="direction:rtl">
                <thead>
                  <tr>
                      <th
                      class="text-uppercase text-secondary  font-weight-bolder opacity-7"
                    >
                      رقم الطالب
                    </th>
                    <th
                      class="text-uppercase text-secondary  font-weight-bolder opacity-7"
                    >
                      اسم الطالب
                    </th>
                       <th
                      class="text-uppercase text-secondary  font-weight-bolder opacity-7"
                    >
                        رقم الهاتف
                    </th>
                       <th
                      class="text-uppercase text-secondary  font-weight-bolder opacity-7"
                    >
                       المجموعة
                    </th>
                      <th
                      class="text-uppercase text-secondary  font-weight-bolder opacity-7"
                    >
                       المستخدم
                    </th>
                     <th
                      class="text-uppercase text-secondary  font-weight-bolder opacity-7"
                    >
                       الدفع
                    </th>
                     <th
                      class="text-uppercase text-secondary  font-weight-bolder opacity-7"
                    >
                       الحضور
                    </th>
                  </tr>
                </thead>
                 <tbody>
                  <tr v-for='item in students' :key="item.id" >
                     <td>
                     <p class=" text-s  mb-0">{{item.number}} </p>
                    </td>
                    <td>
                     <p class=" text-s  mb-0">{{item.full_name}} </p>
                    </td>
                     <td>
                     <p class=" text-s  mb-0">  {{item.mobile}}  </p>
                    </td>
               <td>
                     <p class=" text-s  mb-0">  {{item.group?.name}}  </p>
                    </td>
                      <td>
                      <p class="text-s mb-0">{{item?.user?.user_name === 'undefined' ? '-' : item?.user?.user_name}}</p>
                                </td>
                       <td>
                     <p class=" text-s  mb-0">  {{item.payed?'تم الدفع':'لم يتم الدفع'}}  </p>
                    </td>
                     <td v-if="item.attended" class="text-center">
                        <span class="badge badge-sm bg-gradient-success"
                          ><i class="fas fa-check"></i
                        ></span>
                      </td>
                      <td v-else class="text-center">
                        <span class="badge badge-sm bg-gradient-danger">X</span>
                      </td>
                  </tr>
                    <tr style="background:#dad6d669;font-weight:bold">
                            <td colspan="6">اجمالي الحضور</td>
                            <td>{{totalAttendance}}</td>
                          </tr>
                 </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
      </div>
    </div>
     </div>
</template>
<script>
import VmdInput from '@/components/VmdInput.vue'
export default {
  name: 'ShowLecture',
  components: {
    VmdInput
  },
  data () {
    return {
      lectureDetails: {},
      subjectName: '',
      students: [],
      totalAttendance: 0
    }
  },
  created () {
    console.log(this.$route.params.id)
    this.$http.get(`${this.$hostUrl}lectures/${this.$route.params.id}`).then(response => {
      this.lectureDetails = response.data.data
      this.subjectName = response.data.data.subject
      this.students = this.lectureDetails.students
      this.totalAttendance = this.students.filter(student => student.attended === true).length
    })
  }
}
</script>
