<template>
    <div class="row">
      <div class="col-12">
        <div class="card my-4">
          <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
            <div
              class="bg-gradient-success shadow-success border-radius-lg pt-4 pb-3"
            >
              <h6 class="text-white text-capitalize ps-3">التحويل لدورة</h6>
            </div>
          </div>
          <div class="card-body px-4 pb-2">
            <form @submit.prevent="transformCourse" role="form" class="text-start mt-3">
              <div class="row">
                <div class="mb-3 col-lg-6">
                  <label>
                    اسم الطالب <span color="danger" class="red">*</span
                    ></label
                  >
                  <div class="input-group input-group-outline null">
                     <vmd-input
                        type="text"
                        disabled
                        v-model="studentName"
                      />
                  </div>
                </div>
                <div class="mb-3 col-lg-6">
                  <label>
                    اسم الدورة
                    <span color="danger" class="red">*</span>
                  </label>
                   <div class="input-group input-group-outline null">
                     <vmd-input
                        type="text"
                        disabled
                        v-model="fromCourseName"
                      />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="mb-3 col-lg-6">
                    <label>
                      الكورس المحول إليه
                      <span color="danger" class="red">*</span>
                    </label>
                    <div class="input-group input-group-outline null">
                      <select class="form-control" v-model="selectedCourse" required>
                        <option v-for="item in coursesList" :key="item.id" :value="item.id">
                          {{item.name}}
                        </option>
                      </select>
                    </div>
                  </div>
              </div>
              <div class="row">
                <div class="text-center col-lg-6">
                  <vmd-button class="my-4 mb-2" variant="gradient" color="info">
                    حفظ</vmd-button
                  >
                </div>
                <div class="text-center col-lg-6">
                  <vmd-button
                    type="button"
                    class="my-4 mb-2"
                    variant="gradient"
                    color="danger"
                    @click="closeModel"
                  >
                    إلغاء
                  </vmd-button>
                </div>
              </div>
             </form>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import VmdInput from '@/components/VmdInput.vue'
import VmdButton from '@/components/VmdButton.vue'
import swal from 'sweetalert'

export default {
  name: 'TransformCourse',
  components: {
    VmdInput,
    VmdButton
  },
  props: {
    student: {
      type: Array
    },
    course: {
      type: Array
    },
    coursesList: {
      type: Array
    }
  },
  date () {
    return {
      fromCourse: {},
      fromCourseName: '',
      courseName: 'Course name 2',
      studentName: '',
      studentId: '',
      courses: [{ id: '', name: '' }],
      selectedCourse: ''
    }
  },
  watch: {
    course (newVal, oldVal) {
      this.fromCourse = newVal
      this.fromCourseName = newVal.number + ' - ' + newVal.name
    },
    student (newVal, oldVal) {
      this.studentName = newVal.full_name
      this.studentId = newVal.id
    },
    coursesList (newVal, oldVal) {
      console.log('newVal', newVal)
      this.courses = newVal
    }
  },
  methods: {
    async transformCourse (discount, selectedDiscount, e) {
      this.$emit('hide-transform-modal')
      const current = this
      const formData = new FormData()
      formData.append('student_id', this.student.id)
      formData.append('from', this.fromCourse.id)
      if (!this.selectedCourse) {
        swal('الرجاء اختيار كورس للتجويل إليه')
        return 0
      }
      formData.append('to', this.selectedCourse)
      console.log(this.selectedCourse)
      await this.$http.post(this.$hostUrl + 'student-courses/transfer', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
        .then((response) => {
          swal({ title: response.data.message, buttons: 'تم' })
        })
        .catch(function (error) {
          if (Object.prototype.hasOwnProperty.call(error, 'response')) {
            console.log('2')
            if (error.response.data.data) {
              current.errors = error.response.data.data
              swal(current.errors.student_id || current.errors.course_id)
            } else {
              swal({ title: error.response.data.message, buttons: 'تم' })
            }
            window.scrollTo(0, 0)
          }
        })
    },
    closeModel () {
      this.selectedCourse = true
      this.$emit('hide-transform-modal')
    }
  }
}
</script>
