<template>
  <div class="container-fluid py-4 add-student ">
    <div class="row">
      <div class="col-12">
        <div class="card my-4">
          <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
            <div
              class="bg-gradient-success shadow-success border-radius-lg pt-4 pb-3"
            >
              <h6 class="text-white text-capitalize ps-3"> {{$t('message.showCustomer')}}</h6>
            </div>
          </div>
          <div class="card-body px-0 pb-2">
            <form role="form" class="text-start mt-3">
              <div class="row">
                <div class=" px-0 pb-2  table-responsive p-0 overflow client-info">
                <table class="table my-4 align-items-center mb-0">
                  <thead>
                    <tr>
                      <th
                      class="text-uppercase  font-weight-bolder "
                      >
                         {{$t('message.id')}}
                      </th>
                      <td>{{client.number}}</td>
                    </tr>

                    <tr>
                      <th
                      class="text-uppercase  font-weight-bolder "
                      >
                         {{$t('message.name')}}
                      </th>
                      <td>{{client.full_name}}</td>
                    </tr>
                    <tr>
                      <th
                      class="text-uppercase  font-weight-bolder "
                      >
                         {{$t('message.mobile')}}
                      </th>
                      <td>{{client.mobile}}</td>
                    </tr>
                     <tr>
                      <th
                      class="text-uppercase  font-weight-bolder "
                      >
                         {{$t('message.job')}}
                      </th>
                      <td>{{client.status?.name}}</td>
                    </tr>
                     <tr>
                      <th
                      class="text-uppercase  font-weight-bolder "
                      >
                         {{$t('message.source')}}
                      </th>
                      <td>{{client.source?.name}}</td>
                    </tr>
                      <tr>
                      <th
                      class="text-uppercase  font-weight-bolder "
                      >
                         {{$t('message.status')}}
                      </th>
                      <td>{{client.condition?.name}}</td>
                    </tr>

                  </thead>
                </table>
                </div>
              </div>
            </form>
          </div>
        </div>
        <!-- tabs -->
        <div class="row">
                   <div class="mb-1 col-lg-12">
          <ul class="nav nav-tabs" id="myTab" role="tablist">
            <li class="nav-item" role="presentation" style="width:14% !important">
        <button @click ="getClientCalls()" class="nav-link active btn  w-96 mb-0 bg-gradient-info btn-md null null btn-lg" id="calls-tab" data-bs-toggle="tab" data-bs-target="#calls" type="button" role="tab" aria-controls="calls" aria-selected="true"> {{$t('message.calls')}}</button>
      </li>
      <li class="nav-item" role="presentation" style="width:14% !important">
        <button class="nav-link  btn  w-96 mb-0 bg-gradient-info btn-md null null btn-lg" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true"> {{$t('message.receipts')}}</button>
      </li>
       <li class="nav-item" role="presentation" style="width:18.6% !important">
        <button @click ="getClientReceipts()" class="nav-link w-96 btn mb-0 bg-gradient-info btn-md null null btn-lg" id="receipts-tab" data-bs-toggle="tab" data-bs-target="#receipts" type="button" role="tab" aria-controls="receipts" aria-selected="true">{{$t('message.printedReceipt')}}</button>
      </li>
       <li class="nav-item" role="presentation" style="width:18.6% !important">
        <button @click ="getClientPayments()" class="nav-link  w-96 btn mb-0 bg-gradient-info btn-md null null btn-lg" id="wallet-tab" data-bs-toggle="tab" data-bs-target="#wallet" type="button" role="tab" aria-controls="home" aria-selected="true">{{$t('message.walletReceipts')}}</button>
      </li>
      <li class="nav-item" role="presentation" style="width:18.6% !important" >
        <button @click ="getPlacementInfo()" class="nav-link btn mb-0 bg-gradient-info  w-96 btn-md null null btn-lg" id="placement-tab" data-bs-toggle="tab" data-bs-target="#placement" type="button" role="tab" aria-controls="placement" aria-selected="false"> {{$t('message.placement')}}</button>
      </li>
      <li class="nav-item" role="presentation" style="width:14.6% !important">
        <button class="nav-link btn mb-0 bg-gradient-info   w-96 btn-md null null btn-lg" id="contact-tab" data-bs-toggle="tab" data-bs-target="#contact" type="button" role="tab" aria-controls="contact" aria-selected="false">  {{$t('message.courses')}} </button>
      </li>
    </ul>
    </div>
<div class="tab-content" id="myTabContent">
  <!-- calls -->
  <div class="tab-pane fade show active" id="calls" role="tabpanel" aria-labelledby="calls-tab">
         <div class="row mt-4">
          <div class="card col-12 my-4">
            <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
              <div
                class="bg-gradient-success shadow-success border-radius-lg pt-4 pb-3"
              >
              <div class="row">
                <div class="col-md-9">
                <h6 class="text-white text-capitalize ps-3">
                      {{$t('message.calls')}}
                </h6>
                </div>
                <div class="col-md-3">
                   <button
                  style="background: #fff, color:#222"
                  @click="
                    getCallDetails(
                      client.full_name,
                      client.id,
                      client.calls
                    )
                  "
                  class="badge btn call-btn badge-sm pointer"
                >
                   {{$t('message.addCall')}}
                </button>
                </div>
              </div>
              </div>
            </div>
            <div class="card-body px-0 pb-2">
               <div
                class="row align-center"
                style="margin-top: 40px; direction: rtl"
              >
                <div
                  class="text-center table-responsive col-lg-12 overflow-x"

                >
                  <table class="b-block table" >
                    <thead>
                      <tr>
                        <th> {{$t('message.user')}}</th>
                        <th>  {{$t('message.id')}}</th>
                        <th>  {{$t('message.name')}}</th>
                        <th>  {{$t('message.feedbackType')}}</th>
                        <th> {{$t('message.feedback')}}</th>
                        <th> {{$t('message.rating')}}</th>
                        <th>  {{$t('message.createdAt')}}</th>
                        <th>  {{$t('message.nextCall')}}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="call in calls" :key="call.id">
                        <td>{{ call.user?.user_name }}</td>
                        <td>{{ call.student?.number }}</td>
                        <td>{{ call.student?.full_name }}</td>
                        <td
                          class="wrap-text"
                        >
                          {{ call.feedback_category?.name }}
                        </td>
                        <td
                          class="wrap-text"
                        >
                          {{
                            call.feedback??''
                          }}
                        </td>
                        <td v-if="call.rating">
                        <span  :class="{
                            'success-back': call.rating > 5,
                            'warning-back': call.rating > 2 && call.rating < 6,
                            'danger-back': call.rating <= 2,
                          }" class="  badge rating-con font-size-12"> {{ call?.rating || null }}
                <i class="fas fa-star w-star"></i></span>
                        </td>
                        <td v-else></td>
                        <td>{{ call.created_at }}</td>
                        <td>{{ call.next_call_date }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
  </div>
  <div class="tab-pane fade show " id="placement" role="tabpanel" aria-labelledby="placement-tab">
     <!-- placement -->
         <div class="row mt-4">
          <div class="card col-12 my-4">
            <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
              <div
                class="bg-gradient-success shadow-success border-radius-lg pt-4 pb-3"
              >
                <h6 class="text-white text-capitalize ps-3">
                      {{$t('message.placement')}}
                </h6>
              </div>
            </div>
            <div class="card-body px-0 pb-2">
              <div class="table-responsive p-0 overflow">
                <table class="table align-items-center mb-0">
                  <thead>
                    <tr>
                      <th
                        class="text-uppercase  font-weight-bolder "
                      >
                      {{$t('message.levelType')}}
                      </th>
                        <th
                        class="text-uppercase  font-weight-bolder "
                      >
                         {{$t('message.paymentStatus')}}
                      </th>
                      <th
                        class="text-uppercase  font-weight-bolder "
                      >
                            {{$t('message.listening')}}
                      </th>
                       <th
                        class="text-uppercase  font-weight-bolder "
                      >
                           {{$t('message.G&A')}}
                      </th>
                       <th
                        class="text-uppercase  font-weight-bolder "
                      >
                            {{$t('message.reading')}}
                      </th>
                       <th
                        class="text-uppercase  font-weight-bolder "
                      >
                           {{$t('message.writing')}}
                      </th>
                      <th
                        class="text-uppercase  font-weight-bolder "
                      >
                           {{$t('message.speaking')}}
                      </th>
                       <th
                        class="text-uppercase  font-weight-bolder "
                      >
                           {{$t('message.overall')}}
                      </th>
                      <th
                        class="text-uppercase  font-weight-bolder "
                      >
                           {{$t('message.branch')}}
                      </th>
                      <th
                        class="text-uppercase  font-weight-bolder "
                      >
                           {{$t('message.assessor')}}
                      </th>
                       <th
                        class="text-uppercase  font-weight-bolder "
                      >
                           {{$t('message.value')}}
                      </th>
                       <th
                        class="text-uppercase  font-weight-bolder "
                      >
                           {{$t('message.note')}}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(level,index) in levels" :key="index">
                      <td>
                        <p class="text-s mb-0">{{level.level?.name}}</p>
                      </td>
                      <td v-if="level.status" class="text-center">
                              <span class="badge badge-sm bg-gradient-success"
                                ><i class="fas fa-check"></i
                              ></span>
                            </td>
                            <td v-else class="text-center">
                              <span class="badge badge-sm bg-gradient-danger">X</span>
                            </td>
                       <td>
                        <p class="text-s mb-0 ">
                          {{ level.listening }}
                        </p>
                      </td>
                      <td>
                        <p class="text-s mb-0">
                          {{
                           level.g_a
                          }}
                        </p>
                        </td>
                         <td>
                        <p class="text-s mb-0 ">
                          {{ level.reading }}
                        </p>
                      </td>
                      <td> <p class="text-s mb-0 ">{{ level.writing }}
                        </p>
                      </td>
                      <td> <p class=" mb-0 ">{{ level.speaking }}
                        </p>
                      </td>
                        <td class="overall back-color">  <p class="text-s mb-0 ">
                          {{
                            (level?.listening || 0) +
                            (level?.reading || 0) +
                            (level?.writing || 0) +
                            (level?.g_a || 0) +
                            (level?.speaking || 0)
                          }}
                        </p>
                      </td>
                       <td> <p class="text-s mb-0 ">{{ level.student?.branch?.name }}
                        </p>
                      </td>
                      <td> <p class="text-s mb-0 ">{{ level.instructor?.name }}
                        </p>
                      </td>
                      <td> <p class="text-s mb-0 ">{{  $filters.number(level.paid)}}
                        </p>
                      </td>
                       <td> <p class="text-s mb-0 ">{{ level.note }}
                        </p>
                      </td>
                        <!-- <td>
                          &ensp;<a v-if='device.active' href="javascript:void(0);" @click="toggleDevice(device.id)"><i class="mdi mdi-square-edit-outline"></i><span class="badge badge-sm bg-gradient-success"><i class="fas fa-lock-open"></i></span></a>
                          &ensp;<a v-else href="javascript:void(0);" @click="toggleDevice(device.id)"><i class="mdi mdi-square-edit-outline"></i><span class="badge badge-sm bg-gradient-danger"><i class="fas fa-lock"></i></span></a>
                      </td> -->
                    </tr>
                  </tbody>
                  <tfoot>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
        </div>
  </div>
  <!-- receipts -->
  <div class="tab-pane fade show " id="home" role="tabpanel" aria-labelledby="home-tab">
      <div class="row mt-4">
          <div class="card col-12 my-4">
            <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
              <div
                class="bg-gradient-success shadow-success border-radius-lg pt-4 pb-3"
              >
                <div class="row">
              <div class="col-lg-6">
              <h6 class="text-white text-capitalize ps-3">   {{$t('message.receipts')}}</h6>
              </div>
               <div class="col-lg-2">
                    <router-link :to="{ name:' إضافة إيصال ', params: { id: client.id}}">
               <span  class="badge badge-sm new-student pointer"><i  class="fas fa-location-arrow"></i> {{$t('message.createReceipt')}} </span>
                </router-link>
               </div>
                <div class="col-lg-2">
                  <router-link :to="{ name:' إيصال عرض  ', params: { id: client.id}}">
               <span  class="badge badge-sm new-student pointer"><i  class="fas fa-location-arrow"></i>  {{$t('message.offerReceipt')}} </span>
                 </router-link>
               </div>
                <div class="col-lg-2">
                <router-link :to="{ name:' إيصال مراجعة  ', params: { id: client.id}}">
               <span  class="badge badge-sm new-student pointer"><i  class="fas fa-location-arrow"></i> {{$t('message.revisionReceipt')}} </span>
                </router-link>
               </div>
              </div>
              </div>
            </div>
            <div class="card-body px-0 pb-2">
              <div class="table-responsive p-0">
                <table class="table align-items-center mb-0">
                  <thead style="background: #252527 !important">
                    <tr>
                      <th
                      class="text-uppercase  font-weight-bolder "
                      >
                        #
                      </th>
                      <th
                        class="text-uppercase  font-weight-bolder "
                      >
                          {{$t('message.branch')}}
                      </th>
                      <th
                        class="text-uppercase  font-weight-bolder "
                      >
                          {{$t('message.receiptType')}}
                      </th>
                      <th
                        class="text-uppercase  font-weight-bolder "
                      >
                           {{$t('message.course')}}\  {{$t('message.offer')}}

                      </th>
                      <th
                        class="text-uppercase  font-weight-bolder "
                      >
                          {{$t('message.value')}}
                      </th>
                       <th
                        class="text-uppercase  font-weight-bolder "
                      >
                          {{$t('message.date')}}
                      </th>
                       <th
                        class="text-uppercase  font-weight-bolder "
                      >
                          {{$t('message.user')}}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="receipt in receipts" :key="receipt.id">
                      <td>
                        <p class="text-s mb-0">{{ receipt.code }}</p>
                      </td>
                      <td>
                        <p class="text-s mb-0">{{ receipt.branch?receipt.branch.name:''}}</p>
                      </td>
                      <td>
                        <p class="text-s mb-0">
                          {{ receipt.transaction_type_name }}
                        </p>
                      </td>
                      <td>
                        <p class="text-s mb-0" v-if="receipt.course_number">
                          {{
                            receipt.course_number + ' - ' + receipt.course
                          }}
                        </p>
                        <p class="text-s mb-0" v-else>
                          {{
                            receipt.offer
                          }}
                        </p>
                      </td>
                      <td  :class="{'paid-back' :receipt.transaction_type.name_en === 'pay',
                        'refund-back' :receipt.transaction_type.name_en === 'refund'}">
                        <p class="text-s mb-0">
                          {{ $filters.number(receipt.amount) }}
                        </p>
                      </td>
                       <td>
                        <p class="text-s mb-0">
                          {{receipt.created_at}}
                        </p>
                      </td>
                       <td>
                        <p class="text-s mb-0">
                          {{receipt.user_name}}
                        </p>
                      </td>
                    </tr>
                  </tbody>
                  <tfoot class="back-color">
                    <tr>
                      <th colspan="4">  {{$t('message.balance')}}</th>
                      <td style="font-size:17px !important" class="bold bolder">{{ $filters.number(totalPayed) }}</td>
                      <td></td>
                      <td></td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
        </div>
    </div>
    <!-- printed receipts -->
     <div class="tab-pane fade show " id="receipts" role="tabpanel" aria-labelledby="receipts-tab">
      <div class="row mt-4">
          <div class="card col-12 my-4">
            <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
              <div
                class="bg-gradient-success shadow-success border-radius-lg pt-4 pb-3"
              >
                <div class="row">
              <div class="col-lg-6">
              <h6 class="text-white text-capitalize ps-3">  {{$t('message.printedReceipt')}} </h6>
              </div>
              </div>
              </div>
            </div>
            <div class="card-body px-0 pb-2">
              <div class="table-responsive p-0">
                <table class="table align-items-center mb-0">
                  <thead style="background: #252527 !important">
                    <tr>
                      <th
                      class="text-uppercase  font-weight-bolder "
                      >
                        #
                      </th>
                      <th
                        class="text-uppercase  font-weight-bolder "
                      >
                           {{$t('message.receiptType')}}
                      </th>
                      <th
                        class="text-uppercase  font-weight-bolder "
                      >
                           {{$t('message.course')}}\  {{$t('message.offer')}}

                      </th>
                      <th
                        class="text-uppercase  font-weight-bolder "
                      >
                          {{$t('message.value')}}
                      </th>
                       <th
                        class="text-uppercase  font-weight-bolder "
                      >
                          {{$t('message.date')}}
                      </th>
                       <th
                        class="text-uppercase  font-weight-bolder "
                      >
                          {{$t('message.user')}}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="receipt in ReceiptPayments" :key="receipt.id">
                      <td>
                        <p class="text-s mb-0">{{ receipt.code }}</p>
                      </td>
                      <td>
                        <p class="text-s mb-0">
                          {{ receipt.transaction_type_name }}
                        </p>
                      </td>
                      <td>
                        <p class="text-s mb-0" v-if="receipt.course_number">
                          {{
                            receipt.course_number + ' - ' + receipt.course
                          }}
                        </p>
                        <p class="text-s mb-0" v-else-if="receipt.offer">
                          {{
                            receipt.offer
                          }}
                        </p>
                         <p class="text-s mb-0" v-else>
                          {{
                           receipt.receipt_type_name
                          }}
                        </p>
                      </td>
                      <td  :class="{'paid-back' :receipt.transaction_type.name_en === 'pay',
                        'refund-back' :receipt.transaction_type.name_en === 'refund'}">
                        <p class="text-s mb-0">
                          {{ $filters.number(receipt.amount) }}
                        </p>
                      </td>
                       <td>
                        <p class="text-s mb-0">
                          {{receipt.created_at}}
                        </p>
                      </td>
                       <td>
                        <p class="text-s mb-0">
                          {{receipt.user_name}}
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
    </div>
    <!-- wallet -->
     <div class="tab-pane fade  " id="wallet" role="tabpanel" aria-labelledby="wallet-tab">
      <div class="row mt-4">
          <div class="card col-12 my-4">
            <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
              <div
                class="bg-gradient-success shadow-success border-radius-lg pt-4 pb-3"
              >
                <div class="row">
              <div class="col-lg-6">
              <h6 class="text-white text-capitalize ps-3">  {{$t('message.walletReceipts')}}</h6>
              </div>
              </div>
              </div>
            </div>
            <div class="card-body px-0 pb-2">
              <div class="table-responsive p-0">
                <table class="table align-items-center mb-0">
                  <thead style="background: #252527 !important">
                    <tr>
                      <th
                      class="text-uppercase  font-weight-bolder "
                      >
                        #
                      </th>
                      <th
                        class="text-uppercase  font-weight-bolder "
                      >
                           {{$t('message.receiptType')}}
                      </th>
                       <th
                        class="text-uppercase  font-weight-bolder "
                      >
                           {{$t('message.movementType')}}
                      </th>
                      <th
                        class="text-uppercase  font-weight-bolder "
                      >
                          {{$t('message.value')}}
                      </th>
                       <th
                        class="text-uppercase  font-weight-bolder "
                      >
                          {{$t('message.date')}}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in walletPayments" :key="item.id">
                      <td>
                        <p class="text-s mb-0">{{ item.code }}</p>
                      </td>
                      <td>
                        <p class="text-s mb-0">
                          {{ item.transaction_type_name }}
                        </p>
                      </td>
                      <td>
                        <p class="text-s mb-0">
                          {{ item.receipt_type_name}}
                        </p>
                      </td>
                      <td :class="{'paid-back' :item.receipt_type?.name_en === 'fillWallet',
                        'refund-back' :item.receipt_type?.name_en === 'useWallet'}">
                        <p class="text-s mb-0">
                          {{ $filters.number(item.amount) }}
                        </p>
                      </td>
                       <td>
                        <p class="text-s mb-0">
                          {{item.created_at}}
                        </p>
                      </td>
                    </tr>
                  </tbody>
                  <tfoot class="back-color">
                    <tr >
                      <th colspan="3">  {{$t('message.balance')}}</th>
                      <td style="font-size:17px !important" class="bold bolder">{{ $filters.number(walletTotal) }}</td>
                       <td></td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
        </div>
    </div>
  <div class="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">
          <!-- current registration -->
        <div class="row mt-4">
          <div class="col-12">
            <div class="card my-4">
              <div
                class="card-header p-0 position-relative mt-n4 mx-3 z-index-2"
              >
                <div
                  class="bg-gradient-success shadow-success border-radius-lg pt-4 pb-3"
                >
                  <h6 class="text-white text-capitalize ps-3">
                         {{$t('message.courses')}}
                  </h6>
                </div>
              </div>
              <div class="card-body px-0 pb-2">
                <div class="table-responsive p-0 overflow">
                  <table class="table align-items-center mb-0 client-enroll-c">
                    <thead>
                      <tr>
                         <th
                          class="text-uppercase  font-weight-bolder "
                        >
                            {{$t('message.id')}}
                        </th>
                        <th
                          class="text-uppercase  font-weight-bolder "
                        >
                             {{$t('message.course')}}
                        </th>
                         <th
                          class="text-uppercase  font-weight-bolder "
                        >
                             {{$t('message.attendance')}}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(course,index) in myCourses" :key="index">
                        <td >
                          <p class="text-s mb-0">{{ course.number }}</p>
                        </td>
                        <td style=" width: 35%; white-space: normal;" class="wrap-text">
                          <p class="text-s mb-0">{{ course.name }}</p>
                        </td>
                         <td class="text-center">
                          <p class="text-s mb-0">{{ course.lectures_attended_count }}</p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
  </div>
</div>
        </div>
      </div>
    </div>
     <CreateCall
      v-model="showModal"
      @hide-modal="showModal = false"
      :studentInfo="studentInfo"
      :calls="calls"
      hideDetails="true"
      @update-data="updateData"
    ></CreateCall>
  </div>
</template>
<script>
// import VmdInput from '@/components/VmdInput.vue'
// import VmdButton from '@/components/VmdButton.vue'
import CreateCall from '@/components/CreateCall.vue'
import swal from 'sweetalert'
export default {
  name: 'ShowClient',
  components: {
    CreateCall
  },
  data () {
    return {
      client: {},
      courses: [],
      devices: [],
      myCourses: [],
      receipts: [],
      selectedVideo: '',
      courseWatches: false,
      totalPayed: 0,
      clicksCount: 0,
      walletPayments: [],
      ReceiptPayments: [],
      walletTotal: 0,
      showModal: false,
      studentInfo: {},
      levels: [],
      calls: []
    }
  },
  created () {
    const current = this
    this.$http
      .get(`${this.$hostUrl}students/${this.$route.params.id}`)
      .then((response) => {
        this.client = response.data.data
        this.devices = this.client.device
        this.myCourses = this.client.my_courses
        const receipts = this.client.payment_history
        receipts.forEach(function (receipt) {
          if (receipt.number === null && (receipt.offer || receipt.course)) {
            current.receipts.push(receipt)
            receipt.subject_name = receipt.payable.subject ? receipt.payable.subject.name : ''
            if (
              receipt.transaction_type.id ===
              '78d9a393-9b84-4372-bb38-5d8b42eccd29'
            ) {
              current.totalPayed -= receipt.amount
            } else {
              current.totalPayed += receipt.amount
            }
          }
        })
        current.receipts.sort(
          (p1, p2) => (Date.parse(p1.created_at) > Date.parse(p2.created_at)) ? 1 : (Date.parse(p1.created_at) < Date.parse(p2.created_at)) ? -1 : 0)
      })
    this.getClientCalls()
  },
  methods: {
    getWatchLimit ($event, index) {
      this.myCourses[index].watchLimit = $event.client_watch_limit
      this.myCourses[index].clientWatchLimit = $event.client_repeats
      this.myCourses[index].videoId = $event.id
      this.myCourses[index].watchTimes = $event.client_watches
      const array = this.myCourses[index].watchTimes
      array.sort((a, b) => new Date(a.pivot.watched_at).getTime() - new Date(b.pivot.watched_at).getTime())
      console.log(array, 'array')
      console.log(this.myCourses[index].watchTimes, 'watches')
      if (this.myCourses[index].watchTimes.length) {
        this.courseWatches = true
      } else {
        this.courseWatches = false
      }
    },
    async increaseWatchLimit ($event, index, e) {
      if (this.myCourses[index].watchLimit === 0 || this.myCourses[index].watchLimit === null) {
        swal('الدورة مجانية')
      }
      this.myCourses[index].watchLimit++
      const formData = new FormData()
      formData.append('video_id', this.myCourses[index].videoId)
      formData.append('watch_limit', this.myCourses[index].watchLimit)
      this.errors = await this.postRequest(formData, `${this.$hostUrl}client/${this.$route.params.id}/increase-limit`, '')
    },
    async toggleDevice (id) {
      swal({
        title: 'هل انت متأكد ؟',
        text: 'بمجرد الموافقة ، سيتم  تغيير حالة الجهاز !',
        icon: 'warning',
        buttons: ['لا', 'نعم'],
        dangerMode: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33'
      }).then((willDelete) => {
        if (willDelete) {
          this.$http
            .post(
              `${this.$hostUrl}device/${id}/toggle/student/${this.$route.params.id}`
            )
            .then((response) => {
              if (!response.data.error) {
                swal({ title: response.data.message, buttons: 'تم' })
                // refresh data
                this.$http
                  .get(`${this.$hostUrl}students/${this.$route.params.id}`)
                  .then((response) => {
                    this.client = response.data.data
                    this.devices = this.client.device
                  })
              }
            }).catch(function (error) {
              if (Object.prototype.hasOwnProperty.call(error, 'response')) {
                swal({ title: error.response.data.message, buttons: 'تم' })
              }
            })
        } else {
          swal({ title: ' تم الإلغاء  !', buttons: 'تم' })
        }
      })
    },
    getClientPayments () {
      this.$http
        .get(`${this.$hostUrl}student/${this.$route.params.id}/payments`)
        .then((response) => {
          this.walletPayments = response.data.data.wallet_history
          this.walletTotal = response.data.data.wallet
        })
    },
    getClientReceipts () {
      this.$http
        .get(`${this.$hostUrl}student/${this.$route.params.id}/payments`)
        .then((response) => {
          this.ReceiptPayments = response.data.data.payments
        })
    },
    getPlacementInfo () {
      this.$http
        .get(`${this.$hostUrl}levels?student_id=${this.$route.params.id}`)
        .then((response) => {
          this.levels = response.data.data
        })
    },
    getClientCalls () {
      this.$http
        .get(`${this.$hostUrl}calls?student_id=${this.$route.params.id}`)
        .then((response) => {
          this.calls = response.data.data
        })
    },
    getCallDetails (studentName, id, calls) {
      this.studentInfo = {
        name: studentName,
        id: id
      }
      this.showModal = true
    },
    updateData () {
      this.getClientCalls()
    }
  }
}

</script>
<style>
.margin0{
  margin-top:0 !important
}
.tab-div{
  margin-top: 50px !important;
}
.nav-tabs{
  border-bottom:unset !important
}
.tab-div li{
  width:33%
}
.tab-div .btn-info{
  color:#fff
}
.nav-tabs .nav-link.active,
 .nav-tabs .nav-item.show .nav-link{
  color:#fff !important;
  background-image: linear-gradient(195deg, #49a3f1 0%, #1a73e8 100%) !important;
 }
.nav-tabs .nav-link{
  color:#222 !important;
  background:#f8f9fa !important
}
.watches-time{
  font-weight: bold;
  font-size: 13px !important;
}
.paid-back{
 background: #baf2c5a1 !important;
}
.refund-back{
  background: #fb060669 !important;
}
.client-info table{
  width:75%;
  text-align: right;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
   rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}
.client-info td{
  background: #fff !important;
}
.close-btn{
  width:14%;
  margin-top:30px !important
}
#ch-receipt{
  padding-top: 20px;
  padding-right: 20px;
}
.client-enroll-c td{
  white-space: normal;
}
.w-96{
 width: 96% !important
}
.bold{
  font-size: 17px !important;
}
.call-btn{
    float: left;
    margin-left: 20px;
    padding: 13px 20px;
    background-color: #fff !important;
    color: #666e7c !important;
}
.overall{
  background-color: #eeeeee !important;
  text-align: center;
}
.overall p{
  font-weight: bold;
  font-size:17px !important;
  font-family: Montserrat, sans-serif !important;
}
</style>
