<template>
     <div class="container-fluid py-4 add-student lecture-details">
    <div class="row">
      <div class="col-12">
        <div class="card my-4">
          <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
            <div
              class="bg-gradient-success shadow-success border-radius-lg pt-4 pb-3"
            >
              <h6 class="text-white text-capitalize ps-3">   عرض  الشرائح</h6>
            </div>
          </div>
          <div class="card-body px-0 pb-2">
            <div class="row">
                      <div class="mb-3 col-lg-6 text-center">
                  <label for='image' class="required">

                  </label>
                  <img class="img-thumbnail insrt-img" :src='slider.slide_url'  width="200px" height="200px" alt="لايوجد صورة" >
                  </div>
                      </div>
                  <form role="form" class="text-start mt-3">
                         <div class="row">
                    <div class="mb-3 col-lg-6">
                         <label>
                     الوصف</label>
                  <div class="input-group">
                 <textarea class="form-control"
                 rows="4"
                 cols="100"
                 maxlength='100'
                 placeholder='الرسالة'
                 v-model='slider.description'
                 disabled
                  >
                 </textarea>
                 </div>
                   </div>
                     <div class="mb-3 col-lg-6">
                         <label>
                    الوصف بالإنجليزية</label>
                  <div class="input-group">
                 <textarea class="form-control"
                 rows="4"
                 cols="100"
                 maxlength='100'
                 placeholder='الرسالة'
                  v-model='slider.description_en'
                  disabled
                  >
                 </textarea>
                 </div>
                        </div>
                        </div>
                            <div class="row justify-content">
                <div class="text-center col-lg-6">
                   <vmd-button
                   @click='goBack($event)'
                    class="my-4 mb-2 m-auto"
                    variant="gradient"
                    color="warning"
                    > رجوع</vmd-button>
                       </div>
                       </div>

              </form>
          </div>
        </div>
      </div>
    </div>
     </div>
</template>
<script>
import VmdButton from '@/components/VmdButton.vue'
export default {
  name: 'ShowSlider',
  components: {
    VmdButton
  },
  data () {
    return {
      slider: {}
    }
  },
  created () {
    this.$http.get(`${this.$hostUrl}slides/${this.$route.params.id}`).then(response => {
      this.slider = response.data.data
    })
  },
  methods: {
    goBack (e) {
      e.preventDefault()
      this.$router.push({ path: '/slider' })
    }
  }
}
</script>
<style>
.justify-content{
  justify-content: center;
}

</style>
