<!-- eslint-disable vue/no-v-model-argument -->
<template>
     <div class="container-fluid py-4 add-student teachers">
    <div class="row">
      <div class="col-12">
        <div class="card my-4">
          <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
            <div
              class="bg-gradient-success shadow-success border-radius-lg pt-4 pb-3"
            >
              <h6 class="text-white text-capitalize ps-3">   إضافة إشعار </h6>
            </div>
          </div>
          <div class="card-body px-0 pb-2">
                   <form @submit='createNotifications' role="form" class="text-start mt-3">
                       <div class="row">
                   <div class="mb-3 col-lg-6" >
                  <label>
                     ملف هواتف الطلاب  (xlsx) <span class="red">*</span><span color="danger" class="error">{{
                      errors.extension? errors.extension[0] : ''
                    }}</span></label>
                  <vmd-input
                    id="excelFile"
                    type="file"
                    @change='onFileChange'
                    required
                  />
                </div>
                  <div class="mb-3 col-lg-6" >
                  <label>
                     عنوان الرسالة  <span class="red">*</span><span color="danger" class="error">{{
                      errors.title ? errors.title[0] : ''
                    }}</span></label>
                  <vmd-input
                    id="title"
                    type="text"
                    placeholder='عنوان الرسالة'
                    v-model='message.title'
                    required
                  />
                </div>
                </div>
                <div class='row'>
                    <div class="mb-3 col-lg-6" >
                  <label>
                      العنوان الفرعي  <span class="red">*</span><span color="danger" class="error">{{
                      errors.title ? errors.title[0] : ''
                    }}</span></label>
                  <vmd-input
                    id="title"
                    type="text"
                    placeholder='العنوان الفرعي '
                    v-model='message.subtitle'
                    required
                  />
                </div>
                <div class="mb-3 col-lg-6" >
                   <label>
                     الرسالة <span color="danger" class="error">{{
                      errors.content ? errors.content[0] : ''
                    }}</span></label>
                  <div class="">
                  <quill-editor theme="snow" required :content ="message.content"
                  v-model:content="message.content" contentType ='html'></quill-editor>
                  </div>
                  </div>
                </div>
                       <div class="row">
                <div class="text-center col-lg-6">
                  <vmd-button
                    class="my-4 mb-2"
                    variant="gradient"
                    color="info"
                    > حفظ</vmd-button
                  >
                </div>
                 <div class="text-center col-lg-6">
                  <vmd-button
                  type='reset'
                    class="my-4 mb-2"
                    variant="gradient"
                    color="danger"
                    > مسح</vmd-button
                  >
                </div>
                       </div>
              </form>
          </div>
        </div>
      </div>
    </div>
     </div>
</template>
<script>
import VmdInput from '@/components/VmdInput.vue'
import VmdButton from '@/components/VmdButton.vue'
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css'
export default {
  name: 'CreateNotification',
  components: {
    VmdInput,
    VmdButton,
    QuillEditor
  },
  data () {
    return {
      message: {},
      excelFile: '',
      errors: []
    }
  },
  methods: {
    onFileChange (e) {
      this.excelFile = e.target.files[0]
    },
    async createNotifications (e) {
      e.preventDefault()
      const formData = new FormData()
      formData.append('file', this.excelFile)
      formData.append('title', this.message.title)
      formData.append('subtitle', this.message.subtitle)
      formData.append('content', this.message.content)

      this.errors = await this.postRequest(formData, this.$hostUrl + 'notification/send', '/notifications')
    }
  }
}
</script>
<style >
.ql-toolbar{
  margin-top: 30px;
}
.ql-container{
    background: #f8f9fa;
    box-shadow: 0 4px 6px -1px rgb(0 0 0 / 10%), 0 2px 4px -1px rgb(0 0 0 / 6%);
}

</style>
