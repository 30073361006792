<template>
     <div class="container-fluid py-4 add-student">
    <div class="row">
      <div class="col-12">
        <div class="card my-4">
          <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
            <div
              class="bg-gradient-success shadow-success border-radius-lg pt-4 pb-3"
            >
              <h6 class="text-white text-capitalize ps-3">   {{$t('message.createCustomer')}}</h6>
            </div>
          </div>
          <div class="card-body px-0 pb-2">
                   <form @submit="createclient" role="form" class="text-start mt-3">
                       <div class="row">
                <div class="mb-3 col-lg-6">
                    <label>{{$t('message.customerName')}} <span class="red">*</span>
                    <span color='danger' class="error">{{errors.full_name?errors.full_name[0]:''}}</span></label>
                  <vmd-input
                    id=""
                    type="text"
                   :label="$t('message.nameAr')  "
                   v-model='client.fullName'
                    required
                   :invalidFeedback="errors.name"
                  />
                </div>
                 <div class="mb-3 col-lg-6">
                    <label> {{$t('message.mobile')}}<span class="red">*</span>
               <span color='danger' class="error">{{errors.mobile?errors.mobile[0]:''}}</span></label>
                  <vmd-input
                    id=""
                    type="text"
                   :label="$t('message.phoneValidation')"
                   v-model='client.mobile'
                    required
                  />
                </div>
                       </div>
                        <div class="row">
                <div class="mb-3 col-lg-6">
                    <label class='unrequired'> {{$t('message.guardianPhone')}}<span class="green">({{$t('message.optional')}})</span>
               <span color='danger' class="error">{{errors.guardian_mobile?errors.guardian_mobile[0]:''}}</span></label>
                  <!-- <vmd-input
                    id=""
                    type="text"
                   :label="$t('message.phoneValidation')"
                   v-model='client.guardianMobile'
                  /> -->
                  <div class="main-tel">
                      <div class="cntry-cd" style="display:inline;width: 22%;line-height:28px"> +{{ countryCode }}</div>
                     <vue-tel-input  :placeholder="placeholder" style="display:inline-flex; border-width:0px" id="phone" :value="phone" @input="onInput" @country-changed="countryChanged"></vue-tel-input>
                </div>
                </div>
                <div class="mb-3 col-lg-6">
                      <label class='unrequired'>   {{$t('message.email')}}<span class="green">({{$t('message.optional')}})</span></label>
                       <span color='danger' class="error"></span>
                  <vmd-input
                    id="email"
                    type="email"
                    :label="$t('message.email')"
                    v-model='client.email'
                    :invalidFeedback="errors.email"
                  />
                </div>
                </div>
                <div class='row'>
                   <div class="mb-3 col-lg-6">
                    <label class='unrequired'>  {{$t('message.facebookUrl')}}<span class="green">({{$t('message.optional')}})</span>
                      <span color='danger' class="error">{{errors.facebook_url?errors.facebook_url[0]:''}}</span></label>
                  <vmd-input
                    id=""
                    type="text"
                   :label=" $t('message.facebookUrl') "
                    v-model='client.facebookUrl'
                  />
                </div>
                    <div class="mb-3 col-lg-6">
                    <label> {{$t('message.job')}} <span class="red">*</span>
                    <span color='danger' class="error">{{errors.grade_id?errors.grade_id[0]:''}}</span>
                    </label>
                      <multiselect
                        class='required form-control'
                        id='grade'
                        v-model="selectedStatus"
                        :options='status'
                        :searchable='true'
                        :close-on-select='true'
                        :placeholder=" $t('message.select') "
                        label='name'
                        :custom-label="customLabel"
                        track-by='id'
                        required='required'
                        :internal-search="true"
                        >
                      >
                        <template v-slot:noOptions>
                          <span> فارغة </span>
                        </template>
                        <template v-slot:noResult>
                          <span>لا يوجد نتيجة </span>
                        </template>
                      </multiselect>
                </div>
                       </div>
                           <div class='row'>
                <div class="mb-3 col-lg-6">
                      <label> {{$t('message.source')}} <span class="red">*</span>
                      <span color='danger' class="error">{{errors.group_id?errors.group_id[0]:''}}</span></label>
                    <multiselect
                        class='required form-control'
                        id='grade'
                        v-model=" selectedSource"
                        :options='sources'
                        :searchable='true'
                        :close-on-select='true'
                        :placeholder="$t('message.select') "
                        label='name'
                        :custom-label="customLabel"
                        track-by='id'
                        required='required'
                        :internal-search="true"
                        >
                      >
                        <template v-slot:noOptions>
                          <span> فارغة </span>
                        </template>
                        <template v-slot:noResult>
                          <span>لا يوجد نتيجة </span>
                        </template>
                      </multiselect>
                </div>
                 <div class="mb-3 col-lg-6">
                      <label class="unrequired"> {{$t('message.status')}} <span class="green">({{$t('message.optional')}})</span>
                      <span color='danger' class="error">{{errors.condition_id?errors.condition_id[0]:''}}</span></label>
                    <multiselect
                        class='required form-control'
                        id='grade'
                        v-model="selectedCondition"
                        :options='conditions'
                        :searchable='true'
                        :close-on-select='true'
                        :placeholder="$t('message.select') "
                        label='name'
                        :custom-label="customLabel"
                        track-by='id'
                        required='required'
                        :internal-search="true"
                        >
                      >
                        <template v-slot:noOptions>
                          <span> فارغة </span>
                        </template>
                        <template v-slot:noResult>
                          <span>لا يوجد نتيجة </span>
                        </template>
                      </multiselect>
                       </div>
                       </div>
                        <div class="row">
                        <div class="mb-3 col col-6">
                      <label >  {{$t('message.salesEmployee')}} <span class="green">({{$t('message.optional')}})</span>
                      <span color='danger' class="error">{{errors.area_id?errors.area_id[0]:''}}</span></label>
                    <multiselect
                        class='required form-control'
                        id='area'
                        v-model=" selectedSales"
                        :options='salespeople'
                        :searchable='true'
                        :close-on-select='true'
                        :placeholder="$t('message.select') "
                        label='name'
                        :custom-label="customLabel"
                        track-by='id'
                        required='required'
                        :internal-search="true"
                        >
                      >
                        <template v-slot:noOptions>
                          <span> فارغة </span>
                        </template>
                        <template v-slot:noResult>
                          <span>لا يوجد نتيجة </span>
                        </template>
                      </multiselect>
                      </div>
                       <div class="mb-3 col col-6">
                      <label >  {{$t('message.branch')}} <span class="red">*</span>
                      <span color='danger' class="error">{{errors.branch_id?errors.branch_id[0]:''}}</span></label>
                    <multiselect
                        class='required form-control'
                        id='area'
                        v-model=" selectedBranch"
                        :options='branches'
                        :searchable='true'
                        :close-on-select='true'
                        :placeholder="$t('message.select') "
                        label='name'
                        :custom-label="customLabel"
                        track-by='id'
                        required='required'
                        :internal-search="true"
                        >
                      >
                        <template v-slot:noOptions>
                          <span> فارغة </span>
                        </template>
                        <template v-slot:noResult>
                          <span>لا يوجد نتيجة </span>
                        </template>
                      </multiselect>
                      </div>
                       </div>
                       <div class="row">
                <div class="text-center col-lg-6">
                  <vmd-button
                    class="my-4 mb-2"
                    variant="gradient"
                    color="info"
                    > {{$t('message.save')}}</vmd-button
                  >
                </div>
                 <div class="text-center col-lg-6">
                  <vmd-button
                  type='reset'
                    class="my-4 mb-2"
                    variant="gradient"
                    color="danger"
                    > {{$t('message.delete')}}</vmd-button
                  >
                </div>
                       </div>
              </form>
        </div>
      </div>
      </div>
    </div>
    </div>
</template>
<script>
import VmdInput from '@/components/VmdInput.vue'
import VmdButton from '@/components/VmdButton.vue'
import { VueTelInput } from 'vue3-tel-input'
import 'vue3-tel-input/dist/vue3-tel-input.css'
import $ from 'jquery'
import swal from 'sweetalert'
export default {
  name: 'CreateClient',
  components: {
    VmdInput,
    VmdButton,
    VueTelInput
  },
  data () {
    return {
      client: {},
      status: [],
      sources: [],
      areas: [],
      selectedStatus: '',
      selectedSource: '',
      selectedArea: '',
      conditions: [],
      selectedCondition: '',
      guard: '',
      countryIso: 'EG',
      countryCode: '+20',
      salespeople: [],
      selectedSales: '',
      branches: [],
      selectedBranch: '',
      errors: []
    }
  },
  created () {
    const status = []
    this.$http.get(`${this.$hostUrl}statuses`)
      .then((response) => {
        $.each(response.data.data, function (key, value) {
          status.push({
            name: value.name,
            id: value.id
          })
        })
        this.status = status
      })
    const sources = []
    this.$http.get(`${this.$hostUrl}sources`)
      .then((response) => {
        $.each(response.data.data, function (key, value) {
          sources.push({
            name: value.name,
            id: value.id
          })
        })
        this.sources = sources
      })
    const areas = []
    this.$http.get(`${this.$hostUrl}areas`)
      .then((response) => {
        $.each(response.data.data, function (key, value) {
          areas.push({
            name: value.name,
            id: value.id
          })
        })
        this.areas = areas
      })
    const conditions = []
    this.$http.get(`${this.$hostUrl}conditions`)
      .then((response) => {
        $.each(response.data.data, function (key, value) {
          conditions.push({
            name: value.name,
            id: value.id
          })
        })
        this.conditions = conditions
      })
    this.$http.get(`${this.$hostUrl}salespeople`)
      .then((response) => {
        this.salespeople = response.data.data.map(value => ({
          name: value.name,
          id: value.id
        }))
      })
    this.$http.get(`${this.$hostUrl}branches`)
      .then((response) => {
        this.branches = response.data.data.map(value => ({
          name: value.name,
          id: value.id
        }))
      })
  },
  methods: {
    onInput (phone, phoneObject, input) {
      if (phoneObject?.formatted) {
        this.phone = phoneObject.formatted
      }
    },
    countryChanged (country) {
      this.countryIso = country.iso2
      this.countryCode = country.dialCode
    },
    async createclient (e) {
      e.preventDefault()
      const formData = new FormData()
      if (/^\d+$/.test(this.client.fullName)) {
        swal('اسم الطالب يجب ان يحتوي على حروف')
        return 0
      }
      if (!this.selectedBranch) {
        this.errors.branch_id = []
        this.errors.branch_id.push(' اختيار الفرع مطلوب')
        return 0
      }
      formData.append('full_name', this.client.fullName)
      formData.append('mobile', this.client.mobile)
      if (this.client.email) {
        formData.append('email', this.client.email)
      }
      if (this.client.facebookUrl) {
        formData.append('facebook_url', this.client.facebookUrl)
      }
      if (this.phone) {
        const formattedPhone = `+${this.countryCode} ${this.phone}`
        formData.append('guardian_mobile', formattedPhone)
        formData.append(' country_iso', this.countryIso)
      }
      if (this.selectedCondition) {
        formData.append('condition_id', this.selectedCondition.id)
      }
      formData.append('source_id', this.selectedSource.id)
      if (this.selectedSales.id) {
        formData.append('salesperson_id', this.selectedSales.id)
      }
      formData.append('status_id', this.selectedStatus.id)
      formData.append('branch_id', this.selectedBranch.id)
      this.errors = await this.postRequest(formData, this.$hostUrl + 'students', '/students')
    }
  },
  mounted () {
    $('.vti__input').attr('placeholder', this.$t('message.phone'))
  }
}
</script>
<style >
.error{
  color:#f44335 !important;
  font-size:13px;
   padding-right: 25px;
}
.unrequired{
      display: flex;
    gap: 5px;
}
.main-tel{
  display: inline-flex;
  direction: ltr !important;
    background: none;
    background: #f8f9fa;
    border: 1px solid #d2d6da;
    border-radius: 0.375rem;
    border-top-left-radius: 0.375rem !important;
    border-bottom-left-radius: 0.375rem !important;
    padding: 0.3rem 0.75rem !important;
    line-height: 1.3 !important;
}
.vti__dropdown-list{
  left:unset !important;
  z-index: 10 !important;
}

</style>
