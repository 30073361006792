import { h, resolveComponent } from 'vue'
import i18n from '../i18n'
import { createRouter, createWebHashHistory } from 'vue-router'
import Dashboard from '../views/Dashboard'
import Setting from '../views/Setting'
// common route between seven eleven and vision centers
import dynamicRoutes from './dynamicRoutes'
import CreateLecture from '../views/Courses/CreateLecture.vue'
// users
import Users from '../views/Users/Index.vue'
import AddUser from '../views/Users/Create.vue'
import EditUser from '../views/Users/Edit.vue'
import ShowUser from '../views/Users/Show.vue'
import UserCollections from '../views/UserCollections.vue'
// notifications
import Notifications from '../views/Notifications/Index.vue'
import CreateNotification from '../views/Notifications/Create.vue'
import ShowNotification from '../views/Notifications/Show.vue'
// system notifications
import SystemNotifications from '../views/SystemNotifications.vue'
// lectures
import Lectures from '../views/Lectures/Index.vue'
import EditLecture from '../views/Lectures/Edit.vue'
import ShowLecture from '../views/Lectures/Show.vue'
// videos
import Videos from '../views/Videos/Index.vue'
import EditVideo from '../views/Videos/Edit.vue'
// import ShowLecture from '../views/Lectures/Show.vue'
import ShowVideo from '../views/Videos/Show.vue'
// grades
import Grades from '../views/Grades/Index.vue'
import GradeDetail from '../views/Grades/GradeDetail.vue'
import AddGroup from '../views/Grades/AddGroup.vue'
// rooms
import Rooms from '../views/Rooms/Index.vue'
import RoomDetail from '../views/Rooms/RoomDetail.vue'
// branches
import Branches from '../views/Branches/Index.vue'
import CreateBranch from '../views/Branches/Create.vue'
import EditBranch from '../views/Branches/Edit.vue'
import BranchDetails from '../views/Branches/Details.vue'
// roles
import Roles from '../views/Roles/Index.vue'
import createRole from '../views/Roles/Create.vue'
import editRole from '../views/Roles/Edit.vue'
import showRole from '../views/Roles/Show.vue'
// slider
import Slider from '../views/Slider/Index.vue'
import CreateSlider from '../views/Slider/Create.vue'
import EditSlider from '../views/Slider/Edit.vue'
import ShowSlider from '../views/Slider/Show.vue'

// special receipts
import SpecialReceipt from '../views/SpecialReceipt.vue'

// Attendance
import Attendance from '../views/Attendance.vue'
import NewAttendance from '../views/NewAttendance.vue'
// reports
import InstructorReport from '../views/Reports/Instructor.vue'
import InstructorCollections from '../views/Reports/InstructorCollections.vue'
import DailyCollections from '../views/Reports/DailyCollections.vue'
import CollectionsReport from '../views/Reports/CollectionsReport.vue'
import StudentAttendanceReport from '../views/Reports/StudentAttendance.vue'
import OverallIncome from '../views/Reports/OverallIncome.vue'
import CourseStudents from '../views/Reports/CourseStudents.vue'
import SubjectsPayments from '../views/Reports/SubjectsPayments.vue'
import CallsReport from '../views/Reports/CallsReport.vue'
import NumbersReport from '../views/Reports/NumbersReport.vue'
import AreaReports from '../views/Reports/AreaReports.vue'
import CenterReport from '../views/Reports/CenterReport.vue'
import WatchesReport from '../views/Reports/Watches.vue'
import AppFees from '../views/Reports/AppFees.vue'
import OfflineCourses from '../views/Reports/OfflineCourses.vue'
// statistics
import StudentsNumbers from '../views/Statistics/StudentsNumbers.vue'
import RTL from '../views/Rtl.vue'
// import Notifications from '../views/Notifications.vue'
import Profile from '../views/Profile.vue'
import SignIn from '../views/SignIn.vue'

const SubjectRoutes = dynamicRoutes.SubjectRoutes
const TeacherRoutes = dynamicRoutes.TeacherRoutes
const CourseRoutes = dynamicRoutes.CourseRoutes
const StudentsRoutes = dynamicRoutes.StudentsRoutes
const OfferRoutes = dynamicRoutes.OffersRoute
const ReceiptsRoute = dynamicRoutes.ReceiptsRoute
const PaiedRemainingReport = dynamicRoutes.PaiedRemainingReport
const ResrvationReport = dynamicRoutes.ResrvationReport
function generateRoutes () {
  const centerType = localStorage.centerType
  let AttendanceRoutes = ''
  if (centerType === 'english -school') {
    AttendanceRoutes = () => import('../views/SummerAttendance.vue')
  } else {
    AttendanceRoutes = () => import('../views/StudentAttendance.vue')
  }
  const routes = [
    {
      path: '/',
      name: '/',
      redirect: '/dashboard'
    },
    {
      path: '/dashboard',
      name: i18n.global.t('message.home'),
      component: Dashboard,
      meta: {
        title: 'الرئيسية',
        page: 'الرئيسية'
      }
    },
    {
      path: '/students',
      component: {
        render () {
          return h(resolveComponent('router-view'))
        }
      },
      children: StudentsRoutes
    },

    // teachers
    {
      path: '/teachers',
      name: 'المدرسين ',
      component: {
        render () {
          return h(resolveComponent('router-view'))
        }
      },
      children: TeacherRoutes

    },

    // subjects
    {
      path: '/subjects',
      name: 'المواد ',
      component: {
        render () {
          return h(resolveComponent('router-view'))
        }
      },
      children: SubjectRoutes
    },
    // courses
    {
      path: '/courses',
      name: ' الدورات التدريبية ',
      component: {
        render () {
          return h(resolveComponent('router-view'))
        }
      },
      children: CourseRoutes
    },
    // users
    {
      path: '/users',
      name: '  كل المستخدمين ',
      component: {
        render () {
          return h(resolveComponent('router-view'))
        }

      },
      children: [
        {
          path: '',
          name: '  كل المستخدمين ',
          component: Users,
          meta: {
            title: 'المستخدمين'
          }
        },
        {
          path: 'create',
          name: ' إضافة مستخدم ',
          component: AddUser,
          meta: {
            title: 'إضافة مستخدم'
          }
        },
        {
          path: ':id/edit',
          name: ' تعديل المستخدم ',
          component: EditUser,
          meta: {
            title: 'تعديل مستخدم'
          }
        },
        {
          path: ':id/show',
          name: ' عرض المستخدم ',
          component: ShowUser,
          meta: {
            title: 'عرض المستخدم'
          }
        }
      ]
    },
    {
      path: '/user-collections',
      name: 'تحصيلات المستخدم',
      component: UserCollections,
      meta: {
        title: ' تحصيلات المستخدم'
      }
    },

    // notification
    {
      path: '/notifications',
      name: ' الإشعارات',
      component: {
        render () {
          return h(resolveComponent('router-view'))
        }
      },
      meta: {
        title: ' الإشعارات'
      },
      children: [
        {
          path: '',
          name: 'الإشعارات',
          component: Notifications,
          meta: {
            title: ' الإشعارات'
          }
        },
        {
          path: 'create',
          name: ' إضافة اشعار ',
          component: CreateNotification,
          meta: {
            title: 'إضافة اشعار'
          }
        },
        {
          path: ':id/show',
          name: ' تفاصيل الإشعار',
          component: ShowNotification,
          meta: {
            title: ' تفاصيل الإشعار'
          }
        }
      ]
    },
    // system notifications
    {
      path: '/filters-notifications',
      name: '  اشعارات الفلاتر',
      component: SystemNotifications,
      meta: {
        title: ' اشعارات الفلاتر'
      }
    },

    // receipts
    {
      path: '/receipts',
      name: '  كل الإيصالات',
      component: {
        render () {
          return h(resolveComponent('router-view'))
        }
      },
      children: ReceiptsRoute
    },
    {
      path: '/special-receipt',
      name: '  ايصال خاص  ',
      component: SpecialReceipt,
      meta: {
        title: '  ايصال خاص'
      }
    },
    // lectures
    {
      path: '/lectures',
      name: '  المحاضرات ',
      component: {
        render () {
          return h(resolveComponent('router-view'))
        }
      },
      children: [
        {
          path: '/lectures',
          name: 'المحاضرات',
          component: Lectures,
          meta: {
            title: ' المحاضرات'
          }
        },
        {
          path: 'create',
          name: ' إضافة محاضرة ',
          component: CreateLecture,
          meta: {
            title: 'إضافة محاضرة'
          }
        },
        {
          path: ':id/edit',
          name: '   تعديل المحاضرة ',
          component: EditLecture,
          meta: {
            title: ' تعديل المحاضرة'
          }
        },
        {
          path: ':id/show',
          name: '   عرض المحاضرة ',
          component: ShowLecture,
          meta: {
            title: ' عرض المحاضرة'
          }
        }
      ]
    },
    // videos
    {
      path: '/videos',
      name: 'الفيديوهات ',
      component: {
        render () {
          return h(resolveComponent('router-view'))
        }
      },
      children: [
        {
          path: '/videos',
          name: 'الفيديوهات',
          component: Videos,
          meta: {
            title: ' الفيديوهات'
          }
        },
        {
          path: ':id/edit',
          name: 'تعديل الفيديو ',
          component: EditVideo,
          meta: {
            title: ' تعديل الفيديو'
          }
        },
        {
          path: ':id/show',
          name: 'عرض الفيديو ',
          component: ShowVideo,
          meta: {
            title: ' عرض الفيديو'
          }
        }
      ]
    },
    // grades
    {
      path: '/grades',
      name: '  جميع الفرق ',
      component: {
        render () {
          return h(resolveComponent('router-view'))
        }
      },
      children: [
        {
          path: '',
          name: 'جميع الفرق',
          component: Grades,
          meta: {
            title: ' جميع الفرق'
          }
        },
        {
          path: ':id/detail',
          name: '  تفاصيل الفرقة ',
          component: GradeDetail
        },
        {
          path: 'create',
          name: '   إضافة مجموعه ',
          component: AddGroup
        }
      ]
    },
    // rooms
    {
      path: '/rooms',
      name: 'جميع القاعات',
      component: {
        render () {
          return h(resolveComponent('router-view'))
        }
      },
      children: [
        {
          path: '',
          name: 'جميع القاعات',
          component: Rooms,
          meta: {
            title: ' جميع القاعات'
          }
        },
        {
          path: ':id/detail',
          name: 'تفاصيل القاعة',
          component: RoomDetail,
          meta: {
            title: ' تفاصيل القاعة'
          }
        }
      ]
    },
    // offers
    {
      path: '/offers',
      name: '',
      component: {
        render () {
          return h(resolveComponent('router-view'))
        }
      },
      children: OfferRoutes
    },
    // Roles
    {
      path: '/roles',
      name: '',
      component: {
        render () {
          return h(resolveComponent('router-view'))
        }
      },
      children: [
        {
          path: '',
          name: ' الوظائف',
          component: Roles,
          meta: {
            title: ' الوظائف'
          }
        },
        {
          path: 'create',
          name: 'إضافة وظيفة ',
          component: createRole,
          meta: {
            title: 'إضافة وظيفة'
          }
        },
        {
          path: ':id/edit',
          name: '   تعديل الوظيفة ',
          component: editRole,
          meta: {
            title: ' تعديل الوظيفة'
          }
        },
        {
          path: ':id/show',
          name: 'عرض الوظيفة ',
          component: showRole,
          meta: {
            title: ' عرض الوظيفة'
          }
        }
      ]
    },
    // Slider
    {
      path: '/slider',
      name: '',
      component: {
        render () {
          return h(resolveComponent('router-view'))
        }
      },
      children: [
        {
          path: '',
          name: 'الشرائح المتحركة ',
          component: Slider,
          meta: {
            title: 'الشرائح المتحركة'
          }
        },
        {
          path: 'create',
          name: 'إضافة شرائح ',
          component: CreateSlider,
          meta: {
            title: 'إضافة شرائح'
          }
        },
        {
          path: ':id/edit',
          name: '   تعديل الشرائح ',
          component: EditSlider,
          meta: {
            title: ' تعديل الشرائح'
          }
        },
        {
          path: ':id/show',
          name: 'عرض الشرائح ',
          component: ShowSlider,
          meta: {
            title: ' عرض الشرائح'
          }
        }
      ]
    },
    // sales
    {
      path: '/sales',
      name: '   ',
      component: {
        render () {
          return h(resolveComponent('router-view'))
        }
      },
      children: [
        {
          path: '',
          name: '  المبيعات ',
          component: () => import('@/views/Sales/Index.vue'),
          meta: {
            title: '  المبيعات'
          }
        },
        {
          path: 'create',
          name: 'إضافة  ',
          component: () => import('@/views/Sales/Create'),
          meta: {
            title: 'إضافة '
          }
        },
        {
          path: ':id/edit',
          name: '   تعديل  ',
          component: () => import('@/views/Sales/Edit'),
          meta: {
            title: ' تعديل '
          }
        },
        {
          path: ':id/show',
          name: 'عرض  ',
          component: () => import('@/views/Sales/Show'),
          meta: {
            title: ' عرض '
          }
        }
      ]
    },
    // branches
    {
      path: '/branches',
      name: 'جميع الفروع',
      component: {
        render () {
          return h(resolveComponent('router-view'))
        }
      },
      children: [
        {
          path: '',
          name: ' الفروع',
          component: Branches,
          meta: {
            title: ' الفروع'
          }
        },
        {
          path: 'create',
          name: '   إضافة فرع ',
          component: CreateBranch,
          meta: {
            title: 'إضافة فرع'
          }
        },
        {
          path: ':id/edit',
          name: ' تفاصيل الفرع ',
          component: EditBranch,
          meta: {
            title: ' '
          }
        },
        {
          path: ':id/details',
          name: '  تفاصيل الفرع ',
          component: BranchDetails,
          meta: {
            title: ' تفاصيل الفرع'
          }
        }

      ]
    },
    // reports
    {
      path: '/reports',
      name: 'التقارير',
      component: {
        render () {
          return h(resolveComponent('router-view'))
        }
      },
      children: [
        {
          path: 'instructors',
          name: 'تقارير المدرس',
          component: InstructorReport,
          meta: {
            title: ' تقارير المدرس'
          }
        },
        {
          path: 'instructor-collections',
          name: '  اجمالي تحصيلات المدرس',
          component: InstructorCollections,
          meta: {
            title: ' اجمالي تحصيلات المدرس'
          }
        },
        {
          path: 'collections',
          name: 'تقارير التحصيلات',
          component: DailyCollections,
          meta: {
            title: ' تقارير التحصيلات'
          }
        },
        {
          path: 'student-attendance',
          name: 'تقارير حضور الطالب',
          component: StudentAttendanceReport,
          meta: {
            title: ' تقارير حضور الطالب'
          }
        },
        {
          path: 'course-students',
          name: 'تقارير الطلاب طبقا للدورة',
          component: CourseStudents,
          meta: {
            title: ' تقارير الطلاب طبقا للدورة'
          }
        },
        {
          path: 'subjects-payments',
          name: 'تقارير   مدفوعات المواد',
          component: SubjectsPayments,
          meta: {
            title: ' تقارير   مدفوعات المواد'
          }
        },
        {
          path: 'numbers-report',
          name: 'تقارير  الأعداد',
          component: NumbersReport,
          meta: {
            title: ' تقارير  الأعداد'
          }
        },
        {
          path: 'overall-income',
          name: '  تقرير دخل السنتر',
          component: OverallIncome,
          meta: {
            title: ' تقرير دخل السنتر'
          }
        },
        {
          path: 'calls-report',
          name: 'تقارير المكالمات',
          component: CallsReport,
          meta: {
            title: ' تقارير المكالمات'
          }
        },
        {
          path: 'wallet-report',
          name: 'تقارير المحفظة',
          component: () => import('../views/Reports/WalletReport'),
          meta: {
            title: ' تقارير المحفظة'
          }
        },
        PaiedRemainingReport,
        ResrvationReport,
        {
          path: 'watches-report',
          name: 'تقارير   المشاهدات ',
          component: WatchesReport,
          meta: {
            title: ' تقارير المشاهدات'
          }
        },
        {
          path: 'sales-report',
          name: 'تقارير   المبيعات ',
          component: () => import('@/views/Reports/SalesReport'),
          meta: {
            title: ' تقارير المبيعات'
          }
        },
        {
          path: 'area-report',
          name: 'تقارير   المناطق ',
          component: AreaReports,
          meta: {
            title: ' تقارير المناطق'
          }
        },
        {
          path: 'collections-report',
          name: 'تقارير   التحصيلات ',
          component: CollectionsReport,
          meta: {
            title: ' تقارير التحصيلات'
          }
        },
        {
          path: 'center-report',
          name: 'تقارير   الالتحاق بالمركز ',
          component: CenterReport,
          meta: {
            title: ' تقارير الالتحاق بالمركز'
          }
        },
        {
          path: 'app-fees',
          name: 'تقارير  رسوم التطبيق ',
          component: AppFees,
          meta: {
            title: ' تقارير  رسوم التطبيق'
          }
        },
        {
          path: 'offline-courses',
          name: 'تقارير   الدورات الأوفلاين ',
          component: OfflineCourses,
          meta: {
            title: ' تقارير  الدورات الأوفلاين'
          }
        },
        {
          path: 'placement-test',
          name: i18n.global.t('message.placement'),
          component: () => import('../views/Reports/SummerCourses/Placement.vue'),
          meta: {
            title: i18n.global.t('message.placement')
          }
        },
        {
          path: 'users-sales',
          name: i18n.global.t('message.userSales'),
          component: () => import('../views/Reports/SummerCourses/UsersSales.vue'),
          meta: {
            title: i18n.global.t('message.userSales')
          }
        },
        {
          path: 'persistance-average',
          name: i18n.global.t('message.persistanceAverage'),
          component: () => import('../views/Reports/SummerCourses/persistanceAverage.vue'),
          meta: {
            title: i18n.global.t('message.persistanceAverage')
          }
        },
        {
          path: 'levels-students',
          name: i18n.global.t('message.levelStudents'),
          component: () => import('../views/Reports/SummerCourses/LevelsStudents.vue'),
          meta: {
            title: i18n.global.t('message.levelStudents')
          }
        },
        {
          path: 'students-count',
          name: i18n.global.t('message.studentsCount'),
          component: () => import('../views/Reports/SummerCourses/StudentsCount.vue'),
          meta: {
            title: i18n.global.t('message.studentsCount')
          }
        }

      ]
    },
    // statistics
    {
      path: '/statistics',
      name: 'الإحصائيات',
      component: {
        render () {
          return h(resolveComponent('router-view'))
        }
      },
      children: [
        {
          path: 'students-numbers',
          name: '  احصائيات اعداد الطلاب',
          component: StudentsNumbers,
          meta: {
            title: ' اعداد الطلاب'
          }
        }

      ]
    },
    {
      path: '/attendance',
      name: 'الحضور',
      component: Attendance
    },
    {
      path: '/students-attendance',
      name: 'حضور الطلاب',
      component: AttendanceRoutes,
      meta: {
        title: ' حضور الطلاب'
      }
    },
    {
      path: '/multiple-attendance',
      name: ' حضور   متعدد ',
      component: NewAttendance,
      meta: {
        title: ' حضور   متعدد'
      }
    },
    {
      path: '/add-attendance',
      name: i18n.global.t('message.addAttendance'),
      component: () => import('../views/InsAttendance.vue'),
      meta: {
        title: i18n.global.t('message.addAttendance')
      }
    },
    // setting
    {
      path: '/setting',
      name: ' الإعدادات',
      component: Setting,
      meta: {
        title: ' الإعدادات'
      }
    },

    {
      path: '/rtl-page',
      name: 'RTL',
      component: RTL
    },
    {
      path: '/videos/:id/edit-video',
      name: 'تعديل بيانات الفيديو',
      component: () => import('../views/Courses/ByGrade/EditVideo.vue'),
      meta: {
        title: ' تعديل بيانات الفيديو'
      }
    },
    {
      path: '/profile',
      name: 'Profile',
      component: Profile
    },
    {
      path: '/login',
      name: 'SignIn',
      component: SignIn,
      meta: {
        title: ' تسجيل الدخول'
      }
    }
  ]
  return routes
}

const router = createRouter({
  mode: 'hash',
  history: createWebHashHistory(),
  routes: generateRoutes(),
  linkActiveClass: 'active'
})

export default router
