<template>
    <vue-final-modal
      :clickToClose="true"
      class='cancel-offer call-modal'
      name="example"
      v-model="content"
      classes="modal-container"
      content-class="modal-content"
    >
      <div class="card my-4">
        <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
          <div
            class="bg-gradient-success shadow-success border-radius-lg pt-4 pb-3"
          >
            <h4 class="text-white text-capitalize ps-3 call-details">      {{$t('message.addCall')}} : {{studentInfo.name}} </h4>
          </div>
        </div>
        <div class="card-body px-0 pb-2">
                   <form @submit="createCall" role="form" class="text-start mt-3">
                <div class='row'>
                      <div class="mb-3 col-lg-6">
                    <label>  {{$t('message.feedbackType')}} <span class="red">*</span>
                    <span color='danger' class="error">{{}}</span>
                    </label>
                      <multiselect
                        class='required form-control'
                        id='grade'
                        v-model=" selectedFeedback"
                        :options='feedbackCategories'
                        :searchable='true'
                        :close-on-select='true'
                        :placeholder="$t('message.select')"
                        label='name'
                        :custom-label="customLabel"
                        track-by='id'
                        required='required'
                        :internal-search="true"
                        >
                      >
                        <template v-slot:noOptions>
                          <span> فارغة </span>
                        </template>
                        <template v-slot:noResult>
                          <span>لا يوجد نتيجة </span>
                        </template>
                      </multiselect>
                </div>
                    <div class="mb-3 col-lg-6">
                    <label>  {{$t('message.nextCall')}} <span class="red">*</span>
                    <span color='danger' class="error">{{}}</span>
                    </label>
                      <vmd-input
                    id=""
                    type="datetime-local"
                    v-model='student.nextCall'
                  />
                </div>
                </div>
                <div class="row">
                   <div class="mb-3 col-lg-6">
                    <label > {{$t('message.feedback')}}<span class="red">*</span>
                      <span color='danger' class="error">{{errors.feedback?errors.feedback[0]:''}}</span></label>
                      <div class="input-group">
                 <textarea class="form-control message"
                 rows="2"
                 cols="100"
                 :placeholder="$t('message.feedback')"
                 v-model='student.feedback'
                  >
                 </textarea>
                  </div>
                </div>
                <div class="col-lg-6">
                  <label class='unrequired'>   {{$t('message.rating')}}<span class="green">({{$t('message.optional')}})</span>
                      <span color='danger' class="error">{{errors.rating?errors.rating[0]:''}}</span></label><br>
                        <div class="rating">
          <span v-for="(n, index) in 10" :key="index + n" @click="rate(n)">
            <i v-if="n <= rateNum" class="fas fa-star"></i>
            <i v-else class="far fa-star"></i>
          </span>
        </div>
                </div>
                       </div>
                       <div class="row">
                <div class="text-center col-6">
                  <vmd-button
                    class="my-4 mb-2"
                    variant="gradient"
                    color="info"
                    > {{$t('message.save')}}</vmd-button
                  >
                </div>
                 <div class="text-center col-6">
                  <vmd-button
                  type='reset'
                    class="my-4 mb-2"
                    variant="gradient"
                    color="danger"
                    > {{$t('message.delete')}}</vmd-button
                  >
                </div>
                   </div>
              </form>
              <div v-if="!hideDetails">
                <div style="margin-top:20px !important;margin-bottom: 10px;" class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
          <div
            class="bg-gradient-success shadow-success border-radius-lg last-call-con"
          >
            <h4 class="text-white text-capitalize ps-3 call-details">    تفاصيل  المكالمات السابقة </h4>
          </div>
        </div>
        <div class="overflow-x">
          <table class="table ">
      <thead>
        <tr>
          <th>نوع الفيدباك</th>
          <th> فيدباك</th>
          <th> التقييم</th>
          <th>تاريخ الإنشاء</th>
        </tr>
      </thead>
      <tbody >
        <tr v-for="call in calls" :key="call.id">
          <td style="white-space:normal; width:26%" class="wrap-text">
              <span class="course-name">{{ call.feedback_category? call.feedback_category.name: null}}</span>
          </td>
          <td style="white-space:normal; width:34%" class="wrap-text">
             <span class="course-name">{{ call.feedback}}</span>
          </td>
           <td>
             <span class="course-name">{{ call.rating}}</span>
          </td>
            <td>
             <span class="course-name">{{ call.created_at}}</span>
          </td>
        </tr>
      </tbody>
    </table>
    </div>
        </div>
        </div>
        <div class="card-footer text-center call-footer">
          <button
            id="unroll"
            style="width:unset !important"
            @click="$emit('hide-modal')"
            class="btn bg-gradient-danger  col-4"
            >{{$t('message.close')}} </button>
        </div>
      </div>

    </vue-final-modal>
    </template>
<script>
import { VueFinalModal } from 'vue-final-modal'
import $ from 'jquery'
import VmdInput from '@/components/VmdInput.vue'
import VmdButton from '@/components/VmdButton.vue'
export default {
  name: 'CreateCall',
  components: {
    VueFinalModal,
    VmdInput,
    VmdButton
  },
  data () {
    return {
      student: {},
      feedbackCategories: [],
      selectedFeedback: '',
      rateNum: 0,
      errors: []
    }
  },
  props: {
    modelValue: String,
    calls: {
      type: Array
    },
    studentInfo: {
      type: Object
    },
    hideDetails: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:modelValue'],
  created () {
    const feedbackCategories = []
    this.$http.get(`${this.$hostUrl}feedback-categories`)
      .then((response) => {
        $.each(response.data.data, function (key, value) {
          feedbackCategories.push({
            name: value.name,
            id: value.id
          })
        })
        this.feedbackCategories = feedbackCategories
      })
  },
  computed: {
    content: {
      get () {
        return this.modelValue
      },
      set (val) {
        this.$emit('update:modelValue', val)
      }
    }
  },
  methods: {
    rate (n) {
      this.rateNum = n
    },
    async createCall (e) {
      e.preventDefault()
      const formData = new FormData()
      formData.append('student_id', this.studentInfo.id)
      if (this.student.feedback) {
        formData.append('feedback', this.student.feedback)
      }
      formData.append('feedback_category_id', this.selectedFeedback.id)
      if (this.rateNum) {
        formData.append('rating', this.rateNum)
      }
      const sendCall = this.student.nextCall.replace('T', ' ')
      formData.append('next_call', sendCall)
      this.errors = await this.postRequest(formData, this.$hostUrl + 'calls', '')
      this.$emit('hide-modal')
      this.$emit('update-data')
      this.student.feedback = ''
      this.selectedFeedback = ''
      this.student.nextCall = ''
      this.rateNum = 0
    }
  }

}
</script>
<style>
.call-details{
font-size: 16px !important;
}
.last-call-con{
  padding-bottom: 0.2rem!important;
  padding-top: 0.7rem!important;
}
.call-footer{
  padding:unset !important
}
.message{
  padding:10px !important;
  border: 1px solid #d2d6da !important;
}
.rating {
 font-size: 23px;
  cursor: pointer;
  width: 100%;
  display: flex;
  gap: 1px;
}

.fas.fa-star {
  color: gold;
}

.far.fa-star {
  color: #ddd;
}
.call-modal .modal-content{
    margin: auto;
    z-index: 99999;
    width: 50% !important;
    position: absolute;
    top: 50%;
    left:50%;
    transform: translate(-60%,-50%);
    max-height: 700px;
    overflow-y: scroll;
}
</style>
