<template>
  <aside
    id="sidenav-main"
    class="sidenav navbar navbar-vertical navbar-expand-xs border-0 border-radius-xl my-3 ms-3"
    :class="`${
      isRTL ? 'me-3 rotate-caret fixed-end' : 'fixed-start ms-3'
    } ${sidebarType}`"
  >
    <div class="sidenav-header">
      <i
        class="top-0 p-3 cursor-pointer fas fa-times text-secondary opacity-5 position-absolute end-0 d-none d-xl-none"
        aria-hidden="true"
        id="iconSidenav"
      ></i>
      <a class="m-0 navbar-brand text-center" href="/">
        <!-- <img
          :src="
            sidebarType === 'bg-white' ||
            (sidebarType === 'bg-transparent' && !isDarkMode)
              ? logoDark
              : logo
          "
          class="navbar-brand-img h-100"
          alt="main_logo"
        /> -->
        <span  class=" font-weight-bold text-white seven brand-con"
          ><img class="brand-img" src="@/assets/img/savior-logo.jpg"/></span
        ><br>
        <span class="sub-brand">{{centerName?centerName:''}}</span>
      </a>
    </div>
    <hr class="horizontal mr-top light  mb-2" />
    <sidenav-list />
  </aside>
</template>
<script>
import SidenavList from './SidenavList.vue'
import logo from '@/assets/img/logo-ct.png'
import logoDark from '@/assets/img/logo-ct-dark.png'
import { mapState } from 'vuex'

export default {
  name: 'index',
  components: {
    SidenavList
  },
  data () {
    return {
      logo,
      logoDark,
      centerName: localStorage.centerName
    }
  },
  computed: {
    ...mapState(['isRTL', 'sidebarType', 'isDarkMode'])
  }
}
</script>
<style >
.navbar-brand span{
  line-height: 24px;
      font-size: 37px;
    font-weight: bold;
}
.sub-brand{
      color: #b9f0bc;
    font-size: 16px !important;
    font-family: Arial, Helvetica, sans-serif !important;
}
.mr-top {
  margin-top:10px !important
}
.brand-con{
  display: flex;
  justify-content: center;
}
.brand-img{
  width: 100% !important;
    height: 115px !important;
    margin-bottom: 10px;
}
.sub-brand{
    position: absolute;
    top: 148px;
    left: 26%;
   color: #e5c9c8
}
</style>
