<template>
  <div class="container-fluid py-4 add-student grade-detail">
    <div class="row">
      <div class="col-12">
        <div class="card my-4">
          <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
            <div
              class="bg-gradient-success shadow-success border-radius-lg pt-4 pb-3"
            >
              <div class="row">
                <div class="col-lg-6">
                  <h6 class="text-white text-capitalize ps-3">الحضور</h6>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body px-0 pb-2">
            <div class="row align-center">
              <div class="mb-3 col-lg-4">
                <label> ادخل رقم الطالب <span class="red">*</span></label>
                <vmd-input
                  id="student-number"
                  type="text"
                  label="  رقم الطالب"
                  v-model="student.number"
                  @input="search"
                  required
                />
              </div>
              <div class="mb-3 col-lg-4">
                <label> اسم الطالب </label>
                <vmd-input
                  id="student-name"
                  type="text"
                  v-model="student.full_name"
                  disabled
                />
              </div>
              <div class="mb-3 col-lg-4">
                <label>الفرقة </label>
                <vmd-input
                  id="student-grade"
                  type="text"
                  v-model="student.grade_name"
                  disabled
                />
              </div>
            </div>
            <div class="row align-center">
              <h5 id="data">
                محاضرات اليوم
                <input
                  type="text"
                  class="form-control search"
                  v-model="searchLecture"
                  placeholder="بحث باسم الكورس"
                />
              </h5>
              <div class="text-center col-lg-12">
                <table class="table b-block">
                  <thead>
                    <tr>
                      <th>رقم المحاضرة</th>
                      <th>الكورس</th>
                      <th>الفرقة</th>
                      <th colspan="2">تسجيل حضور</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(lecture, index) in activeLecturesFiltered"
                      :key="index"
                    >
                      <td>
                        {{ lecture.lecture_number }}
                      </td>
                      <td>
                        {{ lecture.courseName }}
                      </td>
                      <td>
                        {{ lecture.grade }}
                      </td>
                      <td>
                        <button
                          class="btn btn-primary"
                          @click="checkRegistration(lecture, lecture.course, 0)"
                        >
                          إيصال حضور
                        </button>
                      </td>
                      <td>
                        <button
                          class="btn btn-warning"
                          @click="checkRegistration(lecture, lecture.course, 1)"
                        >
                          حضور ودفع
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <vue-final-modal
      v-model="showModal"
      name="example"
      classes="modal-container"
      content-class="modal-content"
    >
      <div class="row">
        <div class="col-12">
          <div class="card my-4">
            <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
              <div
                class="bg-gradient-success shadow-success border-radius-lg pt-4 pb-3"
              >
                <h6 class="text-white text-capitalize ps-3">إضافة إيصال</h6>
              </div>
            </div>
            <div class="card-body px-0 pb-2">
              <form @submit="createReceipt" role="form" class="text-start mt-3">
                <div class="row">
                  <div class="mb-3 col-lg-6">
                    <label>
                      نوع الحركة
                      <span color="danger" class="red">*</span></label
                    >
                    <div class="input-group input-group-outline null">
                      <select class="form-control" v-model="selectedType">
                        <option value="205e848e-fd18-4108-a70d-fc4038bbf36b">
                          نقدا
                        </option>
                        <option value="5291a8ed-2fc5-4a8b-90c7-9becd86dfbf6">
                          محفظة
                        </option>
                      </select>
                      <i class="fas fa-caret-down" aria-hidden="true"></i>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="mb-3 col-lg-6">
                    <label>
                      اسم الطالب
                      <span color="danger" class="red">*</span></label
                    >
                    <div class="input-group input-group-outline null">
                      <vmd-input
                        id="receipt-student"
                        type="text"
                        disabled
                        v-model="student.full_name"
                      />
                    </div>
                  </div>
                  <div class="mb-3 col-lg-6">
                    <label>
                      اسم الدورة
                      <span color="danger" class="red">*</span>
                    </label>
                    <div class="input-group input-group-outline null">
                      <vmd-input
                        id="receipt-course"
                        type="text"
                        disabled
                        v-model="course.name"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="mb-3 col-lg-6">
                    <label>
                      القيمة <span color="danger" class="red">*</span></label
                    >
                    <vmd-input
                      id="receipt-amount"
                      type="number"
                      label="القيمة"
                      v-model="receipt.amount"
                    />
                  </div>
                  <div class="mb-3 col-lg-6">
                    <label>
                      البيان <span color="danger" class="red">*</span>
                    </label>
                    <vmd-input
                      id="receipt-description"
                      type="text"
                      v-model="receipt.description"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="text-center col-lg-6">
                    <vmd-button
                      class="my-4 mb-2"
                      variant="gradient"
                      color="info"
                      @click="showModal = false"
                    >
                      حفظ</vmd-button
                    >
                  </div>
                  <div class="text-center col-lg-6">
                    <vmd-button
                      type="reset"
                      class="my-4 mb-2"
                      variant="gradient"
                      color="danger"
                      @click="showModal = false"
                    >
                      إلغاء
                    </vmd-button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </vue-final-modal>
   <div>
      <PrintReceipt :receipt="receipt"></PrintReceipt>
    </div>
  </div>
</template>
<script>
import VmdInput from '@/components/VmdInput.vue'
import swal from 'sweetalert'
import $ from 'jquery'
import { VueFinalModal } from 'vue-final-modal'
import VmdButton from '@/components/VmdButton.vue'
import PrintReceipt from '@/components/PrintReceipt.vue'
const date = new Date()
const today =
  date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate()
export default {
  name: 'Attendance',
  components: {
    PrintReceipt,
    VmdInput,
    VmdButton,
    VueFinalModal
  },
  computed: {
    activeLecturesFiltered () {
      return this.activeLectures.filter((item) => {
        return item.courseName.indexOf(this.searchLecture.toLowerCase()) > -1
      })
    }
  },
  data () {
    return {
      student: {},
      activeLectures: [],
      searchLecture: '',
      selectedLecture: '',
      attend: 0,
      course: {},
      receiptTypes: [],
      receipt: {
        number: null,
        amount: '',
        title: 'حضور',
        description: 'سداد',
        userName: localStorage.userName,
        branchName: localStorage.branchName
      },
      selectedType: '205e848e-fd18-4108-a70d-fc4038bbf36b',
      receiptTitle: '',
      showModal: false
    }
  },
  created () {
    const activeLectures = []
    this.$http
      .get(`${this.$hostUrl}active-lectures?date=${today}`)
      .then((response) => {
        $.each(response.data.data, function (key, val) {
          activeLectures.push({
            lecture_number: val.lecture_number,
            courseName: val.course_name,
            course: {
              id: val.course.id,
              name: val.course_name
            },
            grade: val.grade,
            id: val.id
          })
        })
        this.activeLectures = activeLectures
      })
    this.receiptTypes = [
      {
        name: 'نقدا',
        id: '205e848e-fd18-4108-a70d-fc4038bbf36b'
      },
      {
        name: 'محفظة',
        id: '5291a8ed-2fc5-4a8b-90c7-9becd86dfbf6'
      }
    ]
  },
  methods: {
    openModal () {
      this.showModal = true
    },
    search () {
      this.student.id = null
      this.student.full_name = null
      this.student.grade_name = null
      this.receipt = {
        amount: '',
        title: 'حضور',
        description: 'سداد',
        userName: localStorage.userName,
        branchName: localStorage.branchName
      }
      this.$http
        .get(`${this.$hostUrl}students-list?search=${this.student.number}`)
        .then((response) => {
          if (response.data.data.length === 1) {
            this.student.full_name = response.data.data[0].full_name
            this.student.mobile = response.data.data[0].mobile
            this.student.id = response.data.data[0].id
            this.student.grade_name = response.data.data[0].grade_name
          } else {
            this.student.id = null
            this.student.full_name = null
            this.student.mobile = null
            this.student.grade_name = null
          }
        })
    },
    checkRegistration (lecture, course, withPayment) {
      console.log(this.student.id)
      const current = this
      this.course = course
      this.selectedLecture = lecture
      if (!this.student.id) {
        swal('الرجاء ادخال رقم هاتف صحيح للطالب لطلب إيصال الحضور')
        return false
      }
      this.$http
        .get(`${this.$hostUrl}enrolled/${lecture.id}/${this.student.id}`)
        .then((response) => {
          console.log(response.data.data)
          if (!response.data.data.enrolled) {
            current.receipt.title = 'حضور استثنائي'
            current.receipt.description = 'حضور استثنائي'
            if (withPayment) {
              swal('الطالب غير مسجل في الكورس لايمكن تسجيل دفعيه له')
            }
          } else if (withPayment) {
            current.showModal = true
          }
          current.attendance(response.data.data.enrolled)
          setTimeout(
            this.$htmlToPaper(
              'print-receipt',
              `@media print {
                size: 5.5in 8.5in;
              }
              body {
                font-size: 10px;
              }
              `
            ),
            10000
          )
        })
    },
    async createReceipt (e) {
      e.preventDefault()
      const formData = new FormData()
      formData.append('payable_id', this.course.id)
      formData.append('student_id', this.student.id)
      formData.append('receipt_type_id', this.selectedType)
      formData.append(
        'transaction_type_id',
        '2a187fd8-574f-4260-8e18-cd75ccea891f'
      )
      formData.append('amount', this.receipt.amount)
      if (this.receipt.description) {
        formData.append('description', this.receipt.description)
      }
      formData.append('attend', 0)
      this.errors = await this.postRequest(
        formData,
        this.$hostUrl + 'receipts',
        null
      )
      if (!this.errors) {
        setTimeout(
          this.$htmlToPaper(
            'print-receipt',
            `@media print {
                size: 5.5in 8.5in;
              }
              body {
                font-size: 10px;
              }
              `
          ),
          10000
        )
      }
    },
    async attendance (enrolled) {
      const current = this
      const formData = new FormData()
      if (!this.selectedLecture) {
        swal('يرجي اختيار المحاضرة')
      } else {
        const errors = this.errors
        formData.append('attend', 1)
        formData.append('exceptional', enrolled ? 0 : 1)
        await this.$http
          .post(
            `${this.$hostUrl}attendance/add/${this.selectedLecture.id}/${this.student.id}`,
            formData
          )
          .then((response) => {
            console.log(response.data)
            if (!response.data.error) {
              current.receipt = response.data.data
              console.log(response.data.data)
            } else {
              swal({ title: response.data.message, buttons: 'تم' })
            }
          })
          .catch(function (error) {
            if (Object.prototype.hasOwnProperty.call(error, 'response')) {
              const data = (error.response || {}).data
              if (data.error) {
                swal({
                  title: data.message,
                  buttons: 'تم'
                })
                console.log(data.message)
                if (data.message === 'الطالب غير مُسجَّل') {
                  $('.showAttend').addClass('block')
                }
                return data.data
              } else {
                swal({
                  title: 'حدث خطـأ اثناء معالجة الطلب الرجاء المحاولة لاحقا',
                  buttons: 'تم'
                })
              }
            } else {
              swal({
                title: 'حدث خطـأ اثناء معالجة الطلب الرجاء المحاولة لاحقا',
                buttons: 'تم'
              })
            }
          })
        return errors
      }
    }
  }
}
</script>
<style>
#data {
  background: #55ae59;
  color: #fff;
  padding: 13px;
  border-radius: 8px;
  margin: 30px 0px;
}
.showAttend {
  display: none !important;
}
.block {
  display: block !important;
}
/* .attend {
  margin: 0 !important;
} */
</style>
<style scoped>
.search {
  background-color: #fff;
  display: inline;
  width: auto;
  float: left;
  padding: 7px;
}
.search:focus {
  background-color: #fff;
}
:deep(.modal-container) {
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal__title {
  font-size: 1.5rem;
  font-weight: 700;
}
:deep(.modal-content) {
  width: 50% !important;
}
</style>

<style scoped>
.dark-mode div:deep(.modal-content) {
  border-color: #2d3748;
  background-color: #1a202c;
}
</style>
