<template>
  <div class="form-check p-0">
    <input
      :id="id"
      class="form-check-input"
      type="checkbox"
      :name="name"
      v-model="content"
      :checked="checked"
    />
    <label :for="id" class="custom-control-label">
      <slot />
    </label>
  </div>
</template>

<script>
export default {
  name: 'VmdCheckbox',
  props: {
    modelValue: String,
    name: {
      type: String,
      default: ''
    },
    id: {
      type: String,
      default: ''
    },
    checked: Boolean
  },
  data () {
    // return {
    //   content: this.checked
    // }
  },
  emits: ['update:modelValue'],
  computed: {
    content: {
      get () {
        return this.modelValue
      },
      set (val) {
        this.$emit('update:modelValue', val)
      }
    }
  },
  methods: {
    // handleInput (e) {
    //   this.$emit('update:modelValue', this.content)
    //   console.log('input content', this.content)
    // }
  }
}
</script>
