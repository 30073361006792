<template>
     <div class="container-fluid py-4 add-student grade-detail reserve">
    <div class="row">
      <div class="col-12">
        <div class="card my-4">
          <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
            <div
              class="bg-gradient-success shadow-success border-radius-lg pt-4 pb-3"
            >
             <div class="row">
              <div class="col-lg-6">
              <h6 class="text-white text-capitalize ps-3">تغيير الفرقة</h6>
              </div>
              </div>
            </div>
          </div>
          <div class="card-body px-0 pb-2">
            <div class="row align-center">
              <div class="mb-3 col-lg-3">
                      <label>   الطلاب :</label>
                   <div class="input-group input-group-outline null">
                  <select class="form-control">
                    <option>جميع الطلاب</option>
                    <option>الفرقة الأولي</option>
                    <option> الفرقة الثانية</option>
                    <option>  الفرقة الثالثة</option>
                    <option> الفرقة الرابعه</option>
                  </select>
                   <i class="fas fa-caret-down" aria-hidden="true"></i>
                  </div>
              </div>
               <div class="mb-3 col-lg-3">
                      <label>   المجموعات :</label>
                   <div class="input-group input-group-outline null">
                  <select class="form-control">
                    <option>جميع المجموعات</option>
                    <option>المجموعة 1</option>
                    <option>المجموعة 2</option>
                    <option>المجموعة 3</option>
                   <option>المجموعة 4</option>
                  </select>
                   <i class="fas fa-caret-down" aria-hidden="true"></i>
                  </div>
              </div>
             <div class="mb-3 col-lg-3">
                      <label class="change">   التحويل لفرقة أخري :</label>
                   <div class="input-group input-group-outline null">
                  <select class="form-control">
                    <option> اختر الفرقة</option>
                    <option>الفرقة الأولي</option>
                    <option> الفرقة الثانية</option>
                    <option>  الفرقة الثالثة</option>
                    <option> الفرقة الرابعه</option>
                  </select>
                   <i class="fas fa-caret-down" aria-hidden="true"></i>
                  </div>
              </div>
              <div class="mb-3 col-lg-3">
                  <label>  المجموعة</label>
                   <div class="input-group input-group-outline null">
                  <select class="form-control">
                    <option> اختر المجموعة</option>
                    <option>المجموعة 1</option>
                     <option>المجموعة 2</option>
                    <option>المجموعة 3</option>
                   <option>المجموعة 4</option>
                  </select>
                   <i class="fas fa-caret-down" aria-hidden="true"></i>
                  </div>
              </div>
                </div>
            <div class="row">
            <vdtnet-table class=" table-striped table table-borderless "
            :jquery="jq"
            ref="table"
            :fields="fields"
            :opts="options"
            @edit="doAlertEdit"
            @register="doAlertRegister"
            @delete="doAlertDelete"
            @reloaded="doAfterReload"
            @table-creating="doCreating"
            @table-created="doCreated"
          >
          </vdtnet-table>
          </div>
          </div>
        </div>
      </div>
    </div>
     </div>
</template>
<script>
import VdtnetTable from 'vue-datatables-net'
import $ from 'jquery'
// import VmdButton from "@/components/VmdButton.vue";
export default {
  name: 'ChangeGrade',
  components: {
    // VmdButton,
    VdtnetTable
  },
  data () {
    return {
      pagination: {
        rowsPerPage: 30
      },
      jq: $,
      options: {
        ajax: {
          beforeSend: function (xhr) {
            xhr.setRequestHeader(
              'Authorization',
              'Bearer ' + localStorage.token
            )
          },
          url: 'https://jsonplaceholder.typicode.com/users',
          dataSrc: (json) => {
            return json
          }
        },
        buttons: [
          {
            extend: 'print',
            text: 'طباعة',
            titleAttr: 'print'
          },
          {
            extend: 'copy',
            text: 'نسخ',
            titleAttr: 'copy'
          },
          {
            extend: 'csv',
            text: 'إكسل',
            titleAttr: 'CSV'
          }
        ],
        dom:
          "<'row'<'col-sm-12'tr>>" +
          "<'row'<'col-sm-12 col-md-6'p><'col-sm-12 col-md-6'i>>",
        paging: true,
        language: {
          url: 'https://cdn.datatables.net/plug-ins/1.10.24/i18n/Arabic.json'
        },
        responsive: false,
        processing: true,
        searching: false,
        searchDelay: 1500,
        destroy: true,
        ordering: true,
        lengthChange: true,
        serverSide: false,
        fixedHeader: true,
        saveState: true,
        select: {
          style: 'multi'
        }
      },
      fields: {
        name: {
          label: 'إسم الطالب',
          sortable: true,
          searchable: true,
          defaultOrder: 'desc',
          defaultcontent: 'hello'
        }
      }
    }
  }
}
</script>
