<template>
  <div class="container-fluid py-4 add-student add-course">
    <div class="row">
      <div class="col-12">
        <div class="card my-4">
          <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
            <div
              class="bg-gradient-success shadow-success border-radius-lg pt-4 pb-3"
            >
              <h6 class="text-white text-capitalize ps-3">
               تعديل بيانات الدورة
              </h6>
            </div>
          </div>
          <div class="card-body px-0 pb-2">
            <form @submit="createCourse" role="form" class="text-start mt-3">
              <div class="row">
                <div class="mb-3 col-lg-6">
                  <label
                    >اسم الدورة<span class="red">*</span>
                    <span color="danger" class="error">{{
                      errors.name ? errors.name[0] : ''
                    }}</span></label
                  >
                  <vmd-input
                    id=""
                    type="text"
                    placeholder="اسم الدورة"
                    v-model="course.name"
                  />
                </div>
                <div class="mb-3 col-lg-6">
                   <label>
                    اسم الدورة بالإنجليزية<span class="green">(اختياري) </span>
                    <span color="danger" class="error">{{
                      errors.name_en ? errors.name_en[0] : ''
                    }}</span></label
                  >
                  <vmd-input
                    id=""
                    type="text"
                    placeholder="
                    الاسم  بالإنجليزي"
                    v-model="course.name_en"
                  />
                </div>
              </div>
              <div class='row'>
                <div class="mb-3 col-lg-6 text-center" v-if="course.photo_url">
                  <label for='image' class="required">
                     صورة الدورة
                  </label>
                  <img :src='course.photo_url'  width="200px" height="200px">
                  </div>
                   <div class="mb-3 col-lg-6" >
                  <label>
                    {{course.photo_url?'تغيير الصورة':'صورة الدورة'}} <span color="danger" class="error">{{
                      errors.photo ? errors.photo[0] : ''
                    }}</span></label>
                  <vmd-input
                    id="image"
                    type="file"
                    @change='onFileChange'
                  />
                </div>
              </div>
              <div class='row'>
                  <h6 class="bg-gradient-danger grade-filter">يجب اختيار الجامعة ثم الكلية لظهور المادة المناسبة</h6>
                </div>
              <div class="row">
                <div class="mb-3 col-lg-6">
                  <label>
                    السنة الدراسية<span class="red">*</span>
                    <span color="danger" class="error">{{
                      errors.academic_year_id ? errors.academic_year_id[0] : ''
                    }}</span></label
                  >
                  <div class="input-group input-group-outline null">
                    <select class="form-control" v-model="SelectedYear.id">
                      <option selected value="">اختر السنة الدراسية</option>
                      <option
                        v-for="year in academicYear"
                        :key="year.id"
                        :value="year.id"
                      >
                        {{ year.name }}
                      </option>
                    </select>
                    <i class="fas fa-caret-down" aria-hidden="true"></i>
                  </div>
                </div>
                <div class="mb-3 col-lg-6">
                  <label> الجامعة <span class="red">*</span>
                  <span color='danger' class="error">{{errors.university_id?errors.university_id[0]:''}}</span></label>
                  <multiselect
                    class='required form-control'
                    id='grade'
                    v-model="selectedUniversity"
                    :options='universities'
                    placeholder="اختر الجامعة"
                    label='name'
                    @select="getFaculties"
                    track-by='id'
                    required='required'
                  >
                    <template v-slot:noOptions>
                      <span> فارغة </span>
                    </template>
                    <template v-slot:noResult>
                      <span>لا يوجد نتيجة </span>
                    </template>
                  </multiselect>
                </div>
              </div>
              <div class="row">
                <div class="mb-3 col-lg-6">
                  <label> الكلية <span class="red">*</span>
                   <span color='danger' class="error">{{errors.faculty_id?errors.faculty_id[0]:''}}</span></label>
                      <multiselect
                        class='required form-control'
                        id='grade'
                        v-model=" selectedFaculty"
                        :options='faculties'
                        :searchable='true'
                        :close-on-select='true'
                        placeholder="اختر الكلية"
                        label='name'
                        track-by='id'
                        @select="getSubjects"
                        required='required'
                        :internal-search="true"
                        >
                        <template v-slot:noOptions>
                          <span> فارغة </span>
                        </template>
                        <template v-slot:noResult>
                          <span>لا يوجد نتيجة </span>
                        </template>
                      </multiselect>
                </div>
                <div class="mb-3 col-lg-6">
                  <label>
                    المواد <span class="red">*</span>
                    <span color="danger" class="error">{{
                      errors.subject_id ? errors.subject_id[0] : ''
                    }}</span></label
                  >
                  <multiselect
                    class="required form-control"
                    id="area"
                    v-model="selectedSubject"
                    :options="subjects"
                    :searchable="true"
                    :close-on-select="true"
                    placeholder="اختر المادة"
                    label="name"
                    track-by="id"
                    required="required"
                    :internal-search="true"
                    @select="setCourseCost"
                  >
                    <template v-slot:noOptions>
                      <span> فارغة </span>
                    </template>
                    <template v-slot:noResult>
                      <span>لا يوجد نتيجة </span>
                    </template>
                  </multiselect>
                </div>
              </div>
              <div class="row">
                <div class="mb-3 col-lg-6">
                  <label>
                    عدد المحاضرات <span class="red">*</span>
                    <span color="danger" class="error">{{
                      errors.no_of_lectures ? errors.no_of_lectures[0] : ''
                    }}</span></label
                  >
                  <vmd-input
                    id=""
                    type="text"
                    placeholder=" عدد المحاضرات"
                    v-model="course.no_of_lectures"
                  />
                </div>
                <div class="mb-3 col-lg-6">
                  <label>
                    المدرس <span class="red">*</span>
                    <span color="danger" class="error">{{
                      errors.instructor_id ? errors.instructor_id[0] : ''
                    }}</span></label
                  >
                   <multiselect
                        class='required form-control'
                        id='group'
                        v-model="selectedInstructor"
                        :options='instructors'
                        :searchable='true'
                        :close-on-select='true'
                        placeholder="  اختر  المدرس"
                        label='name'
                        :custom-label="customLabel"
                        track-by='id'
                        required='required'
                        :internal-search="true"
                        >
                      >
                        <template v-slot:noOptions>
                          <span> فارغة </span>
                        </template>
                        <template v-slot:noResult>
                          <span>لا يوجد نتيجة </span>
                        </template>
                      </multiselect>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-6">
                  <div class="row">
                    <div class="mb-3 col-lg-8" v-if="!course.free">
                      <label>
                        قيمة الدورة <span class="red">*</span>
                        <span color="danger" class="error">{{
                          errors.total_cost ? errors.total_cost[0] : ''
                        }}</span></label>
                          <vmd-input
                            id=""
                            type="text"
                            placeholder="قيمة الدورة"
                            v-model="course.total_cost"
                            required
                          />
                    </div>
                  </div>
                </div>
                <div class="mb-3 col-lg-6" v-if="!course.free">
                  <label>
                    قيمة المحاضرة <span class="red">*</span>
                    <span color="danger" class="error"></span
                  ></label>
                  <vmd-input
                    id=""
                    type="text"
                    placeholder=" قيمة المحاضرة"
                    v-model="course.lectureCost"
                    required
                  />
                </div>
              </div>
              <div class="row">
                <div class="mb-3 col-lg-4">
                  <div class="online attend ">
                    <vmd-checkbox
                      :checked="true"
                      disabled
                      v-model="course.available"
                    ></vmd-checkbox>
                    <span> الدورة متاحة أون لاين</span>
                  </div>
                </div>
                <div class="mb-3 col-lg-4">
                  <div class="online attend ">
                    <vmd-checkbox id="free-video" type="text" v-model='course.free' :value="true">
                    </vmd-checkbox>
                    <span>كورس مجاني</span>
                  </div>
                </div>
                 <div class="mb-3 col-lg-4">
                  <div class="online attend ">
                    <vmd-checkbox
                      :checked="course.revision"
                      v-model="course.revision"
                    ></vmd-checkbox>
                    <span>   مراجعة نهائية </span>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="text-center col-lg-6">
                  <vmd-button class="my-4 mb-2" variant="gradient" color="info">
                    حفظ</vmd-button
                  >
                </div>
                <div class="text-center col-lg-6">
                  <vmd-button
                    class="my-4 mb-2"
                    variant="gradient"
                    type="reset"
                    color="danger"
                  >
                    مسح</vmd-button
                  >
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import VmdInput from '@/components/VmdInput.vue'
import VmdButton from '@/components/VmdButton.vue'
import VmdCheckbox from '@/components/VmdCheckbox.vue'
import swal from 'sweetalert'
import $ from 'jquery'
export default {
  name: 'AddCourse',
  components: {
    VmdInput,
    VmdButton,
    VmdCheckbox
  },
  data () {
    return {
      course: {},
      subjects: [],
      groups: [],
      grades: [],
      instructors: [],
      academicYear: [],
      universities: [],
      faculties: [],
      selectedSubject: '',
      selectedInstructor: '',
      SelectedYear: '',
      selectedUniversity: '',
      selectedFaculty: '',
      SelectedSemester: '',
      image: '',
      errors: []
    }
  },
  created () {
    this.$http
      .get(`${this.$hostUrl}courses/${this.$route.params.id}`)
      .then((response) => {
        this.course = response.data.data
        this.course.free = (response.data.data.free === 1)
        // alert(this.course.type)
        this.course.type = (this.course.type === 1)
        this.selectedFaculty = {
          id: this.course.subject_object.faculty.id,
          name: this.course.subject_object.faculty.name
        }
        this.selectedSubject = {
          id: this.course.subject_object.id,
          name: this.course.subject_object.name
        }
        this.selectedInstructor = {
          id: this.course.instructor_object.id,
          name: this.course.instructor_object.name
        }
        const selectedUniversity = []
        $.each(this.course.subject.faculty.university, function (key, val) {
          selectedUniversity.push({
            id: val.id,
            name: val.name
          })
        })
        this.selectedUniversity = selectedUniversity
        this.SelectedYear = {
          id: this.course.academic_year_object.id,
          name: this.course.academic_year_object.name
        }
        this.selectedBranch = {
          id: this.course.branch_object.id,
          name: this.course.branch_object.name
        }
        this.SelectedSemester = this.course.subject_object.semester
        const subjects = []
        this.$http
          .get(
            `${this.$hostUrl}grade/${this.selectedGrade.id}/subjects?semester=${this.SelectedSemester}`
          )
          .then((response) => {
            $.each(response.data.data, function (key, value) {
              subjects.push({
                name: value.name,
                id: value.id,
                cost: value.cost
              })
            })
            this.subjects = subjects
          })
      })
    const universities = []
    this.$http.get(`${this.$hostUrl}universities`).then((response) => {
      $.each(response.data.data, function (key, value) {
        universities.push({
          name: value.name,
          id: value.id
        })
      })
      this.universities = universities
    })
    const faculties = []
    this.$http.get(`${this.$hostUrl}faculties`).then((response) => {
      $.each(response.data.data, function (key, value) {
        faculties.push({
          name: value.name,
          id: value.id
        })
      })
      this.faculties = faculties
    })
    const instructors = []
    this.$http.get(`${this.$hostUrl}instructors`).then((response) => {
      $.each(response.data.data, function (key, value) {
        instructors.push({
          name: value.name,
          id: value.id
        })
      })
      this.instructors = instructors
    })
    const academicYear = []
    this.$http.get(`${this.$hostUrl}academic-years`).then((response) => {
      $.each(response.data.data, function (key, value) {
        academicYear.push({
          name: value.name,
          id: value.id
        })
      })
      this.academicYear = academicYear
    })
  },
  methods: {
    getFaculties (element) {
      const faculties = []
      this.$http.get(`${this.$hostUrl}faculties?university_id=${element.id}`)
        .then((response) => {
          $.each(response.data.data, function (key, value) {
            faculties.push({
              name: value.name,
              id: value.id
            })
          })
          this.faculties = faculties
        })
    },
    getSubjects ($event) {
      this.selectedSubject = ''
      const subjects = []
      this.$http.get(`${this.$hostUrl}subjects?faculty_id=${$event.id}`)
        .then((response) => {
          $.each(response.data.data, function (key, value) {
            subjects.push({
              name: value.name,
              id: value.id,
              cost: value.cost
            })
          })
          this.subjects = subjects
        })
    },
    setCourseCost (event) {
      this.course.total_cost = event.cost
    },
    onFileChange (e) {
      const _URL = window.URL || window.webkitURL
      this.image = e.target.files[0]
      let file = ''
      if ((file = e.target.files[0])) {
        const img = new Image()
        img.src = _URL.createObjectURL(file)
      }
    },
    async createCourse (e) {
      e.preventDefault()
      const formData = new FormData()
      if (/^\d+$/.test(this.course.name)) {
        swal('اسم الدورة يجب ان يحتوي على حروف')
        return 0
      }
      formData.append('name', this.course.name)
      if (this.course.name_en) {
        formData.append('name_en', this.course.name_en)
      }
      formData.append('faculty_id', this.selectedFaculty.id)
      formData.append('subject_id', this.selectedSubject.id)
      formData.append('instructor_id', this.selectedInstructor.id)
      formData.append('academic_year_id', this.SelectedYear.id)
      formData.append('no_of_lectures', this.course.no_of_lectures)
      formData.append('total_cost', this.course.free ? 0 : this.course.total_cost)
      formData.append('free', this.course.free ? 1 : 0)
      if (this.course.lectureCost) {
        formData.append('lecture_cost', this.course.free ? 0 : this.course.lectureCost)
      }
      formData.append('_method', 'PUT')
      if (this.course.type) {
        formData.append('type', 1)
      } else {
        formData.append('type', 0)
      }
      if (this.image) {
        formData.append('photo', this.image)
      }

      this.errors = await this.postRequest(
        formData,
        this.$hostUrl + 'courses/' + this.$route.params.id,
        '/courses'
      )
    }
  }
}
</script>
<style >
img{
  width:200px !important;
  height:200px !important;
  border-radius: 10px;
}
</style>
