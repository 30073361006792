<template>
<div class="container-fluid py-4 add-student grade-detail">
    <div class="row">
      <div class="col-12">
        <div class="card my-4">
          <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
            <div
              class="bg-gradient-success shadow-success border-radius-lg pt-4 pb-3"
            >
              <div class="row">
                <div class="col-lg-6">
                  <h6 class="text-white text-capitalize ps-3">   تقارير المشاهدات </h6>
                </div>
              </div>
            </div>
          </div>
       <div class="card-body px-0 pb-2">
         <div class="mb-3 col-lg-12 tab-div tab2">
              <ul class="nav nav-tabs justify-content-center" id="myTab" role="tablist">
                <li class="nav-item list2 " role="presentation">
                  <button style="width:100% !important" class="  nav-link active btn mb-0 bg-gradient-info btn-md null null btn-lg" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">  المشاهدات اليومية</button>
                </li>
                <li class="nav-item list2" role="presentation">
                  <button style="width:100% !important" class="nav-link btn mb-0 bg-gradient-info  btn-md null null btn-lg" id="monthes-tab" data-bs-toggle="tab" data-bs-target="#monthes" type="button" role="tab" aria-controls="monthes" aria-selected="false">  المشاهدات الشهرية</button>
                </li>
              </ul>
            </div>
           <div class="tab-content" id="myTabContent">
             <div class="tab-pane fade active show " id="home" role="tabpanel" aria-labelledby="home-tab">
                              <!-- daily watches -->
             <div class="row align-center ex-row">
               <div class="mb-3 col-lg-4">
                  <label> التاريخ </label>
                 <vmd-input
                    id=""
                    type="date"
                    v-model="fromDate"
                    @change="filterData"
                  />
                </div>
                 <div class="mb-3 col-lg-4 total-w">
                <span>إجمالي المشاهدات اليومية : <span class="green">{{formatNumber(totalPerDay)}}</span></span>
                </div>
        <div class="row mb-4 margin-top d-flex justify-content" >
         <div class="col-lg-11 col-md-6 mt-4 chart-col">
          <div class="chart-container" :style="{ height: chartHeight }">
          <CChartLine
                :options="options"
              :data="{
              labels: watchesLabels,
              datasets: [
            {
              label: ' اجمالي المشاهدات  ',
              backgroundColor: 'rgba(151, 187, 205, 0.2)',
              borderColor: 'rgba(151, 187, 205, 1)',
              pointBackgroundColor: 'rgba(151, 187, 205, 1)',
              pointBorderColor: '#fff',
              data: totalWatches
            }
          ]
        }"
      ></CChartLine>
      </div>
          </div>
      </div>
        </div>
                  </div>
                <div class="tab-pane  " id="monthes" role="tabpanel" aria-labelledby="monthes-tab">
                  <div class="row align-center ex-row">
                  <div class="mb-3 col-lg-3">
                    <label> من : </label>
                    <vmd-input id="" type="date" v-model="fromDate2" @change="refreshData" />
                  </div>
                  <div class="mb-3 col-lg-3">
                    <label> الي: </label>
                    <vmd-input id="" type="date" v-model="toDate" @change="getMonthlyData" />
                  </div>
                   <div class="mb-3 col-lg-5 total-w">
                <span>إجمالي المشاهدات الشهرية : <span class="green">{{formatNumber(totalPerMonth)}}</span></span>
                </div>
                       </div>
                    <div class="row mb-4 margin-top d-flex justify-content" >
                        <div class="col-lg-11 col-md-6 mt-4 chart-col">
                          <CChartLine
                          :options="options"
                        :data="{
                        labels: monthlyWatchesLabels,
                        datasets: [
                      {
                        label: ' اجمالي المشاهدات  ',
                        backgroundColor: 'rgba(151, 187, 205, 0.2)',
                        borderColor: 'rgba(151, 187, 205, 1)',
                        pointBackgroundColor: 'rgba(151, 187, 205, 1)',
                        pointBorderColor: '#fff',
                        data: totalMonthlyWatches
                      }
                    ]
                  }"
                ></CChartLine>
                          </div>
                      </div>
                         </div>
                        </div>
        </div>
        </div>
      </div>
    </div>
    </div>
</template>
<script>
import { CChartLine } from '@coreui/vue-chartjs'
import VmdInput from '@/components/VmdInput.vue'
import $ from 'jquery'
const today =
 new Date().toISOString().slice(0, 10)
export default {
  name: 'WatchesReport',
  components: {
    VmdInput,
    CChartLine

  },
  data () {
    return {
      options: {
        responsive: true,
        maintainAspectRatio: false,
        elements: {
          line: {
            tension: 0.6// Set lineTension to 0.4 for a curved line
          }
        }
      },
      chartHeight: '500px',
      fromDate: today,
      fromDate2: '',
      toDate: '',
      watches: [],
      monthlyWatches: [],
      watchesLabels: [],
      monthlyWatchesLabels: [],
      totalWatches: [],
      totalMonthlyWatches: [],
      totalPerDay: 0,
      totalPerMonth: 0

    }
  },
  created () {
    this.$http.get(`${this.$hostUrl}watches?from=${this.fromDate}&to=${this.fromDate}`).then(response => {
      this.watches = response.data
      const watchesLabels = []
      const totalWatches = []
      $.each(this.watches, (key, val) => {
        watchesLabels.push(
          val.hour_time
        )
        totalWatches.push(
          val.total_watches
        )
        this.totalPerDay += val.total_watches
      })
      this.watchesLabels = watchesLabels
      this.totalWatches = totalWatches
    })
    this.fromDate2 = this.getStartOfMonth()
    this.toDate = this.getToday()
    this.getMonthlyData()
  },
  mounted () {
    this.fromDate2 = this.getStartOfMonth()
    this.toDate = this.getToday()
  },
  methods: {
    getMonthlyData () {
      this.totalPerMonth = 0
      this.$http.get(`${this.$hostUrl}report/month/watches?from=${this.fromDate2}&to=${this.toDate}`).then(response => {
        this.monthlyWatches = response.data
        const monthlyWatchesLabels = []
        const totalMonthlyWatches = []
        $.each(this.monthlyWatches, (key, val) => {
          monthlyWatchesLabels.push(
            val.day
          )
          totalMonthlyWatches.push(
            val.total_watches
          )
          this.totalPerMonth += val.total_watches
        })
        this.monthlyWatchesLabels = monthlyWatchesLabels
        this.totalMonthlyWatches = totalMonthlyWatches
      })
    },
    refreshData () {
      this.monthlyWatchesLabels = []
      this.totalMonthlyWatches = []
      this.toDate = ''
    },
    filterData () {
      this.watchesLabels = []
      this.totalWatches = []
      this.totalPerDay = 0
      this.$http.get(`${this.$hostUrl}watches?from=${this.fromDate}&to=${this.fromDate}`).then(response => {
        this.watches = response.data
        const watchesLabels = []
        const totalWatches = []
        $.each(this.watches, (key, val) => {
          watchesLabels.push(
            val.hour_time
          )
          totalWatches.push(
            val.total_watches
          )
          this.totalPerDay += val.total_watches
        })
        this.watchesLabels = watchesLabels
        this.totalWatches = totalWatches
      })
    },
    getStartOfMonth () {
      const date = new Date()
      date.setDate(1)
      date.setHours(0, 0, 0, 0)
      date.setMonth(date.getMonth()) // Set the month to the current month
      return date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2)
    },
    getToday () {
      const date = new Date()
      return date.toISOString().slice(0, 10)
    },
    formatNumber (number) {
      return number.toLocaleString()
    }
  }
}
</script>
<style >
.chartjs-tooltip{
  position: absolute !important;
  background: rgb(238, 238, 238) !important;
    color: rgb(34, 34, 34) !important;
    font-size: 14px !important;
    /* left:825.165px !important */
}
.total-w{
 margin-top: 20px !important;
  color: #222;
}
.total-w .green{
font-size: 17px;
font-weight: bold;
font-family: Montserrat, sans-serif !important;
letter-spacing: 2px;
}
.nav-tabs .nav-link.active,
 .nav-tabs .nav-item.show .nav-link{
  color:#fff !important;
  background-image: linear-gradient(195deg, #49a3f1 0%, #1a73e8 100%) !important;
 }
.nav-tabs .nav-link{
  color:#222 !important;
  background:#eee !important
}
.chart-container {
  position: relative;
}
canvas {
  height: 450px !important;
}
</style>
