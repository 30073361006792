<template>
  <div class="container-fluid py-4 add-student add-course">
    <div class="row">
      <div class="col-12">
        <div class="card my-4">
          <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
            <div
              class="bg-gradient-success shadow-success border-radius-lg pt-4 pb-3"
            >
              <h6 class="text-white text-capitalize ps-3">
                {{ $t("message.createCourse") }}
              </h6>
            </div>
          </div>
          <div class="card-body px-0 pb-2">
            <form @submit="createCourse" role="form" class="text-start mt-3">
              <div class="row">
                <div class="mb-3 col-lg-6">
                  <label>
                    {{ $t("message.nameAr") }}<span class="red">*</span>
                    <span color="danger" class="error">{{
                      errors.name ? errors.name[0] : ""
                    }}</span></label
                  >
                  <vmd-input
                    id=""
                    type="text"
                    :placeholder="$t('message.nameAr')"
                    v-model="course.name"
                    required
                  />
                </div>
                <div class="mb-3 col-lg-6">
                  <label>
                    {{ $t("message.nameEn")
                    }}<span class="green">({{ $t("message.optional") }}) </span>
                    <span color="danger" class="error">{{
                      errors.name_en ? errors.name_en[0] : ""
                    }}</span></label
                  >
                  <vmd-input
                    id=""
                    type="text"
                    :placeholder="$t('message.nameEn')"
                    v-model="course.name_en"
                  />
                </div>
              </div>
              <div class="row">
                <div class="mb-3 col-lg-6">
                  <label>
                    {{ $t("message.startDate") }}<span class="red">*</span>
                    <span color="danger" class="error">{{
                      errors.name ? errors.name[0] : ""
                    }}</span></label
                  >
                  <vmd-input
                    id=""
                    type="date"
                    :placeholder="$t('message.startDate')"
                    v-model="course.startDate"
                    required
                  />
                </div>
                <div class="mb-3 col-lg-6">
                  <label>
                    {{ $t("message.endDate") }}<span class="red">* </span>
                    <span color="danger" class="error">{{
                      errors.name_en ? errors.name_en[0] : ""
                    }}</span></label
                  >
                  <vmd-input
                    id=""
                    type="date"
                    :placeholder="$t('message.endDate')"
                    v-model="course.endDate"
                  />
                </div>
              </div>
              <div class="row">
                <div class="mb-3 col-lg-6">
                  <label>
                    {{ $t("message.startTime") }}<span class="red">*</span>
                    <span color="danger" class="error">{{
                      errors.name ? errors.name[0] : ""
                    }}</span></label
                  >
                  <vmd-input
                    id=""
                    type="time"
                    :placeholder="$t('message.startTime')"
                    v-model="course.startTime"
                    required
                  />
                </div>
                <div class="mb-3 col-lg-6">
                  <label>
                    {{ $t("message.duration") }}<span class="red">* </span>
                    <span color="danger" class="error">{{
                      errors.duration ? errors.duration[0] : ""
                    }}</span></label
                  >
                  <vmd-input
                    id=""
                    type="text"
                    :placeholder="'01:30'"
                    v-model="course.duration"
                  />
                </div>
              </div>
              <div class="row">
                <div class="mb-3 col-lg-6">
                  <label>
                    {{ $t("message.lecturesNum") }} <span class="red">*</span>
                    <span color="danger" class="error">{{
                      errors.no_of_lectures ? errors.no_of_lectures[0] : ""
                    }}</span></label
                  >
                  <vmd-input
                    id=""
                    type="text"
                    :placeholder="$t('message.lecturesNum')"
                    v-model="course.noOfLectures"
                    required
                  />
                </div>
                <div class="mb-3 col-lg-6" v-if="!course.free">
                  <label>
                    {{ $t("message.lectureCost") }} <span class="red">*</span>
                    <span color="danger" class="error"></span
                  ></label>
                  <vmd-input
                    id=""
                    type="text"
                    :placeholder="$t('message.lectureCost')"
                    v-model="course.lectureCost"
                    required
                  />
                </div>
              </div>
              <div class="row">
                <div class="mb-3 col-lg-6">
                  <label>
                    {{ $t("message.subjects") }} <span class="red">*</span>
                    <span color="danger" class="error">{{
                      errors.subject_id ? errors.subject_id[0] : ""
                    }}</span></label
                  >
                  <multiselect
                    class="required form-control"
                    id="area"
                    v-model="selectedSubject"
                    :options="subjects"
                    :searchable="true"
                    :close-on-select="true"
                    :placeholder="$t('message.select')"
                    label="name"
                    track-by="id"
                    required="required"
                    :internal-search="true"
                    @select="setCourseCost"
                  >
                    >
                    <template v-slot:noOptions>
                      <span> فارغة </span>
                    </template>
                    <template v-slot:noResult>
                      <span>لا يوجد نتيجة </span>
                    </template>
                  </multiselect>
                </div>
                <div class="mb-3 col-lg-6">
                  <label>
                    {{ $t("message.teacher") }} <span class="red">*</span>
                    <span color="danger" class="error">{{
                      errors.instructor_id ? errors.instructor_id[0] : ""
                    }}</span></label
                  >
                  <multiselect
                    class="required form-control"
                    id="group"
                    v-model="selectedInstructor"
                    :options="instructors"
                    :searchable="true"
                    :close-on-select="true"
                    :placeholder="$t('message.select')"
                    label="name"
                    :custom-label="customLabel"
                    track-by="id"
                    required="required"
                    :internal-search="true"
                  >
                    >
                    <template v-slot:noOptions>
                      <span> فارغة </span>
                    </template>
                    <template v-slot:noResult>
                      <span>لا يوجد نتيجة </span>
                    </template>
                  </multiselect>
                </div>
              </div>
              <div class="row">
                <div class="mb-3 col-lg-6">
                  <label>
                    {{ $t("message.branch") }} <span class="red">*</span>
                    <span color="danger" class="error">{{
                      errors.branch_id ? errors.branch_id[0] : ""
                    }}</span></label
                  >
                  <multiselect
                    class="required form-control"
                    id="branch"
                    v-model="selectedBranch"
                    :options="branches"
                    :searchable="true"
                    :close-on-select="true"
                    :placeholder="$t('message.branch')"
                    label="name"
                    :custom-label="customLabel"
                    track-by="id"
                    required="required"
                    :internal-search="true"
                  >
                    >
                    <template v-slot:noOptions>
                      <span> فارغة </span>
                    </template>
                    <template v-slot:noResult>
                      <span>لا يوجد نتيجة </span>
                    </template>
                  </multiselect>
                </div>
                <div class="mb-3 col-lg-6">
                  <div class="row">
                    <div class="mb-3 mt-4 col-lg-4">
                      <vmd-checkbox
                        id="free-video"
                        type="text"
                        v-model="course.free"
                        :value="true"
                      >
                        {{ $t("message.freeCourse") }}
                      </vmd-checkbox>
                    </div>
                    <div class="mb-3 col-lg-8" v-if="!course.free">
                      <label>
                        {{ $t("message.courseCost") }}
                        <span class="red">*</span>
                        <span color="danger" class="error">{{
                          errors.total_cost ? errors.total_cost[0] : ""
                        }}</span></label
                      >
                      <vmd-input
                        id=""
                        type="text"
                        :placeholder="$t('message.courseCost')"
                        v-model="course.total_cost"
                        required
                      />
                    </div>
                  </div>
                </div>
              </div>
               <div class="row mb-5">
               <div class="mb-3 col-lg-6">
                  <label>
                    {{ $t("message.comment") }} <span class="green">({{$t('message.optional')}}) </span>
                    <span color="danger" class="error">{{
                      errors.comment ? errors.comment[0] : ""
                    }}</span></label
                  >
                  <vmd-textarea
                    id=""
                    type="date"
                    :placeholder="$t('message.comment')"
                    v-model="course.comment"
                  />
                </div>
              </div>
              <div class="row">
                <div class="mb-3 col-12">
                  <label>
                    {{ $t("message.day") }} <span class="red">*</span>
                    <span color="danger" class="error">{{
                      errors.no_of_lectures ? errors.no_of_lectures[0] : ""
                    }}</span></label
                  >
                  <ul
                    style="margin-top: 30px"
                    class="list-group list-group-horizontal"
                  >
                    <li
                      v-for="day in days"
                      :key="day.id"
                      class="list-group-item"
                    >
                      <vmd-checkbox v-model="day.isChecked">{{
                        day.name
                      }}</vmd-checkbox>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="row">
                <div class="text-center col-lg-6">
                  <vmd-button class="my-4 mb-2" variant="gradient" color="info">
                    {{ $t("message.save") }}</vmd-button
                  >
                </div>
                <div class="text-center col-lg-6">
                  <vmd-button
                    class="my-4 mb-2"
                    variant="gradient"
                    type="reset"
                    color="danger"
                  >
                    {{ $t("message.delete") }}</vmd-button
                  >
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import VmdInput from '@/components/VmdInput.vue'
import VmdButton from '@/components/VmdButton.vue'
import VmdCheckbox from '@/components/VmdCheckbox.vue'
import VmdTextarea from '@/components/VmdTextarea.vue'
import swal from 'sweetalert'
import $ from 'jquery'
export default {
  name: 'AddCourse',
  components: {
    VmdInput,
    VmdButton,
    VmdCheckbox,
    VmdTextarea
  },
  data () {
    return {
      course: {},
      subjects: [],
      instructors: [],
      branches: [],
      selectedSubject: '',
      selectedInstructor: '',
      selectedBranch: '',
      days: [],
      sentDays: [],
      errors: []
    }
  },
  created () {
    const subjects = []
    this.$http.get(`${this.$hostUrl}subjects`).then((response) => {
      $.each(response.data.data, function (key, value) {
        subjects.push({
          name: value.name,
          id: value.id,
          cost: value.cost
        })
      })
      this.subjects = subjects
    })
    const days = []
    const isEn = document.querySelector('#app').classList.contains('ltr')
    this.$http.get(`${this.$hostUrl}days`).then((response) => {
      $.each(response.data.data, function (key, value) {
        days.push({
          name: isEn ? value.name_en : value.name,
          id: value.id
        })
      })
      const weekOrder = isEn
        ? ['Saturday', 'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday']
        : ['السبت', 'الاحد', 'الاثنين', 'الثلاثاء', 'الأربعاء', 'الخميس', 'الجمعه']
      const sortedDays = days.sort((a, b) => {
        const dayIndexA = weekOrder.indexOf(a.name)
        const dayIndexB = weekOrder.indexOf(b.name)
        return dayIndexA - dayIndexB
      })
      this.days = sortedDays
    })
    const instructors = []
    this.$http.get(`${this.$hostUrl}instructors`).then((response) => {
      $.each(response.data.data, function (key, value) {
        instructors.push({
          name: value.name,
          id: value.id
        })
      })
      this.instructors = instructors
    })
    const branches = []
    this.$http.get(`${this.$hostUrl}branches`).then((response) => {
      $.each(response.data.data, function (key, value) {
        branches.push({
          name: value.name,
          id: value.id
        })
      })
      this.branches = branches
    })
  },
  watch: {
    days: {
      handler (newDays) {
        this.sentDays = newDays
          .filter((day) => day.isChecked)
          .map((day) => day.id)
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    // enforce the user to choose the semster first for right subjects
    setCourseCost (event) {
      this.course.total_cost = event.cost
    },
    async createCourse (e) {
      e.preventDefault()
      console.log(this.sentDays)
      const formData = new FormData()
      if (/^\d+$/.test(this.course.name)) {
        swal('اسم الدورة يجب ان يحتوي على حروف')
        return 0
      }
      formData.append('name', this.course.name)
      if (this.course.name_en) {
        formData.append('name_en', this.course.name_en)
      }
      if (this.course.comment) {
        formData.append('comment', this.course.comment)
      }

      formData.append('subject_id', this.selectedSubject.id)
      formData.append('instructor_id', this.selectedInstructor.id)
      formData.append('branch_id', this.selectedBranch.id)
      formData.append('no_of_lectures', this.course.noOfLectures)
      formData.append('start', this.course.startDate)
      formData.append('end', this.course.endDate)
      formData.append('start_time', this.course.startTime)
      formData.append('duration', this.course.duration)
      for (let i = 0; i < this.sentDays.length; i++) {
        formData.append('days[]', this.sentDays[i])
      }

      formData.append(
        'total_cost',
        this.course.free ? 0 : this.course.total_cost.replace(/\.\d*0+$/, '')
      )
      formData.append('free', this.course.free ? 1 : 0)
      formData.append(
        'lecture_cost',
        this.course.free ? 0 : this.course.lectureCost
      )
      if (this.course.available) {
        formData.append('type', 1)
      } else {
        formData.append('type', 0)
      }
      if (this.image) {
        formData.append('photo', this.image)
      }
      if (this.course.revision) {
        formData.append('revision', 1)
      } else {
        formData.append('revision', 0)
      }
      this.errors = await this.postRequest(
        formData,
        this.$hostUrl + 'courses',
        '/courses'
      )
    }
  }
}
</script>
<style>
.add-course .online {
  margin: 20px 0 !important;
}
</style>
