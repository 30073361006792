<template>
     <div class="container-fluid py-4 add-student">
    <div class="row">
      <div class="col-12">
        <div class="card my-4">
          <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
            <div
              class="bg-gradient-success shadow-success border-radius-lg pt-4 pb-3"
            >
              <h6 class="text-white text-capitalize ps-3">    عرض الفيديو  :  دورة {{courseName}}  </h6>
            </div>
          </div>
          <div class="card-body px-0 pb-2">
                 <form  role="form" class="text-start mt-3">
                            <div class='row'>
                     <div class="mb-3 col-lg-6 ">
                    <label> عنوان الفيديو<span class="red">*</span></label>
                  <vmd-input
                    id="date-input"
                    type="text"
                   placeholder="  عنوان الفيديو "
                   v-model='video.name'
                    disabled
                  />
                  </div>
                    <div class="mb-3 col-lg-6 ">
                    <label>   عنوان الفيديو بالإنجليزية<span class="red">*</span></label>
                  <vmd-input
                    id="date-input"
                    type="text"
                   placeholder=" عنوان الفيديو بالإنجليزية "
                   v-model='video.name_en'
                    disabled
                  />
                  </div>
                  </div>
                 <div class='row' >
                       <div class="mb-3 col-lg-6 ">
                    <label>  قيمة الفيديو</label>
                  <vmd-input
                    id="date-input"
                    type="text"
                   placeholder=" قيمة الفيديو  "
                   v-model='video.cost'
                    disabled
                  />
                  </div>
                     <div class="mb-3 col-lg-6 " v-if="video.url">
                    <label>  رابط الفيديو</label>
                    <a class="link vid-url" :href="video.url" target="_blank">{{video.url}}</a>
                </div>
                </div>
                <div class='row mb-5' >
                       <div class="mb-3 col-lg-6 ">
                    <label>     الطلاب الذين شاهدوا الفيديو </label>
                  <vmd-input
                    id="date-input"
                    type="text"
                   v-model='video.watched_students_count
'
                    disabled
                  />
                  </div>
                     <div class="mb-3 col-lg-6 ">
                    <label>   عدد مرات المشاهدة</label>
                  <vmd-input
                    id="date-input"
                    type="text"
                   v-model='video.watches_count'
                    disabled
                  />
                  </div>
                </div>
                <div class="row mt-3"  v-if="video.media && video.media.length >= 1">
                   <h5 id='data' class='video-header' style="display: flex"> <span>الفيديوهات </span>
               <div class="  tab-div">
          <ul class="nav nav-tabs" id="myTab" role="tablist">
            <li class="nav-item" role="presentation">
        <button  class="nav-link active btn mb-0 bg-gradient-info btn-md null null btn-lg" id="new-tab" data-bs-toggle="tab" data-bs-target="#new" type="button" role="tab" aria-controls="home" aria-selected="true"> جودة 1080</button>
      </li>
      <li class="nav-item" role="presentation">
        <button  class="nav-link  btn mb-0 bg-gradient-info btn-md null null btn-lg" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true" > جودة 720</button>
      </li>
      <li class="nav-item" role="presentation">
        <button   class="nav-link btn mb-0 bg-gradient-info  btn-md null null btn-lg" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false"> جودة 480</button>
      </li>
      <li class="nav-item" role="presentation">
        <button  class="nav-link btn mb-0 bg-gradient-info  btn-md null null btn-lg" id="contact-tab" data-bs-toggle="tab" data-bs-target="#contact" type="button" role="tab" aria-controls="contact" aria-selected="false"> جودة 360</button>
      </li>
    </ul>
    </div>
        </h5>
            <div class="row">
              <div class="tab-content" id="myTabContent">
                  <div class="tab-pane fade show active" id="new" role="tabpanel" aria-labelledby="home-tab">
            <div class="row" v-if="video1080">
        <div class="mb-3 col-lg-6 ">
                        <label>   جودة الفيديو<span class="red">*</span></label>
                      <vmd-input
                        id="date-input"
                        type="text"
                      placeholder="1080 "
                      disabled
                      />
                  </div>
                  <div class="col-lg-6 mb-3 text-center">
                 <div>
      <video width="320" height="240" controls class="img-thumbnail">
      <source :src="video1080" type="video/mp4">
        Your browser does not support the video tag.
      </video>
                </div>
                </div>
                </div>
                 <div class="row" v-else>
                    <h5  class="text-center">غير متاح</h5>
                </div>
  </div>
      <div class="tab-pane fade show " id="home" role="tabpanel" aria-labelledby="home-tab">
            <div class="row" v-if="video720">
        <div class="mb-3 col-lg-6 ">
                        <label>   جودة الفيديو<span class="red">*</span></label>
                      <vmd-input
                        id="date-input"
                        type="text"
                      placeholder="720 "
                      disabled
                      />
                  </div>
                  <div class="col-lg-6 mb-3 text-center">
                 <div>
      <video width="320" height="240" controls class="img-thumbnail">
      <source :src="video720" type="video/mp4">
        Your browser does not support the video tag.
      </video>
                </div>
                </div>
                </div>
                 <div class="row" v-else>
                    <h5  class="text-center">غير متاح</h5>
                </div>
  </div>
  <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
    <div class="row" v-if="video480">
     <div class="mb-3 col-lg-6 ">
            <label>   جودة الفيديو<span class="red">*</span></label>
                  <vmd-input
                    id="date-input"
                    type="text"
                   placeholder="480 "
                   disabled
                  />
                  </div>
                  <div class="col-lg-6 mb-3 text-center">
                 <div>
                  <video title="Bruce Lee meets Jackie Chan" width="320" height="240" controls class="img-thumbnail">
   <!-- eslint-disable-next-line vue/no-parsing-error -->
            <source :src="video480" type="video/mp4">
        Your browser does not support the video tag.
      </video>
                </div>
                </div>
                </div>
                <div class="row" v-else>
                    <h5  class="text-center">غير متاح</h5>
                </div>
  </div>
  <div class="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">
    <div class="row" v-if="video360">
     <div class="mb-3 col-lg-6 ">
                   <label>   جودة الفيديو<span class="red">*</span></label>
                  <vmd-input
                    id="date-input"
                    type="text"
                   placeholder="360 "
                   disabled
                  />
                  </div>
                  <div class="col-lg-6 mb-3 text-center">
                 <div>
               <video width="320" height="240" controls class="img-thumbnail">
          <source :src="video360" type="video/mp4">
      </video>
                </div>
                </div>
              </div>
               <div class="row" v-else>
                    <h5  class="text-center">غير متاح</h5>
                </div>
            </div>
                    </div>
                  </div>
                  </div>
                   <div class="row enroll-row codes-row text-center" v-if="promoCodes.length">
                <div class="col-md-4">
                <h6>  عدد البروموكود     : <span class="total-tex green">{{promoCodes.length}}</span></h6>
                </div>
              <div class="col-md-4">
                <h6> الأكواد المستخدمة     : <span class="total-tex green">{{usedCodes}}</span></h6>
                </div>
                <div class="col-md-4 d-flex">
                 <h6 class="gr-text">    الأكواد الغير مستخدمة : <span class="total-tex green">{{unusedCode}}</span></h6>
                </div>
              </div>
                   <div style="text-align:right" class="row" v-if="promoCodes.length">
        <div class="col-12">
          <div class="card my-4">
            <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
              <div
                class="bg-gradient-success shadow-success border-radius-lg pt-4 pb-3"
              >
                <h6 class="text-white text-capitalize ps-3">البروموكود</h6>
              </div>
            </div>
            <div class="card-body px-0 pb-2">
              <div class="table-responsive p-0">
                <table class="table align-items-center mb-0">
                  <thead>
                    <tr>
                      <th class="text-uppercase font-weight-bolder">الكود</th>
                      <th class="text-uppercase font-weight-bolder">
                        الطالب المستخدم للكود
                      </th>
                      <th class="text-uppercase font-weight-bolder">
                        تاريخ الإنشاء
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in  promoCodes" :key="item.id">
                      <td>
                        <p class="text-s mb-0">{{ item.value }}</p>
                      </td>
                      <td>
                        <p class="text-s mb-0">
                          {{ item.student ? item.student.full_name : "" }}
                        </p>
                      </td>
                      <td>
                        <p class="text-s mb-0">{{ item.created_at }}</p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
                       <div class="row text-center back-btn">
                 <div class="text-center col-lg-6">
                  <vmd-button
                   @click='goBack($event)'
                    class="my-4 mb-2 "
                    type='btn'
                    variant="gradient"
                    color="warning"
                    > رجوع</vmd-button
                  >
                </div>
                       </div>
               </form>
          </div>
        </div>
      </div>
    </div>
     </div>
</template>
<script>
import VmdButton from '@/components/VmdButton.vue'
import VmdInput from '@/components/VmdInput.vue'
import $ from 'jquery'

export default {
  name: 'ShowVideo',
  components: {
    VmdButton,
    VmdInput
  },
  data () {
    return {
      video: {},
      media: [],
      video360: '',
      video480: '',
      video720: '',
      video1080: '',
      courseName: '',
      promoCodes: [],
      usedCodes: 0,
      unusedCode: 0,
      errors: []
    }
  },
  async created () {
    let usedCodes = 0
    await this.$http
      .get(`${this.$hostUrl}videos/${this.$route.params.id}`)
      .then((response) => {
        this.video = response.data.data
        this.getVideoResolutions(this.video)
        this.promoCodes = response.data.data.promo_codes
        $.each(this.promoCodes, (key, val) => {
          if (val.student) {
            usedCodes++
          }
        })
        this.usedCodes = usedCodes
        this.unusedCode = (this.promoCodes.length) - usedCodes
        this.courseName = this.video.course.name
      })
  },
  methods: {
    getVideoResolutions (video) {
      for (let i = 0; i < video.media.length; i++) {
        const resolution = video.media[i].resolution
        const mediaUrl = video.media[i].media_url

        if (resolution === '1080') {
          this.getWithToken(mediaUrl).then(res => {
            const blob = new Blob([res], { type: 'video/mp4' })
            const url = URL.createObjectURL(blob)
            this.video1080 = url
          })
        } else if (resolution === '720') {
          this.getWithToken(mediaUrl).then(res => {
            const blob = new Blob([res], { type: 'video/mp4' })
            const url = URL.createObjectURL(blob)
            this.video720 = url
          })
        } else if (resolution === '480') {
          this.getWithToken(mediaUrl).then(res => {
            const blob = new Blob([res], { type: 'video/mp4' })
            const url = URL.createObjectURL(blob)
            this.video480 = url
          })
        } else if (resolution === '360') {
          this.getWithToken(mediaUrl).then(res => {
            console.log(res, 'vidres')
            const blob = new Blob([res], { type: 'video/mp4' })
            const url = URL.createObjectURL(blob)
            this.video360 = url
          })
        }
      }
    },
    getWithToken (url) {
      const token = localStorage.token
      const headers = { authorization: `Bearer ${token}` }

      return this.$http.get(url, { headers })
        .then(response => response.data)
        .catch(error => {
          console.error('Error:', error)
          throw error
        })
    },
    goBack (e) {
      e.preventDefault()
      this.$router.push({ path: '/courses' })

      // location.reload()
    }
  }
}
</script>
<style >
.dropzone__box {
      width: 100% !important;
    background: #f8f9fa !important;
    min-height: 40px !important;
    cursor: pointer !important;
}
.tab-div li{
  width:25% !important;
}
.video-header{
  justify-content: center;
  align-items: center;
  padding:18px !important;
}
.video-header .tab-div{
  width:75% !important;
  margin-top:0 !important
}
.back-btn{
  display:flex;
  justify-content: center;
  margin-top: 20px !important;
}
.vid-url{
  display: inline-flex !important;
}
</style>
