<template>
  <div class="container-fluid py-4 add-student grade-detail">
    <div class="row">
      <div class="col-12">
        <div class="card my-4">
          <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
            <div
              class="bg-gradient-success shadow-success border-radius-lg pt-4 pb-3"
            >
              <div class="row">
                <div class="col-lg-6">
                  <h6 class="text-white text-capitalize ps-3">التحصيلات على مستوى اليوم للمستخدمين</h6>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body px-0 pb-2">
                      <!-- exceptional attendance row -->
                        <div class="row align-center ex-row">
                <div class="mb-3 col-lg-3">
                  <label>  التاريخ: </label>
                  <vmd-input
                    id="collection-date"
                    type="text"
                    v-model="tableDate"
                    disabled
                  />
                </div>
                 <!-- xslx extension -->
                 <div class=" col-lg-6 btns-col">
                  <vmd-button
                    class="my-4 mb-2"
                    variant="gradient"
                    color="warning"
                    @click="downloadFile()"
                    > استخراج ملف اكسل </vmd-button
                  >
                  <vmd-button
                    class="my-4 mb-2"
                    variant="gradient"
                    color="info"
                    @click="printReport"
                    >  طباعة</vmd-button
                  >
                </div>
                <!-- xslx extension -->
                  <!-- <div class="text-center col-lg-3">
                          <xlsx-workbook>
            <xlsx-sheet
            style="direction:rtl"
              :collection="sheet.data"
              v-for="sheet in sheets"
              :key="sheet.name"
              :sheet-name="sheet.name"
            />
            <xlsx-download>
              <button>Download</button>
            </xlsx-download>
          </xlsx-workbook>
              </div> -->
                        </div>
            <div class="row align-center" style=" margin-top: 40px;direction:rtl" id="print-report">
              <div class="text-center col-lg-12" style="direction:rtl">
                <table class=" b-block  table " style="direction:rtl">
                  <thead>
                    <tr>
                      <th rowspan="2">المستخدم</th>
                     <th colspan="3" >
                          استلام نقدية
                          </th>
                      <th rowspan="2">   استرداد  نقدية</th>
                      <th rowspan="2">الصافي</th>
                      <th rowspan="2">المدرس</th>
                    </tr>
                      <tr>
                        <th>الدورات</th>
                        <th>الدخول</th>
                        <th>الصافي</th>
                        </tr>
                          <tr>
                        </tr>

                  </thead>
                  <tbody>
                    <tr v-for="user in users" :key="user.id">
                      <td>{{user.name}}</td>
                      <td>{{user.course_payment??'-'}}</td>
                      <td>{{user.attendance_payment??'-'}}</td>
                       <td style="background:#d1fdd3">{{user.total_payments??'-'}}</td>
                      <td>{{user.total_refunds??'-'}}</td>
                      <td style="background:#d1fdd3">{{user.total??'-'}}</td>
                      <td>{{user.instructor??'-'}}</td>
                    </tr>
                     <tr class="back-color">
                      <td><span class="bolder bold"> الإجماليات: </span></td>
                      <td><span class="bolder bold">{{coursePayments}}</span></td>
                      <td><span class="bolder bold">{{attendancePayment}}</span></td>
                       <td style=""><span class="bolder bold">{{totalReceived}}</span></td>
                      <td style=""><span class="bolder bold">{{totalRefunds}}</span></td>
                      <td><span class="bolder bold">{{total}}</span></td>
                     <td><span class="bolder bold">{{instructorPayment}}</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import VmdInput from '@/components/VmdInput.vue'
import VmdButton from '@/components/VmdButton.vue'
import exportFromJSON from 'export-from-json'
// import { XlsxWorkbook, XlsxSheet, XlsxDownload } from 'vue3-xlsx'
import $ from 'jquery'
const date = new Date()
const today =
  date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate()
export default {
  name: 'DailyCollections',
  components: {
    VmdInput,
    VmdButton
    // XlsxWorkbook,
    // XlsxSheet,
    // XlsxDownload
  },
  data () {
    return {
      tableDate: today,
      users: [],
      usersFile: [],
      coursePayments: 0,
      attendancePayment: 0,
      totalReceived: 0,
      totalRefunds: 0,
      total: 0,
      instructorPayment: 0,
      errors: [],
      sheets: []
    }
  },
  created () {
    this.$http.get(`${this.$hostUrl}receipt/report`).then(response => {
      this.users = response.data.data
      for (let i = 0; i < this.users.length; i++) {
        if (this.users[i].total_payments != null) {
          this.coursePayments += parseInt(this.users[i].course_payment)
          this.attendancePayment += parseInt(this.users[i].attendance_payment)
          this.totalReceived += parseInt(this.users[i].total_payments)
          this.total += parseInt(this.users[i].total)
        }
        if (this.users[i].total_refunds != null) {
          this.totalRefunds += parseInt(this.users[i].total_refunds)
        }
        if (this.users[i].instructor != null) {
          this.instructorPayment += parseInt(this.users[i].instructor)
        }
      }
      $.each(this.users, (key, val) => {
        this.usersFile.push({
          المستخدم: val.name,
          مدفوعات_الدورة: val.course_payment === null ? '-' : val.course_payment,
          مدفوعات_الحضور: val.attendance_payment === null ? '-' : val.attendance_payment,
          صافي_المستلم: val.total_payments === null ? '-' : val.total_payments,
          استرداد_نقدية: val.total_refunds === null ? '-' : val.total_refunds,
          الصافي: val.total === null ? '-' : val.total,
          مدفوعات_المدرس: val.instructor === null ? '-' : val.instructor

        })
      })
      console.log(this.usersFile)
      this.sheets = [{ name: 'SheetOne', data: this.usersFile }]
    })
  },
  methods: {
    downloadFile () {
      const data = this.usersFile
      const fileName = `التحصيلات اليومية - ${today}`
      const exportType = exportFromJSON.types.xls

      if (data) exportFromJSON({ data, fileName, exportType })
    },
    printReport () {
      $('.card-footer').hide()
      const options = {
        name: ' Seven Eleven System ',
        specs: ['fullscreen=yes', 'titlebar=yes', 'scrollbars=yes'],
        styles: [
          'https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.rtl.min.css',
          `${window.location.origin}/print-report.css`
        ],
        timeout: 1000, // default timeout before the print window appears
        autoClose: true, // if false, the window will not close after printing
        windowTitle: 'Seven Eleven System' // override the window title
      }
      setTimeout(this.$htmlToPaper('print-report', options), 10000)
      $('.card-footer').show()
    }
  }
}
</script>
<style>
#collection-date{
  font-weight: 800 !important;
  font-size: 18px !important;
}
.table > :not(:first-child) {
    border-bottom: 1px solid #f0f2f5 !important;
}
.width{
  width:100%;
}
.online{
  border:1px solid #ddd
}
.btns-col{
  display: flex;
    justify-content: center;
    gap: 29px;
}
.bold{
  font-weight: bold;
}
.back-color{
  background: #f8f9fa;
  padding: 10px;
   margin-right:5px !important;
    margin-top: -17px !important;
    color: #222 !important
}
</style>
