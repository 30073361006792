<template>
  <div class="container-fluid py-4 add-student add-receipt">
    <div class="row">
      <div class="col-12">
        <div class="card my-4">
          <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
            <div
              class="bg-gradient-success shadow-success border-radius-lg pt-4 pb-3"
            >
              <h6 class="text-white text-capitalize ps-3">إضافة إيصال</h6>
            </div>
          </div>
          <div class="card-body px-0 pb-2">
            <form @submit="createReceipt" role="form" class="text-start mt-3">
              <div class="row">
                <div class="mb-0 col-12 col">
                  <label>
                    نوع الحركة <span color="danger" class="red">*</span
                    ><span class="error">{{
                      errors.receipt_type_id ? errors.receipt_type_id[0] : ''
                    }}</span></label
                  >
                  <div class="  tab-div tab2 br-w-0 mb-0">
              <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li style="" class="nav-item" role="presentation">
                  <button
                  @click="handleCheckboxChange"
                    class="w-96 nav-link active btn mb-0 bg-gradient-info btn-md null null btn-lg"
                    id="home-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#home"
                    type="button"
                    role="tab"
                    aria-controls="home"
                    aria-selected="true"
                  >
                     حركة نقدية
                  </button>
                </li>
                <li style="" class="nav-item wallet-tab" role="presentation">
                  <button
                  @click="handleWalletReceipt"
                   class="w-96 nav-link btn mb-0 bg-gradient-info btn-md null null btn-lg"
                    id="profile-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#profile"
                    type="button"
                    role="tab"
                    aria-controls="profile"
                    aria-selected="false"
                  >
                     حركة داخلية
                  </button>
                </li>
              </ul>
            </div>
                </div>
                  <div class="tab-content border-bottom mb-5" id="myTabContent">
              <div
                class="tab-pane fade active show"
                id="home"
                role="tabpanel"
                aria-labelledby="home-tab"
              >
              <div class ="row d-flex cache-r">
                <label>
            <span class="error">{{
                      errors.receipt_type_id ? errors.receipt_type_id[0] : ''
                    }}</span></label>
                <div class="col-6">
                  <div class="row checked-row ">
                  <div class="col-4" v-for="type in receiptTypes" :key="type.id">
                    <vmd-checkbox id="free-video" type="text" :value="type.id"
                    v-model="type.selectedType" @change="handleCheckboxChange(type)">
                      {{ type.name }}
                    </vmd-checkbox>
                        </div>
                    </div>
                </div>
                 <div class="mb-4 col-6  radio-row paid-back checked-row">
                  <label class="radio-label">
                    <span color="danger" class="error">{{
                      errors.transaction_type_id
                        ? errors.transaction_type_id[0]
                        : ''
                    }}</span></label
                  >
                  <div
                    class="form-check
                    "
                    v-for="item in transactionTypes"
                    :key="item.id"
                  >
                    <input
                      class="form-check-input"
                      :name="item.name"
                      type="radio"
                      :value="item.id"
                      v-model="receipt.trans"
                      @change="changColor(item.name, item.id)"
                    />
                    <label class="form-check-label" :for="item.name">
                      {{ item.name }}
                    </label>
                  </div>
                </div>
              </div>
              </div>
               <div
                class="tab-pane fade show"
                id="profile"
                role="tabpanel"
                aria-labelledby="profile-tab"
              >
              <div :class="{'paid-back':receipt.wallet === 'wallet-receive',
              'refund-back':receipt.wallet === 'wallet-refund'}"
              class="mb-4 col-8  cache-r radio-row paid-back checked-row">
                  <label class="radio-label">
                    <span color="danger" class="error">{{
                      errors.transaction_type_id
                        ? errors.transaction_type_id[0]
                        : ''
                    }}</span></label
                  >
                  <div
                    class="form-check
                    "
                  >
                    <input
                      class="form-check-input"
                      type="radio"
                      value="wallet-receive"
                      v-model="receipt.wallet"
                      @change='handleWalletReceipt'
                    />
                    <label class="form-check-label">
                      من محفظة لدورة
                    </label>
                  </div>
                   <div
                    class="form-check
                    "
                  >
                    <input
                      class="form-check-input wallet-refund"
                      type="radio"
                      value="wallet-refund"
                      v-model="receipt.wallet"
                      @change='handleWalletReceipt'
                    />
                    <label class="form-check-label" >
                      من  دورة لمحفظة
                    </label>
                  </div>
                </div>
              </div>
              </div>
              </div>
               <div class="row align-center ex-row">
                <div class="mb-3 col-lg-3">
                  <label>  ادخل رقم الطالب أو الهاتف<span class="red">*</span></label>
                  <vmd-input
                    id="student-number"
                    type="text"
                   placeholder="  رقم الطالب او الهاتف "
                    v-model="student.number"
                    @keyup.enter="search"
                    required
                  />
                </div>
                <div class="mb-3 col-lg-3">
                  <label> اسم الطالب </label>
                  <vmd-input
                    id="student-name"
                    type="text"
                    v-model="student.full_name"
                    readonly
                  />
                </div>
                <div class="mb-3 col-lg-3">
                  <label> رقم الهاتف الطالب </label>
                  <vmd-input
                    id="student-name"
                    type="text"
                    v-model="student.mobile"
                    readonly
                  />
                </div>
                <div class="mb-3 col-lg-2">
                  <label>الفرقة </label>
                  <vmd-input
                    id="student-grade"
                    type="text"
                    v-model="student.grade_name"
                    readonly
                  />
                </div>
                <div class="mb-3 col-lg-1">
                  <label>المجموعة </label>
                  <vmd-input
                    id="student-grade"
                    type="text"
                    v-model="student.group_name"
                    readonly
                  />
                </div>

              </div>
              <div class="row">
                <div class="mb-3 col-lg-6" v-if="showCourse">
                  <label>
                    الدورة <span color="danger" class="red">*</span
                    ><span class="error">{{
                      errors.payabel_id ? errors.payabel_id[0] : ''
                    }}</span></label
                  >
                  <multiselect
                    class="required form-control"
                    id="course"
                    v-model="selectedCourse"
                    :options="courses"
                    :searchable="true"
                    :close-on-select="true"
                    placeholder=" اختر الدورة"
                    label="name"
                    track-by="id"
                    required="required"
                    :internal-search="true"
                    @select="getActiveLectures"
                  >
                    <template v-slot:noOptions>
                      <span> فارغة </span>
                    </template>
                    <template v-slot:noResult>
                      <span>لا يوجد نتيجة </span>
                    </template>
                  </multiselect>
                </div>
                <div class="mb-3 col-lg-6" v-if="receipt.attend">
                  <label>
                    المحاضرة <span color="danger" class="red">*</span
                    ><span class="error">{{
                      errors.lecture_id ? errors.lecture_id[0] : ''
                    }}</span></label
                  >
                  <multiselect
                    class="required form-control"
                    id="grade"
                    v-model="selectedLecture"
                    :options="activeLectures"
                    :searchable="true"
                    :close-on-select="true"
                    placeholder=" اختر محاضرة"
                    label="name"
                    track-by="id"
                    required="required"
                    @select="checkStudentEnroll"
                    :internal-search="true"
                  >
                    <template v-slot:noOptions>
                      <span> فارغة </span>
                    </template>
                    <template v-slot:noResult>
                      <span>لا يوجد محاضرات اليوم </span>
                    </template>
                  </multiselect>
                </div>
              </div>
              <div class="row">
                <div class="mb-3 col-lg-6">
                  <label>
                    القيمة <span color="danger" class="red">*</span
                    ><span class="error">{{
                      errors.amount ? errors.amount[0] : ''
                    }}</span></label
                  >
                  <vmd-input
                    id="value"
                    type="number"
                    placeholder="القيمة"
                    v-model="receipt.amount"
                    required
                  />
                </div>
                <div class="mb-3 col-lg-6">
                  <label>
                    البيان <span color="danger" class="red">*</span
                    ><span class="error">{{
                      errors.description ? errors.description[0] : ''
                    }}</span></label
                  >
                  <vmd-input id="" type="text" v-model="receipt.description" />
                </div>
              </div>
              <br/>
              <div class="row border-top pt-4" v-if="!receipt.attend">
                <div class="col-6 text-end">
                  <h6>الرصيد المسدد : {{coursePayed}}</h6>
                </div>
                <div class="col-6 text-end">
                  <h6>الرصيد المتبقي : {{ courseRemain }}</h6>
                </div>
              </div>
              <div class="row">
                <div class="text-center col-lg-6">
                  <vmd-button class="my-4 mb-2 pay-btn" variant="gradient" color="info" type="button" @click="createReceipt">
                    حفظ</vmd-button
                  >
                </div>
                <div class="text-center col-lg-6">
                  <vmd-button
                    type="reset"
                    class="my-4 mb-2"
                    variant="gradient"
                    color="danger"
                  >
                    مسح</vmd-button
                  >
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-4">
      <div class="card col-12 my-4">
        <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
          <div
            class="bg-gradient-success shadow-success border-radius-lg pt-4 pb-3"
          >
            <h6 class="text-white text-capitalize ps-3">
              الإيصالات المالية السابقة
            </h6>
          </div>
        </div>
        <div class="card-body px-0 pb-2">
          <div class="table-responsive p-0">
            <table class="table b-block table-responsive">
              <thead>
                  <tr>
                    <th>رقم</th>
                    <th>الدورة / العرض</th>
                    <th>مسجل حاليا</th>
                    <th>المادة</th>
                    <th>التكلفة</th>
                     <th>تغيير</th>
                    <th> المسدد</th>
                    <th>المتبقي</th>
                  </tr>
              </thead>
              <tbody >
                  <tr v-for="course in student.payments" :key="course.id">
                    <td>
                        <span class="link pointer" @click="getCoursePayments(course.id)">{{ course.number }}</span>
                    </td>
                    <td style="white-space:normal; width:27%" class="wrap-text">
                        <span class="course-name">{{ course.name }}</span>
                    </td>
                    <td v-if="course.pivot.enrolled" class="text-center">
                              <span class="badge badge-sm bg-gradient-success"
                                ><i class="fas fa-check"></i
                              ></span>
                            </td>
                            <td v-else class="text-center">
                              <span class="badge badge-sm bg-gradient-danger">X</span>
                            </td>
                    <td>
                        {{ course.subject?course.subject.name : null }}
                    </td>
                    <td>
                        {{ $filters.number(course.student_cost) }}
                    </td>
                    <td v-if="course.type === 'course'">
                       <vmd-button style="width:100%" class=" text-center " variant="gradient" color="info"
                        type="button" @click="getCourseInfo(course.id, course.name,course.pivot.cost)">
                    تغيير</vmd-button
                  >
                    </td>
                     <td v-else>

                    </td>
                    <td :class="{'paid-back' :course.paid> 0}">
                        {{ $filters.number(course.paid) }}
                    </td>
                    <td>
                        {{ $filters.number(course.remain) }}
                    </td>
                  </tr>
              </tbody>
              <tfoot>
                <tr>
                  <th colspan="6">رصيد الدورات</th>
                  <td>{{ $filters.number(student.totalPayment) }}</td>
                  <td>{{ $filters.number(student.totalRemain) }}</td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div>
      <PrintReceipt :receipt="receipt"></PrintReceipt>
    </div>
    <!-- modal -->
    <vue-final-modal v-model="showModal" name="example" classes="modal-container course-modal" content-class="modal-content">
      <div class="row">
        <div class="col-12">
          <div class="card my-4">
            <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
              <div class="bg-gradient-success shadow-success border-radius-lg pt-4 pb-3">
                <h6 class="text-white text-capitalize ps-3">   تغيير سعر دورة  : {{courseInfo.name}}</h6>
              </div>
            </div>
            <div class="card-body px-0 pb-2">
              <form @submit="changeCourseCost" role="form" class="text-start mt-3">
                <div class="row modal-row">
                  <div class="mb-3 col-lg-6">
                    <label> التكلفة الحالية <span class="red"></span>
                      <span color="danger" class="error">{{
                    }}</span>
                    </label>
                    <vmd-input id="excelFile" type="text"  required  v-model='courseInfo.cost'  readonly/>
                  </div>
                    <div class="mb-3 col-lg-6">
                    <label>  التكلفة الجديدة <span class="red">*</span>
                      <span color="danger" class="error">{{
                    }}</span>
                    </label>
                    <vmd-input id="excelFile" type="text"  required v-model='courseInfo.newCost' />
                  </div>
                </div>
                <div class="row">
                  <div class="text-center col-lg-6">
                    <vmd-button class="my-4 mb-2" variant="gradient" color="info"> حفظ</vmd-button>
                  </div>
                  <div class="text-center col-lg-6">
                    <vmd-button type="reset" class="my-4 mb-2" variant="gradient" color="danger" @click="showModal = false"> إلغاء </vmd-button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </vue-final-modal>
    <!-- course payments comp -->

    <CoursePayments :showPaymentsModal="showPaymentsModal"
    :totalCoursePaments="totalCoursePaments" :coursePayments="coursePayments"
    :courseName='coursePayments?.[0]?.course'  @hide-modal="showPaymentsModal = false"/>
  </div>
</template>
<script>
import VmdInput from '@/components/VmdInput.vue'
import VmdButton from '@/components/VmdButton.vue'
import PrintReceipt from '@/components/PrintReceipt.vue'
import VmdCheckbox from '@/components/VmdCheckbox.vue'
import { VueFinalModal } from 'vue-final-modal'
import swal from 'sweetalert'
import $ from 'jquery'
import CoursePayments from '@/components/CoursePayments.vue'

let timeout = null

export default {
  name: 'AddReceipt',
  components: {
    PrintReceipt,
    VmdInput,
    VmdButton,
    VueFinalModal,
    VmdCheckbox,
    CoursePayments
  },
  data () {
    return {
      showModal: false,
      showPaymentsModal: false,
      coursePayments: [],
      totalCoursePaments: 0,
      receipt: {
        number: null,
        full_name: null,
        amount: '',
        title: '',
        description: 'سداد',
        user_name: localStorage.userName,
        branch: localStorage.branchName,
        trans: '1413a440-d013-416c-a882-dc23fb8d52c4',
        wallet: 'wallet-receive'
      },
      students: [],
      courses: [],
      student: {
        id: null,
        full_name: null,
        mobile: null,
        number: null
      },
      selectedCourse: '',
      coursePayed: 0,
      courseRemain: 0,
      selectedLecture: '',
      activeLectures: [],
      receiptTypes: [],
      transactionTypes: [],
      selectedType: 'd2d3aa71-8c7e-4f94-bbc9-ec516f1ba759',
      enrolled: '',
      courseInfo: {},
      showCourse: true,
      errors: []
    }
  },
  created () {
    this.receiptTypes = [{
      id: 'd2d3aa71-8c7e-4f94-bbc9-ec516f1ba759',
      name: 'دورة'
    },
    {
      id: 'a7e640c6-dbe0-4224-b9a2-e53c7d406a8c',
      name: 'محفظة'
    }]
    this.receiptTypes.find((type) => {
      if (type.id === 'd2d3aa71-8c7e-4f94-bbc9-ec516f1ba759') {
        type.selectedType = true
      }
    })

    this.transactionTypes = this.$store.state.transactionTypes

    if (this.$route.params.id) {
      this.$http.get(`${this.$hostUrl}students/${this.$route.params.id}`).then((response) => {
        this.student = response.data.data
        this.getStudentCourse(this.$route.params.id)
      })
    }
  },
  methods: {
    handleCheckboxChange (selectedType) {
      this.selectedType = selectedType.id
      this.receiptTypes.forEach(type => {
        if (type !== selectedType) {
          type.selectedType = false // Uncheck other types
        }
        if (selectedType.id === 'd2d3aa71-8c7e-4f94-bbc9-ec516f1ba759') {
          this.showCourse = true
        } else {
          this.showCourse = false
        }
      })
    },
    handleWalletReceipt () {
      this.showCourse = true
      if (!this.receipt.wallet) {
        this.receipt.wallet = 'wallet-receive'
      }
      this.selectedType = 'cec350eb-de0c-4fba-8e0d-110d236a95e8'
      if (this.receipt.wallet === 'wallet-receive') {
        this.receipt.trans = '1413a440-d013-416c-a882-dc23fb8d52c4'
        this.receipt.description = 'سداد'
      } else {
        this.receipt.trans = '78d9a393-9b84-4372-bb38-5d8b42eccd29'
        this.receipt.description = 'استرداد'
      }
    },
    getStudentCourse (studentId) {
      const current = this
      current.student.payments = []
      current.student.totalPayment = 0
      current.student.totalRemain = 0
      current.$http.get(`${this.$hostUrl}student/courses?student_id=${studentId}&no_offer=1`).then((response) => {
        current.courses.splice(0)
        response.data.data.forEach(function (course) {
          current.courses.push({
            id: course.id,
            name: course.number + '-' + course.name
          })
          course.subject_name = ''
        })
      })
      current.$http.get(`${current.$hostUrl}student/${studentId}/transactions`).then((response) => {
        current.student.payments = response.data.data
        $.each(current.student.payments, (key, val) => {
          current.student.totalPayment += val.paid
          current.student.totalRemain += val.remain
        })
      })
    },
    getCourseInfo (id, name, cost) {
      this.showModal = true
      this.courseInfo = {
        id: id,
        name: name,
        cost: cost
      }
    },
    async changeCourseCost (e) {
      e.preventDefault()
      const current = this
      const formData = new FormData()
      formData.append('student_id', this.student.id)
      formData.append('course_id', this.courseInfo.id)
      formData.append('cost', this.courseInfo.newCost)
      await this.$http
        .post(this.$hostUrl + 'student/course/change-cost', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then((response) => {
          if (response.data.error) {
            console.log('error')
            swal({ title: response.data.message, buttons: 'تم' })
          } else {
            swal({ title: response.data.message, buttons: 'تم' })
            this.showModal = false
            current.$http.get(`${current.$hostUrl}student/${current.student.id}/transactions`).then((response) => {
              current.student.payments = response.data.data
              $.each(current.student.payments, (key, val) => {
                current.student.totalPayment += val.payed
                current.student.totalRemain += val.remain
              })
            })
          }
        }).catch(function (error) {
          if (Object.prototype.hasOwnProperty.call(error, 'response')) {
            swal({ title: error.response.data.message, buttons: 'تم' })
          }
        })
    },
    async checkStudentEnroll (event) {
      if (!this.student.id || !this.selectedCourse.id) {
        this.errors.lecture_id = []
        this.errors.lecture_id.push(
          'الرجاء ادخال بيانات الطالب واختيار الدورة اولا'
        )
        this.selectedLecture = ''
        console.log(event)
        return false
      }
      const current = this
      await this.$http
        .get(`${this.$hostUrl}enrolled/${event.id}/${this.student.id}`)
        .then((response) => {
          this.enrolled = response.data.data.enrolled
          if (!response.data.data.enrolled) {
            current.receipt.description = 'حضور استثنائي'
          } else {
            current.receipt.description = 'سداد'
          }
        })
    },
    changeReceiptAmount (event) {
      if (this.receipt.attend) {
        this.receipt.amount = 10
      } else {
        this.receipt.amount = ''
      }
    },
    getActiveLectures (event) {
      const current = this
      console.log('event', event.id)
      console.log('event', current.student.id)
      const activeLectures = []
      this.$http
        .get(
          `${this.$hostUrl}student/courses?student_id=${current.student.id}&course_id=${event.id}`
        )
        .then((response) => {
          current.coursePayed = response.data.data[0].payed
          current.courseRemain = response.data.data[0].remain
          $.each(response.data.data[0].lectures, function (key, val) {
            activeLectures.push({
              name: val.lecture_number,
              lecture_number: val.lecture_number,
              courseName: response.data.data[0].name,
              course: {
                id: response.data.data[0].id,
                name: response.data.data[0].name
              },
              grade: val.grade,
              id: val.id
            })
          })
          this.activeLectures = activeLectures
        })
    },
    search () {
      this.student.id = null
      this.student.full_name = null
      this.student.grade_name = null
      this.student.mobile = null
      this.student.payments = []
      this.student.totalPayment = 0
      this.selectedLecture = ''
      if (!this.student.number.length) {
        return false
      }
      const current = this
      this.$store.commit('setSearchVal', current.student.number)
      clearTimeout(timeout)
      timeout = setTimeout(function () {
        current.$http
          .get(`${current.$hostUrl}students-list?search=${current.student.number}`)
          .then((response) => {
            if (response.data.data.length === 1) {
              current.student = response.data.data[0]
              current.student.id = response.data.data[0].id
              current.student.payments = []
              current.student.totalPayment = 0
              current.student.totalRemain = 0
              current.$http.get(`${current.$hostUrl}student/courses?student_id=${response.data.data[0].id}&no_offer=1`).then((response) => {
                current.courses = []
                response.data.data.forEach(function (course) {
                  current.courses.push({
                    id: course.id,
                    name: course.number + '-' + course.name
                  })
                  course.subject_name = ''
                })

                current.$http.get(`${current.$hostUrl}student/${current.student.id}/transactions`).then((response) => {
                  current.student.payments = response.data.data
                  $.each(current.student.payments, (key, val) => {
                    current.student.totalPayment += val.paid
                    current.student.totalRemain += val.remain
                  })
                })
              })
            } else {
              this.student.id = null
              this.student.full_name = null
              this.student.mobile = null
              this.student.grade_name = null
              this.student.payments = []
              this.student.totalPayment = 0
              this.student.totalRemain = 0
            }
            if (response.data.error) {
              swal(response.data.message)
            }
          })
      }, 800)
    },
    changColor (name, id) {
      this.receipt.trans = id
      if (name === 'استلام') {
        this.receipt.description = 'سداد'
        $('.radio-row').addClass('paid-back')
        $('.radio-row').removeClass('refund-back')
      } else {
        this.receipt.description = 'استرداد'
        $('.radio-row').addClass('refund-back')
        $('.radio-row').removeClass('paid-back')
      }
    },
    async createReceipt (e) {
      const current = this
      e.preventDefault()
      const formData = new FormData()
      // if (!this.selectedType) {
      //   this.errors.receipt_type_id = []
      //   this.errors.receipt_type_id.push('الرجاء اختيار نوع الحركة أولا')
      //   return 0
      // }
      if (!this.receipt.amount) {
        this.errors.amount = []
        this.errors.amount.push('حقل السعر مطلوب ')
        return 0
      }
      if (!this.selectedCourse && this.showCourse === true) {
        this.errors.payabel_id = []
        this.errors.payabel_id.push('الرجاء اختيار الدورة أولا')
        return 0
      }
      if (!this.receipt.trans) {
        this.errors.transaction_type_id = []
        this.errors.transaction_type_id.push('الرجاء اختيار نوع الإيصال أولا')
        return 0
      }
      if (this.receipt.attend && !this.selectedLecture) {
        this.errors.lecture_id = []
        this.errors.lecture_id.push('الرجاء اختيار محاضرة لإيصالات الحضور')
        return 0
      }
      if (this.receipt.attend) {
        formData.append('payable_id', this.selectedLecture.id)
      } else {
        formData.append('payable_id', this.selectedCourse.id)
      }
      this.receipt.course = this.selectedCourse.name
      formData.append('student_id', this.student.id)
      this.receipt.full_name = this.student.full_name
      formData.append('receipt_type_id', this.selectedType)
      formData.append('transaction_type_id', this.receipt.trans)
      formData.append('amount', this.receipt.amount)
      formData.append('exceptional', this.enrolled ? 0 : 1)
      if (this.receipt.description) {
        formData.append('description', this.receipt.description)
      }
      if (this.receipt.attend) {
        formData.append('attend', 1)
        this.receipt.attend = 1
      } else {
        formData.append('attend', 0)
        this.receipt.attend = 0
      }
      $('.pay-btn').attr('disabled', true)
      await this.$http
        .post(this.$hostUrl + 'receipts', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then((response) => {
          $('.pay-btn').attr('disabled', false)
          if (response.data.error) {
            swal({ title: response.data.message, buttons: 'تم' })
          } else {
            swal({ title: response.data.message, buttons: 'تم' })
            current.receipt = response.data.data
            current.receipt.transaction_type = response.data.data.transaction_type_name
            current.selectedCourse = ''
            current.receipt.subject_name = ''
            if (current.receipt.number === null) {
              current.receipt.subject_name = current.receipt.payable.subject ? current.receipt.payable.subject.name : ''
            }
          }
        })
        .catch(function (error) {
          $('.pay-btn').attr('disabled', false)
          if (Object.prototype.hasOwnProperty.call(error, 'response')) {
            if (error.response.data.data) {
              current.errors = error.response.data.data
            } else {
              swal({ title: error.response.data.message, buttons: 'تم' })
            }
            window.scrollTo(0, 0)
          }
        })
      if (this.receipt.created_at) {
        console.log(this.receipt.created_at, 'date')
        setTimeout(this.$htmlToPaper('print-receipt'), 90000)
        this.student = {}
        this.courses = []
        this.coursePayed = 0
        this.courseRemain = 0
        this.receipt.amount = ''
        this.receipt.created_at = ''
        this.receipt.wallet = 'wallet-receive'
        this.receipt.trans = '1413a440-d013-416c-a882-dc23fb8d52c4'
        $('.radio-row').addClass('paid-back')
        $('.radio-row').removeClass('refund-back')
      }
    },
    getCoursePayments (courseId) {
      this.totalCoursePaments = 0
      this.coursePayments = []
      this.showPaymentsModal = true
      this.$http
        .get(`${this.$hostUrl}student/${this.student.id}/payments?course_id=${courseId}`)
        .then((response) => {
          this.coursePayments = response.data.data.payments
          this.coursePayments.map((receipt) => {
            if (receipt.transaction_type.id === '78d9a393-9b84-4372-bb38-5d8b42eccd29') {
              this.totalCoursePaments -= receipt.amount
            } else {
              this.totalCoursePaments += receipt.amount
            }
          })
        })
    }
  }
}
</script>
<style>
.radio-row {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f8f9fa;
  margin-top: 28px !important;
  border-radius: 20px;
  gap: 30px;
  position: relative;
  height:50.7px;
  padding-right:40px
}
.custom-control-label {
  float: left !important;
}
.cache-r{
  align-items: center;
  margin-top:-40px !important;
}
.cache-r .col-6{
  padding-left:30px
}
.checked-row label{
  color:#222 !important;
  font-weight:normal !important
}
.checked-row .col-4{
      display: flex;
    align-items: center;
    justify-content: flex-end;
    letter-spacing: 1px;
}
.cache-r .form-check:not(.form-switch)
 .form-check-input[type=checkbox]:checked
{
    font-size: 21px !important
 }
 .cache-r
  .form-check:not(.form-switch) .form-check-input[type=radio]:after {
    background-color: #4caf50 !important;
 }
.attend {
  padding: 5px 25px;
  width: 45%;
  font-weight: 700;
  border-radius: 7px;
  font-weight: bold;
  background: #f8f9fa;
  margin: 25px 0 !important;
}
.check:not(.form-switch) .form-check-input[type='radio']:checked {
  border: 1px solid #222 !important;
}
.green-type {
  background: #78f178 !important;
}
.radio-label {
  position: absolute;
  top: -30px;
}
</style>
