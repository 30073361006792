<template>
     <div class="container-fluid py-4 add-student">
    <div class="row">
      <div class="col-12">
        <div class="card my-4">
          <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
            <div
              class="bg-gradient-success shadow-success border-radius-lg pt-4 pb-3"
            >
              <h6 class="text-white text-capitalize ps-3">  {{$t('message.roomDetail')}} :    {{roomDetails.name}}</h6>
            </div>
          </div>
          <div class="card-body px-0 pb-2">
                   <form v-if='roomDetails.active_lecture.length' role="form" class="text-start mt-3">
                       <div class="row">
                <div class="mb-3 col-lg-6">
                    <label> عدد المحاضرات</label>
                  <vmd-input
                    id=""
                    type="text"
                   label="   10"
                   disabled
                  />
                </div>
                 <div class="mb-3 col-lg-6">
                    <label>  الفرقة</label>
                  <vmd-input
                    id=""
                    type="text"
                   label="   الأولي "
                   disabled
                  />
                </div>
                </div>
                  <div class='row'>
                     <div class="mb-3 col-lg-6">
                    <label>   المجموعة</label>
                  <vmd-input
                    id=""
                    type="text"
                   label="  الأولي "
                   disabled
                  />
                </div>
                <div class="mb-3 col-lg-6">
                      <label>  الترم الدراسي  </label>
                  <vmd-input
                    id=""
                    type="text"
                    label="  الأول"
                    disabled
                  />
                </div>
                       </div>
                <div class='row'>

                <div class="mb-3 col-lg-6">
                      <label>  اسم المادة </label>
                  <vmd-input
                    id=""
                    type="text"
                    label="  اقتصاد"
                    disabled
                  />
                </div>
                 <div class="mb-3 col-lg-6">
                    <label>   اسم المدرس</label>
                  <vmd-input
                    id=""
                    type="text"
                   label="  محمد سعيد "
                   disabled
                  />
                </div>
                 <div class="text-center col-lg-12 back">
                  <vmd-button @click="goBack()"
                    class="my-4 mb-2"
                    variant="gradient"
                    color="secondary"
                    > رجوع</vmd-button
                  >
                </div>
                       </div>

              </form>
              <h3 v-else>{{$t('message.noLectures')}}</h3>
          </div>
        </div>
      </div>
    </div>
     </div>
</template>
<script>
import VmdInput from '@/components/VmdInput.vue'
import VmdButton from '@/components/VmdButton.vue'
export default {
  name: 'RommDetail',
  components: {
    VmdInput,
    VmdButton
  },
  data () {
    return {
      roomDetails: {}
    }
  },
  created () {
    console.log(this.$route.params.id)
    this.$http.get(`${this.$hostUrl}rooms/${this.$route.params.id}`).then(response => {
      this.roomDetails = response.data.data
      console.log(this.roomDetails.active_lecture)
    })
  },
  methods: {
    goBack () {
      this.$router.push({ path: '/rooms' })
    }
  }
}
</script>
