<template>
     <div class="container-fluid py-4 add-student lecture-details">
    <div class="row">
      <div class="col-12">
        <div class="card my-4">
          <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
            <div
              class="bg-gradient-success shadow-success border-radius-lg pt-4 pb-3"
            >
              <h6 class="text-white text-capitalize ps-3"> تفاصيل الدورة - أوفلاين</h6>
            </div>
          </div>
          <div class="card-body px-0 pb-2">
          <form role="form" class="text-start mt-3">
                       <div class="row">
                           <div class="mb-3 col-lg-6">
                            <label> اسم الدورة</label>
                  <vmd-input
                    id=""
                    type="text"
                    :label="course.name"
                    disabled
                  />
                </div>
                    <div class="mb-3 col-lg-6">
                     <label>  المادة</label>
                  <vmd-input
                    id=""
                    type="text"
                    :label="course.subject"
                    disabled
                  />
                </div>
                   </div>
                   <div class='row'>
                     <div class="mb-3 col-lg-6">
                     <label>   الفرقة</label>
                  <vmd-input
                    id=""
                    type="text"
                    :label="course.grade"
                    disabled
                  />
                </div>
                 <div class="mb-3 col-lg-6">
                     <label>  المجموعة</label>
                  <vmd-input
                    id=""
                    type="text"
                    :label="groupArray"
                    disabled
                  />
                </div>
                </div>
                   <div class='row'>
                     <div class="mb-3 col-lg-6">
                     <label>  المدرس</label>
                  <vmd-input
                    id=""
                    type="text"
                    :label="course.instructor"
                    disabled
                  />
                </div>
                 <div class="mb-3 col-lg-6">
                     <label>  الفرع</label>
                  <vmd-input
                    id=""
                    type="text"
                    :label="course.branch"
                    disabled
                  />
                </div>
                </div>
                <div class="row">
                   <div class="mb-3 col-lg-6">
                     <label>  السنة الدراسية</label>
                  <vmd-input
                    id=""
                    type="text"
                    :label="course.academic_year"
                    disabled
                  />
                </div>
                </div>
              </form>
          </div>
        </div>
      </div>
      <!-- lectures -->
      <div class="row">
        <div class="col-12">
          <div class="card my-4">
            <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
              <div
                class="bg-gradient-success shadow-success border-radius-lg pt-4 pb-3"
              >
                <h6 class="text-white text-capitalize ps-3">
                     المحاضرات
                </h6>
              </div>
            </div>
            <div class="card-body px-0 pb-2">
              <div class="table-responsive p-0">
                <table class="table align-items-center mb-0">
                  <thead>
                    <tr>
                         <th
                        class="text-uppercase text-secondary font-weight-bolder opacity-7"
                      >
                        رقم
                      </th>
                      <th
                        class="text-uppercase text-secondary font-weight-bolder opacity-7"
                      >
                        القاعة
                      </th>
                      <th
                        class="text-uppercase text-secondary font-weight-bolder opacity-7"
                      >
                         تاريخ بدء المحاضرة
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="lecture in lectures"
                      :key="lecture.id"
                    >
                      <td>
                        <p class="text-s mb-0">{{ lecture.lecture_number }}</p>
                      </td>
                      <td>
                        <p class="text-s mb-0">{{lecture.room.name}}</p>
                      </td>
                      <td>
                        <p class="text-s mb-0">{{lecture.start  }}</p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- students -->
      <div class="row">
        <div class="col-12">
          <div class="card my-4">
            <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
              <div
                class="bg-gradient-success shadow-success border-radius-lg pt-4 pb-3"
              >
                <h6 class="text-white text-capitalize ps-3">
                     الطلاب المسجلين في الدورة
                </h6>
              </div>
            </div>
            <div class="card-body px-0 pb-2">
              <div class="table-responsive p-0">
                <table class="table align-items-center mb-0">
                  <thead>
                    <tr>
                         <th
                        class="text-uppercase text-secondary font-weight-bolder opacity-7"
                      >
                        رقم
                      </th>
                      <th
                        class="text-uppercase text-secondary font-weight-bolder opacity-7"
                      >
                        الاسم
                      </th>
                      <th
                        class="text-uppercase text-secondary font-weight-bolder opacity-7"
                      >
                          رقم الهاتف
                      </th>
                       <th
                        class="text-uppercase text-secondary font-weight-bolder opacity-7"
                      >
                           المجموعة
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="student in students"
                      :key="student.id"
                    >
                      <td>
                        <p class="text-s mb-0">{{ student.number }}</p>
                      </td>
                      <td>
                        <p class="text-s mb-0">{{student.full_name  }}</p>
                      </td>
                      <td>
                        <p class="text-s mb-0">{{student.mobile  }}</p>
                      </td>
                       <td>
                        <p class="text-s mb-0">{{student.group?student.group.name:'-'  }}</p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
     </div>
</template>
<script>
import VmdInput from '@/components/VmdInput.vue'
// import $ from 'jquery'
export default {
  name: 'ShowCourse',
  components: {
    VmdInput
  },
  data () {
    return {
      course: {},
      groupArray: '',
      lectures: [],
      students: []
    }
  },
  created () {
    this.$http.get(`${this.$hostUrl}courses/${this.$route.params.id}`).then(response => {
      this.course = response.data.data
      const sliceEnd = this.course.groups.length - 1
      this.groupArray = (this.course.groups).split(' ').join('-').slice(0, sliceEnd)
      this.lectures = this.course.lectures
      this.students = this.course.students
    })
  }
}
</script>
<style scoped>
.message{
  padding:10px
}
</style>
