<template>
  <div class="container-fluid py-4 all-students">
    <div class="row">
      <div class="col-12">
        <div class="card my-4">
          <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
            <div class="bg-gradient-success shadow-success border-radius-lg pt-4 pb-3">
              <div class="row">
                <div class="col-lg-6">
                  <h6 class="text-white text-capitalize ps-3">  {{$t('message.courses')}}</h6>
                </div>
                <div class="col-lg-6">
                  <router-link to="/courses/create">
                    <span class="badge badge-sm new-student">
                      <i class="fas fa-location-arrow"></i> {{$t('message.createCourse')}} </span>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body px-0 pb-2">
            <!-- filters -->
            <div class="row align-center padding">
              <div class="mb-3 col-lg-4">
                <label> {{$t('message.teachers')}}</label>
                <multiselect class='required form-control' id='grade' v-model="selectedInsrtuctor" :options='instructors' :searchable='true' :close-on-select='true'
                 :placeholder="$t('message.select')" label='name' track-by='name' required='required' :internal-search="true">
                  <template v-slot:noOptions>
                    <span> فارغة </span>
                  </template>
                  <template v-slot:noResult>
                    <span>لا يوجد نتيجة </span>
                  </template>
                </multiselect>
              </div>
              <div class="mb-3 col-lg-4">
                <label> {{$t('message.subjects')}}</label>
                <multiselect class='required form-control' id='grade' v-model="selectedSubject"
                 :options='subjects' :searchable='true'
                 :close-on-select='true'
                 :placeholder="$t('message.select')" label='name' track-by='name' required='required' :internal-search="true"> > <template v-slot:noOptions>
                    <span> فارغة </span>
                  </template>
                  <template v-slot:noResult>
                    <span>لا يوجد نتيجة </span>
                  </template>
                </multiselect>
              </div>
               <div class="mb-3 col-lg-4">
                <label> {{$t('message.department')}}</label>
                <multiselect class='required form-control' id='grade'
                 v-model="selectedDepartment"
                 :options='departments' :searchable='true'
                 :close-on-select='true'
                 :placeholder="$t('message.select')" label='name' track-by='name' required='required' :internal-search="true"> > <template v-slot:noOptions>
                    <span> فارغة </span>
                  </template>
                  <template v-slot:noResult>
                    <span>لا يوجد نتيجة </span>
                  </template>
                </multiselect>
              </div>
              <div class="text-center col-lg-4 ">
                <vmd-button class="my-4 mb-2 width" variant="gradient" color="info" @click="filterData()"> {{$t('message.filter')}}</vmd-button>
              </div>
              <div class="text-center col-lg-4 ">
                <vmd-button class="my-4 mb-2 width" variant="gradient" color="danger" @click="clearData()">{{$t('message.delete')}}</vmd-button>
              </div>
            </div>
            <div class="row">
              <vdtnet-table class=" table-striped table table-borderless "
               :jquery="jq" ref="table" :fields="fields" :opts="options"
                @edit="doAlertEdit"
                @show="doAlertShow"
                @changeStatus="doAlertChangeStatus"
                @add='doAlertLecture'
                @promoCode='createPromoCode'
                @registerGroup='doAlertRegisterGroup'>
                    </vdtnet-table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- modal -->
    <vue-final-modal v-model="showModal" name="example" classes="modal-container course-modal" content-class="modal-content">
      <div class="row">
        <div class="col-12">
          <div class="card my-4">
            <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
              <div class="bg-gradient-success shadow-success border-radius-lg pt-4 pb-3">
                <h6 class="text-white text-capitalize ps-3"> تسجيل مجموعة طلاب في دورة {{courseName}}</h6>
              </div>
            </div>
            <div class="card-body px-0 pb-2">
              <form @submit="enrollGroup" role="form" class="text-start mt-3">
                <div class="row modal-row">
                  <div class="mb-3 col-lg-9">
                    <label> ملف هواتف الطلاب (xlsx) <span class="red">*</span>
                      <span color="danger" class="error">{{ errors.extension? errors.extension[0] : ''
                    }}</span>
                    </label>
                    <vmd-input id="excelFile" type="file" @change='onFileChange' required />
                  </div>
                </div>
                <div class="row">
                  <div class="text-center col-lg-6">
                    <vmd-button class="my-4 mb-2" variant="gradient" color="info"> حفظ</vmd-button>
                  </div>
                  <div class="text-center col-lg-6">
                    <vmd-button type="reset" class="my-4 mb-2" variant="gradient" color="danger" @click="showModal = false"> إلغاء </vmd-button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </vue-final-modal>
  </div>
</template>

<script>
import VdtnetTable from 'vue-datatables-net'
import swal from 'sweetalert'
import $ from 'jquery'
import { VueFinalModal } from 'vue-final-modal'
import VmdInput from '@/components/VmdInput.vue'
import VmdButton from '@/components/VmdButton.vue'
let selectedInsrtuctor = ''
let selectedDepartment = ''
let selectedSubject = ''

export default {
  name: 'Courses',
  components: {
    VdtnetTable,
    VueFinalModal,
    VmdInput,
    VmdButton

  },
  data () {
    return {
      showCourses: 1,
      groups: [],
      grades: [],
      subjects: [],
      instructors: [],
      selectedGrade: '',
      selectedGroup: '',
      selectedInsrtuctor: '',
      selectedSubject: '',
      departments: [],
      selectedDepartment: '',
      showModal: false,
      courseName: '',
      excelFile: '',
      courseId: null,
      errors: [],
      pagination: {
        rowsPerPage: 30
      },
      jq: $,
      options: {
        ajax: {
          beforeSend: function (xhr) {
            xhr.setRequestHeader(
              'Authorization',
              'Bearer ' + localStorage.token
            )
          },
          url: `${this.$hostUrl}courses`,
          data: (d) => {
            if (selectedInsrtuctor) {
              d.instructor_id = selectedInsrtuctor
            }
            if (selectedSubject) {
              d.subject_id = selectedSubject
            }
            if (selectedDepartment) {
              d.department_id = selectedDepartment
            }
          },
          dataSrc: (json) => {
            const filteredCourse = json.data.filter((item) => {
              if (this.showCourses === 1) {
                return item
              } else if (this.showCourses === 2) { return item.type === 1 } else {
                return item.type === 0
              }
            })
            return filteredCourse
          }

        },
        buttons: [
          {
            extend: 'print',
            text: '',
            titleAttr: 'طباعة',
            className: 'fas fa-print btn-primary',
            exportOptions: {
              columns: [0, 1, 2, 3, 4, 5, 6, 7]
            },
            customize: function (win) {
              $(win.document.body).css('direction', 'rtl')
            }
          },
          {
            extend: 'copy',
            text: '',
            className: 'fas fa-copy btn-warning',
            titleAttr: 'نسخ',
            exportOptions: {
              columns: 'th:not(:last-child)'
            }
          },
          {
            extend: 'excel',
            text: '',
            titleAttr: 'إكسل',
            className: 'fas fa-table btn-success',
            exportOptions: {
              columns: 'th:not(:last-child)'
            }
          }
        ],
        dom:
          "<'row'<'col-sm-12 col-md-6'f><'col-sm-12 col-md-6 added'l>>" +
          "<'row'<'col-sm-12'Btr>>" +
          "<'row'<'col-sm-12 col-md-6'p><'col-sm-12 col-md-6'i>>",
        paging: true,
        language: {
          url: this.$t('message.url'),
          processing: '<i class="fa fa-spinner fa-spin" style="font-size:40px;color:#7aeb7f"></i>'
        },
        responsive: false,
        processing: true,
        searching: true,
        searchDelay: 1500,
        destroy: true,
        ordering: true,
        lengthChange: true,
        order: [[5, 'desc']],
        serverSide: true,
        fixedHeader: true,
        saveState: true,
        select: false
      },
      fields: {
        number: {
          label: this.$t('message.id'),
          sortable: true,
          searchable: true
        },
        name: {
          label: this.$t('message.name'),
          sortable: true,
          searchable: true
        },
        subject: {
          label: this.$t('message.subject'),
          sortable: true,
          searchable: true
        },
        'subject_object.department.name': {
          label: this.$t('message.department'),
          sortable: true,
          searchable: true,
          isLocal: true
        },
        instructor: {
          label: this.$t('message.teacher'),
          sortable: true,
          searchable: true
        },
        type_value: {
          label: this.$t('message.type'),
          sortable: true,
          searchable: true
        },
        created_at: {
          label: this.$t('message.createdAt'),
          sortable: true,
          searchable: true,
          defaultOrder: 'desc'
        },
        status_value: {
          label: this.$t('message.status'),
          sortable: true,
          searchable: true
        },
        status_btn: {
          label: this.$t('message.change'),
          sortable: true,
          searchable: true
        },
        lecture: {
          label: this.$t('message.lectures'),
          sortable: true,
          searchable: true,
          defaultContent:
            '&ensp;<a href="javascript:void(0);" data-action="add"><i class="mdi mdi-square-edit-outline"></i><span class="badge badge-sm bg-gradient-primary">' + this.$t('message.add') + '</span></a>'
        },
        studentsGroup: {
          label: this.$t('message.registerStudents'),
          sortable: true,
          searchable: true,
          defaultContent:
            '&ensp;<a href="javascript:void(0);" data-action="registerGroup"><i class="mdi mdi-square-edit-outline"></i><span class="badge badge-sm btn-secondary">' + this.$t('message.add') + '</span></a>'
        },
        actions: {
          isLocal: true,
          label: this.$t('message.operations'),
          defaultContent:
            '&ensp;<a href="javascript:void(0);" data-action="edit"><i class="fas fa-edit"></i> </a>' +
            '&ensp;<a href="javascript:void(0);" data-action="show"><i class="fas fa-eye" title="عرض"></i> </a>'
        }
      }
    }
  },
  created () {
    const subjects = []
    this.$http.get(`${this.$hostUrl}subjects`).then((response) => {
      $.each(response.data.data, function (key, val) {
        subjects.push({
          name: val.name,
          id: val.id
        })
      })
      this.subjects = subjects
    })
    const instructors = []
    this.$http.get(`${this.$hostUrl}instructors`).then((response) => {
      $.each(response.data.data, function (key, val) {
        instructors.push({
          name: val.name,
          id: val.id
        })
      })
      this.instructors = instructors
    })
    const departments = []
    this.$http.get(`${this.$hostUrl}departments`).then((response) => {
      $.each(response.data.data, function (key, val) {
        departments.push({
          name: val.name,
          id: val.id
        })
      })
      this.departments = departments
    })
  },
  methods: {
    getSubjects (event) {
      const subjects = []
      this.$http.get(`${this.$hostUrl}subject/filter?grade_id=${event.id}`).then((response) => {
        console.log('response.data.data', response.data.data)
        $.each(response.data.data, function (key, val) {
          subjects.push({
            name: val.name,
            id: val.id
          })
        })
        this.subjects = subjects
      })
    },
    coursePermission () {
      if (localStorage.permissions) {
        const permissions = localStorage.permissions
        if (permissions.includes('Course:Both')) {
          this.showCourses = 1
        } else if (permissions.includes('Course:Online')) {
          this.showCourses = 2
        } else {
          this.showCourses = 3
        }
      }
    },
    filterData () {
      selectedInsrtuctor = this.selectedInsrtuctor.id
      selectedSubject = this.selectedSubject.id
      selectedDepartment = this.selectedDepartment.id
      const table = this.$refs.table
      table.reload()
    },
    clearData () {
      this.selectedInsrtuctor = ''
      selectedInsrtuctor = ''
      this.selectedSubject = ''
      this.selectedDepartment = ''
      this.filterData()
      // const table = this.$refs.table
      // table.reload()
    },
    translateStatus (data, type, row, meta) {
      console.log(data)
      // if (data.status === 1) {
      //   data = 'مفتوحة'
      // } else {
      //   data = 'مغلقة'
      // }
      // return data
    },
    changeGrade (data, type, row, meta) {
      const sliceEnd = data.length - 1
      return data.split(' ').join('-').slice(0, sliceEnd)
    },
    doAlertShow (data) {
      if (data.type === 0) {
        this.$router.push({
          path: `/courses/${data.id}/show`,
          params: { id: data.id }
        })
      } else {
        this.$router.push({
          path: `/courses/${data.id}/show-online-course`,
          params: { id: data.id }
        })
      }
    },
    doAlertEdit (data) {
      this.$router.push({
        path: `/courses/${data.id}/edit`,
        params: { id: data.id }
      })
    },
    createPromoCode (data) {
      if (data.type === 1) {
        this.$router.push({
          path: `/courses/${data.id}/promo-code`,
          params: { id: data.id }
        })
      } else {
        swal('غير متاح البروموكود للدورة الأوفلاين')
      }
    },
    doAlertLecture (data) {
      if (data.type === 0) {
        this.$router.push({
          path: `/courses/${data.id}/add-lecture`,
          params: { id: data.id }
        })
      } else {
        this.$router.push({
          path: `/courses/${data.id}/add-video`,
          params: { id: data.id }
        })
      }
    },
    async doAlertChangeStatus (data) {
      swal({
        title: 'هل انت متأكد ؟',
        text: 'بمجرد الموافقة ، سيتم   تغيير حالة الدورة  !',
        icon: 'warning',
        buttons: ['لا', 'نعم'],
        dangerMode: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33'
      }).then((willDelete) => {
        if (willDelete) {
          this.$http
            .post(
              `${this.$hostUrl}change-status?course_id=${data.id}`
            )
            .then((response) => {
              console.log(response)
              swal({ title: response.data.message, buttons: 'تم' })
              const table = this.$refs.table
              setTimeout(() => {
                table.reload()
              }, 1500)
            }).catch((error) => {
              if (Object.prototype.hasOwnProperty.call(error, 'response')) {
                swal({ title: error.response.data.message, buttons: 'تم' })
              }
            })
        } else {
          swal({ title: ' تم الإلغاء  !', buttons: 'تم' })
        }
      })
    },
    doAlertRegisterGroup (data) {
      this.showModal = 'true'
      this.courseName = data.name
      this.courseId = data.id
    },
    onFileChange (e) {
      this.excelFile = e.target.files[0]
    },
    async enrollGroup  (e) {
      e.preventDefault()
      // this.excelFile = ''
      console.log(this.courseName, this.courseId)
      const formData = new FormData()
      formData.append('file', this.excelFile)
      formData.append('course_id', this.courseId)

      this.errors = await this.postRequest(formData, this.$hostUrl + 'course/enroll/students', '')
      if (!this.errors.extension) {
        this.showModal = false
      }
    }
  }
}
</script>
<style>
.ps-3 {
    padding-right: 1rem !important;}
    .me-3 {
    margin-left: 1rem !important;}
    .course-modal .modal-content{
     margin: auto;
    z-index: 99999;
    width:40% !important;
    position:absolute;
    top:40%;
    left:40%;
    transform: translate(-40%,-50%);
}
.modal-row{
    display: flex;
    justify-content: center;
    align-items: center;
}
.padding{
  padding-right:10px;
  padding-left:10px
}

</style>
