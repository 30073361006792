<template>
     <div class="container-fluid py-4 add-student lecture-details">
    <div class="row">
      <div class="col-12">
        <div class="card my-4">
          <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
            <div
              class="bg-gradient-success shadow-success border-radius-lg pt-4 pb-3"
            >
              <h6 class="text-white text-capitalize ps-3">   عرض  الوظيفة</h6>
            </div>
          </div>
          <div class="card-body px-0 pb-2">
          <form role="form" class="text-start mt-3">
                       <div class="row">
                           <div class="mb-3 col-lg-5">
                            <label>الاسم</label>
                  <vmd-input
                    id=""
                    type="text"
                    :label="role.name"
                    disabled
                  />
                </div>
                </div>
              </form>
            <!-- permissions -->
            <div class="row margin-top">
        <div class="col-12">
          <div class="card my-4">
            <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
              <div
                class="bg-gradient-success shadow-success border-radius-lg pt-4 pb-3"
              >
                <h6 class="text-white text-capitalize ps-3">
                   الاذونات
                </h6>
              </div>
            </div>
            <div class="card-body px-0 pb-2">
              <div class="table-responsive p-0">
                <table class="table align-items-center mb-0">
                  <thead>
                    <tr>
                      <th
                        class="text-uppercase text-secondary font-weight-bolder opacity-7"
                      >
                        الرقم
                      </th>
                      <th
                        class="text-uppercase text-secondary font-weight-bolder opacity-7"
                      >
                        الاسم
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="item in permissions"
                      :key="item.id"
                    >
                      <td>
                        <p class="text-s mb-0">{{ item.id }}</p>
                      </td>
                      <td>
                        <p class="text-s mb-0">{{item.name  }}</p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
          </div>
        </div>
      </div>
    </div>
     </div>
</template>
<script>
import VmdInput from '@/components/VmdInput.vue'
import $ from 'jquery'
export default {
  name: 'ShowRole',
  components: {
    VmdInput
  },
  data () {
    return {
      role: {},
      permissions: [],
      errors: []
    }
  },
  created () {
    this.$http.get(`${this.$hostUrl}roles/${this.$route.params.id}`)
      .then((response) => {
        this.role = response.data.data
        const permissions = []
        $.each(response.data.data.permissionsArray, function (key, value) {
          permissions.push({
            name: value.name_ar,
            id: value.id
          })
        })
        this.permissions = permissions
      })
  }
}
</script>
