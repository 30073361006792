<template>
     <div class="container-fluid py-4 add-student">
    <div class="row">
      <div class="col-12">
        <div class="card my-4">
          <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
            <div
              class="bg-gradient-success shadow-success border-radius-lg pt-4 pb-3"
            >
              <h6 class="text-white text-capitalize ps-3">  إضافة محاضرة</h6>
            </div>
          </div>
          <div class="card-body px-0 pb-2">
                   <form @submit='createLecture' role="form" class="text-start mt-3">
                       <div class='row'>
                      <div class="mb-3 col-lg-6">
                  <label>  رقم المحاضرة<span class="red">*</span>
                    <span color="danger" class="error">{{
                      errors.lecture_number ? errors.lecture_number[0] : ''
                    }}</span></label
                  >
                  <vmd-input
                    id=""
                    min-length='10'
                    type="number"
                   v-model='course.next_lecture_number'
                   required
                  />
                </div>
                   <div class="mb-3 col-lg-6">
                  <label>   القاعة<span class="red">*</span>
                  </label>
                     <multiselect
                        class='required form-control'
                        id='room'
                        v-model="selectedRoom"
                        :options='rooms'
                        :searchable='true'
                        :close-on-select='true'
                        placeholder="  اختر القاعة"
                        label='name'
                        :custom-label="customLabel"
                        track-by='name'
                        required='required'
                        :internal-search="true"
                        >
                      >
                        <template v-slot:noOptions>
                          <span> فارغة </span>
                        </template>
                        <template v-slot:noResult>
                          <span>لا يوجد نتيجة </span>
                        </template>
                      </multiselect>
                </div>
                       </div>
                       <div class='row'>
                     <div class="mb-3 col-lg-6 ">
                    <label>   تاريخ بدء  المحاضرة<span class="red">*</span></label>
                    <div class='date'>
                  <vmd-input
                    id="date-input"
                    type="datetime-local"
                   label="    تاريخ  بدء المحاضرة "
                   v-model='lecture.date'
                   required
                  />
                  </div>
                </div>
                       </div>
                            <div class='row' v-if='course.type'>
                              <h5 id='data' style="display: flex">اضافة فيديو</h5>
                     <div class="mb-3 col-lg-6 ">
                    <label> عنوان الفيديو<span class="red">*</span></label>
                  <vmd-input
                    id="date-input"
                    type="text"
                   placeholder="  عنوان الفيديو "
                   v-model='video.name'
                   required
                  />
                  </div>
                    <div class="mb-3 col-lg-6 ">
                    <label>   عنوان الفيديو بالإنجليزية<span class="red">*</span></label>
                  <vmd-input
                    id="date-input"
                    type="text"
                   placeholder=" عنوان الفيديو بالإنجليزية "
                   v-model='video.name_en'
                   required
                  />
                  </div>
                  </div>
                  <div class='row' v-if='course.type'>
                       <div class="mb-3 col-lg-6 ">
                    <label>  قيمة الفيديو<span class="red">*</span></label>
                  <vmd-input
                    id="date-input"
                    type="text"
                   placeholder=" قيمة الفيديو  "
                   v-model='video.cost'
                   required
                  />
                  </div>
                  <div class="col-lg-6 mb-3">
                 <label> تحميل الفيديو (mp4,x-flv,x-mpegURL,MP2T,3gpp,x-msvideo,x-ms-wmv)<span class="red">*</span></label><br>
                 <div>
                                <vue-dropzone
                  ref="myVueDropzone"
                  id="dropzone"
                  :options="dropzoneOptions"
                  @vdropzone-sending="sendingEvent"
                />
                </div>
                </div>
                  </div>
                       <div class="row">
                <div class="text-center col-lg-6">
                  <vmd-button
                    class="my-4 mb-2"
                    variant="gradient"
                    color="info"
                    > حفظ</vmd-button
                  >
                </div>
                 <div class="text-center col-lg-6">
                  <vmd-button
                    type='reset'
                    class="my-4 mb-2"
                    variant="gradient"
                    color="danger"
                    > مسح</vmd-button
                  >
                </div>
                       </div>
              </form>
          </div>
        </div>
      </div>
    </div>
     </div>
</template>
<script>
import VmdButton from '@/components/VmdButton.vue'
import VmdInput from '@/components/VmdInput.vue'
import vueDropzone from 'vue2-dropzone-vue3'
import swal from 'sweetalert'
import $ from 'jquery'
export default {
  name: 'CreateLecture',
  components: {
    VmdButton,
    VmdInput,
    vueDropzone
  },
  data () {
    return {
      dropzoneOptions: {
        url: `${this.$hostUrl}video/upload`,
        thumbnailWidth: 150,
        maxFilesize: 500,
        addRemoveLinks: true,
        autoProcessQueue: true,
        timeout: 200000,
        chunking: true,
        chunkSize: 2000000,
        headers: { Authorization: 'Bearer ' + localStorage.token },
        dictDefaultMessage: '<i class="fas fa-cloud-upload-alt"></i>تحميل الفيديو'
      },
      rooms: [],
      headers: { Authorization: 'Bearer ' + localStorage.token },
      selectedRoom: '',
      lecture: {},
      course: {},
      video: {},
      errors: []
    }
  },
  created () {
    this.$http
      .get(`${this.$hostUrl}courses/${this.$route.params.id}`)
      .then((response) => {
        this.course = response.data.data
        this.video.cost = this.course.lecture_cost
        if (this.course.latest_room_id) {
          this.selectedRoom = this.course.latest_room_id
        }
      })
    const rooms = []
    this.$http.get(`${this.$hostUrl}rooms`)
      .then((response) => {
        $.each(response.data.data, function (key, value) {
          rooms.push({
            name: value.name,
            id: value.id
          })
        })
        this.rooms = rooms
      })
  },
  methods: {
    sendingEvent (file, xhr, formData) {
      formData.append('course_id', this.$route.params.id)
      formData.append('name', this.video.name)
      formData.append('name_en', this.video.name_en)
      formData.append('cost', this.video.cost)
      formData.append('watch_limit', 5)
    },
    async createLecture (e) {
      e.preventDefault()
      const formData = new FormData()
      formData.append('course_id', this.$route.params.id)
      formData.append('lecture_number', this.course.next_lecture_number)
      formData.append('room_id', this.selectedRoom.id)
      const formatDate = this.lecture.date.replace('T', ' ')
      formData.append('start', formatDate)
      await this.$http
        .post(
            `${this.$hostUrl}lectures`,
            formData
        )
        .then((response) => {
          if (!response.data.error) {
            swal({ title: response.data.message, buttons: 'تم' })
          } else {
            this.errors = response.data.data
          }
        }).catch(function (error) {
          if (Object.prototype.hasOwnProperty.call(error, 'response')) {
            const data = (error.response || {}).data
            if (data.error) {
              swal(data.message)
            }
          }
        })
    }
  }
}
</script>
<style >
.dropzone__box {
      width: 100% !important;
    background: #f8f9fa !important;
    min-height: 40px !important;
    cursor: pointer !important;
}
</style>
