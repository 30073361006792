 <template>
     <div class="container-fluid py-4 add-student ">
    <div class="row">
      <div class="col-12">
        <div class="card my-4">
          <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
            <div
              class="bg-gradient-success shadow-success border-radius-lg pt-4 pb-3"
            >
              <h6 class="text-white text-capitalize ps-3">  تفاصيل الإشعارات</h6>
            </div>
          </div>
          <div class="card-body px-0 pb-2">
          <form role="form" class="text-start mt-3">
                       <div class="row">
                           <div class="mb-3 col-lg-6">
                            <label> العنوان</label>
                  <vmd-input
                    id=""
                    type="text"
                    :label="notification.title"
                    disabled
                  />
                </div>
                   <div class="mb-3 col-lg-6">
                      <label> تاريخ الإنشاء</label>
                  <vmd-input
                    id=""
                    type="text"
                    :label="notification.created_at"
                    disabled
                  />
                </div>
                   </div>
                   <div class='row'>
                 <div class="mb-3 col-lg-5">
                  <label>  الرسالة</label>
                    <div class="input-group">
                 <textarea class="form-control message"
                 rows="4"
                 cols="100"
                 placeholder='الرسالة'
                 :value='notification.content?removehtmltags(notification.content):null'
                 disabled
                  >
                 </textarea>
                  </div>
                </div>
                </div>
              </form>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="card my-4">
            <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
              <div
                class="bg-gradient-success shadow-success border-radius-lg pt-4 pb-3"
              >
                <h6 class="text-white text-capitalize ps-3">
                   الطلاب الذين تم  إشعارهم
                </h6>
              </div>
            </div>
            <div class="row notifi-row">
              <div class="col-3">
                <h6>    اجمالي الطلاب : <span class="green">{{totals.students}}</span></h6>
                </div>
                <div class="col-3">
                <h6>     من لديه التطبيق : <span class="green">{{totals.app}}</span></h6>
                </div>
                <div class="col-3 d-flex">
                 <h6 class="gr-text">   اجمالي الإرسال : <span class="green">{{ totals.sent }} </span></h6>
                </div>
                <div class="col-3 d-flex">
                 <h6 class="gr-text">   اجمالي المشاهدة : <span class="green">{{ totals.seen }} </span></h6>
                </div>
              </div>
            <div class="card-body px-0 pb-2">
              <div class="table-responsive p-0">
                <table class="table align-items-center mb-0">
                  <thead>
                    <tr>
                         <th
                        class="text-uppercase text- font-weight-bolder "
                      >
                        رقم
                      </th>
                      <th
                        class="text-uppercase text- font-weight-bolder "
                      >
                        اسم الطالب
                      </th>
                      <th
                        class="text-uppercase text- font-weight-bolder "
                      >
                        رقم الهاتف
                      </th>
                       <th
                        class="text-uppercase text- font-weight-bolder "
                      >
                        الفرقة
                      </th>
                       <th
                        class="text-uppercase text- font-weight-bolder "
                      >
                        لديه التطبيق
                      </th>
                        <th
                        class="text-uppercase text- font-weight-bolder "
                      >
                        الإرسال
                      </th>
                       <th
                        class="text-uppercase text- font-weight-bolder "
                      >
                        المشاهدة
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="student in students"
                      :key="student.id"
                    >
                      <td>
                        <p class="text-s mb-0">{{ student?.number }}</p>
                      </td>
                      <td>
                        <p class="text-s mb-0">{{student?.full_name  }}</p>
                      </td>
                      <td>
                        <p class="text-s mb-0">{{student?.mobile  }}</p>
                      </td>
                       <td>
                        {{student?.grade?.name}}
                      </td>
                       <td v-if="student?.app === 1" class="text-center">
                              <span  class=" pointer badge badge-sm bg-gradient-success"
                                ><i class="fas fa-check"></i
                              ></span>
                            </td>
                            <td v-else class="text-center">
                              <span class="badge pointer badge-sm bg-gradient-danger" title=" لم يتم الإرسال">X</span>
                            </td>
                       <td v-if="student?.sent" class="text-center">
                              <span  class=" pointer badge badge-sm bg-gradient-success"
                                ><i class="fas fa-check"></i
                              ></span>
                            </td>
                            <td v-else class="text-center">
                              <span class="badge pointer badge-sm bg-gradient-danger" title=" لم يتم الإرسال">X</span>
                            </td>
                        <td v-if='student.pivot?.seen' class="text-center">
                      <i class="fas fa-eye green pointer" title="تمت المشاهدة"></i>
                      </td>
                       <td v-else class="text-center">
                      <i  class="fas fa-eye-slash red pointer" title="لم تتم المشاهدة"></i>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
     </div>
</template>
<script>
import VmdInput from '@/components/VmdInput.vue'
// import $ from 'jquery'
export default {
  name: 'ShowNotification',
  components: {
    VmdInput
  },
  data () {
    return {
      notification: {},
      students: []
    }
  },
  created () {
    this.$http.get(`${this.$hostUrl}notifications/${this.$route.params.id}`).then(response => {
      this.notification = response.data.data
      this.students = response.data.data.students
    })
  },
  computed: {
    totals () {
      const sent = this.students.filter(student => student.sent === 1).length
      const seen = this.students.filter(student => student.pivot.seen === 1).length
      const app = this.students.filter(student => student.app === 1).length
      return {
        students: this.students.length,
        sent,
        seen,
        app
      }
    }
  },
  methods: {
    removehtmltags (content) {
      content = content.replace(/<p>/g, '')
      content = content.replace(/<\/p>/g, '\n')
      const div = document.createElement('div')
      div.innerHTML = content
      return div.textContent || div.innerText || ''
    }
  }
}
</script>
<style scoped>
.message{
  padding:10px
}
.pointer{
  cursor: pointer;
}
.notifi-row span{
  font-size: 17px;
font-weight: bold;
font-family: Montserrat, sans-serif !important;
letter-spacing: 2px;
}
.notifi-row .col-3{
  padding-top: 21px;
  padding-right: 27px;
}
</style>
