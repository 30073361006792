<template>
  <div class="container-fluid py-4 instr-report">
    <div class="row">
      <div class="col-12">
        <div class="card mx-4">
          <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
            <div
              class="bg-gradient-success shadow-success border-radius-lg pt-4 pb-3"
            >
              <h6 class="text-white text-capitalize ps-3">تقارير المدرس</h6>
            </div>
          </div>
          <div class="card-body px-4 pb-2" style="direction: rtl">
            <div class="row">
              <h4 style="font-size:16px" class="text-warning">يجب اختيار المدرس أولا</h4>
              <div class="mb-3 col-6">
                <label>
                  المدرسين <span color="danger" class="red">*</span>
                </label>
                <multiselect
                  class="required form-control"
                  id="grade"
                  v-model="instructor"
                  :options="instructors"
                  :searchable="true"
                  :close-on-select="true"
                  placeholder=" اختر مدرس"
                  label="name"
                  track-by="id"
                  required="required"
                  :internal-search="true"
                >
                  <template v-slot:noOptions>
                    <span> فارغة </span>
                  </template>
                  <template v-slot:noResult>
                    <span>لا يوجد نتيجة </span>
                  </template>
                </multiselect>
              </div>
              <div class="col-3">
                <button
                  @click="printReport"
                  type="button"
                  class="my-4 mb-2 btn bg-gradient-info width"
                >
                  <span class="d-none d-sm-block">طباعة</span>
                  <i class="fas fa-print d-block d-sm-none" title="طباعة"></i>
                </button>
              </div>
              <div class="col-3">
                <button
                  @click="exportToExcel('myTable')"
                  type="button"
                  class="my-4 mb-2 btn bg-gradient-warning width"
                >
                  <span class="d-none d-sm-block">اكسل</span>
                  <i
                    class="fas fa-file-excel d-block d-sm-none"
                    title="اكسل"
                  ></i>
                </button>
              </div>
            </div>
            <hr />
            <div class="row nav-tabs">
              <div class="col-3">
                <vmd-button
                  @click="getInstructorReport('account', 0)"
                  variant="gradient"
                  class="btn-lg nav-link"
                  :class="{ active: activeBtnIndex === 0 }"
                  color="info"
                >
                  تقرير حساب المدرس
                </vmd-button>
              </div>
              <div class="col-3">
                <vmd-button
                  @click="getInstructorReport('courses', 1)"
                  variant="gradient"
                  class="btn-lg nav-link"
                  :class="{ active: activeBtnIndex === 1 }"
                  color="info"
                >
                  الدورات التابعة للمدرس
                </vmd-button>
              </div>
              <div class="col-3">
                <vmd-button
                  @click="getInstructorReport('branch', 2)"
                  variant="gradient"
                  color="info nav-link"
                  class="btn-lg"
                  :class="{ active: activeBtnIndex === 2 }"
                >
                  حساب المدرس للفرع
                </vmd-button>
              </div>
              <div class="col-3">
                <vmd-button
                  @click="getInstructorReport('receipt', 3)"
                  variant="gradient"
                  color="info nav-link"
                  class="btn-lg"
                  :class="{ active: activeBtnIndex === 3 }"
                >
                  اجمالي تحصيلات المدرس
                </vmd-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-4" v-if="reportType">
      <div class="col-12">
        <div
          class="card mx-4 print-sect"
          id="print-report"
          style="direction: rtl"
        >
          <div class="card-header text-center">
            <h3 v-if="reportType">{{ reportType.title }}</h3>
          </div>
          <div class="card-body px-4 pb-2" style="direction: rtl">
            <div style="direction: rtl" class="overflow-x">
              <div class="row"  v-if="reportType.id === 2 || reportType.id === 4 ">
                 <div class="mb-3 col-lg-6">
                  <label>
                    الترم الدراسي<span class="red">*</span>
                  </label>
                  <div class="input-group input-group-outline null">
                    <select
                      class="form-control"
                      v-model="selectedSemester"
                       @change='getInstCourses'
                      required
                    >
                      <option value="" selected>
                        اختر الترم الدراسي
                        </option>
                      <option value="1"> الأول</option>
                      <option value="2"> الثاني</option>
                    </select>
                    <i class="fas fa-caret-down" aria-hidden="true"></i>
                  </div>
                </div>
                 <div class="mb-3 col-lg-6">
                  <label>
                    السنة الدراسية<span class="red">*</span>
                  </label>
                  <div class="input-group input-group-outline null">
                    <select
                      class="form-control"
                      v-model="selectedYear"
                      @change='getInstCourses'
                      required
                    >
                      <option selected value="">اختر السنة الدراسية</option>
                      <option
                        v-for="year in academicYear"
                        :key="year.id"
                        :value="year.id"
                      >
                        {{ year.name }}
                      </option>
                    </select>
                    <i class="fas fa-caret-down" aria-hidden="true"></i>
                  </div>
                </div>
              </div>
              <table
                id="myTable"
                class="table"
                v-if="reportType.id === 2 && instructor"
              >
                <thead>
                  <tr>
                    <th colspan="3">
                      <h4>المدرس: {{ instructor.name }}</h4>
                    </th>
                  </tr>
                  <tr>
                    <th>رقم الدورة</th>
                    <th>اسم الدورة</th>
                    <th>قيمة الدورة</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(row, index) in reportData" :key="index">
                    <td>{{ row.course_number }}</td>
                    <td>{{ row.course }}</td>
                    <td>{{ $filters.number(row.total_cost) }}</td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <th class="text-center" colspan="1">
                      عدد الدورات: {{ footer.count }}
                    </th>
                    <th class="text-center" colspan="1">الإجمالي</th>
                    <td style="font-weight: bold">
                      {{ $filters.number(allCoursesTotal) }}
                    </td>
                  </tr>
                </tfoot>
              </table>
              <div class="row" v-if="reportType.id === 1 && instructor">
                <div class="col-12">
                  <h5 class="border p-2">المدرس: {{ instructor.name }}</h5>
                </div>
                <div
                  class="col-12"
                  v-for="(row, index) in reportData"
                  :key="index"
                >
                  <div class="row mt-4">
                    <div class="col-12">
                      <h6 class="border p-2">
                        الدورة التدريبية:
                        {{ row.course_number + " " + row.course }}
                      </h6>
                    </div>
                    <div class="col-12">
                      <table class="table">
                        <thead>
                          <th>عدد الطلاب</th>
                          <th>قيمة السداد</th>
                          <th>الاجمالي</th>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(receipt, index2) in row.receipts"
                            :key="index2"
                          >
                            <td>{{ receipt.course_student_count }}</td>
                            <td>{{ $filters.number(receipt.course_payed) }}</td>
                            <td>{{ $filters.number(receipt.total) }}</td>
                          </tr>
                        </tbody>
                        <tfoot class="border-top border-bottom">
                          <tr
                            style="font-weight:bold;font-size:17px;background: rgb(240, 242, 245"
                          >
                            <td > عدد الطلاب : {{ row.student_count }}</td>
                            <td >اجمالي المدفوع</td>
                            <td>{{ $filters.number(row.total_payed) }}</td>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <!-- branch -->
              <div class="row" v-if="reportType.id === 3 && instructor">
                <div class="col-12">
                  <h5 class="border p-2">المدرس {{ instructor.name }}</h5>
                </div>
                <!-- new -->
                <div
                  class="col-12"
                  v-for="(value, key) in instructorBranch"
                  :key="key"
                >
                  <h5 style="background: rgb(240, 242, 245)" class="border p-2">
                    الفرع : {{ key }}
                  </h5>
                  <div v-for="(subValue, subKey) in value" :key="subKey">
                    <h6 class="p-2">
                      المادة : <span class="green">{{ subKey }}</span>
                    </h6>
                    <div class="col-lg-12">
                      <table class="table">
                        <thead>
                          <tr>
                            <th class="branch-g-head">المجموعات</th>
                            <th class="text-center">عدد الطلاب</th>
                            <th class="text-center">قيمة السداد</th>
                            <th class="text-center">الإجمالي</th>
                          </tr>
                        </thead>
                        <tbody
                          v-for="(subSubValue, subSubKey) in subValue"
                          :key="subSubKey"
                        >
                          <div
                            class="group-num"
                            colspan="4"
                            style="position: absolute; width: 155px"
                          >
                            <h6 style="background: #e0dadd">
                              مج {{ (groupNum = subSubKey) }}
                            </h6>
                          </div>
                          <tr
                            v-for="(
                              subsubsubValue, subsubsubkey
                            ) in subSubValue"
                            :key="subsubsubkey"
                          >
                            <td
                              style="
                                background: #e0dadd;
                                width: 100px !important;
                              "
                            ></td>
                            <td
                              @click="
                                getstudentPayments(subsubsubValue.students)
                              "
                              class="text-center link pointer"
                              style="color: blue !important"
                            >
                              {{ subsubsubValue.course_student_count }}
                            </td>
                            <td class="text-center">
                              {{ $filters.number(subsubsubValue.course_payed) }}
                            </td>
                            <td class="text-center">
                              {{ $filters.number(subsubsubValue.total) }}
                            </td>
                          </tr>
                          <tr
                            style="
                              font-weight: bold;
                              font-size: 17px;
                              margin-bottom: 30px;
                              background: rgb(240, 242, 245);
                            "
                          >
                            <td>الإجمالي للمجموعة</td>
                            <td class="text-center">
                              {{ groupStudentCount(subSubValue) }}
                            </td>
                            <td></td>
                            <td class="text-center">
                              {{ $filters.number(groupTotals(subSubValue)) }}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <table class="table">
                      <tr style="font-weight: bold; width: 100%" class="">
                        <th
                          class="text-center"
                          style="width: 16%; padding: 10px"
                        >
                          الإجمالي للمادة
                        </th>
                        <th class="text-center line-height">
                          {{ subjectStudentCount(subValue) }}
                        </th>
                        <th class="text-center line-height"></th>
                        <th class="text-center line-height">
                          {{ $filters.number(SubjectTotals(subValue)) }}
                        </th>
                      </tr>
                    </table>
                  </div>
                  <table class="table">
                    <tr style="font-weight: bold; width: 100%" class="">
                      <th class="text-center" style="width: 16%; padding: 10px">
                        الإجمالي للفرع
                      </th>
                      <th class="text-center line-height">
                        {{ branchStudentCount(value) }}
                      </th>
                      <th class="text-center line-height"></th>
                      <th class="text-center line-height">
                        {{ $filters.number(branchTotals(value)) }}
                      </th>
                    </tr>
                  </table>
                </div>
              </div>
              <!-- total -->
              <table
                id="myTable2"
                class="table"
                v-if="reportType.id === 4 && instructor"
              >
                <thead>
                  <tr>
                    <th colspan="3">
                      <h4>المدرس: {{ instructor.name }}</h4>
                    </th>
                  </tr>
                  <tr>
                    <th>رقم الدورة</th>
                    <th>اسم الدورة</th>
                    <th>اجمالي التحصيل</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(row, index) in reportData" :key="index">
                    <td>{{ row.course_number }}</td>
                    <td>{{ row.course }}</td>
                    <td>{{ $filters.number(row.instructor_total_paid) }}</td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr
                    style="
                      font-weight: bold;
                      font-size: 17px;
                      background: rgb(240, 242, 245);
                    "
                  >
                    <th class="text-center" colspan="2">الإجمالي</th>
                    <td>{{ $filters.number(footer.total_course_payment) }}</td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
          <!-- attendance report -->
        </div>
      </div>
    </div>
    <vue-final-modal
      v-model="showModal"
      class="students-payments"
      name="example"
      classes="modal-container"
      content-class="modal-content"
    >
      <div class="row">
        <div class="col-12">
          <div class="card my-4">
            <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
              <div
                class="bg-gradient-success shadow-success border-radius-lg pt-4 pb-3"
              >
                <h6 class="text-white text-capitalize ps-3">
                  مدفوعات الطلاب فرع :
                  {{
                    studentsPayments[0] ? studentsPayments[0].branch_name : null
                  }}
                  - مادة:
                  {{
                    studentsPayments[0]
                      ? studentsPayments[0].subject_name
                      : null
                  }}
                </h6>
              </div>
              <div class="text-center col-lg-12 overflow-x">
                <table class="table b-block">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>رقم</th>
                      <th>الطالب</th>
                      <th>مج</th>
                      <th>قيمة السداد</th>
                    </tr>
                  </thead>
                  <tbody v-for="(item, index) in studentsPayments" :key="index">
                    <tr>
                      <td>
                        {{ index + 1 }}
                      </td>
                      <td>
                        {{ item.student_number }}
                      </td>
                      <td>
                        {{ item.student_name }}
                      </td>
                      <td>
                        {{ item.group_name }}
                      </td>
                      <td>
                        {{ $filters.number(item.total) }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="row text-center">
                <div class="text-center col-lg-12">
                  <vmd-button
                    type="reset"
                    class="my-4 mb-2"
                    variant="gradient"
                    color="danger"
                    @click="showModal = false"
                  >
                    اغلاق
                  </vmd-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </vue-final-modal>
  </div>
</template>
<script>
import VmdButton from '@/components/VmdButton.vue'
import { VueFinalModal } from 'vue-final-modal'
import * as XLSX from 'xlsx'
import { utils, writeFile } from 'xlsx'
import swal from 'sweetalert'
import $ from 'jquery'
export default {
  name: 'InstructorReport',
  components: {
    VmdButton,
    VueFinalModal
  },
  data () {
    return {
      instructors: [],
      instructor: {},
      showModal: false,
      reportType: '',
      groupNum: '',
      footer: {},
      reportData: [],
      instructorBranch: [],
      allCoursesTotal: 0,
      groupsSum: 0,
      studentsPayments: [],
      BranchSubjects: [],
      activeBtnIndex: null,
      selectedSemester: '2',
      selectedYear: '75b8039d-4bc9-11ee-bd6a-f23c936035ca',
      academicYear: []
    }
  },
  created () {
    this.$http.get(`${this.$hostUrl}instructors`).then((response) => {
      this.instructors = response.data.data
    })
    const academicYear = []
    this.$http.get(`${this.$hostUrl}academic-years`).then((response) => {
      $.each(response.data.data, function (key, value) {
        academicYear.push({
          name: value.name,
          id: value.id
        })
      })
      this.academicYear = academicYear
    })
  },
  computed: {},
  methods: {
    // clearData () {
    //   this.selectedSemester = null
    //   this.selectedYear = null
    // },
    getInstCourses () {
      this.$http
        .get(
              `${this.$hostUrl}instructor-courses?instructor_id=${this.instructor.id}&semester=${this.selectedSemester || ''}&academic_year_id=${this.selectedYear || ''}`
        )
        .then((response) => {
          this.reportData = response.data.data.courses
          this.footer = response.data.data
          $.each(this.reportData, (key, val) => {
            this.allCoursesTotal += val.total_cost
          })
          this.footer.count = this.reportData.length
        })
    },
    groupStudentCount (subSubValue) {
      let sum = 0
      Object.values(subSubValue).forEach((value) => {
        sum += value.course_student_count
      })

      return sum
    },
    groupTotals (subSubValue) {
      let sum = 0
      Object.values(subSubValue).forEach((value) => {
        sum += value.total
      })
      return sum
    },
    subjectStudentCount (subValue) {
      let sum = 0
      Object.values(subValue).forEach((subSubValue) => {
        Object.values(subSubValue).forEach((value) => {
          sum += value.course_student_count
        })
      })

      return sum
    },
    SubjectTotals (subValue) {
      let sum = 0
      Object.values(subValue).forEach((subSubValue) => {
        Object.values(subSubValue).forEach((value) => {
          sum += value.total
        })
      })

      return sum
    },
    branchStudentCount (val) {
      let sum = 0
      Object.values(val).forEach((subValue) => {
        Object.values(subValue).forEach((subSubValue) => {
          Object.values(subSubValue).forEach((value) => {
            sum += value.course_student_count
          })
        })
      })

      return sum
    },
    branchTotals (val) {
      let sum = 0
      Object.values(val).forEach((subValue) => {
        Object.values(subValue).forEach((subSubValue) => {
          Object.values(subSubValue).forEach((value) => {
            sum += value.total
          })
        })
      })

      return sum
    },
    getstudentPayments (students) {
      this.showModal = true
      this.studentsPayments = students
    },
    getInstructorReport (report, index) {
      if (!this.instructor?.id) {
        swal('رجاء قم باختيار المدرس')
        return 0
      }
      switch (report) {
        case 'account':
          this.activeBtnIndex = index
          this.reportType = {
            id: 1,
            title: 'حساب المدرس'
          }
          this.$http
            .get(
              `${this.$hostUrl}instructor-account?instructor_id=${this.instructor.id}`
            )
            .then((response) => {
              this.reportData = response.data.data
            })
          break
        case 'courses':
          this.activeBtnIndex = index
          this.allCoursesTotal = 0
          this.reportType = {
            id: 2,
            title: 'الدورات التابعة للمدرس'
          }
          this.$http
            .get(
              `${this.$hostUrl}instructor-courses?instructor_id=${this.instructor.id}&semester=${this.selectedSemester || ''}&academic_year_id=${this.selectedYear || ''}`
            )
            .then((response) => {
              this.reportData = response.data.data.courses
              this.footer = response.data.data
              $.each(this.reportData, (key, val) => {
                this.allCoursesTotal += val.total_cost
              })
              this.footer.count = this.reportData.length
            })
          break
        case 'branch':
          this.activeBtnIndex = index
          this.reportType = {
            id: 3,
            title: 'حساب المدرس للفرع'
          }
          this.$http
            .get(
              `${this.$hostUrl}instructor-branches-accounts?instructor_id=${this.instructor.id}`
            )
            .then((response) => {
              this.reportData = response.data.data
              this.instructorBranch = response.data.data
            })
          break
        case 'receipt':
          this.activeBtnIndex = index
          this.reportType = {
            id: 4,
            title: 'اجمالي التحصيلات للمدرس'
          }
          this.$http
            .get(
              `${this.$hostUrl}instructor-courses?instructor_id=${this.instructor.id}&semester=${this.selectedSemester || ''}&academic_year_id=${this.selectedYear || ''}`
            )
            .then((response) => {
              this.reportData = response.data.data.courses
              this.footer = response.data.data
              console.log(this.reportData)
              this.footer.count = this.reportData.length
            })
          break
        default:
          this.reportType = {}
      }
    },
    printReport () {
      $('.card-footer').hide()
      const options = {
        name: ' Seven Eleven System ',
        specs: ['fullscreen=yes', 'titlebar=yes', 'scrollbars=yes'],
        styles: [
          'https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.rtl.min.css',
          `${window.location.origin}/print-report.css`
        ],
        timeout: 1000, // default timeout before the print window appears
        autoClose: true, // if false, the window will not close after printing
        windowTitle: 'Seven Eleven System' // override the window title
      }
      setTimeout(this.$htmlToPaper('print-report', options), 10000)
      $('.card-footer').show()
    },
    prepareExportData (data) {
      const exportData = []

      // Prepare the header row
      const header = [
        'الفرع',
        'المادة',
        'المجموعة',
        'عدد الطلاب',
        'قيمة السداد  ',
        ' الإجمالي'
      ]
      exportData.push(header)

      // Iterate through the data and fill exportData
      for (const branch in data) {
        for (const subject in data[branch]) {
          for (const group in data[branch][subject]) {
            for (const item in data[branch][subject][group]) {
              const row = [
                branch,
                subject,
                group,
                data[branch][subject][group][item].course_student_count,
                data[branch][subject][group][item].course_payed,
                data[branch][subject][group][item].total
              ]
              exportData.push(row)
            }
          }
        }
      }

      return exportData
    },
    exportToExcel (tableId) {
      const instrAccount = 1
      const instrCourses = 2
      const instrBranches = 3
      const instrReceipts = 4
      if (this.reportType.id === instrAccount) {
        // create a new workbook
        const workbook = XLSX.utils.book_new()

        // convert the reportData to an array of arrays
        const data = this.reportData.map((row) => [
          row.course_number + ' ' + row.course,
          row.student_count,
          this.$filters.number(row.total_payed)
        ])

        // add the data to a new worksheet
        const worksheet = XLSX.utils.aoa_to_sheet([
          ['الدورة التدريبية', 'عدد الطلاب', 'اجمالي المدفوع'],
          ...data
        ])
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1')

        // save the workbook to a file
        XLSX.writeFile(
          workbook,
          `تقرير حساب المدرس ${this.instructor.name}.xlsx`
        )
      }
      if (this.reportType.id === instrCourses) {
        var wb = XLSX.utils.table_to_book(document.getElementById(tableId))
        var wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
        var blob = new Blob([wbout], { type: 'application/octet-stream' })
        var url = URL.createObjectURL(blob)
        var a = document.createElement('a')
        document.body.appendChild(a)
        a.href = url
        a.download = `تقرير  الدورات للمدرس ${this.instructor.name}.xlsx`
        a.click()
        setTimeout(function () {
          URL.revokeObjectURL(url)
          document.body.removeChild(a)
        }, 0)
      }
      if (this.reportType.id === instrReceipts) {
        tableId = 'myTable2'
        const wb = XLSX.utils.table_to_book(document.getElementById(tableId))
        const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
        const blob = new Blob([wbout], { type: 'application/octet-stream' })
        const url = URL.createObjectURL(blob)
        const a = document.createElement('a')
        document.body.appendChild(a)
        a.href = url
        a.download = `تقرير التحصيلات للمدرس ${this.instructor.name}.xlsx`
        a.click()
        setTimeout(function () {
          URL.revokeObjectURL(url)
          document.body.removeChild(a)
        }, 0)
      }
      if (this.reportType.id === instrBranches) {
        const data = this.instructorBranch
        // Prepare the data for export
        const exportData = this.prepareExportData(data)

        // Create a new workbook
        const wb = utils.book_new()

        // Convert data to worksheet
        const ws = utils.json_to_sheet(exportData)

        // Add the worksheet to the workbook
        utils.book_append_sheet(wb, ws, 'Instructor Branch Data')

        // Write the workbook to a file and trigger download
        const fileName = `تقرير الفروع للمدرس ${this.instructor.name}.xlsx`
        writeFile(wb, fileName)
      }
    }
  }
}
</script>
<style>
.link {
  color: blue !important;
}
.students-payments .modal-content {
  max-height: 500px;
  overflow-y: auto;
}
</style>
<style scoped>
th h4 {
  color: #d1fdd3;
}
.date {
  padding-left: 130px;
}
span {
  margin-right: 2px;
  margin-left: 2px;
}
.line-height {
  line-height: 47px;
  color: #222 !important;
  width: 28% !important;
}
</style>
