<template>
     <div class="container-fluid py-4 add-student">
    <div class="row">
      <div class="col-12">
        <div class="card my-4">
          <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
            <div
              class="bg-gradient-success shadow-success border-radius-lg pt-4 pb-3"
            >
              <h6 class="text-white text-capitalize ps-3"> إضافة محاضرة - الدورة: {{course.name}}   </h6>
            </div>
          </div>
          <div class="card-body px-0 pb-2">
                   <form @submit='createLecture' role="form" class="text-start mt-3">
                       <div class='row' >
                      <div class="mb-3 col-lg-6">
                  <label>  رقم المحاضرة<span class="red">*</span>
                    <span color="danger" class="error">{{
                      errors.lecture_number ? errors.lecture_number[0] : ''
                    }}</span></label
                  >
                  <vmd-input
                    id=""
                    min-length='10'
                    type="number"
                   v-model='course.next_lecture_number'
                   required
                  />
                </div>
                   <div class="mb-3 col-lg-6">
                  <label>   القاعة<span class="red">*</span>
                  </label>
                     <multiselect
                        class='required form-control'
                        id='room'
                        v-model="selectedRoom"
                        :options='rooms'
                        :searchable='true'
                        :close-on-select='true'
                        placeholder="  اختر القاعة"
                        label='name'
                        :custom-label="customLabel"
                        track-by='name'
                        required='required'
                        :internal-search="true"
                        >
                      >
                        <template v-slot:noOptions>
                          <span> فارغة </span>
                        </template>
                        <template v-slot:noResult>
                          <span>لا يوجد نتيجة </span>
                        </template>
                      </multiselect>
                </div>
                       </div>
                       <div class='row'>
                     <div class="mb-3 col-lg-6 ">
                    <label>   تاريخ بدء  المحاضرة<span class="red">*</span></label>
                    <div class='date'>
                  <vmd-input
                    id="date-input"
                    type="datetime-local"
                   label="    تاريخ  بدء المحاضرة "
                   v-model='lecture.date'
                   required
                  />
                  </div>
                </div>
                       </div>
                       <div class="row">
                <div class="text-center col-lg-6">
                  <vmd-button
                    class="my-4 mb-2"
                    variant="gradient"
                    color="info"
                    > حفظ</vmd-button
                  >
                </div>
                 <div class="text-center col-lg-6">
                  <vmd-button
                    type='reset'
                    class="my-4 mb-2"
                    variant="gradient"
                    color="danger"
                    > مسح</vmd-button
                  >
                </div>
                       </div>
              </form>
          </div>
        </div>
      </div>
    </div>
     </div>
</template>
<script>
import VmdButton from '@/components/VmdButton.vue'
import VmdInput from '@/components/VmdInput.vue'

import swal from 'sweetalert'
import $ from 'jquery'

export default {
  name: 'CreateLecture',
  components: {
    VmdButton,
    VmdInput
  },
  data () {
    return {
      rooms: [],
      selectedRoom: '',
      lecture: {},
      course: {},
      errors: []
    }
  },
  created () {
    this.$http
      .get(`${this.$hostUrl}courses/${this.$route.params.id}`)
      .then((response) => {
        this.course = response.data.data
        if (this.course.latest_room_id) {
          this.selectedRoom = {
            id: this.course.latest_room_id.id,
            name: this.course.latest_room_id.name + ' ' + ' - ' + this.course.branch

          }
        }
      })
    const rooms = []
    this.$http.get(`${this.$hostUrl}rooms`)
      .then((response) => {
        $.each(response.data.data, function (key, value) {
          rooms.push({
            name: value.name + ' ' + ' - ' + value.branch_name,
            id: value.id
          })
        })
        this.rooms = rooms
      })
  },
  methods: {
    async createLecture (e) {
      e.preventDefault()
      const formData = new FormData()
      formData.append('course_id', this.$route.params.id)
      formData.append('lecture_number', this.course.next_lecture_number)
      formData.append('room_id', this.selectedRoom.id)
      const formatDate = this.lecture.date.replace('T', ' ')
      formData.append('start', formatDate)
      await this.$http
        .post(
            `${this.$hostUrl}lectures`,
            formData
        )
        .then((response) => {
          if (!response.data.error) {
            swal({ title: response.data.message, buttons: 'تم' })
          } else {
            this.errors = response.data.data
          }
        }).catch(function (error) {
          if (Object.prototype.hasOwnProperty.call(error, 'response')) {
            const data = (error.response || {}).data
            if (data.error) {
              swal(data.message)
            }
          }
        })
    }
  }
}
</script>
<style >
.dropzone__box {
      width: 100% !important;
    background: #f8f9fa !important;
    min-height: 40px !important;
    cursor: pointer !important;
}
.video-header{
  justify-content: center;
  align-items: center;
  padding:18px !important;
}
.video-header .tab-div{
  width:75% !important;
  margin-top:0 !important
}
</style>
