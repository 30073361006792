<template>
  <div class="container-fluid py-4 add-student grade-detail">
    <div class="row">
      <div class="col-12">
        <div class="card my-4">
          <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
            <div
              class="bg-gradient-success shadow-success border-radius-lg pt-4 pb-3"
            >
              <div class="row">
                <div class="col-lg-6">
                  <h6 class="text-white text-capitalize ps-3">
                    احصائيات أعداد الطلاب
                  </h6>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body px-0 pb-2">
            <div class="mb-3 col-lg-5 total-w">
              <span
                >إجمالي الطلاب : <span class="green">{{ total }}</span></span
              >
            </div>
            <div class="row text-center statistics-row">
              <div class="col-lg-6">
                <div v-if="chartData">
                  <apexchart
                    type="pie"
                    :options="chartOptions"
                    :series="
                      chartData.datasets ? chartData.datasets[0].data : []
                    "
                  ></apexchart>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueApexCharts from 'vue3-apexcharts'
export default {
  components: {
    apexchart: VueApexCharts
  },
  data () {
    return {
      studentsNumbers: {},
      total: 0,
      chartData: {},
      chartOptions: {
        labels: ['الأونلاين فقط', 'الأوفلاين فقط', 'المشترك'],
        colors: ['#FF6384', '#36A2EB', '#FFCE56']
      }
    }
  },
  methods: {
    fetchChartData () {
      this.$http
        .get(`${this.$hostUrl}report/enrolled/count`)
        .then((response) => {
          this.studentsNumbers = response.data.data
          this.total = this.formatNumber(this.studentsNumbers.total)
          const onlineOfflineStudents =
            this.studentsNumbers.online_count +
            this.studentsNumbers.offline_count -
            this.studentsNumbers.total
          const offlineOnly =
            this.studentsNumbers.offline_count - onlineOfflineStudents
          const onlineOnly =
            this.studentsNumbers.online_count - onlineOfflineStudents
          this.chartData = {
            datasets: [
              {
                data: [onlineOnly, offlineOnly, onlineOfflineStudents]
              }
            ]
          }
        })
    },
    formatNumber (number) {
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    }
  },
  mounted () {
    this.fetchChartData()
  }
}
</script>
<style>
.total-w {
  margin: 20px !important;
}
.total-w .green {
  font-size: 17px !important;
  font-weight: bold !important;
  font-family: Montserrat, sans-serif !important;
  letter-spacing: 2px !important;
}
.statistics-row {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
