<template>
     <div class="container-fluid py-4 add-student grade-detail">
    <div class="row">
      <div class="col-12">
        <div class="card my-4">
          <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
            <div
              class="bg-gradient-success shadow-success border-radius-lg pt-4 pb-3"
            >
             <div class="row">
              <div class="col-lg-6">
              <h6 class="text-white text-capitalize ps-3">  تفاصيل الفرقة</h6>
              </div>
               <div class="col-lg-6">
                 <router-link to="/grades/create">

               <span class="badge badge-sm new-student"><i  class="fas fa-user-plus"></i>إضافة مجموعة </span>
                 </router-link>
               </div>
              </div>
            </div>
          </div>
          <div class="card-body px-0 pb-2">
            <h6 class="shadow-success"> <i class="fas fa-users"></i>  المجموعة الأولي</h6>
               <div class="card-body px-0 pb-2">
            <div class="table-responsive p-0">
              <table class="table align-items-center mb-0 table-striped  ">
                <thead>
                  <tr>
                    <th
                      class="text-uppercase text-xs  font-weight-bolder "
                    >
                       اسم الطالب

                    </th>
                    <th
                      class="text-uppercase text-xs  font-weight-bolder ps-2"
                    >
                      رقم التليفون
                    </th>

                     <th
                      class="text-center text-uppercase text-xs font-weight-bolder "
                    >
                      تاريخ الإنشاء
                    </th>

                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                     <p class="text-xs font-weight-bold mb-0">   محمد ابراهيم </p>
                    </td>

                     <td>
                         <p class="text-xs font-weight-bold mb-0"> 0109998345</p>
                    </td>

               <td class="align-middle text-center">
                <span class="text-secondary text-xs font-weight-bold">23/04/18</span>
              </td>
                  </tr>
                  <tr>
                          <td>
                <p class="text-xs font-weight-bold mb-0"> محمد مصطفي</p>
                <!-- <p class="text-xs text-secondary mb-0">Organization</p> -->
              </td>
                <td>
                         <p class="text-xs font-weight-bold mb-0"> 0111998345</p>
                    </td>

               <td class="align-middle text-center">
                <span class="text-secondary text-xs font-weight-bold">23/04/18</span>
              </td>
                  </tr>
                  <tr>
                               <td>
                <p class="text-xs font-weight-bold mb-0"> محمد كمال</p>
                <!-- <p class="text-xs text-secondary mb-0">Organization</p> -->
              </td>
                <td>
                         <p class="text-xs font-weight-bold mb-0"> 0133998345</p>
                    </td>

               <td class="align-middle text-center">
                <span class="text-secondary text-xs font-weight-bold">23/04/18</span>
              </td>

                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          </div>
             <div class="card-body px-0 pb-2">
            <h6 class="shadow-success"> <i class="fas fa-users"></i> المجموعة الثانية</h6>
               <div class="card-body px-0 pb-2">
            <div class="table-responsive p-0">
              <table class="table align-items-center mb-0 table-striped">
                <thead>
                  <tr>
                    <th
                      class="text-uppercase text-xs font-weight-bolder opacity-7"
                    >
                       اسم الطالب

                    </th>
                    <th
                      class="text-uppercase text-xs font-weight-bolder opacity-7 ps-2"
                    >
                      رقم التليفون
                    </th>

                     <th
                      class="text-center text-uppercase text-xs font-weight-bolder opacity-7"
                    >
                      تاريخ الإنشاء
                    </th>

                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                     <p class="text-xs font-weight-bold mb-0">   محمد ابراهيم </p>
                    </td>

                     <td>
                         <p class="text-xs font-weight-bold mb-0"> 0109998345</p>
                    </td>

               <td class="align-middle text-center">
                <span class="text-secondary text-xs font-weight-bold">23/04/18</span>
              </td>
                  </tr>
                  <tr>
                          <td>
                <p class="text-xs font-weight-bold mb-0"> محمد مصطفي</p>
                <!-- <p class="text-xs text-secondary mb-0">Organization</p> -->
              </td>
                <td>
                         <p class="text-xs font-weight-bold mb-0"> 0111998345</p>
                    </td>

               <td class="align-middle text-center">
                <span class="text-secondary text-xs font-weight-bold">23/04/18</span>
              </td>
                  </tr>
                  <tr>
                               <td>
                <p class="text-xs font-weight-bold mb-0"> محمد كمال</p>
                <!-- <p class="text-xs text-secondary mb-0">Organization</p> -->
              </td>
                <td>
                         <p class="text-xs font-weight-bold mb-0"> 0133998345</p>
                    </td>

               <td class="align-middle text-center">
                <span class="text-secondary text-xs font-weight-bold">23/04/18</span>
              </td>

                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          </div>
             <div class="card-body px-0 pb-2">
            <h6 class="shadow-success"> <i class="fas fa-users"></i> المجموعة الثالثة</h6>
               <div class="card-body px-0 pb-2">
            <div class="table-responsive p-0">
              <table class="table align-items-center mb-0 table-striped">
                <thead>
                  <tr>
                    <th
                      class="text-uppercase text-xs font-weight-bolder opacity-7"
                    >
                       اسم الطالب

                    </th>
                    <th
                      class="text-uppercase text-xs font-weight-bolder opacity-7 ps-2"
                    >
                      رقم التليفون
                    </th>

                     <th
                      class="text-center text-uppercase text-xs font-weight-bolder opacity-7"
                    >
                      تاريخ الإنشاء
                    </th>

                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                     <p class="text-xs font-weight-bold mb-0">   محمد ابراهيم </p>
                    </td>

                     <td>
                         <p class="text-xs font-weight-bold mb-0"> 0109998345</p>
                    </td>

               <td class="align-middle text-center">
                <span class="text-secondary text-xs font-weight-bold">23/04/18</span>
              </td>
                  </tr>
                  <tr>
                          <td>
                <p class="text-xs font-weight-bold mb-0"> محمد مصطفي</p>
                <!-- <p class="text-xs text-secondary mb-0">Organization</p> -->
              </td>
                <td>
                         <p class="text-xs font-weight-bold mb-0"> 0111998345</p>
                    </td>

               <td class="align-middle text-center">
                <span class="text-secondary text-xs font-weight-bold">23/04/18</span>
              </td>
                  </tr>
                  <tr>
                               <td>
                <p class="text-xs font-weight-bold mb-0"> محمد كمال</p>
                <!-- <p class="text-xs text-secondary mb-0">Organization</p> -->
              </td>
                <td>
                         <p class="text-xs font-weight-bold mb-0"> 0133998345</p>
                    </td>

               <td class="align-middle text-center">
                <span class="text-secondary text-xs font-weight-bold">23/04/18</span>
              </td>

                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          </div>
             <div class="card-body px-0 pb-2">
            <h6 class="shadow-success">  <i class="fas fa-users"></i> المجموعة الرابعة</h6>
               <div class="card-body px-0 pb-2">
            <div class="table-responsive p-0 ">
              <table class="table align-items-center mb-0 table-striped">
                <thead>
                  <tr>
                    <th
                      class="text-uppercase text-xs font-weight-bolder opacity-7"
                    >
                       اسم الطالب

                    </th>
                    <th
                      class="text-uppercase text-xs font-weight-bolder opacity-7 ps-2"
                    >
                      رقم التليفون
                    </th>

                     <th
                      class="text-center text-uppercase text-xs font-weight-bolder opacity-7"
                    >
                      تاريخ الإنشاء
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                     <p class="text-xs font-weight-bold mb-0">   محمد ابراهيم </p>
                    </td>

                     <td>
                         <p class="text-xs font-weight-bold mb-0"> 0109998345</p>
                    </td>

               <td class="align-middle text-center">
                <span class="text-secondary text-xs font-weight-bold">23/04/18</span>
              </td>
                  </tr>
                  <tr>
                          <td>
                <p class="text-xs font-weight-bold mb-0"> محمد مصطفي</p>
                <!-- <p class="text-xs text-secondary mb-0">Organization</p> -->
              </td>
                <td>
                         <p class="text-xs font-weight-bold mb-0"> 0111998345</p>
                    </td>

               <td class="align-middle text-center">
                <span class="text-secondary text-xs font-weight-bold">23/04/18</span>
              </td>
                  </tr>
                  <tr>
                               <td>
                <p class="text-xs font-weight-bold mb-0"> محمد كمال</p>
                <!-- <p class="text-xs text-secondary mb-0">Organization</p> -->
              </td>
                <td>
                         <p class="text-xs font-weight-bold mb-0"> 0133998345</p>
                    </td>

               <td class="align-middle text-center">
                <span class="text-secondary text-xs font-weight-bold">23/04/18</span>
              </td>

                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          </div>
        </div>
      </div>
    </div>
     </div>
</template>
<script>
// import VmdInput from "@/components/VmdInput.vue";
// import VmdCheckbox from '@/components/VmdCheckbox.vue';
// import VmdButton from "@/components/VmdButton.vue";
export default {
  name: 'GradeDetail',
  components: {
    // VmdInput,
    // VmdCheckbox
    // VmdButton
  }
}
</script>
