<!-- eslint-disable vue/no-v-for-template-key -->
<template>
    <div>
      <div class="row" style="margin-bottom: 40px">
              <div style="margin-bottom: 40px" class="col-12 ">
                <div
                  class="text-center col-lg-12 overflow-x"
                  style="direction: rtl"
                >
                  <table class="b-block table" style="direction: rtl">
                    <thead>
                      <tr>
                        <th > الفرقة</th>
                        <th>الأوفلاين</th>
                       <th>الأونلاين</th>
                       <th>الإجمالي</th>
                      </tr>
                    </thead>
                    <tbody>
                          <!-- <tr v-for="(subVal,subKey) in val" :key="subKey"
                          :class ="{'total-back': subKey === 'totals'}"
                          > -->
                          <tr v-for="(val,key) in branchesDetails" :key="key">
                            <td>{{key}}</td>
                            <td>{{(val['totals'].total_enrolled_grade_count) - (getOnlineTotals(val))}}</td>
                            <td>{{getOnlineTotals(val)}}</td>
                            <td>{{getOnlineTotals(val) + ((val['totals'].total_enrolled_grade_count) - (getOnlineTotals(val)))}}</td>
                          </tr>
                          <tr style="background:#dad6d669;font-weight:bold">
                            <td>الإجماليات</td>
                            <td>{{(calculateTotalSum()) -(calculateTotalOnline())}}</td>
                            <td>{{calculateTotalOnline()}}</td>
                            <td>{{calculateTotalSum()}}</td>
                          </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
    </div>
</template>
<script>
export default {
  name: 'GradesOfflineOnline',
  data () {
    return {
      gradesOnlineTotal: 0,
      branchesCount: ['العباسية', 'الزعفران', 'شبرا', 'اونلاين']
    }
  },
  props: {
    branchesDetails: {
      type: Array
    }
  },
  methods: {
    getOnlineTotals (val) {
      let total = 0
      // eslint-disable-next-line no-unused-vars
      for (const [key, subVal] of Object.entries(val)) {
        total = subVal['اونلاين'].total_enrolled_count
      }
      return total
    },
    calculateTotalOnline () {
      let sum = 0

      for (const val of Object.values(this.branchesDetails)) {
        const total = this.getOnlineTotals(val) // Call the existing getOnlineTotals method
        sum += total
      }
      this.gradesOnlineTotal = sum
      return sum
    },
    calculateTotalSum () {
      let sum = 0

      for (const val of Object.values(this.branchesDetails)) {
        const total = val.totals.total_enrolled_grade_count // Call the existing getOnlineTotals method
        sum += total
      }
      this.gradesOnlineTotal = sum
      return sum
    }

  }
}
</script>
