<template>
     <div class="container-fluid py-4 add-student lecture-details">
    <div class="row">
      <div class="col-12">
        <div class="card my-4">
          <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
            <div
              class="bg-gradient-success shadow-success border-radius-lg pt-4 pb-3"
            >
              <h6 class="text-white text-capitalize ps-3">   عرض بيانات المدرس</h6>
            </div>
          </div>
          <div class="card-body px-0 pb-2">
            <div class="row">
                      <div class="mb-3 col-lg-6 text-center">
                  <label for='image' class="required">

                  </label>
                  <img class="img-thumbnail insrt-img" :src='instructor.photo_url'  width="200px" height="200px" alt="لايوجد صورة" >
                  </div>
                      </div>
          <form role="form" class="text-start mt-3">
                       <div class="row">
                           <div class="mb-3 col-lg-5">
                              <label>  اسم المدرس</label>
                  <vmd-input
                    id=""
                    type="text"
                    :label="instructor.name"
                    disabled
                  />
                </div>
                     <div class="mb-3 col-lg-5">
                      <label> هاتف المساعد</label>
                  <vmd-input
                    id=""
                    type="text"
                    :label="instructor.assistant_mobile"
                    disabled
                  />
                </div>
                </div>

              </form>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="card my-4">
            <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
              <div
                class="bg-gradient-success shadow-success border-radius-lg pt-4 pb-3"
              >
                <h6 class="text-white text-capitalize ps-3">
                  مواد المدرس
                </h6>
              </div>
            </div>
            <div class="card-body px-0 pb-2">
              <div class="table-responsive p-0">
                <table class="table align-items-center mb-0">
                  <thead>
                    <tr>
                      <th
                        class="text-uppercase text-secondary font-weight-bolder opacity-7"
                      >
                        اسم المادة
                      </th>
                      <th
                        class="text-uppercase text-secondary font-weight-bolder opacity-7"
                      >
                        المجموعة
                      </th>
                       <th
                        class="text-uppercase text-secondary font-weight-bolder opacity-7"
                      >
                        الفرقة
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="subject in subjectList"
                      :key="subject.id"
                    >
                      <td>
                        <p class="text-s mb-0">{{ subject.name }}</p>
                      </td>
                      <td>
                        <p class="text-s mb-0">{{subject.groups  }}</p>
                      </td>
                      <td>
                        <p class="text-s mb-0">{{subject.grade  }}</p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
     </div>
</template>
<script>
import VmdInput from '@/components/VmdInput.vue'
import $ from 'jquery'
export default {
  name: 'ShowTeacher',
  components: {
    VmdInput
  },
  data () {
    return {
      instructor: {},
      subjectList: []
    }
  },
  created () {
    this.$http.get(`${this.$hostUrl}instructors/${this.$route.params.id}`).then(response => {
      this.instructor = response.data.data
      const subjectList = []
      $.each(this.instructor.subject_list, (key, val) => {
        const subjectGroups = []
        $.each(val.groups, (key, value) => {
          subjectGroups.push(parseInt(value.name))
        })
        subjectList.push({
          name: val.name,
          groups: subjectGroups,
          grade: val.grade_name
        })
      })
      this.subjectList = subjectList
    })
  }
}
</script>
