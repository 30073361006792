<template>
  <div>
    <div class="card-body px-0 pb-2">
      <div v-if="!coursesSubmit">
        <span class="text-warning">يجب إضافة الطالب أولا ثم اختيار حضور</span>
        <form @submit="createStudent" role="form" class="text-start mt-3">
          <div class="row">
            <div class="mb-3 col-lg-6">
              <label>اسم الطالب <span class="red">*</span>
                <span color='danger' class="error">{{errors.full_name?errors.full_name[0]:''}}</span>
              </label>
              <vmd-input id="" type="text" placeholder="الاسم  بالعربي" v-model='student.fullName' required :invalidFeedback="errors.name" />
            </div>
            <div class="mb-3 col-lg-6">
              <label> رقم الهاتف <span class="red">*</span>
                <span color='danger' class="error">{{errors.mobile?errors.mobile[0]:''}}</span>
              </label>
              <vmd-input id="" type="text" v-model='student.mobile' @input='updateVal' required />
            </div>
          </div>
          <div class='row'>
            <div class="mb-3 col-lg-6">
              <label class='unrequired'> البريد الإلكتروني <span class="green">(اختياري)</span>
              </label>
              <span color='danger' class="error"></span>
              <vmd-input id="email" type="email" label=" البريد الإلكتروني" v-model='student.email' :invalidFeedback="errors.email" />
            </div>
            <div class="mb-3 col-lg-6">
              <label class='unrequired'> رابط الفيسبوك <span class="green">(اختياري)</span>
                <span color='danger' class="error">{{errors.facebook_url?errors.facebook_url[0]:''}}</span>
              </label>
              <vmd-input id="" type="text" label=" رابط الفيسبوك" v-model='student.facebookUrl' />
            </div>
          </div>
          <div class='row'>
            <div class="mb-3 col-lg-6">
              <label> الفرقة <span class="red">*</span>
                <span color='danger' class="error">{{errors.grade_id?errors.grade_id[0]:''}}</span>
              </label>
              <multiselect class='required form-control' id='grade' v-model=" selectedGrade" :options='grades' :searchable='true' :close-on-select='true' placeholder=" اختر الفرقة" label='name' :custom-label="customLabel" track-by='id' required='required' :internal-search="true"> > <template v-slot:noOptions>
                  <span> فارغة </span>
                </template>
                <template v-slot:noResult>
                  <span>لا يوجد نتيجة </span>
                </template>
              </multiselect>
            </div>
            <div class="mb-3 col-lg-6">
              <label> المجموعة <span class="red">*</span>
                <span color='danger' class="error">{{errors.group_id?errors.group_id[0]:''}}</span>
              </label>
              <multiselect class='required form-control' id='grade' v-model=" selectedGroup" :options='groups' :searchable='true' :close-on-select='true' placeholder=" اختر المجموعة" label='name' :custom-label="customLabel" track-by='id' required='required' :internal-search="true"> > <template v-slot:noOptions>
                  <span> فارغة </span>
                </template>
                <template v-slot:noResult>
                  <span>لا يوجد نتيجة </span>
                </template>
              </multiselect>
            </div>
          </div>
          <div class='row'>
            <div class="mb-3 col-lg-6">
              <label> المناطق <span class="red">*</span>
                <span color='danger' class="error">{{errors.area_id?errors.area_id[0]:''}}</span>
              </label>
              <multiselect class='required form-control' id='area' v-model=" selectedArea" :options='areas' :searchable='true' :close-on-select='true' placeholder="اختر المنطقة" label='name' :custom-label="customLabel" track-by='id' required='required' :internal-search="true"> > <template v-slot:noOptions>
                  <span> فارغة </span>
                </template>
                <template v-slot:noResult>
                  <span>لا يوجد نتيجة </span>
                </template>
              </multiselect>
            </div>
            <div class="mb-3 col-lg-6">
                      <label>  المصدر <span class="red">*</span>
                      <span color='danger' class="error">{{errors.source_id?errors.source_id[0]:''}}</span></label>
                    <multiselect
                        class='required form-control'
                        id='area'
                        v-model=" selectedSource"
                        :options='sources'
                        :searchable='true'
                        :close-on-select='true'
                        placeholder="اختر المصدر"
                        label='name'
                        :custom-label="customLabel"
                        track-by='id'
                        required='required'
                        :internal-search="true"
                        >
                      >
                        <template v-slot:noOptions>
                          <span> فارغة </span>
                        </template>
                        <template v-slot:noResult>
                          <span>لا يوجد نتيجة </span>
                        </template>
                      </multiselect>
                </div>
                       </div>
                       <div class="row" v-if="selectedSource.id === '2c481399-60f5-11ee-bd6a-f23c936035ca'">
                        <div class="mb-3 col col-6">
                      <label >  المبيعات <span class="red">*</span>
                      <span color='danger' class="error">{{errors.area_id?errors.area_id[0]:''}}</span></label>
                    <multiselect
                        class='required form-control'
                        id='area'
                        v-model=" selectedSales"
                        :options='salespeople'
                        :searchable='true'
                        :close-on-select='true'
                        placeholder="اختر الاسم"
                        label='name'
                        :custom-label="customLabel"
                        track-by='id'
                        required='required'
                        :internal-search="true"
                        >
                      >
                        <template v-slot:noOptions>
                          <span> فارغة </span>
                        </template>
                        <template v-slot:noResult>
                          <span>لا يوجد نتيجة </span>
                        </template>
                      </multiselect>
                      </div>
                       </div>
          <div class="row text-center justify-content">
            <div class="text-center col-lg-2">
              <vmd-button :class="{'disabled' :success}" class="my-4 mb-2" variant="gradient" color="info" style="width:100%"> حفظ</vmd-button>
            </div>
            <div class="text-center col-lg-2">
              <vmd-button class="my-4 mb-2" style="width:100%" variant="gradient" :class="{'disabled':!attendance}" color="success" @click.prevent="$emit('new-attendance',student)"> حضور</vmd-button>
            </div>
            <div class="text-center col-lg-4">
              <vmd-button style="width:100%" class="my-4 mb-2" variant="gradient" color="primary" @click.prevent="getCourses"> تسجيل في دورات اخري</vmd-button>
            </div>
            <div class="text-center col-lg-2">
              <vmd-button style="width:100%" @click.prevent="closeModal" class="my-4 mb-2" variant="gradient" color="danger"> اغلاق</vmd-button>
            </div>
          </div>
        </form>
      </div>
      <!-- submit courses tab -->
      <div v-else>
        <div class='row'>
          <div class="mb-3 col-lg-8">
            <label> الدورات <span class="red">*</span>
              <span color='danger' class="error">{{errors.grade_id?errors.grade_id[0]:''}}</span>
            </label>
            <multiselect class='required form-control' id='grade' v-model=" selectedCourse" :options='courses' :searchable='true' :close-on-select='true' placeholder=" اختر الدورة" label='name' :custom-label="customLabel" track-by='id' required='required' :internal-search="true"> > <template v-slot:noOptions>
                <span> فارغة </span>
              </template>
              <template v-slot:noResult>
                <span>لا يوجد نتيجة </span>
              </template>
            </multiselect>
          </div>
          <div class="mb-3 col-lg-3">
            <vmd-button style="width:100%; margin-top: 30px;" @click.prevent="doAlertRegister()" class="mb-2" variant="gradient" color="warning"> تسجيل</vmd-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import VmdInput from '@/components/VmdInput.vue'
import VmdButton from '@/components/VmdButton.vue'
import $ from 'jquery'
import swal from 'sweetalert'
export default {
  name: 'NewStudent',
  components: {
    VmdInput,
    VmdButton
  },
  data () {
    return {
      student: {
      },
      groups: [
        { id: '5d2a4482-5848-43c4-b30e-7d69ec2efcc5', name: '4' },
        { id: '7cdf25a8-4837-4480-98ae-2ed752287ad4', name: '2' },
        { id: '9a2f0d62-093c-4e38-a3f9-8c01cbf35739', name: '3' },
        { id: 'fff0c94b-3406-494e-9cce-516b021fbc39', name: '1' }],
      grades: [
        { id: '5de6043a-bc65-437b-bb57-0cbf69573dd7', name: 'الفرقة الرابعة' },
        { id: 'ab1fb157-188b-45ea-b046-302ff1221253', name: 'الفرقة الأولى' },
        { id: 'd96e432f-9a95-4bdb-9a87-bceaf981fe0e', name: 'الفرقة الثانية' },
        { id: 'f4a80ded-3d68-4795-84b0-5692daa74b78', name: 'الفرقة الثالثة' }],
      areas: [],
      selectedGroup: '',
      selectedGrade: '',
      selectedArea: '',
      coursesSubmit: false,
      studentId: '',
      courses: [],
      selectedCourse: '',
      attendance: false,
      mobile: '',
      sources: [],
      salespeople: [],
      selectedSource: '',
      selectedSales: '',
      salesPersonN: '',
      errors: []
    }
  },
  emits: ['update:modelValue'],
  async created () {
    const areas = []
    await this.$http.get(`${this.$hostUrl}areas`)
      .then((response) => {
        $.each(response.data.data, function (key, value) {
          areas.push({
            name: value.name,
            id: value.id
          })
        })
        this.areas = areas
      })
    await this.$http.get(`${this.$hostUrl}sources`)
      .then((response) => {
        this.sources = response.data.data.map(value => ({
          name: value.name,
          id: value.id
        }))
      })
    await this.$http.get(`${this.$hostUrl}salespeople`)
      .then((response) => {
        this.salespeople = response.data.data.map(value => ({
          name: value.name,
          id: value.id
        }))
      })
  },

  methods: {
    getCourses () {
      if (!this.studentId) {
        swal('يجب إضافة الطالب أولا')
      } else {
        this.coursesSubmit = true
        this.$http.get(`${this.$hostUrl}student/${this.studentId}/courses`)
          .then((response) => {
            this.courses = response.data.data
          })
      }
    },
    async createStudent (e) {
      e.preventDefault()
      const current = this
      const formData = new FormData()
      if (/^\d+$/.test(this.student.fullName)) {
        swal('اسم الطالب يجب ان يحتوي على حروف')
        return 0
      }
      if (!this.selectedSource) {
        this.errors.source_id = []
        this.errors.source_id.push(' اختيار المصدر مطلوب')
        return 0
      }
      formData.append('full_name', this.student.fullName)
      if (this.student.email) {
        formData.append('email', this.student.email)
      }
      if (this.student.facebookUrl) {
        formData.append('facebook_url', this.student.facebookUrl)
      }
      formData.append('mobile', this.student.mobile)
      formData.append('user_name', this.student.username)
      formData.append('group_id', this.selectedGroup.id)
      formData.append('grade_id', this.selectedGrade.id)
      formData.append('area_id', this.selectedArea.id)
      formData.append('source_id', this.selectedSource.id)
      if (this.selectedSource.id === '2c481399-60f5-11ee-bd6a-f23c936035ca') {
        formData.append('salesperson_id', this.selectedSales.id)
      }
      await this.$http
        .post(this.$hostUrl + 'students', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then((response) => {
          this.$store.commit('setNewStudent', response.data.data)
          this.studentId = response.data.data.id
          if (response.data.error) {
            swal({ title: response.data.message, buttons: 'تم' })
          } else {
            swal({
              title: response.data.message,
              text: `رقم : ${response.data.data.number} `,
              buttons: 'تم'
            })
            current.receipt = response.data.data
            current.selectedCourse = ''
            current.student = {}
            current.selectedGrade = ''
            current.selectedGroup = ''
            current.selectedArea = ''
            current.attendance = true
          }
        })
        .catch(function (error) {
          if (Object.prototype.hasOwnProperty.call(error, 'response')) {
            console.log('error.response.data.data', error.response.data.data)
            if (error.response.data.data) {
              current.errors = error.response.data.data
            } else {
              swal({ title: error.response.data.message, buttons: 'تم' })
            }
          }
        })
    },
    doAlertRegister () {
      const formData = new FormData()
      formData.append('student_id', this.studentId)
      formData.append('course_id', this.selectedCourse.id)
      this.errors = this.postRequest(
        formData,
        this.$hostUrl + 'course/enroll',
        ''
      ).then((response) => {
        if (response.course_id) { swal(response.course_id[0]) }
        this.coursesSubmit = false
        this.$emit('update-table')
        this.$emit('hide-modal')
        this.attendance = false
      })
    },
    closeModal () {
      this.$emit('hide-modal')
      this.attendance = false
    }
  }
}
</script>
