<template>
     <div class="container-fluid py-4 add-student lecture-details">
    <div class="row">
      <div class="col-12">
        <div class="card my-4">
          <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
            <div
              class="bg-gradient-success shadow-success border-radius-lg pt-4 pb-3"
            >
              <h6 class="text-white text-capitalize ps-3">   {{$t('message.showUser')}}</h6>
            </div>
          </div>
          <div class="card-body px-0 pb-2">
                   <form role="form" class="text-start mt-3">
                       <div class="row">
                           <div class="mb-3 col-lg-6">
                      <label>   {{$t('message.name')}} </label>
                  <vmd-input
                    id=""
                    type="text"
                    :label="user.name"
                    disabled
                  />
                </div>
                <div class="mb-3 col-lg-6">
                    <label>  {{$t('message.email')}}</label>
                  <vmd-input
                    id=""
                    type="text"
                   :label="user.email"
                   disabled
                  />
                </div>
                </div>
                  <div class='row'>
                    <div class="mb-3 col-lg-6">
                    <label>   {{$t('message.branch')}} </label>
                  <vmd-input
                    id=""
                    type="text"
                   :label="user.branch?user.branch.name : ''"
                   disabled
                  />
                </div>
                  <div class="mb-3 col-lg-6">
                    <label>    {{$t('message.userType')}} </label>
                  <vmd-input
                    id=""
                    type="text"
                    :label="user.user_type?user.user_type.name:''"
                   disabled
                  />
                </div>
                       </div>

              </form>
          </div>
        </div>
      </div>
    </div>
     </div>
</template>
<script>
import VmdInput from '@/components/VmdInput.vue'
export default {
  name: 'ShowUser',
  components: {
    VmdInput
  },
  data () {
    return {
      user: {}
    }
  },
  created () {
    console.log(this.$route.params.id)
    this.$http.get(`${this.$hostUrl}users/${this.$route.params.id}`).then(response => {
      this.user = response.data.data
      console.log(this.user.name)
    })
  }
}
</script>
