<template>
    <div>
        <CreateCall
                    v-model="showLectCalls"
                    @hide-modal="showLectCalls = false"
                    :studentInfo="studentInfo"
                    :calls="calls"
                    @update-data="updateLecturesData"
                  ></CreateCall>
                <div class="px-0 pb-2">
                  <!-- exceptional attendance row -->
                  <span class="filter-text pb-2"
                    >اختر الفرقة ثم المادة ثم الدورة للمحاضرة المناسبة</span
                  >
                  <div class="row align-center ex-row">
                    <div class="mb-3 col-lg-3">
                      <label>الفرقة</label>
                      <multiselect
                        class="required form-control"
                        id="student"
                        v-model="selectedGrade"
                        :options="grades"
                        :searchable="true"
                        :close-on-select="true"
                        placeholder=" اختر الفرقة"
                        @select="getSubjects($event)"
                        label="name"
                        track-by="id"
                        required="required"
                        :internal-search="true"
                      >
                        >
                        <template v-slot:noOptions>
                          <span> فارغة </span>
                        </template>
                        <template v-slot:noResult>
                          <span>لا يوجد نتيجة </span>
                        </template>
                      </multiselect>
                    </div>
                    <div class="mb-3 col-lg-3">
                      <label>المجموعة</label>
                      <multiselect
                        class="required form-control"
                        id="student"
                        v-model="selectedGroup"
                        :options="groups"
                        :multiple="true"
                        :close-on-select="true"
                        placeholder=" اختر المجموعة"
                        label="name"
                        track-by="id"
                        required="required"
                        :internal-search="true"
                      >
                        >
                        <template v-slot:noOptions>
                          <span> فارغة </span>
                        </template>
                        <template v-slot:noResult>
                          <span>لا يوجد نتيجة </span>
                        </template>
                      </multiselect>
                    </div>
                    <div class="mb-3 col-lg-3">
                      <label>المادة</label>
                      <multiselect
                        class="required form-control"
                        id="student"
                        v-model="selectedSubject"
                        :options="subjects"
                        :searchable="true"
                        :close-on-select="true"
                        placeholder=" اختر المادة"
                        label="name"
                        @select="getCourses($event)"
                        track-by="id"
                        required="required"
                        :internal-search="true"
                      >
                        >
                        <template v-slot:noOptions>
                          <span> فارغة </span>
                        </template>
                        <template v-slot:noResult>
                          <span>لا يوجد نتيجة </span>
                        </template>
                      </multiselect>
                    </div>
                    <div class="mb-3 col-lg-3">
                      <label>الدورة</label>
                      <multiselect
                        class="required form-control"
                        id="student"
                        v-model="selectedCourse"
                        :options="courses"
                        @select="getLectures($event)"
                        :searchable="true"
                        :close-on-select="true"
                        placeholder=" اختر الدورة"
                        label="name"
                        track-by="id"
                        required="required"
                        :internal-search="true"
                      >
                        >
                        <template v-slot:noOptions>
                          <span> فارغة </span>
                        </template>
                        <template v-slot:noResult>
                          <span>لا يوجد نتيجة </span>
                        </template>
                      </multiselect>
                    </div>
                  </div>
                  <div class="row align-center ex-row">
                    <div class="mb-3 col-lg-4">
                      <label>المحاضرة<span class="red">*</span></label>
                      <multiselect
                        class="required form-control"
                        id="student"
                        v-model="selectedLecture"
                        :options="lecturesList"
                        :searchable="true"
                        :close-on-select="true"
                        placeholder=" اختر المحاضرة"
                        label="name"
                        track-by="id"
                        required="required"
                        :internal-search="true"
                      >
                        >
                        <template v-slot:noOptions>
                          <span> فارغة </span>
                        </template>
                        <template v-slot:noResult>
                          <span>لا يوجد نتيجة </span>
                        </template>
                      </multiselect>
                    </div>
                    <div class="mb-3 col-lg-2">
                      <label>حالة الحضور</label>
                      <div class="input-group input-group-outline null">
                        <select class="form-control" v-model="selectedStatus">
                          <option selected value="">الكل</option>
                          <option value="2">الحضور</option>
                          <option value="1">الغياب</option>
                        </select>
                        <i class="fas fa-caret-down" aria-hidden="true"></i>
                      </div>
                    </div>
                    <div class="text-center col-lg-2">
                      <vmd-button
                        class="my-4 mb-2 width"
                        variant="gradient"
                        color="primary"
                        @click="filterData()"
                      >
                        فلتر</vmd-button
                      >
                    </div>
                    <div class="text-center col-lg-2">
                      <vmd-button
                        class="my-4 mb-2 width"
                        variant="gradient"
                        color="info"
                        @click="printReport()"
                      >
                        طباعة</vmd-button
                      >
                    </div>
                    <div class="text-center col-lg-2">
                      <vmd-button
                        class="my-4 mb-2 width"
                        variant="gradient"
                        color="warning"
                        @click="downloadFile()"
                      >
                        اكسل</vmd-button
                      >
                    </div>
                  </div>
                  <div
                    id="print-lecture"
                    style="margin-top: 40px; direction: rtl"
                  >
                    <div
                      class="row times"
                      style="margin-top: 40px; direction: rtl"
                    >
                      <div class="col-md-7">
                        <h5>
                          الدورة التدريبية :
                          <span class="green">{{ selectedCourse.name }}</span>
                        </h5>
                      </div>
                      <div class="col-md-5">
                        <h5>
                          المحاضرة :
                          <span class="green">{{ selectedLecture.name }}</span>
                        </h5>
                      </div>
                    </div>
                    <div
                      class="row align-center"
                      style="margin-top: 40px; direction: rtl"
                    >
                      <div class="text-center col-lg-12 overflow-x" style="direction: rtl">
                        <table class="b-block table" style="direction: rtl">
                          <thead>
                            <tr>
                              <th rowspan="2">رقم الطالب</th>
                              <th rowspan="2">اسم الطالب</th>
                              <th rowspan="2">الهاتف</th>
                              <th class="call-th" colspan="3">المكالمات</th>
                              <th rowspan="2">الفرقة</th>
                              <th rowspan="2">المجموعة</th>
                              <th rowspan="2">الاستثنائي</th>
                              <th rowspan="2" >الحضور</th>
                              <th rowspan="2">الغياب</th>
                              <th rowspan="2">
                                وقت <br />
                                الحضور
                              </th>
                            </tr>
                              <tr class="call-th">
                                <th>اضافة</th>
                                <th>اخر مكالمة</th>
                                <th>المكالمة القادمة</th>
                              </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="student in lectureAttendance"
                              :key="student.id"
                            >
                              <td>{{ student.number }}</td>
                              <td>{{ student.full_name }}</td>
                              <td>
                                  <a
                                    class="to-link"
                                    :href="`tel:${student.mobile}`"
                                  >
                                    {{ student.mobile }}</a
                                  >
                                </td>
                               <td id="add-call-btn" class="call-td">
                                  <button
                                    style="width: unset !important"
                                    @click="
                                      getLectureCalls(
                                        student.full_name,
                                        student.id,
                                        student.calls
                                      )
                                    "
                                    class="badge btn btn-info badge-sm pointer"
                                  >
                                    إضافة
                                  </button>
                                </td>
                                <td  class="call-td">
                                  {{
                                    student.last_call
                                      ? student.last_call.created_at
                                      : null
                                  }}
                                </td>
                                <td  class="call-td">
                                  {{
                                    student.last_call
                                      ? student.last_call.next_call_date
                                      : null
                                  }}
                                </td>
                              <td>{{ student.grade }}</td>
                              <td>{{ student.group }}</td>
                              <td>{{ student.exceptional }}</td>
                              <td class="text-center">
                                <span
                                  v-if="student.attend"
                                  class="badge badge-sm bg-gradient-success"
                                  ><i class="fas fa-check"></i
                                ></span>
                              </td>
                              <td class="text-center">
                                <span
                                  v-if="!student.attend"
                                  class="badge badge-sm bg-gradient-success"
                                  ><i class="fas fa-check"></i
                                ></span>
                              </td>
                              <td>{{ student.date }}</td>
                            </tr>
                          </tbody>
                          <tfoot class="card-footer">
                            <tr class="back-color">
                              <th  colspan="8">الاجمالي</th>
                              <td class="total-tex">{{ totalLectureAttand.exceptional }}</td>
                              <td class="total-tex">{{ totalLectureAttand.attended }}</td>
                              <td class="total-tex">{{ totalLectureAttand.absence }}</td>
                              <td></td>
                            </tr>
                          </tfoot>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
    </div>
</template>
<script>
import VmdButton from '@/components/VmdButton.vue'
import CreateCall from '@/components/CreateCall.vue'
import swal from 'sweetalert'
import $ from 'jquery'
import exportFromJSON from 'export-from-json'
const date = new Date()
const today =
  date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate()
export default {
  components: {
    VmdButton,
    CreateCall
  },
  data () {
    return {
      tableDate: today,
      fromDate: today,
      toDate: today,
      courses: [],
      selectedCourse: '',
      groups: [],
      selectedGroup: '',
      subjects: [],
      selectedSubject: '',
      grades: [],
      selectedGrade: '',
      lectures: [],
      lecturesList: [],
      selectedLecture: '',
      lectureFile: [],
      lectureAttendance: [],
      totalAttend: '',
      totalExceptional: '',
      selectedStatus: '',
      exceptional: '',
      grade: '',
      totalLectureAttand: [],
      lectAttendance: true,
      studentInfo: {},
      calls: [],
      showLectCalls: false,
      errors: []
    }
  },
  created () {
    this.$http.get(`${this.$hostUrl}courses`).then((response) => {
      this.courses = response.data.data.filter((course) => {
        return course.type === 0
      })
    })
    this.$http.get(`${this.$hostUrl}subjects`).then((response) => {
      this.subjects = response.data.data
    })
    this.$http.get(`${this.$hostUrl}groups`).then((response) => {
      this.groups = response.data.data
    })
    this.$http.get(`${this.$hostUrl}grades`).then((response) => {
      this.grades = response.data.data
    })
  },
  methods: {
    getSubjects ($event) {
      this.selectedSubject = ''
      this.$http
        .get(`${this.$hostUrl}grade/${$event.id}/subjects?semester=1`)
        .then((response) => {
          this.subjects = response.data.data
        })
    },
    getCourses ($event) {
      this.selectedCourse = ''
      this.$http
        .get(`${this.$hostUrl}courses?subject_id=${$event.id}`)
        .then((response) => {
          this.courses = response.data.data.filter((course) => {
            return course.type === 0
          }).map(val => ({
            id: val.id,
            name: val.number + ' - ' + val.name
          }))
          if (!this.courses.length) {
            swal(' لا توجد دورات اوفلاين تابعه للمادة')
          }
        })
    },
    getLectures ($event) {
      this.$http
        .get(`${this.$hostUrl}course/${$event.id}/lectures`)
        .then((response) => {
          if (!response.data.data.length) {
            swal('لا توجد محاضرات')
          }
          const lectureList = []
          $.each(response.data.data, (key, val) => {
            lectureList.push({
              id: val.id,
              name: val.lecture_number + ' ' + '-' + ' ' + val.start
            })
          })
          this.lecturesList = lectureList
        })
    },
    getLectureCalls (studentName, id, calls) {
      this.studentInfo = {
        name: studentName,
        id: id
      }
      this.calls = calls
      this.showLectCalls = true
    },
    // for lecture calls
    updateLecturesData () {
      this.filterData()
    },
    filterData () {
      if (!this.selectedLecture) {
        swal('برجاء ادخال المحاضرة')
      } else {
        let allSelectedGroups = []
        allSelectedGroups = this.selectedGroup ? this.selectedGroup.map(group => group.id) : null
        if (allSelectedGroups !== null) {
          allSelectedGroups = JSON.stringify(allSelectedGroups).replace(/\s/g, '')
        }
        const current = this
        const totalLectureAttand = []
        totalLectureAttand.attended = 0
        totalLectureAttand.absence = 0
        totalLectureAttand.exceptional = 0
        this.$http
          .get(
                `${this.$hostUrl}attendance-report?lecture_id=${
                  this.selectedLecture.id
                }&attend=${this.selectedStatus}&group_id=${
                  this.selectedGroup ? allSelectedGroups : ''
                }`
          )
          .then((response) => {
            const lectureAttendance = []
            current.selectedLecture.date = response.data.data.date
            if (!response.data.data.students.length) {
              swal('لايوجد حضور لهذه المحاضرة')
            }
            $.each(response.data.data.students, (key, val) => {
              lectureAttendance.push({
                id: val.id,
                number: val.number,
                full_name: val.full_name,
                mobile: val.mobile,
                calls: val.calls,
                grade: val.grade ? current.getGrade(val.grade_id) : '-',
                group: val.group ? val.group.name : '-',
                attend: val.pivot ? val.pivot.attended : '',
                exceptional:
                      val.pivot.exceptional === 1 &&
                      val.pivot.exceptional !== 'undefined'
                        ? '1'
                        : '',
                date: val.lecture_date
                  ? val.lecture_date.substring(11)
                  : val.attend_date
                    ? val.attend_date.substring(11)
                    : null
              })
              totalLectureAttand.attended +=
                    val.pivot.attended === 1 ? 1 : 0
              totalLectureAttand.absence +=
                    val.pivot.attended === 0 ? 1 : 0
              totalLectureAttand.exceptional +=
                    val.pivot.exceptional === 1 &&
                    val.pivot.exceptional !== 'undefined'
                      ? 1
                      : 0
            })
            this.totalLectureAttand = totalLectureAttand
            this.lectureAttendance = lectureAttendance
          })
      }
    },
    getGrade (id) {
      let number = ''
      switch (id) {
        case 'ab1fb157-188b-45ea-b046-302ff1221253':
          number = 1
          break
        case 'd96e432f-9a95-4bdb-9a87-bceaf981fe0e':
          number = 2
          break
        case 'f4a80ded-3d68-4795-84b0-5692daa74b78':
          number = 3
          break
        case '5de6043a-bc65-437b-bb57-0cbf69573dd7':
          number = 4
          break
      }
      return number
    },
    printReport (report) {
      $('.call-th,.call-td,.card-footer').hide()
      const options = {
        name: ' Seven Eleven System ',
        specs: ['fullscreen=yes', 'titlebar=yes', 'scrollbars=yes'],
        styles: [
          'https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.rtl.min.css',
          'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.1/css/all.min.css',
          `${window.location.origin}/print-report.css`
        ],
        timeout: 1000, // default timeout before the print window appears
        autoClose: true, // if false, the window will not close after printing
        windowTitle: 'Seven Eleven System' // override the window title
      }
      setTimeout(this.$htmlToPaper('print-lecture', options), 10000)
      $('.call-th,.call-td,.card-footer').show()
    },
    downloadFile (report) {
      const current = this
      $.each(this.lectureAttendance, (key, val) => {
        this.lectureFile.push({
          رقم_الطالب: val.number,
          اسم_الطالب: val.full_name,
          الفرقة: current.getGrade(val.grade_id) ?? '-',
          المجموعة: val.group ?? '-',
          الحضور: val.attend === 1 ? 'o' : '-',
          الغياب: val.attend === 0 ? 'o' : '-',
          الإستثنائي: val.exceptional,
          تاريخ_المحاضرة: val.date
        })
      })
      this.lectureFile.push({
        رقم_الطالب: '',
        اسم_الطالب: '',
        الفرقة: '',
        المجموعة: 'الإجمالي',
        الحضور: this.totalLectureAttand.attended,
        الغياب: this.totalLectureAttand.absence,
        الإستثنائي: this.totalLectureAttand.exceptional,
        تاريخ_المحاضرة: ''
      })
      const data = this.lectureFile
      const fileName = ` تقرير حضور المحاضرة - ${today}`
      const exportType = exportFromJSON.types.xls
      if (data) exportFromJSON({ data, fileName, exportType })
    }
  }

}

</script>
