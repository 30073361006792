<template>
     <div class="container-fluid py-4 add-student ">
    <div class="row">
      <div class="col-12">
        <div class="card my-4">
          <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
            <div
              class="bg-gradient-success shadow-success border-radius-lg pt-4 pb-3"
            >
              <h6 class="text-white text-capitalize ps-3">   عرض بيانات المادة</h6>
            </div>
          </div>
          <div class="card-body px-0 pb-2">
                   <form role="form" class="text-start mt-3">
                       <div class="row">
                           <div class="mb-3 col-lg-6">
                      <label>  اسم المادة </label>
                  <vmd-input
                    id=""
                    type="text"
                    :label="subject.name"
                    disabled
                  />
                </div>
                <div class="mb-3 col-lg-6">
                    <label>  الفرقة</label>
                  <vmd-input
                    id=""
                    type="text"
                   :label="subject.grade?.name || null"
                   disabled
                  />
                </div>
                </div>
                  <div class='row'>
                    <div class="mb-3 col-lg-6">
                    <label>  الترم الدراسي </label>
                  <vmd-input
                    id=""
                    type="text"
                   :label="subject.semester"
                   disabled
                  />
                </div>
                  <div class="mb-3 col-lg-6">
                    <label>   قيمة المادة </label>
                  <vmd-input
                    id=""
                    type="text"
                   :label="subject.cost"
                   disabled
                  />
                </div>
                       </div>

              </form>
                <!-- students -->
      <div class="row" style="margin-top:40px">
        <div class="col-12">
          <div class="card my-4">
            <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
              <div
                class="bg-gradient-success shadow-success border-radius-lg pt-4 pb-3"
              >
                <h6 class="text-white text-capitalize ps-3">
                  الطلاب المسجلين في المادة وغير ملتحقين بدورة
                </h6>
              </div>
            </div>
            <div class="card-body px-0 pb-2">
              <div class="table-responsive p-0">
                <table class="table align-items-center mb-0">
                  <thead>
                    <tr>
                      <th class="text-uppercase x font-weight-bolder o">رقم</th>
                      <th class="text-uppercase x font-weight-bolder o">
                        الاسم
                      </th>
                      <th class="text-uppercase x font-weight-bolder o">
                        المدفوع
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="student in students" :key="student.id">
                      <td>
                        <p class="text-s mb-0">{{ student.number }}</p>
                      </td>
                      <td>
                        <p class="text-s mb-0"><router-link class="to-link pointer" :to="{path:'/students/' +student.id+'/show'}">{{ student.full_name }}</router-link></p>
                      </td>
                      <td>
                        <p class="text-s mb-0">{{$filters.number(student.subject_paid)}}</p>
                      </td>
                    </tr>
                    <tr style="background:#dad6d669;font-weight:bold">
                      <td colspan="2">الإجماليات</td>
                       <td>{{$filters.number(totalPayments)}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
          </div>
        </div>
      </div>
    </div>
     </div>
</template>
<script>
import VmdInput from '@/components/VmdInput.vue'
export default {
  name: 'ShowSubject',
  components: {
    VmdInput
  },
  data () {
    return {
      subject: {},
      students: [],
      total_payment: 0
    }
  },
  created () {
    this.$http.get(`${this.$hostUrl}subjects/${this.$route.params.id}`).then(response => {
      this.subject = response.data.data
    })
    this.$http.get(`${this.$hostUrl}report/paid/subject/students?subject_id=${this.$route.params.id}`).then(response => {
      this.students = response.data.data.subject.students
      this.totalPayments = response.data.data.total_payment
    })
  }
}
</script>
