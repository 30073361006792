import { createApp } from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import jquery from 'jquery'
import swal from 'sweetalert'
import axios from 'axios'
import i18n from './i18n'
import './assets/css/nucleo-icons.css'
import './assets/css/nucleo-svg.css'
import MaterialDashboard from './material-dashboard'
import Multiselect from 'vue-multiselect'
// optionally import default styles
import { numberFormat } from '././assets/js/number-format'
import 'bootstrap'
import 'datatables.net-bs5'
// // Example: import buttons and plugins
import 'datatables.net-buttons/js/dataTables.buttons.js'
import 'datatables.net-buttons/js/buttons.html5.js'
import 'datatables.net-buttons/js/buttons.print.js'

// import the rest for your specific theme
import 'datatables.net-buttons-bs5'
import 'datatables.net-select-bs5'

import 'datatables.net-select-bs5/css/select.bootstrap5.min.css'
import 'datatables.net-buttons-bs5/css/buttons.bootstrap5.min.css'
import VueHtmlToPaper from '../plugin/VueHtmlToPaper'

const basePath = 'https://dev.savior.riseupsoftservices.com'
const options = {
  name: ' Seven Eleven System ',
  specs: [
    'fullscreen=yes',
    'titlebar=yes',
    'scrollbars=yes'
  ],
  styles: [
     `${basePath}/print.css`
  ],
  timeout: 1000, // default timeout before the print window appears
  autoClose: true, // if false, the window will not close after printing
  windowTitle: 'Seven Eleven System' // override the window title
}

const appInstance = createApp(App)
appInstance.use(VueHtmlToPaper, options)
appInstance.use(jquery)
appInstance.use(i18n)
appInstance.component('multiselect', Multiselect)
appInstance.use(store)
appInstance.use(router)
appInstance.use(MaterialDashboard)
appInstance.config.globalProperties.$hostUrl = process.env.VUE_APP_BACKEND_SERVER
appInstance.mount('#app')
appInstance.config.productionTip = false
appInstance.config.performance = true
appInstance.config.globalProperties.$http = axios
appInstance.config.globalProperties.i18n = i18n
appInstance.config.globalProperties.$ = jquery
if (localStorage.Lang !== null) {
  i18n.locale = localStorage.Lang
} else {
  i18n.locale = 'ar'
}
appInstance.config.globalProperties.$filters = {
  number (value) {
    return numberFormat(value, 2)
  }
}

appInstance.config.globalProperties.$filters = {
  number (value) {
    return numberFormat(value, 2)
  },
  formatTime (timeString) {
    const [hourString, minute] = timeString.split(':')
    const hour = +hourString % 24
    return (hour % 12 || 12) + ':' + minute + (hour < 12 ? ' AM' : ' PM')
  }
}

axios.interceptors.request.use(req => {
  jquery('.spinner-container').show()
  req.headers['Accept-Language'] = 'ar'
  req.headers.authorization = 'Bearer ' + localStorage.token
  return req
})
axios.interceptors.response.use(function (response) {
  jquery('.spinner-container').hide()
  return response
}, function (error) {
  (jquery('.spinner-container') ? jquery('.spinner-container').remove() : jquery('.spinner-container').show())
  console.log(error)
  if (error.response.status === 401) {
    swal({
      title: 'انتهت الجلسة',
      text: 'انتهت صلاحية جلسة العمل الخاصة بك. هل ترغب في إعادة توجيهك إلى صفحة تسجيل الدخول؟',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#DD6B55',
      confirmButtonText: 'Yes',
      closeOnConfirm: false
    }).then((oKBtn) => {
      router.push({ path: '/login' })
    })
  } else {
    console.log(error)
  }
  return Promise.reject(error)
})

appInstance.mixin({

  methods: {
    async postRequest (formData, url, create) {
      console.log(jquery('.spinner-container'))
      let errors = []
      await this.$http.post(`${url}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
        .then((response) => {
          if (!response.data.error) {
            console.log(response.data.message)
            swal({ title: response.data.message, buttons: 'تم' })
            if (create) {
              this.$router.push({ path: create })
            }
          } else {
            errors = response.data.data
          }
        }).catch(function (error) {
          if (Object.prototype.hasOwnProperty.call(error, 'response')) {
            errors = error.response.data.data
            // const data = (error.response || {}).data
            // if (data.error) {
            //   errors = data.message
            // }
            // } else {
            //   swal({
            //     title: 'حدث خطـأ اثناء معالجة الطلب الرجاء المحاولة لاحقا',
            //     buttons: 'تم'
            //   })
            // }
          // } else {
          //   swal({
          //     title: 'حدث خطـأ اثناء معالجة الطلب الرجاء المحاولة لاحقا',
          //     buttons: 'تم'
          //   })
          }
        })
      return errors
    }
  }
})
