<template>
  <div class="container-fluid py-4 add-student grade-detail">
    <div class="row">
      <div class="col-12">
        <div class="card my-4">
          <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
            <div
              class="bg-gradient-success shadow-success border-radius-lg pt-4 pb-3"
            >
              <div class="row">
                <div class="col-lg-6">
                  <h6 class="text-white text-capitalize ps-3">   تقارير رسوم التطبيق   </h6>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body px-0 pb-2">
            <!-- exceptional attendance row -->
         <div class="row align-center ex-row">
                 <div class="text-center col-lg-2">
                  <vmd-button
                    class="my-4 mb-2 width"
                    variant="gradient"
                    color="info"
                    @click="printReport()"
                    >  طباعة</vmd-button
                  >
                </div>
                  <div class="text-center col-lg-2">
                  <vmd-button
                    class="my-4 mb-2 width"
                    variant="gradient"
                    color="warning"
                    @click="downloadFile()"
                    >  اكسل</vmd-button
                  >
                </div>
              </div>

              <div id='print-report' style=" margin-top: 40px;direction:rtl">
            <div class="row align-center" style=" margin-top: 40px;direction:rtl" >
              <div class="text-center col-lg-12 overflow-x" style="direction:rtl">
                <table class=" b-block  table " style="direction:rtl">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th >رقم</th>
                        <th>اسم الدورة</th>
                        <th> الفرقة</th>
                        <th> المدرس</th>
                        <th>رسوم التطبيق</th>
                          <th> الطلاب المسجلين</th>
                           <th>  مدفوعات الدورة</th>
                        <th>المدفوع من الرسوم</th>
                        <th>  الإجمالي</th>
                        </tr>

                  </thead>
                  <tbody>
                    <tr v-for="(course,index) in courses" :key="course.id">
                      <td>{{index+1}}</td>
                      <td>{{course.course_number}}</td>
                      <td style="white-space:normal; width:27%" class="wrap-text">{{course.course}}</td>
                      <td>{{course.grade.name}}</td>
                      <td>{{course.instructor.name}}</td>
                      <td>{{$filters.number(course.app_fees)}}</td>
                      <td>{{course.enrolled_students_count}}</td>
                       <td>{{$filters.number(course.course_paid)}}</td>
                       <td @click="getFeesDetails(course.id,course.course)" class="pointer" style="color: blue !important" >{{$filters.number(course.fees_paid)}}</td>
                       <td>{{$filters.number(course.total_paid)}}</td>
                    </tr>
                      <tr style="background:#dad6d669;font-weight:bold">
                      <td colspan="2">الإجماليات</td>
                      <td>{{totalsInfo.courses_count}} دورة</td>
                      <td colspan="2"></td>
                       <td>{{$filters.number(totalsInfo.total_cost)}}</td>
                      <td>{{totalsInfo.totalStudents}} طالب</td>
                      <td>{{$filters.number(totalsInfo.totalCoursesPaid)}}</td>
                        <td>{{$filters.number(totalsInfo.total_fees_payment)}}</td>
                         <td>{{$filters.number(totalsInfo.total)}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            </div>
          </div>
   <!-- seconed tab -->
          </div>
          </div>

      </div>
      <!-- main modal for paid fees -->
         <vue-final-modal
      v-model="showFeesModal"
      class="students-payments"
      name="example"
      classes="modal-container"
      content-class="modal-content"
    >
      <div class="row">
        <div class="col-12">
          <div class="card my-4">
            <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
              <div
                class="bg-gradient-success shadow-success border-radius-lg pt-4 pb-3"
              >
                <h6 class="text-white text-capitalize ps-3">   مدفوعات   الرسوم لدورة : {{courseName}} </h6>
                </div>
                 <div class="text-center col-lg-12 overflow-x">
                <table class="table b-block">
                  <thead>
                    <tr>
                      <th>المبلغ المدفوع</th>
                      <th> عدد الطلاب </th>
                       <th>  القيمة </th>
                    </tr>
                  </thead>
                  <tbody >
                    <tr v-for="(value, key) in courseFees" :key="key">
                      <td>
                        {{key}}
                      </td>
                      <td @click="getStudents(value)" class="pointer" style="color: blue !important">
                        {{value.length}}
                      </td>
                      <td>{{$filters.number(key * value.length)}}</td>
                    </tr>
                     <tr style="background:#dad6d669;font-weight:bold">
                      <td colspan="2">الإجماليات</td>
                       <td>{{$filters.number(feesTotals)}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
                <div class="row text-center">
                  <div class="text-center col-lg-12">
                    <vmd-button
                      type="reset"
                      class="my-4 mb-2"
                      variant="gradient"
                      color="danger"
                      @click="showFeesModal = false"
                    >
                      اغلاق
                    </vmd-button>
                  </div>
                </div>
              </div>
          </div>
        </div>
      </div>
    </vue-final-modal>
     <!-- student modal for paid fees -->
         <vue-final-modal
      v-model="showStudentsModal"
      class="students-payments"
      name="example"
      classes="modal-container"
      content-class="modal-content"
    >
      <div class="row">
        <div class="col-12">
          <div class="card my-4">
            <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
              <div
                class="bg-gradient-success shadow-success border-radius-lg pt-4 pb-3"
              >
                <h6 class="text-white text-capitalize ps-3">   مدفوعات   الطلاب لدورة : {{courseName}} </h6>
                </div>
                 <div class="text-center col-lg-12 overflow-x">
                <table class="table b-block">
                  <thead>
                    <tr>
                      <th> رقم </th>
                      <th>  اسم الطالب </th>
                       <th>   القيمة </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr  v-for="(student, key) in courseFeesStudents" :key="key">
                      <td>
                        {{student.student_number}}
                      </td>
                      <td>
                        {{student.student_name}}
                      </td>
                      <td >
                        {{student.total}}
                      </td>
                    </tr>
                     <tr style="background:#dad6d669;font-weight:bold">
                      <td colspan="2">الإجماليات</td>
                       <td>{{$filters.number(studentsFeesTotals)}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
                <div class="row text-center">
                  <div class="text-center col-lg-12">
                    <vmd-button
                      type="reset"
                      class="my-4 mb-2"
                      variant="gradient"
                      color="danger"
                      @click="showStudentsModal = false"
                    >
                      اغلاق
                    </vmd-button>
                  </div>
                </div>
              </div>
          </div>
        </div>
      </div>
    </vue-final-modal>
    </div>
</template>
<script>
import VmdButton from '@/components/VmdButton.vue'
import $ from 'jquery'
import exportFromJSON from 'export-from-json'
import { VueFinalModal } from 'vue-final-modal'
const today = new Date()
const date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate()
const time = today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds()
const CurrentDateTime = date + ' ' + time
export default {
  name: 'AppFees',
  components: {
    VmdButton,
    VueFinalModal
  },
  data () {
    return {
      courses: [],
      totalsInfo: {},
      showFeesModal: false,
      showStudentsModal: false,
      courseName: '',
      courseFees: [],
      courseFeesStudents: [],
      feesTotals: 0,
      studentsFeesTotals: 0,
      excelFile: []
    }
  },
  created () {
    this.$http.get(`${this.$hostUrl}report/courses/app/fees`).then(response => {
      this.totalsInfo = {
        courses_count: response.data.data.courses_count,
        total_cost: response.data.data.total_cost,
        total_fees_payment: response.data.data.total_fees_payment,
        totalStudents: 0,
        totalCoursesPaid: 0,
        total: 0
      }
      this.courses = response.data.data.courses
      $.each(this.courses, (key, val) => {
        this.totalsInfo.totalStudents += val.enrolled_students_count
        this.totalsInfo.totalCoursesPaid += val.course_paid
        this.totalsInfo.total += val.total_paid
      })
    })
  },
  methods: {
    getFeesDetails (courseId, courseName) {
      this.courseFees = []
      this.feesTotals = 0
      this.$http.get(`${this.$hostUrl}report/app/fees/students?course_id=${courseId}`).then(response => {
        this.courseFees = response.data.data
        $.each(this.courseFees, (key, val) => {
          if (key > 0) {
            this.feesTotals += key * val.length
          }
        })
      })
      this.courseName = courseName
      this.showFeesModal = true
    },
    getStudents (courseFeesStudents) {
      this.courseFeesStudents = []
      this.studentsFeesTotals = 0
      this.showStudentsModal = true
      this.courseFeesStudents = courseFeesStudents
      $.each(this.courseFeesStudents, (key, val) => {
        this.studentsFeesTotals += val.total
      })
    },
    printReport () {
    // $('.card-footer').hide()
      const options = {
        name: ' Seven Eleven System ',
        specs: ['fullscreen=yes', 'titlebar=yes', 'scrollbars=yes'],
        styles: [
          'https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.rtl.min.css',
          `${window.location.origin}/print-report.css`
        ],
        timeout: 1000, // default timeout before the print window appears
        autoClose: true, // if false, the window will not close after printing
        windowTitle: 'Seven Eleven System' // override the window title
      }

      setTimeout(this.$htmlToPaper('print-report', options), 10000)
      $('.card-footer').show()
    },
    downloadFile () {
      $.each(this.courses, (key, val) => {
        this.excelFile.push({
          رقم: val.course_number,
          الدورة: val.course,
          رسوم_التطبيق: val.app_fees,
          المدفوع_من_الرسوم: val.fees_paid
        })
      })
      const data = this.excelFile
      const fileName = `تقرير   رسوم التطبيق ${CurrentDateTime}`
      const exportType = exportFromJSON.types.xls
      if (data) exportFromJSON({ data, fileName, exportType })
    }
  }
}

</script>
<style>
#collection-date{
  font-weight: 800 !important;
  font-size: 18px !important;
}
.table > :not(:first-child) {
    border-bottom: 1px solid #f0f2f5 !important;
}
.width{
  width:100%;
}
.online{
  border:1px solid #ddd
}
.times{
  margin-top: 10px !important;
}
.times h5{
  font-size:16px !important
}
.times .red{
  position: relative !important;
}
.filter-text{
  font-weight: 700;
    color: #dfad07;
    font-size: 15px !important;
}
.tab2{
  margin-top: 27px !important;
    margin-bottom: 41px !important;
    border-bottom: 1px solid #ddd;
    padding-bottom: 26px !important;
}
.course-info{
      background: #f8f9fa;
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 38px;
    font-size: 14px;
}
.info-h{
      font-size: 14px !important;
    font-weight: 500;
}
.groups-ul{
  background: #fff;
    padding-top: 10px;
    padding-bottom: 10px;
    width: 206px;
    border-top: 2px solid #5cb360;
    box-shadow: 0 4px 6px -1px rgb(0 0 0 / 10%), 0 2px 4px -1px rgb(0 0 0 / 6%);
    display: none;
    position:absolute;
    border-radius: 10px;
}
/* .groups-con{
  color:blue;
  cursor: pointer;
} */
.groups-con:hover .groups-ul{
  display: block;
}

.info-data{
      font-family: "Roboto", Helvetica, Arial, sans-serif !important;
      font-weight:bold !important;
      font-size:17px !important
}
.paid-fees{
  color: blue !important
}

</style>
