<template>
  <div>
    <div class="row align-center ex-row" v-if="notifiType === 'students'">
      <div class="mb-4 col-4">
        <label> الفرقة<span class="red">*</span></label>
        <multiselect
          class="required form-control"
          id="student"
          v-model="selectedGrade"
          :options="grades"
          :searchable="true"
          :close-on-select="true"
          placeholder="  الكل "
          :multiple="true"
          @select="sendData()"
          label="name"
          track-by="id"
          required="required"
          :internal-search="true"
        >
          >
          <template v-slot:noOptions>
            <span> فارغة </span>
          </template>
          <template v-slot:noResult>
            <span>لا يوجد نتيجة </span>
          </template>
        </multiselect>
      </div>
      <div class="mb-3 col-4" v-if="centerType !== 'secondary'">
        <label>المجموعة<span class="red">*</span></label>
        <multiselect
          class="required form-control"
          id="student"
          v-model="selectedGroup"
          :options="groups"
          :searchable="true"
          :multiple="true"
          :close-on-select="true"
          @select="sendData()"
          placeholder="  الكل"
          label="name"
          track-by="id"
          required="required"
          :internal-search="true"
        >
          >
          <template v-slot:noOptions>
            <span> فارغة </span>
          </template>
          <template v-slot:noResult>
            <span>لا يوجد نتيجة </span>
          </template>
        </multiselect>
      </div>
      <div class="text-center col-4">
        <vmd-button
          class="my-4 mb-2 width action-btn"
          variant="gradient"
          color="primary"
          @click="$emit('get-students')"
        >
          فلتر</vmd-button
        >
      </div>
    </div>
    <!-- seconed tab -->
    <div class="row">
      <h6
        style="text-align: right; width: 53%"
        class="text-warning text-right"
        v-if="notifiType === 'course'"
      >
        يجب اختيار الفرقة ثم المدرس لظهور المادة المناسبة
      </h6>
    </div>
    <div class="row align-center ex-row" v-if="notifiType === 'course'">
      <div class="mb-4 col-3" v-if="centerType !== 'secondary'">
        <label> الفرع<span class="red">*</span></label>
        <multiselect
          class="required form-control"
          id="student"
          v-model="selectedBranch"
          :options="branches"
          :searchable="true"
          :close-on-select="true"
          placeholder="  الكل "
          label="name"
          track-by="id"
          required="required"
          :internal-search="true"
        >
          >
          <template v-slot:noOptions>
            <span> فارغة </span>
          </template>
          <template v-slot:noResult>
            <span>لا يوجد نتيجة </span>
          </template>
        </multiselect>
      </div>
      <div class="mb-4 col-3">
        <label> الفرقة<span class="red">*</span></label>
        <multiselect
          class="required form-control"
          id="student"
          v-model="selectedGrade"
          :options="grades"
          :searchable="true"
          :close-on-select="true"
          placeholder="  الكل "
          label="name"
          track-by="id"
          required="required"
          :internal-search="true"
        >
          >
          <template v-slot:noOptions>
            <span> فارغة </span>
          </template>
          <template v-slot:noResult>
            <span>لا يوجد نتيجة </span>
          </template>
        </multiselect>
      </div>
      <div class="mb-4 col-3">
        <label> المدرس<span class="red">*</span></label>
        <multiselect
          class="required form-control"
          id="student"
          v-model="selectedInstructor"
          :options="instructors"
          :searchable="true"
          @select="getSubjects()"
          :close-on-select="true"
          placeholder="  الكل "
          label="name"
          track-by="id"
          required="required"
          :internal-search="true"
        >
          >
          <template v-slot:noOptions>
            <span> فارغة </span>
          </template>
          <template v-slot:noResult>
            <span>لا يوجد نتيجة </span>
          </template>
        </multiselect>
      </div>
      <div class="mb-4 col-3">
        <label> المادة<span class="red">*</span></label>
        <multiselect
          class="required form-control"
          id="student"
          v-model="selectedSubject"
          :options="subjects"
          :searchable="true"
          :close-on-select="true"
          placeholder="  الكل "
          @select="getCourses($event)"
          label="name"
          track-by="id"
          required="required"
          :internal-search="true"
        >
          >
          <template v-slot:noOptions>
            <span> فارغة </span>
          </template>
          <template v-slot:noResult>
            <span>لا يوجد نتيجة </span>
          </template>
        </multiselect>
      </div>
      <div class="mb-4 col-4">
        <label> الدورة<span class="red">*</span></label>
        <multiselect
          class="required form-control"
          id="student"
          v-model="selectedCourse"
          :options="courses"
          :searchable="true"
          :close-on-select="true"
          placeholder="  الكل "
          @select="sendData()"
          label="name"
          track-by="id"
          required="required"
          :internal-search="true"
        >
          >
          <template v-slot:noOptions>
            <span> فارغة </span>
          </template>
          <template v-slot:noResult>
            <span>لا يوجد نتيجة </span>
          </template>
        </multiselect>
      </div>
      <div class="text-center col-3">
        <vmd-button
          class="my-4 mb-2 width action-btn"
          variant="gradient"
          color="primary"
          @click="$emit('get-students')"
        >
          فلتر</vmd-button
        >
      </div>
    </div>
    <!-- third tab -->
    <div class="row">
      <h6
        style="text-align: right; width: 53%"
        class="text-warning text-right"
        v-if="notifiType === 'lecture'"
      >
        يجب اختيار الفرقة ثم المدرس لظهور المادة المناسبة
      </h6>
    </div>
    <div class="row align-center ex-row" v-if="notifiType === 'lecture'">
      <div class="mb-4 col-3">
        <label> الفرع<span class="red">*</span></label>
        <multiselect
          class="required form-control"
          id="student"
          v-model="selectedBranch"
          :options="branches"
          :searchable="true"
          :close-on-select="true"
          placeholder="  الكل "
          label="name"
          track-by="id"
          required="required"
          :internal-search="true"
        >
          >
          <template v-slot:noOptions>
            <span> فارغة </span>
          </template>
          <template v-slot:noResult>
            <span>لا يوجد نتيجة </span>
          </template>
        </multiselect>
      </div>
      <div class="mb-4 col-3">
        <label> الفرقة<span class="red">*</span></label>
        <multiselect
          class="required form-control"
          id="student"
          v-model="selectedGrade"
          :options="grades"
          :searchable="true"
          :close-on-select="true"
          placeholder="  الكل "
          label="name"
          track-by="id"
          required="required"
          :internal-search="true"
        >
          >
          <template v-slot:noOptions>
            <span> فارغة </span>
          </template>
          <template v-slot:noResult>
            <span>لا يوجد نتيجة </span>
          </template>
        </multiselect>
      </div>
      <div class="mb-4 col-3">
        <label> المدرس<span class="red">*</span></label>
        <multiselect
          class="required form-control"
          id="student"
          v-model="selectedInstructor"
          :options="instructors"
          :searchable="true"
          @select="getSubjects()"
          :close-on-select="true"
          placeholder="  الكل "
          label="name"
          track-by="id"
          required="required"
          :internal-search="true"
        >
          >
          <template v-slot:noOptions>
            <span> فارغة </span>
          </template>
          <template v-slot:noResult>
            <span>لا يوجد نتيجة </span>
          </template>
        </multiselect>
      </div>
      <div class="mb-4 col-3">
        <label> المادة<span class="red">*</span></label>
        <multiselect
          class="required form-control"
          id="student"
          v-model="selectedSubject"
          :options="subjects"
          :searchable="true"
          :close-on-select="true"
          placeholder="  الكل "
          @select="getCourses($event)"
          label="name"
          track-by="id"
          required="required"
          :internal-search="true"
        >
          >
          <template v-slot:noOptions>
            <span> فارغة </span>
          </template>
          <template v-slot:noResult>
            <span>لا يوجد نتيجة </span>
          </template>
        </multiselect>
      </div>
      <div class="mb-4 col-4">
        <label> الدورة<span class="red">*</span></label>
        <multiselect
          class="required form-control"
          id="student"
          v-model="selectedCourse"
          :options="courses"
          :searchable="true"
          :close-on-select="true"
          placeholder="  الكل "
          @select="getLectures($event)"
          label="name"
          track-by="id"
          required="required"
          :internal-search="true"
        >
          >
          <template v-slot:noOptions>
            <span> فارغة </span>
          </template>
          <template v-slot:noResult>
            <span>لا يوجد نتيجة </span>
          </template>
        </multiselect>
      </div>
      <div class="mb-4 col-4">
        <label> المحاضرة<span class="red">*</span></label>
        <multiselect
          class="required form-control"
          id="student"
          v-model="selectedLecture"
          :options="lectures"
          :searchable="true"
          :close-on-select="true"
          placeholder="  الكل "
          @select="sendData()"
          label="name"
          track-by="id"
          required="required"
          :internal-search="true"
        >
          >
          <template v-slot:noOptions>
            <span> فارغة </span>
          </template>
          <template v-slot:noResult>
            <span>لا يوجد نتيجة </span>
          </template>
        </multiselect>
      </div>
      <div class="mb-3 col-lg-2">
        <label>حالة الحضور<span class="red">*</span></label>
        <div class="input-group input-group-outline null">
          <select
            class="form-control"
            v-model="selectedStatus"
            @change="sendData()"
          >
            <option selected value="">الكل</option>
            <option value="1">الحضور</option>
            <option value="0">الغياب</option>
          </select>
          <i class="fas fa-caret-down" aria-hidden="true"></i>
        </div>
      </div>
      <div class="text-center col-2">
        <vmd-button
          class="my-4 mb-2 width action-btn"
          variant="gradient"
          color="primary"
          @click="$emit('get-students')"
        >
          فلتر</vmd-button
        >
      </div>
    </div>
    <!-- fourth tab -->
    <div class="row align-center ex-row" v-if="notifiType === 'subject'">
      <div class="mb-4 col-3">
        <label> الفرقة<span class="red">*</span></label>
        <multiselect
          class="required form-control"
          id="student"
          v-model="selectedGrade"
          :options="grades"
          :searchable="true"
          :close-on-select="true"
          placeholder="  الكل "
          @select="getSubjectsOnly()"
          label="name"
          track-by="id"
          required="required"
          :internal-search="true"
        >
          >
          <template v-slot:noOptions>
            <span> فارغة </span>
          </template>
          <template v-slot:noResult>
            <span>لا يوجد نتيجة </span>
          </template>
        </multiselect>
      </div>
      <div class="mb-4 col-3">
        <label> المادة<span class="red">*</span></label>
        <multiselect
          class="required form-control"
          id="student"
          v-model="selectedSubject"
          :options="subjects"
          :searchable="true"
          :close-on-select="true"
          placeholder="  الكل "
          @select="sendData()"
          label="name"
          track-by="id"
          required="required"
          :internal-search="true"
        >
          >
          <template v-slot:noOptions>
            <span> فارغة </span>
          </template>
          <template v-slot:noResult>
            <span>لا يوجد نتيجة </span>
          </template>
        </multiselect>
      </div>
      <div class="mb-3 col-4">
        <label>المجموعة<span class="red">*</span></label>
        <multiselect
          class="required form-control"
          id="student"
          v-model="selectedGroup"
          :options="groups"
          :searchable="true"
          :multiple="true"
          :close-on-select="true"
          @select="sendData()"
          placeholder="  الكل"
          label="name"
          track-by="id"
          required="required"
          :internal-search="true"
        >
          >
          <template v-slot:noOptions>
            <span> فارغة </span>
          </template>
          <template v-slot:noResult>
            <span>لا يوجد نتيجة </span>
          </template>
        </multiselect>
      </div>
      <div class="text-center col-2">
        <vmd-button
          class="my-4 mb-2 width action-btn"
          variant="gradient"
          color="primary"
          @click="$emit('get-students')"
        >
          فلتر</vmd-button
        >
      </div>
    </div>
  </div>
</template>
<script>
import VmdButton from '@/components/VmdButton.vue'
import $ from 'jquery'
import swal from 'sweetalert'
export default {
  name: 'NotificationsTabs',
  components: {
    VmdButton
  },
  props: {
    notifiType: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      grades: [],
      selectedGrade: '',
      groups: [],
      selectedGroup: '',
      branches: [],
      selectedBranch: '',
      instructors: [],
      selectedInstructor: '',
      subjects: [],
      selectedSubject: '',
      courses: [],
      selectedCourse: '',
      lectures: [],
      selectedLecture: '',
      selectedData: {},
      selectedStatus: '',
      centerType: ''
    }
  },
  created () {
    this.centerType = localStorage.centerType
    if (this.centerType !== 'secondary') {
      this.$http.get(`${this.$hostUrl}groups`).then((response) => {
        this.groups = response.data.data
      })
    }
    this.$http.get(`${this.$hostUrl}grades`).then((response) => {
      this.grades = response.data.data
    })
    this.$http.get(`${this.$hostUrl}branches`).then((response) => {
      this.branches = response.data.data
    })
    this.$http.get(`${this.$hostUrl}instructors`).then((response) => {
      this.instructors = response.data.data
    })
  },
  methods: {
    clearFilters () {
      this.selectedGrade = '' // Clear the selected data
      this.selectedGroup = ''
      this.selectedSubject = ''
      this.selectedLecture = ''
      this.selectedCourse = ''
      this.selectedInstructor = ''
      this.selectedBranch = ''
    },
    getSubjects () {
      this.$http
        .get(
          `${this.$hostUrl}subjects?grade_id=${this.selectedGrade.id}&instructor_id=${this.selectedInstructor.id}`
        )
        .then((response) => {
          this.subjects = response.data.data
          if (!response.data.data.length) {
            swal('لاتوجد مواد تابعه للمدرس والفرقة')
          }
        })
    },
    getSubjectsOnly () {
      this.$http
        .get(
          `${this.$hostUrl}subjects?grade_id=${this.selectedGrade.id}`
        )
        .then((response) => {
          this.subjects = response.data.data
          if (!response.data.data.length) {
            swal('لاتوجد مواد تابعه للمدرس والفرقة')
          }
        })
    },
    getCourses ($event) {
      let url = `${this.$hostUrl}courses?instructor_id=${this.selectedInstructor.id}&subject_id=${$event.id}`

      if (this.centerType !== 'secondary') {
        url += `&branch_id=${this.selectedBranch.id}`
      }

      this.$http
        .get(url)
        .then((response) => {
          this.courses = response.data.data
        })
    },
    getLectures ($event) {
      this.$http
        .get(`${this.$hostUrl}lectures?course_id=${$event.id}`)
        .then((response) => {
          $.each(response.data.data, (key, val) => {
            this.lectures.push({
              id: val.id,
              name: val.lecture_number + ' - ' + val.start
            })
          })
        })
    },
    sendData () {
      if (this.notifiType === 'students') {
        const allSelectedGrades = []
        $.each(this.selectedGrade, function (key, value) {
          allSelectedGrades.push(value.id)
        })
        const allSelectedGroups = []
        $.each(this.selectedGroup, function (key, value) {
          allSelectedGroups.push(value.id)
        })
        const selectedData = {
          selectedGrade: allSelectedGrades,
          selectedGroup: allSelectedGroups
        }
        this.$emit('data-sent', selectedData)
      }
      if (this.notifiType === 'course') {
        const selectedData = {
          course: this.selectedCourse.id
        }
        this.$emit('data-sent', selectedData)
      }
      if (this.notifiType === 'lecture') {
        console.log(this.selectedStatus)
        const selectedData = {
          lecture: this.selectedLecture.id,
          attended: this.selectedStatus
        }
        this.$emit('data-sent', selectedData)
      }
      if (this.notifiType === 'subject') {
        const allSelectedGroups = []
        $.each(this.selectedGroup, function (key, value) {
          allSelectedGroups.push(value.id)
        })
        const selectedData = {
          subject: this.selectedSubject.id,
          selectedGroup: allSelectedGroups,
          grade: this.selectedGrade.id
        }
        this.$emit('data-sent', selectedData)
      }
    }
  },
  mounted () {
    console.log(this.NotifiType, 'mou')
  }
}
</script>
