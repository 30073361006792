<template>
     <div class="container-fluid py-4 add-student lecture-details">
    <div class="row">
      <div class="col-12">
        <div class="card my-4">
          <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
            <div
              class="bg-gradient-success shadow-success border-radius-lg pt-4 pb-3"
            >
              <h6 class="text-white text-capitalize ps-3">   عرض بيانات المادة</h6>
            </div>
          </div>
          <div class="card-body px-0 pb-2">
            <form role="form" class="text-start mt-3">
                <div class="row">
                  <div class="mb-3 col-lg-6">
                    <label>  اسم المادة </label>
                    <vmd-input
                      id=""
                      type="text"
                      :label="subject.name"
                      disabled
                    />
                  </div>
                  <div class="mb-3 col-lg-6">
                    <label>  الجامعة</label>
                    <vmd-input
                      id=""
                      type="text"
                    :label="faculty[0].university.name"
                    disabled
                    />
                  </div>
                </div>
                <div class='row'>
                  <div class="mb-3 col-lg-6">
                    <label>الكلية</label>
                    <vmd-input
                      id=""
                      type="text"
                    :label="faculty[0].name"
                    disabled
                    />
                  </div>
                  <div class="mb-3 col-lg-6">
                    <label>   قيمة المادة </label>
                    <vmd-input
                      id=""
                      type="text"
                      :label="subject.cost"
                      disabled
                    />
                  </div>
                </div>
              </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import VmdInput from '@/components/VmdInput.vue'
export default {
  name: 'ShowSubject',
  components: {
    VmdInput
  },
  data () {
    return {
      subject: {},
      faculty: {}
    }
  },
  created () {
    console.log(this.$route.params.id)
    this.$http.get(`${this.$hostUrl}subjects/${this.$route.params.id}`).then(response => {
      this.subject = response.data.data
      this.faculty = response.data.data.faculty
    })
  }
}
</script>
