<!-- eslint-disable vue/no-use-v-if-with-v-for -->
<!-- eslint-disable vue/no-v-for-template-key -->
<template>
  <div class="container-fluid py-4 add-student grade-detail">
    <div class="row">
      <div class="col-12">
        <div class="card my-4">
          <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
            <div
              class="bg-gradient-success shadow-success border-radius-lg pt-4 pb-3"
            >
              <div class="row">
                <div class="col-lg-6">
                  <h6 class="text-white text-capitalize ps-3">
                    تقارير الأعداد
                  </h6>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body px-0 pb-2">
            <div class="row align-center ex-row">
              <div class="text-center col-lg-3">
                  <label>أدخل القيمة</label>
                <vmd-input
                  id=""
                  type="text"
                  label="  القيمة"
                  v-model="amount"
                />
              </div>
               <div class="text-center col-lg-3">
                 <label>الفرقة</label>
                    <multiselect
                      class="required form-control"
                      id="student"
                      v-model="selectedGrade"
                      :options="gradesList"
                      :searchable="true"
                      :close-on-select="true"
                      placeholder=" اختر الفرقة"
                      label="name"
                      :custom-label="customLabel"
                      track-by="id"
                      required="required"
                      :internal-search="true"
                    >
                      >
                      <template v-slot:noOptions>
                        <span> فارغة </span>
                      </template>
                      <template v-slot:noResult>
                        <span>لا يوجد نتيجة </span>
                      </template>
                    </multiselect>
                  </div>
               <div class="text-center col-lg-3">
                 <label>المجموعة</label>
                    <multiselect
                      class="required form-control"
                      id="student"
                      v-model="selectedGroup"
                      :options="groupsList"
                      :searchable="true"
                      :close-on-select="true"
                      placeholder=" اختر المجموعة"
                      label="name"
                      :custom-label="customLabel"
                      track-by="id"
                      required="required"
                      :internal-search="true"
                    >
                      >
                      <template v-slot:noOptions>
                        <span> فارغة </span>
                      </template>
                      <template v-slot:noResult>
                        <span>لا يوجد نتيجة </span>
                      </template>
                    </multiselect>
                  </div>
                  <div class="text-center col-lg-3">
                 <label>المدرس</label>
                    <multiselect
                      class="required form-control"
                      id="student"
                      v-model="selectedInstructor"
                      :options="instructors"
                      :searchable="true"
                      :close-on-select="true"
                      placeholder=" اختر المدرس"
                      label="name"
                      :custom-label="customLabel"
                      track-by="id"
                      required="required"
                      :internal-search="true"
                    >
                      >
                      <template v-slot:noOptions>
                        <span> فارغة </span>
                      </template>
                      <template v-slot:noResult>
                        <span>لا يوجد نتيجة </span>
                      </template>
                    </multiselect>
                  </div>
            </div>
            <div class="row mt-3">
                    <div class="mb-3 col-lg-4">
                  <label>
                    الترم الدراسي<span class="red">*</span>
                  </label>
                  <div class="input-group input-group-outline null">
                    <select
                      class="form-control"
                      v-model="selectedSemester"
                       @change='getInstCourses'
                      required
                    >
                      <option value="" selected>
                        اختر الترم الدراسي
                        </option>
                      <option value="1"> الأول</option>
                      <option value="2"> الثاني</option>
                    </select>
                    <i class="fas fa-caret-down" aria-hidden="true"></i>
                  </div>
                </div>
                 <div class="mb-3 col-lg-4">
                  <label>
                    السنة الدراسية<span class="red">*</span>
                  </label>
                  <div class="input-group input-group-outline null">
                    <select
                      class="form-control"
                      v-model="selectedYear"
                      @change='getInstCourses'
                      required
                    >
                      <option selected value="">اختر السنة الدراسية</option>
                      <option
                        v-for="year in academicYear"
                        :key="year.id"
                        :value="year.id"
                      >
                        {{ year.name }}
                      </option>
                    </select>
                    <i class="fas fa-caret-down" aria-hidden="true"></i>
                  </div>
                </div>
              <div class="text-center col-lg-4">
                <vmd-button
                style="width:100%;margin-top:20px"
                  class="mb-2 mt-20 pointer action-btn"
                  variant="gradient"
                  color="primary"
                  @click="filterData()"
                >
                  فلتر</vmd-button
                >
              </div>
            </div>
            <div class="mb-3 col-12 tab-div tab2">
              <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="nav-item active" role="presentation">
                  <button
                    class="active nav-link btn mb-0 bg-gradient-info btn-md null null btn-lg"
                    id="home-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#home"
                    type="button"
                    role="tab"
                    aria-controls="home"
                    aria-selected="true"
                  >
                    اجمالي الأعداد
                  </button>
                </li>
                <li class="nav-item" role="presentation">
                  <button
                    @click="getGradesDetails"
                    class="nav-link btn mb-0 bg-gradient-info btn-md null null btn-lg"
                    id="profile-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#profile"
                    type="button"
                    role="tab"
                    aria-controls="profile"
                    aria-selected="false"
                  >
                    تفصيلي الأعداد
                  </button>
                </li>
                <li class="nav-item" role="presentation">
                  <button
                    @click="getBranchedDetails"
                    class="nav-link btn mb-0 bg-gradient-info btn-md null null btn-lg"
                    id="totals-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#totals"
                    type="button"
                    role="tab"
                    aria-controls="totals"
                    aria-selected="false"
                  >
                    تفصيلي الفرق
                  </button>
                </li>
              </ul>
            </div>
            <!-- exceptional attendance row -->
            <div class="tab-content" id="myTabContent">
              <div
                class="tab-pane fade show active"
                id="home"
                role="tabpanel"
                aria-labelledby="home-tab"
              >
                <div id="print-report" style="margin-top: 40px; direction: rtl">
                  <div
                    class="row align-center"
                    style="margin-top: 40px; direction: rtl"
                  >
                    <div
                      class="text-center col-lg-12 overflow-x"
                      style="direction: rtl"
                    >
                      <table class="b-block table" style="direction: rtl">
                        <thead>
                          <tr>
                            <th rowspan="2">المدرس</th>
                            <th rowspan="2">الفرقة</th>
                            <th rowspan="2">المادة</th>
                            <th rowspan="2">المجموعة</th>
                            <th
                              v-for="(val, index) in branchesCount"
                              :key="index"
                              colspan="3"
                            >
                              {{ val }}
                            </th>
                            <th rowspan="2">اجمالي الفروع</th>
                            <th rowspan="2">اجمالي الغير منتظم</th>
                            <th rowspan="2">اجمالي المنتظم</th>
                            <th rowspan="2">الاونلاين</th>
                            <th rowspan="2">الاجمالي للطلاب</th>
                            <th rowspan="2">المدرس</th>
                          </tr>
                          <tr>
                            <!-- eslint-disable-next-line vue/no-v-for-template-key -->
                            <template v-for="n in 4" :key="n">
                              <th>المنتظم</th>
                              <th>الغير منتظم</th>
                              <th>الاجمالي</th>
                            </template>
                          </tr>
                        </thead>
                        <tbody>
                          <template v-for="(value, key) in studentsCount">
                            <template v-for="(subValue, subKey) in value">
                              <tr
                                v-for="(subSubValue, subSubKey,index) in subValue"
                                :key="subSubKey"
                                :class="{
                                  'grade1': subKey === 'الفرقة الأولى' && index === 0,
                                  'grade2': subKey === 'الفرقة الثانية'&& index === 0,
                                  'grade3': subKey === 'الفرقة الثالثة' && index === 0,
                                  'grade4': subKey === 'الفرقة الرابعة' && index === 0,
                                  'grade1-2': subKey === 'الفرقة الأولى' && index === 1,
                                  'grade2-2': subKey === 'الفرقة الثانية' && index === 1,
                                  'grade3-2': subKey === 'الفرقة الثالثة' && index === 1,
                                  'grade4-2': subKey === 'الفرقة الرابعة' && index === 1,
                                  'grade1-3': subKey === 'الفرقة الأولى' && index === 2,
                                  'grade2-3': subKey === 'الفرقة الثانية' && index === 2,
                                  'grade3-3': subKey === 'الفرقة الثالثة' && index === 2,
                                  'grade4-3': subKey === 'الفرقة الرابعة' && index === 2,
                                  'grade3-4': subKey === 'الفرقة الثالثة' && index === 3,
                                      }">
                                <template v-if="subKey.includes('الفرقة')">
                                  <td v-if="subKey.includes('الفرقة')">
                                    {{ key }}
                                  </td>
                                  <td>
                                      {{ subKey }}
                                            </td>
                                </template>
                                <td
                                  v-if="subSubKey && subKey.includes('الفرقة')"
                                >
                                  {{ subSubKey }}
                                </td>
                                <td
                                  style="
                                    padding: 0 !important"
                                >
                                  <table
                                    style="
                                      width: 100%;
                                      border-collapse: collapse;
                                    "
                                  >
                                    <template
                                      v-if="subSubSubKey !== 'اجمالي الفرقه'"
                                    >
                                      <template
                                        v-for="(
                                          subSubSubValue, subSubSubKey
                                        ) in subSubValue"
                                        :key="subSubSubKey"
                                      >
                                        <tr style="border-width: 0 !important">
                                          <td
                                            v-if="
                                              subSubSubKey !== 'اجمالي الفرقه'
                                            "
                                            style="
                                              padding: 10px;
                                              border-width: 0 !important;
                                              border-top: 1px solid green !important;
                                              font-weight: bold;
                                            "
                                          >
                                            مج {{ subSubSubKey }}
                                          </td>
                                          <td
                                          v-else
                                            style="
                                              font-weight: bold;
                                              border-top: 1px solid green !important;
                                              padding:10px
                                            "
                                          >
                                            {{ subSubSubKey }}
                                          </td>
                                        </tr>
                                      </template>
                                    </template>
                                  </table>
                                </td>

                                <!-- eslint-disable-next-line vue/no-v-for-template-key -->
                                <template v-for="(n, index) in 4" :key="n">
                                  <td style="padding: 0 !important">
                                    <table
                                      style="
                                        width: 100%;
                                        border-collapse: collapse;
                                      "
                                    >
                                      <!-- eslint-disable-next-line vue/no-v-for-template-key -->
                                      <template
                                        v-if="
                                          subSubSubKey !== 'اجمالي الفرقه' &&
                                          subSubSubKey !== 'totals'
                                        "
                                      >
                                        <template
                                          v-for="(
                                            subSubSubValue, subSubSubKey
                                          ) in subSubValue"
                                          :key="subSubSubKey"
                                        >
                                          <!-- eslint-disable-next-line vue/no-use-v-if-with-v-for -->
                                          <tr
                                            v-if="
                                              subSubSubKey !==
                                                'اجمالي الفرقه' &&
                                              subSubSubKey !== 'totals'
                                            "
                                            style="
                                              border-width: 0 !important;
                                              border-top: 1px solid green !important;
                                            "
                                          >
                                            <template
                                              v-for="(
                                                subSubSubSubValue,
                                                subSubSubSubKey
                                              ) in subSubSubValue"
                                              :key="subSubSubSubKey"
                                            >
                                              <td
                                                v-if="
                                                  subSubSubSubKey ===
                                                  branchesCount[index]
                                                "
                                                style="
                                                  padding: 10px;
                                                  border-width: 0 !important;
                                                "
                                              >
                                                {{
                                                  subSubSubSubValue.regular_enrolled_count
                                                }}
                                              </td>
                                            </template>
                                          </tr>
                                        </template>
                                      </template>
                                      <template
                                        v-for="(
                                          subSubSubValue, subSubSubKey
                                        ) in subSubValue"
                                        :key="subSubSubKey"
                                      >
                                        <template
                                          v-if="
                                            subSubSubKey === 'اجمالي الفرقه'
                                          "
                                        >
                                          <template
                                            v-for="(
                                              subSubSubSubValue, subSubSubSubKey
                                            ) in subSubSubValue"
                                            :key="subSubSubSubKey"
                                          >
                                            <!-- eslint-disable-next-line vue/no-use-v-if-with-v-for -->
                                            <td
                                              class="total-tex"
                                              style="
                                                height: 44.5px;
                                                border-width: 0 !important;
                                                background: #dad6d669;
                                                font-weight: bold;
                                                padding:10px;
                                                letter-spacing: 0.8px;
                                              "
                                              v-if="
                                                subSubSubSubKey ===
                                                branchesCount[index]
                                              "
                                            >
                                              {{
                                                subSubSubSubValue.regular_enrolled_count
                                              }}
                                            </td>
                                          </template>
                                        </template>
                                      </template>
                                    </table>
                                  </td>
                                  <td style="padding: 0 !important">
                                    <table
                                      style="
                                        width: 100%;
                                        border-collapse: collapse;
                                      "
                                    >
                                      <template
                                        v-if="
                                          subSubSubKey !== 'اجمالي الفرقه' &&
                                          subSubSubKey !== 'totals'
                                        "
                                      >
                                        <template
                                          v-for="(
                                            subSubSubValue, subSubSubKey
                                          ) in subSubValue"
                                          :key="subSubSubKey"
                                        >
                                          <tr
                                            v-if="
                                              subSubSubKey !==
                                                'اجمالي الفرقه' &&
                                              subSubSubKey !== 'totals'
                                            "
                                            style="
                                              border-width: 0 !important;
                                              border-top: 1px solid green !important;
                                            "
                                          >
                                            <template
                                              v-for="(
                                                subSubSubSubValue,
                                                subSubSubSubKey
                                              ) in subSubSubValue"
                                              :key="subSubSubSubKey"
                                            >
                                              <td
                                                v-if="
                                                  subSubSubSubKey ===
                                                  branchesCount[index]
                                                "
                                                style="
                                                  padding: 10px;
                                                  border-width: 0 !important;
                                                "
                                              >
                                                {{
                                                  subSubSubSubValue.irregular_enrolled_count
                                                }}
                                              </td>
                                            </template>
                                          </tr>
                                        </template>
                                      </template>
                                      <!--  totals -->
                                      <template
                                        v-for="(
                                          subSubSubValue, subSubSubKey
                                        ) in subSubValue"
                                        :key="subSubSubKey"
                                      >
                                        <template
                                          v-if="
                                            subSubSubKey === 'اجمالي الفرقه'
                                          "
                                        >
                                          <template
                                            v-for="(
                                              subSubSubSubValue, subSubSubSubKey
                                            ) in subSubSubValue"
                                            :key="subSubSubSubKey"
                                          >
                                            <td
                                              class="total-tex"
                                              style="
                                                height: 44.5px;
                                                border-width: 0 !important;
                                                background: #dad6d669;
                                                font-weight: bold;
                                                padding:10px;
                                                letter-spacing: 0.8px;
                                              "
                                              v-if="
                                                subSubSubSubKey ===
                                                branchesCount[index]
                                              "
                                            >
                                              {{
                                                subSubSubSubValue.irregular_enrolled_count
                                              }}
                                            </td>
                                          </template>
                                        </template>
                                      </template>
                                    </table>
                                  </td>
                                  <td
                                    style="padding: 0 !important"
                                    v-if="subKey.includes('الفرقة')"
                                  >
                                    <table
                                      style="
                                        width: 100%;
                                        border-collapse: collapse;
                                      "
                                    >
                                      <template
                                        v-if="
                                          subSubSubKey !== 'اجمالي الفرقه' &&
                                          subSubSubKey !== 'totals'
                                        "
                                      >
                                        <template
                                          v-for="(
                                            subSubSubValue, subSubSubKey
                                          ) in subSubValue"
                                          :key="subSubSubKey"
                                        >
                                          <tr
                                            v-if="
                                              subSubSubKey !==
                                                'اجمالي الفرقه' &&
                                              subSubSubKey !== 'totals'
                                            "
                                            style="
                                              border-width: 0 !important;
                                              border-top: 1px solid green !important;
                                            "
                                          >
                                            <template
                                              v-for="(
                                                subSubSubSubValue,
                                                subSubSubSubKey
                                              ) in subSubSubValue"
                                              :key="subSubSubSubKey"
                                            >
                                              <td
                                                v-if="
                                                  subSubSubSubKey ===
                                                  branchesCount[index]
                                                "
                                                style="
                                                  padding: 10px;
                                                  border-width: 0 !important;
                                                "
                                              >
                                                {{
                                                  subSubSubSubValue.total_enrolled_count
                                                }}
                                              </td>
                                            </template>
                                          </tr>
                                        </template>
                                      </template>

                                      <template
                                        v-for="(
                                          subSubSubValue, subSubSubKey
                                        ) in subSubValue"
                                        :key="subSubSubKey"
                                      >
                                        <template
                                          v-if="
                                            subSubSubKey === 'اجمالي الفرقه'
                                          "
                                        >
                                          <template
                                            v-for="(
                                              subSubSubSubValue, subSubSubSubKey
                                            ) in subSubSubValue"
                                            :key="subSubSubSubKey"
                                          >
                                            <td
                                               class="total-tex"
                                              style="
                                                height: 44.5px;
                                                border-width: 0 !important;
                                                background: #dad6d669;
                                                font-weight: bold;
                                                padding:10px;
                                                letter-spacing: 0.8px;
                                              "
                                              v-if="
                                                subSubSubSubKey ===
                                                branchesCount[index]
                                              "
                                            >
                                              {{
                                                subSubSubSubValue.total_enrolled_count
                                              }}
                                            </td>
                                          </template>
                                        </template>
                                      </template>
                                    </table>
                                  </td>
                                </template>
                                <td
                                  style="padding: 0 !important"
                                  v-if="subKey.includes('الفرقة')"
                                >
                                  <table
                                    style="
                                      width: 100%;
                                      border-collapse: collapse;
                                    "
                                  >
                                    <template
                                      v-if="
                                        subSubSubKey !== 'اجمالي الفرقه' &&
                                        subSubSubKey !== 'totals'
                                      "
                                    >
                                      <template
                                        v-for="(
                                          subSubSubValue, subSubSubKey
                                        ) in subSubValue"
                                        :key="subSubSubKey"
                                      >
                                        <tr
                                          v-if="
                                            subSubSubKey !== 'اجمالي الفرقه' &&
                                            subSubSubKey !== 'totals'
                                          "
                                          style="
                                            border-width: 0 !important;
                                            border-top: 1px solid green !important;
                                          "
                                        >
                                          <template
                                            v-for="(
                                              subSubSubSubValue, subSubSubSubKey
                                            ) in subSubSubValue"
                                            :key="subSubSubSubKey"
                                          >
                                            <td
                                              v-if="
                                                subSubSubSubKey === 'totals'
                                              "
                                              style="
                                                padding: 10px;
                                                border-width: 0 !important;
                                              "
                                            >
                                              {{
                                                subSubSubSubValue.total_branches_count
                                              }}
                                            </td>
                                          </template>
                                        </tr>
                                      </template>
                                    </template>
                                    <tr>
                                      <template
                                        v-for="(
                                          subSubSubValue, subSubSubKey
                                        ) in subSubValue"
                                        :key="subSubSubKey"
                                      >
                                        <td
                                          class="totals-td"
                                          v-if="
                                            subSubSubKey === 'اجمالي الفرقه'
                                          "
                                        >
                                          {{
                                            subSubValue["اجمالي الفرقه"][
                                              "totals"
                                            ]["total_branches_count"]
                                          }}
                                        </td>
                                      </template>
                                    </tr>
                                  </table>
                                </td>
                                <td
                                  style="padding: 0 !important"
                                  v-if="subKey.includes('الفرقة')"
                                >
                                  <table
                                    style="
                                      width: 100%;
                                      border-collapse: collapse;
                                    "
                                  >
                                    <template
                                      v-if="
                                        subSubSubKey !== 'اجمالي الفرقه' &&
                                        subSubSubKey !== 'totals'
                                      "
                                    >
                                      <template
                                        v-for="(
                                          subSubSubValue, subSubSubKey
                                        ) in subSubValue"
                                        :key="subSubSubKey"
                                      >
                                        <tr
                                          v-if="
                                            subSubSubKey !== 'اجمالي الفرقه' &&
                                            subSubSubKey !== 'totals'
                                          "
                                          style="
                                            border-width: 0 !important;
                                            border-top: 1px solid green !important;
                                          "
                                        >
                                          <template
                                            v-for="(
                                              subSubSubSubValue, subSubSubSubKey
                                            ) in subSubSubValue"
                                            :key="subSubSubSubKey"
                                          >
                                            <td
                                              v-if="
                                                subSubSubSubKey === 'totals'
                                              "
                                              style="
                                                padding: 10px;
                                                border-width: 0 !important;
                                              "
                                            >
                                              {{
                                                subSubSubSubValue.total_irregular_branches_count
                                              }}
                                            </td>
                                          </template>
                                        </tr>
                                      </template>
                                    </template>
                                    <tr>
                                      <template
                                        v-for="(
                                          subSubSubValue, subSubSubKey
                                        ) in subSubValue"
                                        :key="subSubSubKey"
                                      >
                                        <td
                                          class="totals-td"
                                          v-if="
                                            subSubSubKey === 'اجمالي الفرقه'
                                          "
                                        >
                                          {{
                                            subSubValue["اجمالي الفرقه"][
                                              "totals"
                                            ]["total_irregular_branches_count"]
                                          }}
                                        </td>
                                      </template>
                                    </tr>
                                  </table>
                                </td>
                                <td
                                  style="padding: 0 !important"
                                  v-if="subKey.includes('الفرقة')"
                                >
                                  <table
                                    style="
                                      width: 100%;
                                      border-collapse: collapse;
                                    "
                                  >
                                    <template
                                      v-if="
                                        subSubSubKey !== 'اجمالي الفرقه' &&
                                        subSubSubKey !== 'totals'
                                      "
                                    >
                                      <template
                                        v-for="(
                                          subSubSubValue, subSubSubKey
                                        ) in subSubValue"
                                        :key="subSubSubKey"
                                      >
                                        <tr
                                          v-if="
                                            subSubSubKey !== 'اجمالي الفرقه' &&
                                            subSubSubKey !== 'totals'
                                          "
                                          style="
                                            border-width: 0 !important;
                                            border-top: 1px solid green !important;
                                          "
                                        >
                                          <template
                                            v-for="(
                                              subSubSubSubValue, subSubSubSubKey
                                            ) in subSubSubValue"
                                            :key="subSubSubSubKey"
                                          >
                                            <td
                                              v-if="
                                                subSubSubSubKey === 'totals'
                                              "
                                              style="
                                                padding: 10px;
                                                border-width: 0 !important;
                                              "
                                            >
                                              {{
                                                subSubSubSubValue.total_regular_branches_count
                                              }}
                                            </td>
                                          </template>
                                        </tr>
                                      </template>
                                    </template>
                                    <tr>
                                      <template
                                        v-for="(
                                          subSubSubValue, subSubSubKey
                                        ) in subSubValue"
                                        :key="subSubSubKey"
                                      >
                                        <td
                                          class="totals-td"
                                          v-if="
                                            subSubSubKey === 'اجمالي الفرقه'
                                          "
                                        >
                                          {{
                                            subSubValue["اجمالي الفرقه"][
                                              "totals"
                                            ]["total_regular_branches_count"]
                                          }}
                                        </td>
                                      </template>
                                    </tr>
                                  </table>
                                </td>
                                <td
                                  style="padding: 0 !important"
                                  v-if="subKey.includes('الفرقة')"
                                >
                                  <table
                                    style="
                                      width: 100%;
                                      border-collapse: collapse;
                                    "
                                  >
                                    <template
                                      v-if="
                                        subSubSubKey !== 'اجمالي الفرقه' &&
                                        subSubSubKey !== 'totals'
                                      "
                                    >
                                      <template
                                        v-for="(
                                          subSubSubValue, subSubSubKey
                                        ) in subSubValue"
                                        :key="subSubSubKey"
                                      >
                                        <tr
                                          v-if="
                                            subSubSubKey !== 'اجمالي الفرقه' &&
                                            subSubSubKey !== 'totals'
                                          "
                                          style="
                                            border-width: 0 !important;
                                            border-top: 1px solid green !important;
                                          "
                                        >
                                          <template
                                            v-for="(
                                              subSubSubSubValue, subSubSubSubKey
                                            ) in subSubSubValue"
                                            :key="subSubSubSubKey"
                                          >
                                            <td
                                              v-if="
                                                subSubSubSubKey === 'totals'
                                              "
                                              style="
                                                padding: 10px;
                                                border-width: 0 !important;
                                              "
                                            >
                                              {{
                                                subSubSubSubValue.total_online
                                              }}
                                            </td>
                                          </template>
                                        </tr>
                                      </template>
                                    </template>
                                    <tr>
                                      <template
                                        v-for="(
                                          subSubSubValue, subSubSubKey
                                        ) in subSubValue"
                                        :key="subSubSubKey"
                                      >
                                        <td
                                          class="totals-td"
                                          v-if="
                                            subSubSubKey === 'اجمالي الفرقه'
                                          "
                                        >
                                          {{
                                            subSubValue["اجمالي الفرقه"][
                                              "totals"
                                            ]["total_online"]
                                          }}
                                        </td>
                                      </template>
                                    </tr>
                                  </table>
                                </td>
                                <td
                                  style="padding: 0 !important"
                                  v-if="subKey.includes('الفرقة')"
                                >
                                  <table
                                    style="
                                      width: 100%;
                                      border-collapse: collapse;
                                    "
                                  >
                                    <template
                                      v-if="
                                        subSubSubKey !== 'اجمالي الفرقه' &&
                                        subSubSubKey !== 'totals'
                                      "
                                    >
                                      <template
                                        v-for="(
                                          subSubSubValue, subSubSubKey
                                        ) in subSubValue"
                                        :key="subSubSubKey"
                                      >
                                        <tr
                                          v-if="
                                            subSubSubKey !== 'اجمالي الفرقه' &&
                                            subSubSubKey !== 'totals'
                                          "
                                          style="
                                            border-width: 0 !important;
                                            border-top: 1px solid green !important;
                                          "
                                        >
                                          <template
                                            v-for="(
                                              subSubSubSubValue, subSubSubSubKey
                                            ) in subSubSubValue"
                                            :key="subSubSubSubKey"
                                          >
                                            <td
                                              v-if="
                                                subSubSubSubKey === 'totals'
                                              "
                                              style="
                                                padding: 10px;
                                                border-width: 0 !important;
                                              "
                                            >
                                              {{ subSubSubSubValue.total }}
                                            </td>
                                          </template>
                                        </tr>
                                      </template>
                                    </template>
                                    <tr>
                                      <template
                                        v-for="(
                                          subSubSubValue, subSubSubKey
                                        ) in subSubValue"
                                        :key="subSubSubKey"
                                      >
                                        <td
                                          class="totals-td"
                                          v-if="
                                            subSubSubKey === 'اجمالي الفرقه'
                                          "
                                        >
                                          {{
                                            subSubValue["اجمالي الفرقه"][
                                              "totals"
                                            ]["total"]
                                          }}
                                        </td>
                                      </template>
                                    </tr>
                                  </table>
                                </td>
                                <td v-if="subKey.includes('الفرقة')">
                                    {{ key }}
                                  </td>
                              </tr>
                            </template>
                          </template>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>

              <!-- seconed tab -->
              <div
                class="tab-pane fade show"
                id="profile"
                role="tabpanel"
                aria-labelledby="profile-tab"
              >
                <!-- branches details -->
                <h5 class="text-center mr-bt">إجمالي الفروع</h5>
                <div class="row" style="margin-bottom: 40px">
                  <div
                    style="margin-bottom: 40px"
                    class="col-12"
                    v-for="(val, key) in branchesDetails"
                    :key="key"
                  >
                    <div
                      class="text-center col-lg-12 overflow-x"
                      style="direction: rtl"
                    >
                      <table class="b-block table" style="direction: rtl">
                        <thead>
                          <tr>
                            <th colspan="3">{{ key }}</th>
                            <th
                              v-for="(val, index) in branchesCount"
                              :key="index"
                              colspan="2"
                            >
                              {{ val }}
                            </th>
                          </tr>
                          <tr>
                            <th>الصافي</th>
                            <th>إجمالي الدخول</th>
                            <th>المادة</th>
                            <template v-for="n in 4" :key="n">
                              <th>اجمالي الدخول</th>
                              <th>الصافي</th>
                            </template>
                          </tr>
                        </thead>
                        <tbody :class="{
                                  'grade1': key === 'الفرقة الأولى' ,
                                  'grade2': key === 'الفرقة الثانية',
                                  'grade3': key === 'الفرقة الثالثة',
                                  'grade4': key === 'الفرقة الرابعة'}">
                          <tr
                            v-for="(subVal, subKey) in val"
                            :key="subKey"
                            :class="{ 'total-back': subKey === 'totals' }"
                          >
                            <td>
                              {{
                                subKey !== "totals"
                                  ? subVal.regular_enrolled_count
                                  : subVal.total_regular_grade_count
                              }}
                            </td>
                            <td>
                              {{
                                subKey !== "totals"
                                  ? subVal.total_enrolled_count
                                  : subVal.total_enrolled_grade_count
                              }}
                            </td>
                            <td>
                              {{ subKey !== "totals" ? subKey : "الإجمالي" }}
                            </td>
                            <template
                              v-for="(n, index) in branchesCount"
                              :key="index"
                            >
                              <template
                                v-for="(subSubVal, subSubKey) in subVal"
                                :key="subSubKey"
                              >
                                <td
                                  v-if="
                                    branchesCount.includes(subSubKey) &&
                                    subSubKey === branchesCount[index]
                                  "
                                >
                                  {{ subSubVal.total_enrolled_count }}
                                </td>
                                <td
                                  v-if="
                                    branchesCount.includes(subSubKey) &&
                                    subSubKey === branchesCount[index]
                                  "
                                >
                                  {{ subSubVal.regular_enrolled_count }}
                                </td>
                              </template>
                            </template>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <!-- grades details -->
                <h5 class="text-center mr-bt">إجمالي الفرق</h5>
                <div class="row">
                  <div
                    class="col-6"
                    v-for="(val, key) in gradesDetails"
                    :key="key"
                  >
                    <div
                      class="text-center col-lg-12 overflow-x"
                      style="direction: rtl"
                    >
                      <table class="b-block table" style="direction: rtl">
                        <thead>
                          <tr>
                            <th colspan="3">{{ key }}</th>
                          </tr>
                          <tr>
                            <th>الصافي</th>
                            <th>إجمالي الدخول</th>
                            <th>المادة</th>
                          </tr>
                        </thead>
                        <tbody
                        :class="{
                                  'grade1': key === 'الفرقة الأولى' ,
                                  'grade2': key === 'الفرقة الثانية',
                                  'grade3': key === 'الفرقة الثالثة',
                                  'grade4': key === 'الفرقة الرابعة'}">
                          <tr
                            v-for="(subVal, subKey) in val"
                            :key="subKey"
                            :class="{ 'total-back': subKey === 'totals' }"
                          >
                            <td>
                              {{
                                subKey !== "totals"
                                  ? subVal.regular_enrolled_count
                                  : subVal.total_regular_grade_count
                              }}
                            </td>
                            <td>
                              {{
                                subKey !== "totals"
                                  ? subVal.total_enrolled_count
                                  : subVal.total_enrolled_grade_count
                              }}
                            </td>
                            <td>
                              {{ subKey !== "totals" ? subKey : "الإجمالي" }}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <!-- third tab -->
              <div
                class="tab-pane fade show"
                id="totals"
                role="tabpanel"
                aria-labelledby="totals-tab"
              >
                <GradesOfflineOnline :branchesDetails="branchesDetails" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import VmdButton from '@/components/VmdButton.vue'
import GradesOfflineOnline from '@/components/GradesOfflineOnline.vue'
import VmdInput from '@/components/VmdInput.vue'
import $ from 'jquery'
import exportFromJSON from 'export-from-json'
const today = new Date()
const date =
  today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate()
const time =
  today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds()
const CurrentDateTime = date + ' ' + time
export default {
  name: 'NumbersReport',
  components: {
    VmdButton,
    VmdInput,
    GradesOfflineOnline
  },
  data () {
    return {
      count: 0,
      studentsCount: [],
      totalOffersPayments: 0,
      totalCoursesPayments: 0,
      totalSubjectPayments: 0,
      excelFile: [],
      firstSectionSubSubSubKeys: [],
      amount: '',
      branchesCount: ['العباسية', 'الزعفران', 'شبرا', 'اونلاين'],
      gradesDetails: {},
      branchesDetails: {},
      gradesList: [],
      selectedGrade: '',
      groupsList: [],
      selectedGroup: '',
      instructors: [],
      selectedInstructor: '',
      gradesTab: false,
      selectedSemester: '2',
      selectedYear: '75b8039d-4bc9-11ee-bd6a-f23c936035ca',
      academicYear: [],
      errors: []
    }
  },
  created () {
    this.$http.get(`${this.$hostUrl}report/students-count?semester=${this.selectedSemester || ''}&academic_year_id=${this.selectedYear || ''}`).then((response) => {
      this.studentsCount = response.data.data
    })
    this.$http.get(`${this.$hostUrl}grades`).then(response => {
      this.gradesList = response.data.data.map(val => ({
        id: val.id,
        name: val.name
      }))
    })
    this.$http.get(`${this.$hostUrl}groups`).then(response => {
      this.groupsList = response.data.data.map(val => ({
        id: val.id,
        name: val.name
      }))
    })
    this.$http.get(`${this.$hostUrl}instructors`).then(response => {
      this.instructors = response.data.data.map(val => ({
        id: val.id,
        name: val.name
      }))
    })
    this.$http.get(`${this.$hostUrl}academic-years`).then((response) => {
      this.academicYear = response.data.data.map(val => ({
        id: val.id,
        name: val.name
      }))
    })
  },
  computed: {},
  methods: {
    getGradesDetails () {
      this.gradesTab = true
      this.$http
        .get(
          `${this.$hostUrl}report/grades/students/count?amount=${this.amount}&by_grade=1&semester=${this.selectedSemester || ''}&academic_year_id=${this.selectedYear || ''}`
        )
        .then((response) => {
          this.gradesDetails = response.data.data
        })
      this.$http
        .get(
          `${this.$hostUrl}report/branches/students/count?amount=${this.amount}&by_grade=1&semester=${this.selectedSemester || ''}&academic_year_id=${this.selectedYear || ''}`
        )
        .then((response) => {
          this.branchesDetails = response.data.data
        })
    },
    getBranchedDetails () {
      this.$http
        .get(
          `${this.$hostUrl}report/branches/students/count?amount=${this.amount}&by_grade=1&semester=${this.selectedSemester || ''}&academic_year_id=${this.selectedYear || ''}&grade_id=${this.selectedGrade?.id || ''}`
        )
        .then((response) => {
          this.branchesDetails = response.data.data
        })
    },
    filterData () {
      this.$http
        .get(`${this.$hostUrl}report/students-count?amount=${this.amount}&grade_id=${this.selectedGrade?.id || ''}&group_id=${this.selectedGroup?.id || ''}&instructor_id=${this.selectedInstructor?.id || ''}&semester=${this.selectedSemester || ''}&academic_year_id=${this.selectedYear || ''}`)
        .then((response) => {
          this.studentsCount = response.data.data
        })
      if (this.gradesTab) {
        this.$http
          .get(
          `${this.$hostUrl}report/grades/students/count?amount=${this.amount}&by_grade=1&semester=${this.selectedSemester || ''}&academic_year_id=${this.selectedYear || ''}&grade_id=${this.selectedGrade?.id || ''}`
          )
          .then((response) => {
            this.gradesDetails = response.data.data
          })
        this.$http
          .get(
          `${this.$hostUrl}report/branches/students/count?amount=${this.amount}&by_grade=1&semester=${this.selectedSemester || ''}&academic_year_id=${this.selectedYear || ''}&grade_id=${this.selectedGrade?.id || ''}`
          )
          .then((response) => {
            this.branchesDetails = response.data.data
          })
      }
    },
    printReport () {
      // $('.card-footer').hide()
      const options = {
        name: ' Seven Eleven System ',
        specs: ['fullscreen=yes', 'titlebar=yes', 'scrollbars=yes'],
        styles: [
          'https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.rtl.min.css',
          `${window.location.origin}/print-report.css`
        ],
        timeout: 1000, // default timeout before the print window appears
        autoClose: true, // if false, the window will not close after printing
        windowTitle: 'Seven Eleven System' // override the window title
      }

      setTimeout(this.$htmlToPaper('print-report', options), 10000)
      $('.card-footer').show()
    },
    downloadFile () {
      $.each(this.subjectsPayments, (key, val) => {
        this.excelFile.push({
          المادة: val.name,
          مدفوعات_الدورة: val.course_payments,
          مدفوعات_العرض: val.payments,
          اجمالي_مدفوعات_المادة: val.subject_payments
        })
      })
      const data = this.excelFile
      const fileName = `تقرير  الأعداد ${CurrentDateTime}`
      const exportType = exportFromJSON.types.xls
      if (data) exportFromJSON({ data, fileName, exportType })
    }
  }
}
</script>
<style>
#collection-date {
  font-weight: 800 !important;
  font-size: 18px !important;
}

.table > :not(:first-child) {
  border-bottom: 1px solid #f0f2f5 !important;
}

.width {
  width: 100%;
}

.online {
  border: 1px solid #ddd;
}

.times {
  margin-top: 10px !important;
}

.times h5 {
  font-size: 16px !important;
}

.times .red {
  position: relative !important;
}

.filter-text {
  font-weight: 700;
  color: #dfad07;
  font-size: 15px !important;
}

.tab2 {
  margin-top: 27px !important;
  margin-bottom: 41px !important;
  border-bottom: 1px solid #ddd;
  padding-bottom: 26px !important;
}

.course-info {
  background: #f8f9fa;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 38px;
  font-size: 14px;
}

.info-h {
  font-size: 14px !important;
  font-weight: 500;
}
.totals-td {
  height: 44.5px !important;
  border-width: 0 !important;
  background: #dad6d669 !important;
  font-weight: bold !important;
}

.groups-ul {
  background: #fff;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 206px;
  border-top: 2px solid #5cb360;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 10%), 0 2px 4px -1px rgb(0 0 0 / 6%);
  display: none;
  position: absolute;
  border-radius: 10px;
}

/* .groups-con{
  color:blue;
  cursor: pointer;
} */
.groups-con:hover .groups-ul {
  display: block;
}

.info-data {
  font-family: "Roboto", Helvetica, Arial, sans-serif !important;
  font-weight: bold !important;
  font-size: 17px !important;
}

.groups-row {
  display: flex;
  padding: 0 !important;
  border-top: 0;
  border-bottom: 1px solid #5cb360 !important;
}

.column {
  width: 25%;
  /* set width of each column to 25% */
  /* display columns inline */
  box-sizing: border-box;
  /* include padding and border in width */
  padding: 10px;
  /* add some padding to each column */
}
.mr-bt {
  margin-bottom: 20px !important;
}
.total-back {
  background: #dad6d669 !important;
  font-weight: bold !important;
}
.grade1{
  background-color: #f8cbac !important
}
.grade2{
  background-color:#adb8ca !important;
}
.grade3{
  background-color: #aaa6aa !important;
}
.grade4{
  background-color:#c4e1b5 !important;
}
.grade1-2{
  background-color:#efbc98
}
.grade2-2{
  background-color: #98abc9;
}
.grade3-2{
  background-color: #b9b6b9;
}
.grade4-2{
  background-color: #b3dd9d;
}
.grade1-3{
  background-color:#efb993
}
.grade2-3{
  background-color: #8da3c5;
}
.grade3-3{
  background-color: #c0bcc0d9;
}
.grade4-3{
  background-color: #a1db83;
}
/* four case */
.grade3-4{
  background-color: #c0bcc0a8;
}
</style>
